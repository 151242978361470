import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {"context":{"clientName":"super-graph"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DateTimeISO: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
  Timezone: { input: any; output: any; }
};

export type Activity = {
  /** [CHAIN] Notification list, any timestamp is in Europe/Stockholm timezone. */
  notificationList: Scalars['String']['output'];
};

export type Actor = {
  actorMachineRelationships?: Maybe<Array<ActorMachineRelationship>>;
  childActorRelationships?: Maybe<Array<ActorActorRelationship>>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentActorRelationships?: Maybe<Array<ActorActorRelationship>>;
};

export type ActorActorRelationship = {
  childActor: Actor;
  childActorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  parentActor: Actor;
  parentActorId: Scalars['ID']['output'];
};

export type ActorConnection = {
  edges: Array<ActorEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ActorEdge = {
  cursor: Scalars['String']['output'];
  node: Actor;
};

export type ActorMachineConnection = {
  edges: Array<ActorMachineEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ActorMachineEdge = {
  cursor: Scalars['String']['output'];
  node: Machine;
};

export type ActorMachineQueries = {
  byUserActorsIds: Array<Machine>;
  machine: Machine;
  machines: ActorMachineConnection;
};


export type ActorMachineQueries_MachineArgs = {
  id: Scalars['ID']['input'];
};


export type ActorMachineQueries_MachinesArgs = {
  filter?: InputMaybe<SearchFilterInput>;
};

export type ActorMachineRelationship = {
  isDefaultMasterType: Scalars['Boolean']['output'];
  machine: Machine;
  machineId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type ActorMutations = {
  createActorActorRelationship: Scalars['String']['output'];
};


export type ActorMutations_CreateActorActorRelationshipArgs = {
  input: CreateActorActorRelationshipInput;
};

export type ActorQueries = {
  actor: Actor;
  actorByExternalId: Actor;
  actors: ActorConnection;
};


export type ActorQueries_ActorArgs = {
  id: Scalars['ID']['input'];
};


export type ActorQueries_ActorByExternalIdArgs = {
  externalId: Scalars['ID']['input'];
};


export type ActorQueries_ActorsArgs = {
  filter?: InputMaybe<SearchFilterInput>;
};

export type ActorUserConnection = {
  edges: Array<ActorUserEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ActorUserEdge = {
  cursor: Scalars['String']['output'];
  node: User;
};

export type ActorUserQueries = {
  user: User;
  /** Get first user matching in the order of equal id, equal externalId (case insensitive) or equal email (case insensitive) */
  userByFilter: User;
  users: ActorUserConnection;
};


export type ActorUserQueries_UserArgs = {
  id: Scalars['ID']['input'];
};


export type ActorUserQueries_UserByFilterArgs = {
  filter: UserFilterInput;
};


export type ActorUserQueries_UsersArgs = {
  filter?: InputMaybe<SearchFilterInput>;
};

export type AddOrganizationSubscriptionInput = {
  /** CPMS API credentials (API key/Subscription key/Token) */
  credentials: Scalars['String']['input'];
  /** Preferrably same as the name of the Organization */
  name: Scalars['String']['input'];
  /** Identifier for the organization which should be related to a subscription */
  organizationId: Scalars['String']['input'];
  source: IngestionSource;
};

export enum AlarmType {
  Cea2PlusDtc = 'Cea2PlusDtc',
  Tea2Alarm = 'Tea2Alarm',
  Tea2BridgeDtc = 'Tea2BridgeDtc'
}

export type AllowedMachineTypesInput = {
  machineTypeIds: Array<Scalars['ID']['input']>;
  siteId: Scalars['ID']['input'];
};

export type AppStartupEventPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  isInsideSiteBoundary: Scalars['Boolean']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
  weightUnit?: InputMaybe<Scalars['String']['input']>;
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type Application = {
  applicationId: Scalars['ID']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  scopes: Array<Scalars['String']['output']>;
  source: Scalars['String']['output'];
};

export type ApplicationFilter = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationInput = {
  filter: ApplicationFilter;
};

export type ApplicationMutations = {
  create: Application;
  delete: Scalars['Boolean']['output'];
  update: Application;
};


export type ApplicationMutations_CreateArgs = {
  input: CreateApplicationInput;
};


export type ApplicationMutations_DeleteArgs = {
  application: Scalars['String']['input'];
};


export type ApplicationMutations_UpdateArgs = {
  input: UpdateApplicationInput;
};

export type ApplicationQueries = {
  applications: Array<Application>;
};


export type ApplicationQueries_ApplicationsArgs = {
  input?: InputMaybe<ApplicationInput>;
};

/** The available sound settings for the app */
export enum ApplicationSounds {
  High = 'HIGH',
  Low = 'LOW',
  Mute = 'MUTE',
  Tablet = 'TABLET'
}

/** Possible types of external service inputs */
export enum AssetManagerExternalServiceInput {
  Assist = 'ASSIST',
  Flintab = 'FLINTAB',
  Hiboo = 'HIBOO',
  Hibooapi = 'HIBOOAPI',
  Ip21 = 'IP21',
  Loadrite = 'LOADRITE',
  Manuallycreated = 'MANUALLYCREATED',
  Sebadata = 'SEBADATA',
  Siteoperations = 'SITEOPERATIONS',
  Smarttonnes = 'SMARTTONNES',
  Tamtron = 'TAMTRON',
  Unknown = 'UNKNOWN',
  Vei = 'VEI'
}

export type AssetManagerInvitation = {
  createdAt: Scalars['DateTime']['output'];
  deniedReason?: Maybe<Scalars['String']['output']>;
  expires: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invitedBy: Scalars['String']['output'];
  invitedEmail: Scalars['String']['output'];
  siteId: Scalars['String']['output'];
  siteName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type AssetManagerInvitationConnection = {
  edges: Array<AssetManagerInvitationEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AssetManagerInvitationEdge = {
  cursor: Scalars['String']['output'];
  node: AssetManagerInvitation;
};

export type AssetManagerInvitationFilterInput = {
  excludeDenied?: InputMaybe<Scalars['Boolean']['input']>;
  invitedBy?: InputMaybe<Scalars['String']['input']>;
  invitedEmail?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type AssetManagerInvitationMutations = {
  /** *Requires authentication* */
  create: AssetManagerInvitation;
  /** *Requires authentication* */
  deleteInvitation: AssetManagerInvitation;
  /** *Requires authentication* */
  resendInvitation: AssetManagerInvitation;
  /** *Requires authentication* */
  update: AssetManagerInvitation;
};


export type AssetManagerInvitationMutations_CreateArgs = {
  input: CreateAssetManagerInvitationInput;
};


export type AssetManagerInvitationMutations_DeleteInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type AssetManagerInvitationMutations_ResendInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type AssetManagerInvitationMutations_UpdateArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAssetManagerInvitationInput;
};

export type AssetManagerInvitationOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type AssetManagerInvitationQueries = {
  /** *Requires authentication* */
  userInvitations?: Maybe<AssetManagerInvitationConnection>;
};


export type AssetManagerInvitationQueries_UserInvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AssetManagerInvitationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssetManagerInvitationOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Type of relationship between org and asset */
export enum AssetRelationshipType {
  Dealer = 'Dealer',
  Operates = 'Operates',
  Owns = 'Owns',
  WarrantyDealer = 'WarrantyDealer'
}

/** Type of asset */
export enum AssetType {
  Hiboo = 'Hiboo',
  Machine = 'Machine'
}

export enum AssetUsageStatisticsField {
  NumberOfUsedLicenses = 'NUMBER_OF_USED_LICENSES'
}

export type AssetUsageStatisticsFilterBy = {
  source?: InputMaybe<InvoicingAssetUsageSourcesFilter>;
};

export enum AssetUsageStatisticsGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type AssistApp = {
  bundleIdentifier: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type AssistAppConnection = {
  edges: Array<AssistAppEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AssistAppEdge = {
  cursor: Scalars['String']['output'];
  node: AssistApp;
};

export type AssistAppFilter = {
  and?: InputMaybe<Array<AssistAppFilter>>;
  bundleIdentifier?: InputMaybe<StringFilterClass>;
  createdAt?: InputMaybe<DateFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  isEnabled?: InputMaybe<BoolFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<AssistAppFilter>>;
  version?: InputMaybe<StringFilterClass>;
};

export type AssistAppSort = {
  id?: InputMaybe<SortDirection>;
};

export type AssistCreateGroupAsUserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  /** Invitations to be created to the group either clients or users */
  invitations?: InputMaybe<Array<AssistGroupsInvitationInput>>;
  name: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AssistCreateGroupGeoidRelationshipInput = {
  geoidId: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
};

export type AssistCreateGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  /** Invitations to be created to the group either clients or users */
  invitations?: InputMaybe<Array<AssistGroupsInvitationInput>>;
  name: Scalars['String']['input'];
};

export type AssistCreateGroupProjectionRelationshipInput = {
  groupId: Scalars['String']['input'];
  projectionId: Scalars['String']['input'];
};

export type AssistCreateProjectInput = {
  /** The id of the geoid to be associated with the project (added to the group relation in assist-groups). (Has to exist in vce-assist-geosystem) */
  geoidId: Scalars['String']['input'];
  /** Input data for the group to be created and associated with the project */
  group: AssistCreateGroupInput;
  /** The id of the projection to be associated with the project (added to the group relation in assist-groups). (Has to exist in vce-assist-geosystem) */
  projectionId: Scalars['String']['input'];
};

export type AssistDeleteGroupAsUserInput = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AssistGeoid = {
  fileReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  revision: Scalars['Float']['output'];
};

export type AssistGeoidConnection = {
  edges: Array<AssistGeoidEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AssistGeoidEdge = {
  cursor: Scalars['String']['output'];
  node: AssistGeoid;
};

export type AssistGeoidFilter = {
  and?: InputMaybe<Array<AssistGeoidFilter>>;
  fileReference?: InputMaybe<IdFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  modifiedAt?: InputMaybe<DateFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<AssistGeoidFilter>>;
  revision?: InputMaybe<IntFilterClass>;
};

export type AssistGeoidSort = {
  fileReference?: InputMaybe<SortDirection>;
  modifiedAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  revision?: InputMaybe<SortDirection>;
};

export type AssistGeoidsQueries = {
  /** Provides a list of geoids based on the optional filter provided.Without filter, all geoids are returned. */
  geoids: AssistGeoidConnection;
};


export type AssistGeoidsQueries_GeoidsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AssistGeoidFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssistGeoidSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type AssistGroup = {
  clients: Array<AssistGroupClientRelationship>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  geoidRelationship?: Maybe<AssistGroupGeoidRelationship>;
  id: Scalars['ID']['output'];
  invitations: Array<AssistInvitation>;
  isDeleted: Scalars['Boolean']['output'];
  models?: Maybe<Array<AssistModel>>;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  projectionRelationship?: Maybe<AssistGroupProjectionRelationship>;
  users: Array<Scalars['String']['output']>;
};


export type AssistGroup_GeoidRelationshipArgs = {
  filterBy?: InputMaybe<AssistGroupGeoidRelationshipFilter>;
};


export type AssistGroup_ModelsArgs = {
  filterBy?: InputMaybe<AssistModelFilter>;
};


export type AssistGroup_ProjectionRelationshipArgs = {
  filterBy?: InputMaybe<AssistGroupProjectionRelationshipFilter>;
};

export type AssistGroupClientRelationship = {
  clientId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  groupId: Scalars['ID']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
};

export type AssistGroupConnection = {
  edges: Array<AssistGroupEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AssistGroupEdge = {
  cursor: Scalars['String']['output'];
  node: AssistGroup;
};

export type AssistGroupFilter = {
  and?: InputMaybe<Array<AssistGroupFilter>>;
  createdAt?: InputMaybe<DateFilterClass>;
  createdBy?: InputMaybe<StringFilterClass>;
  deletedAt?: InputMaybe<DateFilterClass>;
  deletedBy?: InputMaybe<StringFilterClass>;
  description?: InputMaybe<StringFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  isDeleted?: InputMaybe<BoolFilterClass>;
  modifiedAt?: InputMaybe<DateFilterClass>;
  modifiedBy?: InputMaybe<StringFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<AssistGroupFilter>>;
};

export type AssistGroupGeoidRelationship = {
  geoid: AssistGeoid;
  modifiedAt: Scalars['DateTime']['output'];
};


export type AssistGroupGeoidRelationship_GeoidArgs = {
  filterBy?: InputMaybe<AssistGeoidFilter>;
};

export type AssistGroupGeoidRelationshipFilter = {
  and?: InputMaybe<Array<AssistGroupGeoidRelationshipFilter>>;
  modifiedAt?: InputMaybe<DateFilterClass>;
  or?: InputMaybe<Array<AssistGroupGeoidRelationshipFilter>>;
};

export type AssistGroupProjectionRelationship = {
  modifiedAt: Scalars['DateTime']['output'];
  projection: AssistProjection;
};


export type AssistGroupProjectionRelationship_ProjectionArgs = {
  filterBy?: InputMaybe<AssistProjectionFilter>;
};

export type AssistGroupProjectionRelationshipFilter = {
  and?: InputMaybe<Array<AssistGroupProjectionRelationshipFilter>>;
  modifiedAt?: InputMaybe<DateFilterClass>;
  or?: InputMaybe<Array<AssistGroupProjectionRelationshipFilter>>;
};

export type AssistGroupRelationsFilter = {
  and?: InputMaybe<Array<AssistGroupFilter>>;
  clients?: InputMaybe<StringArrayFilterClass>;
  createdAt?: InputMaybe<DateFilterClass>;
  createdBy?: InputMaybe<StringFilterClass>;
  deletedAt?: InputMaybe<DateFilterClass>;
  deletedBy?: InputMaybe<StringFilterClass>;
  description?: InputMaybe<StringFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  isDeleted?: InputMaybe<BoolFilterClass>;
  modifiedAt?: InputMaybe<DateFilterClass>;
  modifiedBy?: InputMaybe<StringFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<AssistGroupFilter>>;
  users?: InputMaybe<StringArrayFilterClass>;
};

export type AssistGroupSort = {
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  modifiedAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type AssistGroupsClientInvitationInput = {
  /** The chassis id of the machine to be invited to the group such as A25D123123 */
  chassisId: Scalars['String']['input'];
};

export type AssistGroupsInvitationInput = {
  /** A client to be invited a invitation can be either a client (or a user invitation (TODO)) */
  client?: InputMaybe<AssistGroupsClientInvitationInput>;
};

export type AssistGroupsMutations = {
  _key?: Maybe<Scalars['String']['output']>;
  create: AssistGroup;
  /** Create a group as from the user id provided. (requires assist:groups:write or assist:groups:manage scope) */
  createAsUser: AssistGroup;
  /** Creates a new group geoid relationship. */
  createGroupGeoidRelationship: AssistGroupGeoidRelationship;
  /** Creates a new group projection relationship. */
  createGroupProjectionRelationship: AssistGroupProjectionRelationship;
  delete: Scalars['Boolean']['output'];
  deleteAsUser: Scalars['Boolean']['output'];
  /** Deletes an existing group geoid relationship. */
  deleteGroupGeoidRelationship: Scalars['Boolean']['output'];
  /** Deletes an existing group projection relationship. */
  deleteGroupProjectionRelationship: Scalars['Boolean']['output'];
};


export type AssistGroupsMutations_CreateArgs = {
  input: AssistCreateGroupInput;
};


export type AssistGroupsMutations_CreateAsUserArgs = {
  input: AssistCreateGroupAsUserInput;
};


export type AssistGroupsMutations_CreateGroupGeoidRelationshipArgs = {
  input: AssistCreateGroupGeoidRelationshipInput;
};


export type AssistGroupsMutations_CreateGroupProjectionRelationshipArgs = {
  input: AssistCreateGroupProjectionRelationshipInput;
};


export type AssistGroupsMutations_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type AssistGroupsMutations_DeleteAsUserArgs = {
  input: AssistDeleteGroupAsUserInput;
};


export type AssistGroupsMutations_DeleteGroupGeoidRelationshipArgs = {
  groupId: Scalars['String']['input'];
};


export type AssistGroupsMutations_DeleteGroupProjectionRelationshipArgs = {
  groupId: Scalars['String']['input'];
};

export type AssistGroupsQueries = {
  groups: AssistGroupConnection;
  groupsForCurrentUser: AssistGroupConnection;
};


export type AssistGroupsQueries_GroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AssistGroupRelationsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssistGroupSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type AssistGroupsQueries_GroupsForCurrentUserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AssistGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssistGroupSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type AssistInvitation = {
  chassisId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['ID']['output'];
  expiresAt: Scalars['DateTime']['output'];
  groupId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  status: AssistInvitationStatus;
};

export type AssistInvitationConnection = {
  edges: Array<AssistInvitationEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AssistInvitationEdge = {
  cursor: Scalars['String']['output'];
  node: AssistInvitation;
};

export type AssistInvitationFilter = {
  and?: InputMaybe<Array<AssistInvitationFilter>>;
  chassisId?: InputMaybe<IdFilterClass>;
  expiresAt?: InputMaybe<DateFilterClass>;
  groupId?: InputMaybe<IdFilterClass>;
  or?: InputMaybe<Array<AssistInvitationFilter>>;
};

export type AssistInvitationSort = {
  chassisId?: InputMaybe<SortDirection>;
  expiresAt?: InputMaybe<SortDirection>;
  groupId?: InputMaybe<SortDirection>;
};

export enum AssistInvitationStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Declined = 'DECLINED',
  Delivered = 'DELIVERED'
}

export type AssistInvitationsMutations = {
  /** Creates a pending invite for the specified chassis id to the specified group. These invites can then be fetched from the machine and accepted. */
  inviteByChassisIds: Array<AssistInvitation>;
};


export type AssistInvitationsMutations_InviteByChassisIdsArgs = {
  input: AssistInviteByChassisIdsInput;
};

export type AssistInvitationsQueries = {
  invitations: AssistInvitationConnection;
};


export type AssistInvitationsQueries_InvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AssistInvitationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssistInvitationSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type AssistInviteByChassisIdsInput = {
  chassisIds: Array<Scalars['String']['input']>;
  groupId: Scalars['String']['input'];
};

export type AssistModel = {
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  fileReference?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  revision: Scalars['Float']['output'];
};

export type AssistModelFilter = {
  createdAt?: InputMaybe<DateFilterClass>;
  createdBy?: InputMaybe<StringFilterClass>;
  fileReference?: InputMaybe<StringFilterClass>;
  groupId?: InputMaybe<StringFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  modifiedAt?: InputMaybe<DateFilterClass>;
  modifiedBy?: InputMaybe<StringFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  revision?: InputMaybe<IntFilterClass>;
};

export type AssistMutations = {
  _key?: Maybe<Scalars['String']['output']>;
  groups: AssistGroupsMutations;
  invitations: AssistInvitationsMutations;
  projects: AssistProjectsMutations;
};

/** Type of relationship between organizations */
export enum AssistOriginPermissionService {
  AssetManager = 'ASSET_MANAGER',
  Manual = 'MANUAL',
  TaskManager = 'TASK_MANAGER'
}

export type AssistProjection = {
  authId: Scalars['Float']['output'];
  authName: Scalars['String']['output'];
  countryCodes: Array<Scalars['String']['output']>;
  deprecated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  proj4text: Scalars['String']['output'];
  revision: Scalars['Float']['output'];
};

export type AssistProjectionConnection = {
  edges: Array<AssistProjectionEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AssistProjectionEdge = {
  cursor: Scalars['String']['output'];
  node: AssistProjection;
};

export type AssistProjectionFilter = {
  and?: InputMaybe<Array<AssistProjectionFilter>>;
  authId?: InputMaybe<IntFilterClass>;
  authName?: InputMaybe<StringFilterClass>;
  countryCodes?: InputMaybe<CountryCodesFilterClass>;
  deprecated?: InputMaybe<BoolFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  modifiedAt?: InputMaybe<DateFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<AssistProjectionFilter>>;
  revision?: InputMaybe<IntFilterClass>;
};

export type AssistProjectionSort = {
  modifiedAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  revision?: InputMaybe<SortDirection>;
};

export type AssistProjectionsQueries = {
  projections: AssistProjectionConnection;
};


export type AssistProjectionsQueries_ProjectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AssistProjectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssistProjectionSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type AssistProjectsMutations = {
  create: Scalars['String']['output'];
};


export type AssistProjectsMutations_CreateArgs = {
  input: AssistCreateProjectInput;
};

export type AssistQueries = {
  _key?: Maybe<Scalars['String']['output']>;
  apps: AssistAppConnection;
  geoids: AssistGeoidsQueries;
  groups: AssistGroupsQueries;
  invitations: AssistInvitationsQueries;
  projections: AssistProjectionsQueries;
};


export type AssistQueries_AppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AssistAppFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssistAppSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type AvailableStock = {
  availableStock?: Maybe<UnitValue>;
  materialId: Scalars['String']['output'];
  reservedWeight?: Maybe<UnitValue>;
  stockWeight: UnitValue;
};


export type AvailableStock_AvailableStockArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};


export type AvailableStock_ReservedWeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};


export type AvailableStock_StockWeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type BaseData = {
  /** [VDA] Value for Field Number. */
  fieldNumber?: Maybe<Scalars['String']['output']>;
  /** [VDA] Value for Part Number. */
  partNumber?: Maybe<Scalars['String']['output']>;
};

/** ARI data about battery health state. */
export type BatteryHealthStatus = {
  /** [ARI] The percentage of the battery health state. */
  batteryHealthState?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Timestamp on when the battery health state was last reported. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type BatteryHealthStatusHistoryEvent = HistoricalData & {
  /** [ARI] Timestamp on when the battery health state was last reported. TGW3 only. */
  batteryHealthStatePercentage: Scalars['Int']['output'];
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type BatteryHealthStatusQueryResponse = {
  /** Data points for battery health status events from ARI. TGW3 only. */
  batteryHealthStatus: Array<BatteryHealthStatusHistoryEvent>;
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

/** ARI data about battery status. */
export type BatteryStatus = {
  /** [ARI] The power consumption in KiloWatt hours, during current idle state. TGW3 only. */
  consumptionCurrentChargeCycleWhenIdlingPower?: Maybe<Scalars['Float']['output']>;
  /** [ARI] The power consumption in KiloWatt hours, during current work state. TGW3 only. */
  consumptionCurrentChargeCycleWhenWorkingPower?: Maybe<Scalars['Float']['output']>;
  /** [ARI] The percentage of the battery's level of available electricity. */
  currentBatteryLevel?: Maybe<Scalars['Float']['output']>;
  /** [ARI] The total active time, in seconds, that the battery has been used during idle state. TGW3 only. */
  currentChargeCycleBatteryUptimeWhenIdling?: Maybe<Scalars['Float']['output']>;
  /** [ARI] The total active time, in seconds, that the battery has been used during work state. TGW3 only. */
  currentChargeCycleBatteryUptimeWhenWorking?: Maybe<Scalars['Float']['output']>;
  /** [ARI] The remaining active time, in seconds, possible for the current cycle. TGW3 only. */
  currentChargeCycleWorkCapacityLeft?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Timestamp on when battery level was last updated. TGW3 only. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] The current vehicle mode of the machine. TGW only. */
  vehicleMode?: Maybe<VehicleMode>;
};

export type BatteryStatusHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** [ARI] The power consumption in KiloWatt hours, during current idle state. TGW3 only. */
  consumptionCurrentChargeCycleWhenIdlingPower?: Maybe<Scalars['Float']['output']>;
  /** [ARI] The power consumption in KiloWatt hours, during current work state. TGW3 only. */
  consumptionCurrentChargeCycleWhenWorkingPower?: Maybe<Scalars['Float']['output']>;
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  /** [ARI] The percentage of the battery's level of available electricity. */
  currentBatteryLevel?: Maybe<Scalars['Float']['output']>;
  /** [ARI] The total active time, in seconds, that the battery has been used during idle state. TGW3 only. */
  currentChargeCycleBatteryUptimeWhenIdling?: Maybe<Scalars['Int']['output']>;
  /** [ARI] The total active time, in seconds, that the battery has been used during work state. TGW3 only. */
  currentChargeCycleBatteryUptimeWhenWorking?: Maybe<Scalars['Int']['output']>;
  /** [ARI] The remaining active time, in seconds, possible for the current cycle. TGW3 only. */
  currentChargeCycleWorkCapacityLeft?: Maybe<Scalars['Int']['output']>;
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** [ARI] Accumulated number of seconds the machine has been used/running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] The vehicle mode of the machine at the time of the event. TGW only. */
  vehicleMode?: Maybe<VehicleMode>;
};

export type BatteryStatusQueryResponse = {
  /** Data points for battery status events from ARI. TGW3 only. */
  batteryStatus: Array<BatteryStatusHistoryEvent>;
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type BoolFilterClass = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  not?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type Bounds = {
  east: Scalars['Float']['input'];
  north: Scalars['Float']['input'];
  south: Scalars['Float']['input'];
  west: Scalars['Float']['input'];
};

export type BucketConnection = {
  edges: Array<BucketEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type BucketEdge = {
  cursor: Scalars['String']['output'];
  node: BucketModel;
};

export type BucketFilter = {
  loadFilter?: InputMaybe<LoadFilterInput>;
  materialFilter?: InputMaybe<MaterialFilter>;
  targetFilter: TargetFilterRequireChassisId;
};

export type BucketModel = {
  accuracy?: Maybe<Scalars['Int']['output']>;
  anonymous: Scalars['Boolean']['output'];
  bucketCount?: Maybe<Scalars['BigInt']['output']>;
  carryBack?: Maybe<Scalars['BigInt']['output']>;
  carryBackPercentage?: Maybe<Scalars['Int']['output']>;
  certifiedId?: Maybe<Scalars['String']['output']>;
  certifiedScaleInterval?: Maybe<Scalars['Int']['output']>;
  certifiedWeightUnit?: Maybe<Scalars['Int']['output']>;
  chassisId: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerUuid?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  fuelConsumption?: Maybe<Scalars['Int']['output']>;
  groupId: Scalars['String']['output'];
  heapUuid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  loadAltitude?: Maybe<Scalars['Float']['output']>;
  loadDuration?: Maybe<Scalars['Float']['output']>;
  loadGeographicalZoneName?: Maybe<Scalars['String']['output']>;
  loadGeographicalZoneUuid?: Maybe<Scalars['String']['output']>;
  loadLatitude?: Maybe<Scalars['Float']['output']>;
  loadLongitude?: Maybe<Scalars['Float']['output']>;
  loadSelectedZoneName?: Maybe<Scalars['String']['output']>;
  loadSelectedZoneUuid?: Maybe<Scalars['String']['output']>;
  loadStopDuration?: Maybe<Scalars['Float']['output']>;
  loadTime?: Maybe<Scalars['DateTime']['output']>;
  loadTravelDistance?: Maybe<Scalars['BigInt']['output']>;
  loadTravelDuration?: Maybe<Scalars['Float']['output']>;
  loadedTargetName?: Maybe<Scalars['String']['output']>;
  localId?: Maybe<Scalars['BigInt']['output']>;
  machineHours?: Maybe<Scalars['Float']['output']>;
  materialName?: Maybe<Scalars['String']['output']>;
  materialUuid?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  overSpeedingDuration?: Maybe<Scalars['Float']['output']>;
  pauseDuration?: Maybe<Scalars['Float']['output']>;
  payloadPercentage?: Maybe<Scalars['BigInt']['output']>;
  receiptUuid?: Maybe<Scalars['String']['output']>;
  targetUuid: Scalars['String']['output'];
  ticketId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  unloadAltitude?: Maybe<Scalars['Float']['output']>;
  unloadDuration?: Maybe<Scalars['Float']['output']>;
  unloadGeographicalZoneName?: Maybe<Scalars['String']['output']>;
  unloadGeographicalZoneUuid?: Maybe<Scalars['String']['output']>;
  unloadLatitude?: Maybe<Scalars['Float']['output']>;
  unloadLongitude?: Maybe<Scalars['Float']['output']>;
  unloadSelectedZoneName?: Maybe<Scalars['String']['output']>;
  unloadSelectedZoneUuid?: Maybe<Scalars['String']['output']>;
  unloadStopDuration?: Maybe<Scalars['Float']['output']>;
  unloadTime?: Maybe<Scalars['DateTime']['output']>;
  unloadTravelDistance?: Maybe<Scalars['BigInt']['output']>;
  unloadTravelDuration?: Maybe<Scalars['Float']['output']>;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  weight?: Maybe<Scalars['Int']['output']>;
  workMode?: Maybe<Scalars['BigInt']['output']>;
  workOrderName?: Maybe<Scalars['String']['output']>;
  workOrderUuid?: Maybe<Scalars['String']['output']>;
};

export type BucketQueries = {
  /** Fetch bucket data based on filter. Requires authentication. */
  buckets: BucketConnection;
};


export type BucketQueries_BucketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<BucketFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BucketSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type BucketSort = {
  created?: InputMaybe<SortDirection>;
  updated?: InputMaybe<SortDirection>;
};

export type CacheMeta = {
  updatedAt: Scalars['String']['output'];
};

export type CareTrackMachine = {
  id: Scalars['ID']['output'];
  machine?: Maybe<Machine>;
};

export type CareTrackMachineEdge = {
  cursor: Scalars['String']['output'];
  node: CareTrackMachine;
};

export type CareTrackMachineFilter = {
  and?: InputMaybe<Array<CareTrackMachineFilter>>;
  chassisId?: InputMaybe<StringFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  or?: InputMaybe<Array<CareTrackMachineFilter>>;
  pin17?: InputMaybe<StringFilterClass>;
};

export type CareTrackMachineQueries = {
  careTrackMachines: CareTrackMachinesConnection;
};


export type CareTrackMachineQueries_CareTrackMachinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<CareTrackMachineFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CareTrackMachineSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CareTrackMachineSort = {
  chassisId?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  pin17?: InputMaybe<SortDirection>;
};

export type CareTrackMachinesConnection = {
  edges: Array<CareTrackMachineEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type Case = {
  /** [CHAIN] Timestamp of case closure. */
  acCaseClosed: Scalars['DateTime']['output'];
  /** [CHAIN] Timestamp of case opening. */
  acCaseCreated: Scalars['DateTime']['output'];
  /** [CHAIN] Activity posts related to the case. */
  activities: Array<Activity>;
  /** [CHAIN] Internal id of the case. */
  caseId: Scalars['String']['output'];
  /** [CHAIN] Short description. */
  caseShortDescription: Scalars['String']['output'];
  /** [CHAIN] Case summary. */
  caseSummary: Array<CaseSummary>;
  /** [CHAIN] Chassis id of the machine. */
  chassisId: Scalars['String']['output'];
  /** [CHAIN] How many case duplicates exists prior to this one. */
  duplicateCounter: Scalars['String']['output'];
  /** [CHAIN] Fault code. */
  faultCode: Scalars['String']['output'];
  /** [CHAIN] Machine hours. */
  machineHours: Scalars['String']['output'];
  /** [CHAIN] Pin17 of the machine. */
  pin17: Scalars['String']['output'];
  /** [CHAIN] Case state, number of status. */
  stateNumber: Scalars['String']['output'];
  /** [CHAIN] Case status. */
  status: Scalars['String']['output'];
  /** [CHAIN] Priority levels. */
  urgency: Scalars['String']['output'];
};

export type CaseSummary = {
  /** [CHAIN] Case comment. */
  caseComment: Scalars['String']['output'];
  /** [CHAIN] Case info description. */
  caseInfoDescription: Scalars['String']['output'];
  /** [CHAIN] Case info header. */
  caseInfoHeader: Scalars['String']['output'];
};

/** Shortcut category/section */
export enum Category {
  Action = 'Action',
  Generic = 'Generic',
  Service = 'Service'
}

export type Cea2PlusDtc = MachineFaultCode & {
  /** [ARI] If the failure is active or not (true or false). */
  active: Scalars['Boolean']['output'];
  /** [ARI] The text shown on the display in the cabin. */
  dtcDisplayValue?: Maybe<Scalars['String']['output']>;
  /** [ARI] An identifier for the text shown on the display in the cabin. */
  dtcId?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Additional information captured at error occurrence. */
  dtcSnapshot?: Maybe<Scalars['String']['output']>;
  /** [ARI] A human representation of the node number, e.g., HMIC for the HMI ECU. */
  ecuDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] The failure code. */
  failureIdentifier: Scalars['String']['output'];
  /** [ARI] Explanation of failure code. */
  failureIdentifierDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] The description of the failure type. E.g., Hardware failure. */
  failureTypeDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] Identifier for the description of the failure type (decimal value). */
  failureTypeValue?: Maybe<Scalars['String']['output']>;
  /** [ARI] The first time the failure occurred. */
  firstFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** First occurrence in service */
  firstOccurrence?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Timestamp of last time the failure occurrence. */
  lastFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** Last occurrence in service, or last failure time if set in alarm */
  lastOccurrence?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Accumulated number of seconds the machine has been running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Position information at the time of the fault event. */
  machinePosition?: Maybe<MachinePosition>;
  /** [ARI] Represents the "ECU group" by a unique ID. */
  nodeFamily?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Position of the ECU has a unique ID within the Node Type and Node Family. Normally not used. */
  nodePosition?: Maybe<Scalars['Int']['output']>;
  /** [ARI] A physical ECU within the Node Family has a unique ID. */
  nodeType?: Maybe<Scalars['Int']['output']>;
  /** Recorded occurrence count in service, or occurance counter if set in alarm */
  numberOfOccurrences?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Counter of the specific fault code since last "acknowledgement". */
  occurrenceCounter?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Priority of error code, 1-3. Note: Not reliable information. */
  priority?: Maybe<Scalars['Int']['output']>;
  /** [ARI] ECU CAN address. */
  sourceAddress: Scalars['String']['output'];
  type: MachineFaultCodeType;
};

export type ChainQueries = {
  cases: Array<Case>;
};

/** ARI data about charging status. */
export type ChargeMonitoring = {
  /** [ARI] The current charging status of the machine. TGW3 only. */
  chargingStatus?: Maybe<ChargingStatus>;
  /** [ARI] The current flow rate in watt. TGW3 only. */
  currentChargeRate?: Maybe<Scalars['Float']['output']>;
  /** [ARI] The total time in seconds until the battery is fully charged. TGW3 only. */
  timeToFullyChargedSeconds?: Maybe<Scalars['Float']['output']>;
  /** [ARI] The type of charger connected to the machine. TGW3 only. */
  typeOfChargerConnected?: Maybe<ChargerType>;
  /** [ARI] Timestamp on when the charging status was last updated. TGW3 only. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type ChargeMonitoringHistoryEvent = HistoricalData & {
  /** [ARI] The currenct flow rate in watt. TGW3 only. */
  chargingStatus?: Maybe<ChargingStatus>;
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  /** [ARI] The power consumption in KiloWatt hours, during current work state. TGW3 only. */
  currentBatteryLevel?: Maybe<Scalars['Int']['output']>;
  /** [ARI] The current flow rate in watt. TGW3 only. */
  currentChargeRate?: Maybe<Scalars['Int']['output']>;
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** [ARI] The total time in seconds until the battery is fully charged. TGW3 only. */
  timeToFullyChargedSeconds?: Maybe<Scalars['Int']['output']>;
  /** [ARI] The type of charger connected to the machine. TGW3 only. */
  typeOfChargerConnected?: Maybe<ChargerType>;
};

export type ChargeMonitoringQueryResponse = {
  /** Data points for charge monitoring status events from ARI. TGW3 only. */
  chargeMonitoring: Array<ChargeMonitoringHistoryEvent>;
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export enum ChargerConnectorTypes {
  Type_1 = 'TYPE_1',
  Type_1Combo_1 = 'TYPE_1_COMBO_1',
  Type_2 = 'TYPE_2',
  Type_2Combo_2 = 'TYPE_2_COMBO_2'
}

export type ChargerLocation = {
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lon?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ChargerType {
  FastCharger = 'FastCharger',
  MobileCharger = 'MobileCharger',
  None = 'None',
  SlowCharger = 'SlowCharger'
}

export type ChargerUpdateInput = {
  activatedForDataImport?: InputMaybe<Scalars['Boolean']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChargingStation = {
  /** Charger brand */
  brand: Scalars['String']['output'];
  /** Meta data about cached data. Will only be populated if retrieved entity is a cached version */
  cacheMeta?: Maybe<CacheMeta>;
  /** Custom fields that can be set without modifying original charging station data */
  custom: ChargingStationCustomFields;
  /** List of Evses the charging station is equipped with. */
  evses: Array<Evse>;
  /** Charger firmware version */
  firmwareVersion?: Maybe<Scalars['String']['output']>;
  /**
   * Location
   * @deprecated This type has non-nullable fields but the data is actually nullable. Use locationV2 instead
   */
  location?: Maybe<Location>;
  /** LocationV2 */
  locationV2?: Maybe<ChargerLocation>;
  /** Charger model */
  model: Scalars['String']['output'];
  /** Name or alias of charger as it is named in other systems */
  name?: Maybe<Scalars['String']['output']>;
  /** ID from the physical `ChargingStation` used in OCPP communication */
  ocppId?: Maybe<Scalars['ID']['output']>;
  /** External ID of a `ChargingStation` as it appears on external entities. */
  stationExternalId: Scalars['ID']['output'];
  /** DCSP domain ID of a `ChargingStation` that is unique to our domain */
  stationInternalId: Scalars['ID']['output'];
  /** Charger status */
  status: Scalars['String']['output'];
  transactions: ChargingStationTransactionConnection;
  /** Websocket URL */
  websocketUrl?: Maybe<Scalars['String']['output']>;
};


export type ChargingStation_TransactionsArgs = {
  first?: Scalars['Int']['input'];
};

export type ChargingStationCustomFields = {
  /** Custom charging station location */
  location?: Maybe<CustomLocation>;
  /** Custom charging station name */
  name?: Maybe<Scalars['String']['output']>;
};

/** Paginated transactions, Currenly, the arg: `first` is the only one available and more robust pagination args will be added in the future. */
export type ChargingStationTransactionConnection = {
  edges: Array<ChargingStationTransactionEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ChargingStationTransactionEdge = {
  cursor: Scalars['String']['output'];
  node: ChargingStationTransactionNode;
};

export type ChargingStationTransactionNode = {
  /** The end DateTime (ISO 8601) of the `ChargingTransaction`. */
  chargingEndedReason: ChargingTransactionEndedReason;
  /** The connector that is involved in the `ChargingTransaction`. */
  connectorId: Scalars['Float']['output'];
  /** Type of connector. */
  connectorType: ConnectorType;
  /** The end DateTime (ISO 8601) of the `ChargingTransaction` */
  endDateTime?: Maybe<Scalars['String']['output']>;
  /** ID of the `Evse` of the `Transaction`. */
  evseId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Power of kW during the `ChargingTransaction`. */
  powerKw: Scalars['Float']['output'];
  /** The start DateTime (ISO 8601) of the `ChargingTransaction`. */
  startDateTime: Scalars['String']['output'];
  /** The status of the `ChargingTransaction` */
  status: ChargingTransactionStatus;
  /** Total energy in kWh that is charged in the `Transaction`. */
  totalChargedKwh: Scalars['Float']['output'];
};

export enum ChargingStatus {
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  FastCharging = 'FastCharging',
  FastChargingDone = 'FastChargingDone',
  Interrupted = 'Interrupted',
  SlowCharging = 'SlowCharging',
  SlowChargingDone = 'SlowChargingDone'
}

/** Possible `ChargingTransaction` ended reason */
export enum ChargingTransactionEndedReason {
  BeforeReservation = 'BeforeReservation',
  ConcurrentCharging = 'ConcurrentCharging',
  DeAuthorized = 'DeAuthorized',
  EmergencyStop = 'EmergencyStop',
  EnergyLimitReached = 'EnergyLimitReached',
  EvDisconnected = 'EvDisconnected',
  Fault = 'Fault',
  GroundFault = 'GroundFault',
  HardReset = 'HardReset',
  ImmediateReset = 'ImmediateReset',
  Local = 'Local',
  LocalOutOfCredit = 'LocalOutOfCredit',
  ManualStopChargeByOperator = 'ManualStopChargeByOperator',
  MasterPass = 'MasterPass',
  NotIdentified = 'NotIdentified',
  Other = 'Other',
  OvercurrentFault = 'OvercurrentFault',
  PowerLoss = 'PowerLoss',
  PowerQuality = 'PowerQuality',
  Reboot = 'Reboot',
  Remote = 'Remote',
  SocLimitReached = 'SocLimitReached',
  SoftReset = 'SoftReset',
  StoppedByEv = 'StoppedByEv',
  TimeLimitReached = 'TimeLimitReached',
  Timeout = 'Timeout',
  UnlockCommand = 'UnlockCommand',
  UserPressedStopOnCharger = 'UserPressedStopOnCharger'
}

/** Possible `ChargingTransaction` status */
export enum ChargingTransactionStatus {
  Charging = 'Charging',
  Ended = 'Ended',
  NotIdentified = 'NotIdentified',
  PluggedInChargingEnded = 'PluggedInChargingEnded',
  PluggedInIdle = 'PluggedInIdle',
  Preparing = 'Preparing',
  WaitingToBePluggedIn = 'WaitingToBePluggedIn'
}

export type Client = {
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scopes: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ClientEdge = {
  cursor: Scalars['String']['output'];
  node: Client;
};

export type ClientFilter = {
  and?: InputMaybe<Array<ClientFilter>>;
  id?: InputMaybe<IdFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<ClientFilter>>;
  scopes?: InputMaybe<StringArrayFilterClass>;
  type?: InputMaybe<StringFilterClass>;
};

export type ClientMutations = {
  create: Client;
  delete: Scalars['Boolean']['output'];
  update: Client;
};


export type ClientMutations_CreateArgs = {
  input: CreateClientInput;
};


export type ClientMutations_DeleteArgs = {
  clientId: Scalars['String']['input'];
};


export type ClientMutations_UpdateArgs = {
  input: UpdateClientInput;
};

export type ClientQueries = {
  client: Client;
  clients: ClientsConnection;
};


export type ClientQueries_ClientArgs = {
  clientId: Scalars['String']['input'];
};


export type ClientQueries_ClientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<ClientFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ClientSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ClientSort = {
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type ClientsConnection = {
  edges: Array<ClientEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ClusterMarker = {
  count?: Maybe<Scalars['Float']['output']>;
  latestLatNum?: Maybe<Scalars['Float']['output']>;
  latestLngNum?: Maybe<Scalars['Float']['output']>;
};

export type CompanyByUserAuthFilterInput = {
  /** True only included admin related companies, while false also retrieves site related companies for user */
  isAdmin?: Scalars['Boolean']['input'];
};

export enum CompanyLicenseUsageStatisticsField {
  NumberOfBoughtLicenses = 'NUMBER_OF_BOUGHT_LICENSES'
}

export type CompanyLicenseUsageStatisticsFilterBy = {
  numberOfBoughtLicenses?: InputMaybe<InvoicingAssetUsageSourcesFilter>;
};

export enum CompanyLicenseUsageStatisticsGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

/** Possible types of company status */
export enum CompanyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type CompanyUserOnSiteFilterInput = {
  hasAcceptedTermsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConfigurationValueWithUnit = {
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type ConfigurationValueWithUnitInput = {
  unit: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type ConnectedService = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ConnectedServiceClass = {
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type ConnectionStatusModel = {
  /** Status of the cpms connection */
  status: Scalars['String']['output'];
};

export type Connector = {
  /** ID of the connector */
  connectorId: Scalars['ID']['output'];
  /** Custom fields that can be set without modifying original connector data */
  custom: ConnectorCustomFields;
  /** The max power output in kilowatts of a connector. */
  maxPowerKw?: Maybe<Scalars['Float']['output']>;
  /** Name of connector */
  name: Scalars['String']['output'];
  /**
   * The current powerKw
   * @deprecated Do not use this. This is part of a Transaction object. Fetch the latest transaction of a ChargingStation to retrive the current powerKw
   */
  powerKw?: Maybe<Scalars['Float']['output']>;
  /** Availability status for connector */
  status: ConnectorStatus;
  /** Type of connector. */
  type: ConnectorType;
};

export type ConnectorCustomFields = {
  /** Custom connector name */
  name?: Maybe<Scalars['String']['output']>;
};

/** Possible statuses of a `Connector */
export enum ConnectorStatus {
  Available = 'Available',
  Charging = 'Charging',
  Discharging = 'Discharging',
  Faulted = 'Faulted',
  Finishing = 'Finishing',
  New = 'New',
  NotIdentified = 'NotIdentified',
  Occupied = 'Occupied',
  Paused = 'Paused',
  Preparing = 'Preparing',
  Reserved = 'Reserved',
  Unavailable = 'Unavailable',
  Unknown = 'Unknown'
}

/** Possible types of a `Connector */
export enum ConnectorType {
  Ac = 'Ac',
  Ccs = 'Ccs',
  Chademo = 'Chademo',
  Nema115p = 'Nema115p',
  NotIdentified = 'NotIdentified',
  Oppcharge = 'Oppcharge',
  SaeJ1772Avcon = 'SaeJ1772Avcon',
  SaeJ1772ComboUs = 'SaeJ1772ComboUs',
  TeslaR = 'TeslaR',
  TeslaS = 'TeslaS',
  TeslaUniversalMobileConnector = 'TeslaUniversalMobileConnector',
  Type1J1772Yazaki = 'Type1J1772Yazaki',
  Type2 = 'Type2',
  Type2Mennekes = 'Type2Mennekes',
  Type2Tethered = 'Type2Tethered',
  Type3Scame = 'Type3Scame',
  Type4Chademo = 'Type4Chademo',
  TypeBNema515 = 'TypeBNema515',
  TypeBNema520 = 'TypeBNema520',
  TypeCCee716Eu = 'TypeCCee716Eu',
  TypeCCee717GerFra = 'TypeCCee717GerFra',
  TypeComboGermany = 'TypeComboGermany',
  TypeDBs5462PinUk = 'TypeDBs5462PinUk',
  TypeDBs5463PinUk = 'TypeDBs5463PinUk',
  TypeEAndFCee75Fra = 'TypeEAndFCee75Fra',
  TypeECee75Fra = 'TypeECee75Fra',
  TypeFSchukoCee74Ger = 'TypeFSchukoCee74Ger',
  TypeGBs1363Is401And411Ms58Uk = 'TypeGBs1363Is401And411Ms58Uk',
  TypeJSev1011Ch = 'TypeJSev1011Ch',
  TypeKSection1072D1Den = 'TypeKSection1072D1Den',
  TypeLCei2316ViiIta = 'TypeLCei2316ViiIta'
}

export type CountryCodesFilterClass = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A subscription to a CPMS containing credentials which gives API access to ingest or fetch charger data. */
export type CpmsSubscription = {
  /** @deprecated Use organization instead if it exists */
  actor?: Maybe<Actor>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  /** The organization that owns this subscription */
  organization?: Maybe<Organization>;
  source: IngestionSource;
};

export type CreateActorActorRelationshipInput = {
  /** ID of the child actor in the relationship */
  childId: Scalars['ID']['input'];
  /** ID of the parent actor in the relationship */
  parentId: Scalars['ID']['input'];
};

export type CreateAppStartupEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: AppStartupEventPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateApplicationInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAssetManagerInvitationInput = {
  invitedEmail: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  siteName: Scalars['String']['input'];
};

export type CreateClientInput = {
  createdBy: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
  type: Scalars['String']['input'];
};

export type CreateCustomerInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateDriverAssignedEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: DriverAssignedPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateEnterSiteEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: EnterSiteEventPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateEnterZoneEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: EnterZoneEventPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateExitSiteEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: ExitSiteEventPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateExitZoneEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: ExitZoneEventPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateFleetReportTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fleetReportTemplateProperties?: InputMaybe<Array<CreateFleetReportTemplatePropertyInput>>;
  name: Scalars['String']['input'];
  reportType?: InputMaybe<Scalars['String']['input']>;
  system: Scalars['String']['input'];
};

export type CreateFleetReportTemplatePropertyInput = {
  keyName: Scalars['String']['input'];
  operator: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CreateFromInvitationInput = {
  companyName: Scalars['String']['input'];
  invitationId: Scalars['ID']['input'];
};

export type CreateLoadEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: LoadEventPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateMachineTypePermissionInput = {
  machineTypeId: Scalars['String']['input'];
  zoneId: Scalars['String']['input'];
  zonePermissionTypeId: Scalars['String']['input'];
};

export type CreateManagerInput = {
  machineOwningCompanyId: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type CreateManualLoadTicketEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: ManualLoadTicketPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateManyOperatorChargerAssociationInput = {
  stationInternalIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type CreateManyOperatorChargerAssociationWithEmailInput = {
  email: Scalars['String']['input'];
  stationInternalIds: Array<Scalars['String']['input']>;
};

export type CreateManyOperatorMachineAssociationInput = {
  pin17s: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type CreateManyOperatorMachineAssociationWithEmailInput = {
  email: Scalars['String']['input'];
  pin17s: Array<Scalars['String']['input']>;
};

export type CreateMaterialFamilyInput = {
  companyId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  /** Min length of name 3 */
  name: Scalars['String']['input'];
  shortname?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMaterialFlowAssignedEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: MaterialFlowAssignedEventPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateMaterialFlowExternalReferenceInput = {
  type: ExternalReferenceTypes;
  value: Scalars['String']['input'];
};

export type CreateMaterialFlowInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalReferences?: InputMaybe<Array<CreateMaterialFlowExternalReferenceInput>>;
  fromZoneId: Scalars['String']['input'];
  hasDynamicMaterial: Scalars['Boolean']['input'];
  materialVariantId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  toZoneId: Scalars['String']['input'];
};

export type CreateMaterialFlowObjectiveDto = {
  amount: Scalars['Float']['input'];
  basisDays: Scalars['Float']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  interval: MaterialFlowObjectiveInterval;
  materialFlowId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  status: MaterialFlowObjectiveStatusEnum;
  unitType: Scalars['String']['input'];
};

export type CreateMaterialFlowUnassignedEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: MaterialFlowUnassignedEventPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateMaterialThresholdInput = {
  materialId: Scalars['String']['input'];
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
  siteId: Scalars['String']['input'];
};

export type CreateMaterialTransactionInput = {
  amount: Scalars['Float']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  correlationId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  eventBusName?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['String']['input'];
  modified?: InputMaybe<Scalars['Boolean']['input']>;
  modifiedAmount?: InputMaybe<Scalars['Float']['input']>;
  orderItemId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  transactionType: TransactionTypeEnum;
  unitType: Scalars['String']['input'];
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMaterialTypeInput = {
  description: Scalars['String']['input'];
  materialFamilyId: Scalars['ID']['input'];
  /** Min length of name 3 */
  name: Scalars['String']['input'];
  shortname?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMaterialVariantInput = {
  description: Scalars['String']['input'];
  materialFamilyId: Scalars['ID']['input'];
  materialTypeId: Scalars['ID']['input'];
  /** Min length of name 3 */
  name: Scalars['String']['input'];
  shortname?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOffloadEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: OffloadPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateOffloadWarningEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: OffloadWarningPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateOperatorInput = {
  employmentNumber: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  /** Min length of name 2 */
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
};

export type CreateOrUpdateMaterialFlowExternalReferenceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ExternalReferenceTypes>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrUpdateSiteAssetInput = {
  assetId: Scalars['ID']['input'];
  assetOwnerId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  type: SiteAssetTypeInput;
};

export type CreateOrUpdateSiteMaterialFlowExternalReferenceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ExternalReferenceTypes>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrUpdateSiteMetricDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  metricDefinitionId: Scalars['ID']['input'];
  metricDefinitionOwnerId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  type: ProductivityMainMetricDefinitionTypeInput;
};

export type CreateOrUpdateZoneChargerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  emobAssetId?: InputMaybe<Scalars['ID']['input']>;
  emobAssetTypeId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  slots: Array<ChargerConnectorTypes>;
};

export type CreateOrUpdateZoneConnectedChargerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  stationInternalId: Scalars['String']['input'];
};

export type CreateOrganizationAssetRelationshipInput = {
  /** Type of relationship between the organization and the user */
  assetRelationshipType: AssetRelationshipType;
  /** Type of asset */
  assetType: AssetType;
  /** End date of the relationship, if not set no end date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** ExternalID of the user */
  externalId: Scalars['String']['input'];
  /** Platform internalID of the asset, such as machineId */
  internalId: Scalars['String']['input'];
  /** ID of the organization in the relationship */
  organizationId: Scalars['ID']['input'];
  /** Source of the relationship */
  source: Scalars['String']['input'];
  /** Start date of the relationship, if not set it active */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateOrganizationInput = {
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  /** Type of organizations */
  organizationType?: InputMaybe<OrganizationType>;
  source: Scalars['String']['input'];
};

export type CreateOrganizationOrganizationRelationshipInput = {
  /** ID of the child organization in the relationship */
  childOrganizationId: Scalars['ID']['input'];
  /** End date of the relationship, if not set no end date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Type of relationship between the organizations */
  organizationRelationshipType: OrganizationRelationshipType;
  /** ID of the parent organization in the relationship */
  parentOrganizationId: Scalars['String']['input'];
  /** Source of the relationship */
  source: Scalars['String']['input'];
  /** Start date of the relationship, if not set it active */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateOrganizationSiteRelationshipInput = {
  /** End date of the relationship, if not set no end date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Name of the site */
  name: Scalars['String']['input'];
  /** ID of the organization in the relationship */
  organizationId: Scalars['ID']['input'];
  /** ID of the site in the relationship */
  siteId: Scalars['ID']['input'];
  /** Type of relationship between the organization and the site */
  siteRelationshipType: SiteRelationshipType;
  /** Source of the relationship */
  source: Scalars['String']['input'];
  /** Start date of the relationship, if not set it active */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateOrganizationSubscriptionInput = {
  /** Preferrably same as the name of the Organization */
  name: Scalars['String']['input'];
  /** Identifier for the organization who should be related to a subscription */
  organizationId: Scalars['String']['input'];
  source: IngestionSource;
};

export type CreateOrganizationUserRelationshipInput = {
  /** Email of the user */
  email: Scalars['String']['input'];
  /** End date of the relationship, if not set no end date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** ExternalID of the user */
  externalId: Scalars['String']['input'];
  /** ID of the organization in the relationship */
  organizationId: Scalars['ID']['input'];
  /** Source of the relationship */
  source: Scalars['String']['input'];
  /** Start date of the relationship, if not set it active */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID of the user in the relationship */
  userId: Scalars['ID']['input'];
  /** Type of relationship between the organization and the user */
  userRelationshipType: UserRelationshipType;
};

export type CreatePointOfInterestInput = {
  coordinates: Array<Scalars['Float']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pointOfInterestTypeId: Scalars['String']['input'];
  siteMapId: Scalars['String']['input'];
  siteMetricDefinitionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReceivedLoadTicketEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: ReceivedLoadTicketEventPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateRestrictedZoneEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: RestrictedZonePayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateScopeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  scope: Scalars['String']['input'];
};

export type CreateSettingInput = {
  defaultValue: Scalars['JSON']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  options: Scalars['JSON']['input'];
  order: Scalars['Float']['input'];
  scopes: Array<Scalars['String']['input']>;
  tab: Scalars['String']['input'];
};

export type CreateShortcutInput = {
  category: Category;
  icon: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  redirectUrl: Scalars['String']['input'];
  scopesCriteria: Array<Scalars['String']['input']>;
  sortOrder: Scalars['Float']['input'];
  title: Scalars['String']['input'];
  urlCriteria: Array<Scalars['String']['input']>;
};

export type CreateSiteInput = {
  companyId: Scalars['ID']['input'];
  machineOwningCompanyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
};

export type CreateSiteMachineInput = {
  /** Max length 100 char */
  description?: InputMaybe<Scalars['String']['input']>;
  loadCapacity?: InputMaybe<Scalars['Float']['input']>;
  loadCapacityUnitId: Scalars['String']['input'];
  machineOwningCompanyId?: InputMaybe<Scalars['ID']['input']>;
  machineTypeId: Scalars['String']['input'];
  manualLoadTickets?: InputMaybe<Scalars['Boolean']['input']>;
  /** Min length of name 3 */
  name: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
};

export type CreateSiteMapInput = {
  boundary: Array<Array<Array<Scalars['Float']['input']>>>;
  siteId: Scalars['String']['input'];
};

export type CreateSiteMaterialFlowExternalReferenceInput = {
  type: ExternalReferenceTypes;
  value: Scalars['String']['input'];
};

export type CreateSiteMaterialFlowInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalReferences?: InputMaybe<Array<CreateSiteMaterialFlowExternalReferenceInput>>;
  fromId: Scalars['String']['input'];
  hasDynamicMaterial: Scalars['Boolean']['input'];
  materialVariantId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  siteMetricDefinitionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toId: Scalars['String']['input'];
  type: MaterialFlowType;
};

export type CreateSiteTabletDeviceInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  batteryLevel?: InputMaybe<Scalars['Float']['input']>;
  identifierCode: Scalars['String']['input'];
  tabletDeviceId: Scalars['String']['input'];
};

export type CreateSiteUserInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  /** Min length of name 2 */
  firstName: Scalars['String']['input'];
  /** Min length of name 2 */
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSpeedingEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: SpeedingPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateUndoLoadEventInput = {
  correlationId: Scalars['ID']['input'];
  createdAt: Scalars['DateTime']['input'];
  createdAtUnix: Scalars['Float']['input'];
  payload: UndoLoadPayloadInput;
  source: Scalars['String']['input'];
  /** Defaults to EventStatus.Ok */
  status?: InputMaybe<SiteDeviceEventStatus>;
  statusMessage?: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type CreateUserInput = {
  applicationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
  source?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type CreateWorkflowExecution = {
  context: Scalars['JSON']['input'];
  event: Scalars['String']['input'];
  from: Scalars['String']['input'];
  notificationEmail?: InputMaybe<Scalars['String']['input']>;
  product: Scalars['String']['input'];
};

export type CreateWorkflowExecutionInput = {
  notificationEmail?: InputMaybe<Scalars['String']['input']>;
  workflowTemplateId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateZoneInput = {
  chargers?: InputMaybe<Array<CreateOrUpdateZoneChargerInput>>;
  connectedChargers?: InputMaybe<Array<CreateOrUpdateZoneConnectedChargerInput>>;
  coordinates: Array<Array<Array<Scalars['Float']['input']>>>;
  description: Scalars['String']['input'];
  materialVariantIds?: InputMaybe<Array<Scalars['String']['input']>>;
  maxSpeedKmh?: InputMaybe<Scalars['Int']['input']>;
  maxSpeedMs?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  narrowLane?: InputMaybe<Scalars['Boolean']['input']>;
  siteMapId: Scalars['String']['input'];
  weighBridge?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CredentialsMutations = {
  deleteCredentials: Scalars['Boolean']['output'];
  setUsernamePassword: Scalars['Boolean']['output'];
};


export type CredentialsMutations_DeleteCredentialsArgs = {
  parentId: Scalars['ID']['input'];
  service: AssetManagerExternalServiceInput;
};


export type CredentialsMutations_SetUsernamePasswordArgs = {
  parentId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  service: AssetManagerExternalServiceInput;
  username: Scalars['String']['input'];
};

export type CredentialsQueries = {
  credentials: Array<ServiceCredential>;
};


export type CredentialsQueries_CredentialsArgs = {
  services: Array<AssetManagerExternalServiceInput>;
};

export type CredentialsUnion = Token | UsernamePassword;

export type CsvAssetInput = {
  action: CsvImportActionEnum;
  assetType?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  internalId?: InputMaybe<Scalars['String']['input']>;
  organizationExternalId?: InputMaybe<Scalars['String']['input']>;
  relationshipType?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Type of action to perform on csv row */
export enum CsvImportActionEnum {
  Create = 'Create',
  Delete = 'Delete'
}

export type CsvImportMutations = {
  bulkAssets: Array<CsvImportResult>;
  bulkOrganizations: Array<CsvImportResult>;
  bulkUsers: Array<CsvImportResult>;
};


export type CsvImportMutations_BulkAssetsArgs = {
  assets: Array<CsvAssetInput>;
};


export type CsvImportMutations_BulkOrganizationsArgs = {
  organizations: Array<CsvOrganizationInput>;
};


export type CsvImportMutations_BulkUsersArgs = {
  users: Array<CsvUserInput>;
};

export type CsvImportResult = {
  reason?: Maybe<Scalars['String']['output']>;
  status: CsvImportStatus;
};

export enum CsvImportStatus {
  Failure = 'Failure',
  Success = 'Success'
}

export type CsvOrganizationInput = {
  action: CsvImportActionEnum;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  externalId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationRelationshipType?: InputMaybe<Scalars['String']['input']>;
  organizationType?: InputMaybe<Scalars['String']['input']>;
  parentExternalID?: InputMaybe<Scalars['String']['input']>;
  parentName?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CsvUserInput = {
  action: CsvImportActionEnum;
  email: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  externalId: Scalars['String']['input'];
  organizationExternalId: Scalars['String']['input'];
  relationshipType: Scalars['String']['input'];
  source: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Type of Current (alternating/direct) */
export enum CurrentType {
  Ac = 'AC',
  Dc = 'DC',
  NotIdentified = 'NotIdentified'
}

export type CustomLocation = {
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Customer = {
  address?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  customerNumber?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orders: Array<Order>;
};

export type CustomerEdge = {
  cursor: Scalars['String']['output'];
  node: Customer;
};

export type CustomerFilter = {
  address?: InputMaybe<StringFilterInput>;
  customerNumber?: InputMaybe<StringFilterInput>;
  /** use null to filter true or false */
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StringFilterInput>;
};

export type CustomerMutation = {
  create: Customer;
  delete: Scalars['Boolean']['output'];
  update: Customer;
};


export type CustomerMutation_CreateArgs = {
  data: CreateCustomerInput;
};


export type CustomerMutation_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type CustomerMutation_UpdateArgs = {
  data: UpdateCustomerInput;
  id: Scalars['String']['input'];
};

export type CustomerQueries = {
  customer: Customer;
  customers: CustomersConnection;
};


export type CustomerQueries_CustomerArgs = {
  id: Scalars['String']['input'];
};


export type CustomerQueries_CustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  filterBy?: InputMaybe<CustomerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CustomerSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerSort = {
  customerNumber?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type CustomersConnection = {
  edges: Array<CustomerEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DailyAmount = {
  modified: Scalars['Boolean']['output'];
  trunc_timestamp: Scalars['DateTime']['output'];
  weight: UnitValue;
};


export type DailyAmount_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

/** The type of TimeUnit for anything larger then a day */
export enum DailyScaleTimeUnit {
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week',
  Year = 'Year'
}

export type Data = {
  /** [VDA] Compression method for Software. */
  compressionMethod?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Values for Data Patches. */
  dataPatch?: Maybe<Array<DataPatch>>;
  /** [VDA] Encryption method for Software. */
  encryptionMethod?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Indicator if isPreReleaseVerificationData. */
  isCertified?: Maybe<Scalars['Boolean']['output']>;
  /** [VDA] Indicator if isPreReleaseVerificationData. */
  isPatchable?: Maybe<Scalars['Boolean']['output']>;
  /** [VDA] Indicator if isPreReleaseVerificationData. */
  isPreReleaseVerificationData?: Maybe<Scalars['Boolean']['output']>;
  /** [VDA] Identifier for documentation in Software Configurator */
  nodeTemplateDocumentNumber?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Indicator if nonProgrammable. */
  nonProgrammable?: Maybe<Scalars['Boolean']['output']>;
  /** [VDA] Offset address for Software. */
  offsetAddress?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Part number for the Software in TEA2PlusNode. */
  partNumber?: Maybe<Scalars['String']['output']>;
  /** [VDA] Software download signature for Software. */
  softwareDownloadSignature?: Maybe<Scalars['String']['output']>;
};

export type DataPatch = {
  /** [VDA] Identifier for documentation for Data Patch in Software Configurator */
  nodeTemplateDocumentNumber?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Offset Address for Data Patch. */
  offsetAddress?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Part number for the Data Patch. */
  partNumber?: Maybe<Scalars['Float']['output']>;
};

export type DateFilterClass = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeFilterInput = {
  /** Date equal to */
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  /** Date greater than */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Date greater than or equal to */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Date lower than */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Date lower than or equal to */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Date not equal to (mostly used for null) */
  neq?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilterInput = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

/** AMM data about the dealer. */
export type Dealer = {
  /** [AMM] CE Party id from MDHUB. */
  cePartyID?: Maybe<Scalars['String']['output']>;
  /** [AMM] Party that handles warranty issues/dealer. */
  dealerID?: Maybe<Scalars['String']['output']>;
  /** [AMM] Machine alias set by the dealer. */
  dealerMachineAlias?: Maybe<Scalars['String']['output']>;
  /** [AMM] Name used in AMM for the registered dealer. */
  dealerName?: Maybe<Scalars['String']['output']>;
  /** [AMM] Id for the registered dealer for actor tree purposes. */
  dealerParmaID?: Maybe<Scalars['String']['output']>;
  /** [AMM] */
  warrantyArea?: Maybe<Scalars['String']['output']>;
};

export type DeleteFleetReportTemplateInput = {
  id: Scalars['String']['input'];
};

export type DeleteManyOperatorChargerAssociationInput = {
  stationInternalIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type DeleteManyOperatorMachineAssociationInput = {
  pin17s: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type DeliveryAnalyticsQuery = {
  deliveryHistory: Array<GroupedTransactionData>;
};


export type DeliveryAnalyticsQuery_DeliveryHistoryArgs = {
  accumulate?: Scalars['Boolean']['input'];
  generateEmptyDays?: Scalars['Boolean']['input'];
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  timePeriodInput: TimePeriodInput;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export enum DeviceConnectionStateFilter {
  All = 'ALL',
  Connected = 'CONNECTED',
  Unconnected = 'UNCONNECTED'
}

export enum DeviceEventsLoadTicketStatusCode {
  /** Loaded material where zone is not a loading zone */
  LoadedInNoLoadZone = 'LOADED_IN_NO_LOAD_ZONE',
  /** Loaded material where material was not present in that location */
  LoadedMaterialNotPresentInLocation = 'LOADED_MATERIAL_NOT_PRESENT_IN_LOCATION',
  /** Loaded material from a location other than the start zone of the assigned flow */
  LoadedNotInStartZone = 'LOADED_NOT_IN_START_ZONE',
  /** Loaded material outside any zone */
  LoadedOutsideAnyZone = 'LOADED_OUTSIDE_ANY_ZONE',
  /** Offload where the zone is not an offloading zone */
  OffloadedInNoOffloadZone = 'OFFLOADED_IN_NO_OFFLOAD_ZONE',
  /** Offload where material is not allowed to be offloaded */
  OffloadedMaterialNotPresentInLocation = 'OFFLOADED_MATERIAL_NOT_PRESENT_IN_LOCATION',
  /** Offload in a location other that the end zone of the assigned flow */
  OffloadedNotInEndZone = 'OFFLOADED_NOT_IN_END_ZONE',
  /** Offload outside any zone */
  OffloadedOutsideAnyZone = 'OFFLOADED_OUTSIDE_ANY_ZONE',
  /** Different offload weight compared to original load weight */
  OffloadedWeightDifferFromLoaded = 'OFFLOADED_WEIGHT_DIFFER_FROM_LOADED'
}

export enum DeviceEventsStatisticsFields {
  Status = 'STATUS',
  StatusMessage = 'STATUS_MESSAGE',
  Type = 'TYPE'
}

export enum DeviceEventsStatisticsGranularity {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type DistanceHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** [ARI] Accumulated odometer value in meter. */
  distance: Scalars['Int']['output'];
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type DistanceQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Data points for accumulated distance updates from ARI. */
  distance: Array<DistanceHistoryEvent>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type DriverAssignedPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type EcuHardware = {
  /** [VDA] Value for Field Number. */
  fieldNumber?: Maybe<Scalars['String']['output']>;
  /** [VDA] Value for Part Number. */
  partNumber?: Maybe<Scalars['String']['output']>;
  /** [VDA] Value for Serial Number. */
  serialNumber?: Maybe<Scalars['String']['output']>;
};

export type EditMaterialFamilyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Min length of name 3 */
  name: Scalars['String']['input'];
  shortname?: InputMaybe<Scalars['String']['input']>;
};

export type EditMaterialTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  materialFamilyId: Scalars['ID']['input'];
  /** Min length of name 3 */
  name: Scalars['String']['input'];
  shortname?: InputMaybe<Scalars['String']['input']>;
};

export type EditMaterialVariantInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Min length of name 3 */
  name: Scalars['String']['input'];
  shortname?: InputMaybe<Scalars['String']['input']>;
};

export enum EditMode {
  /** Adds machine owning company ids to existing */
  Add = 'ADD',
  /** Removes provided machine owning company ids */
  Remove = 'REMOVE',
  /** Sets machine owning company ids */
  Set = 'SET'
}

export type EditOperatorInput = {
  employmentNumber: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  /** Min length of name 2 */
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type EditSiteMachineInput = {
  /** Max length 100 char */
  description?: InputMaybe<Scalars['String']['input']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
  loadCapacity?: InputMaybe<Scalars['Float']['input']>;
  loadCapacityUnitId: Scalars['String']['input'];
  machineOwningCompanyId?: InputMaybe<Scalars['ID']['input']>;
  machineTypeId: Scalars['String']['input'];
  manualLoadTickets?: InputMaybe<Scalars['Boolean']['input']>;
  /** Min length of name 3 */
  name: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['ID']['input']>;
  trackerModeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EditSiteTabletDeviceInput = {
  appStatus?: InputMaybe<Scalars['String']['input']>;
  appVersion?: InputMaybe<Scalars['String']['input']>;
  batteryLevel?: InputMaybe<Scalars['Float']['input']>;
  healthSnapshot?: InputMaybe<Scalars['String']['input']>;
  machineId?: InputMaybe<Scalars['ID']['input']>;
  reconnectState?: InputMaybe<TabletReconnectState>;
  tabletState?: InputMaybe<TabletDeviceState>;
  updateLastCheckin?: InputMaybe<Scalars['Boolean']['input']>;
  uptime?: InputMaybe<Scalars['Int']['input']>;
};

export type EditSiteUserInput = {
  /** Min length (2) */
  firstName: Scalars['String']['input'];
  /** Min length (2) */
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type EmobAsset = {
  alias?: Maybe<Scalars['String']['output']>;
  assetCurrentStatus?: Maybe<EmobAssetCurrentStatus>;
  assetType?: Maybe<EmobAssetType>;
  chassisId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  hibooExternalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pin17?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type EmobAssetCurrentStatus = {
  assetId?: Maybe<Scalars['String']['output']>;
  chargingStatus?: Maybe<EmobChargingStatus>;
  createdDateTime?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  siteId?: Maybe<Scalars['String']['output']>;
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  zoneId?: Maybe<Scalars['String']['output']>;
  zoneType?: Maybe<Scalars['String']['output']>;
};

export enum EmobAssetGroups {
  Charger = 'CHARGER',
  Vehicle = 'VEHICLE'
}

export type EmobAssetQueries = {
  /** @deprecated This query will be replaced by new operator services */
  emobAssetBySiteId: Array<EmobAsset>;
};


export type EmobAssetQueries_EmobAssetBySiteIdArgs = {
  site: Scalars['ID']['input'];
};

export type EmobAssetType = {
  assetGroup: EmobAssetGroups;
  brand: Scalars['String']['output'];
  connectorTypes?: Maybe<Array<EmobConnectorType>>;
  createdDateTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  model: Scalars['String']['output'];
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type EmobAssetTypeInput = {
  assetGroup?: InputMaybe<EmobAssetGroups>;
  brand?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
};

export type EmobAssetTypeQueries = {
  /** @deprecated This query will be replaced by new operator services */
  emobAssetTypes: Array<EmobAssetType>;
};


export type EmobAssetTypeQueries_EmobAssetTypesArgs = {
  assetTypes?: InputMaybe<Array<EmobAssetTypeInput>>;
};

export enum EmobChargerConnectorTypes {
  Type_1 = 'TYPE_1',
  Type_1Combo_1 = 'TYPE_1_COMBO_1',
  Type_2 = 'TYPE_2',
  Type_2Combo_2 = 'TYPE_2_COMBO_2'
}

export type EmobChargerExampleApp = {
  /** Returns a list of ChargingStations. */
  chargingStations: Array<ChargingStation>;
  /** Returns a list of EmobChargerExampleEntity. */
  exampleEntities: Array<EmobChargerExampleEntity>;
};


export type EmobChargerExampleApp_ChargingStationsArgs = {
  stationInternalIds: Array<Scalars['String']['input']>;
};


export type EmobChargerExampleApp_ExampleEntitiesArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type EmobChargerExampleEntity = {
  /** Every `EmobChargerExampleEntity` has a `ChargingStation` */
  charger?: Maybe<ChargingStation>;
  /** DCSP ID of a `EmobChargerExampleEntity` */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
};

export type EmobChargerMutations = {
  /** Creates a new charging station. */
  createChargingStation: ChargingStation;
};


export type EmobChargerMutations_CreateChargingStationArgs = {
  name: Scalars['String']['input'];
  ocppStationId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type EmobChargerQueries = {
  /** Returns a list of ChargingStations. */
  backofficeChargingStations: Array<Maybe<ChargingStation>>;
};

export enum EmobChargingStatus {
  Charging = 'Charging',
  ChargingDone = 'ChargingDone',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Interrupted = 'Interrupted'
}

export type EmobConnectorType = {
  assetTypeId: Scalars['String']['output'];
  chargerConnectorType: EmobChargerConnectorTypes;
  createdDateTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type EmobCpmsSubscriptionMutations = {
  /** Add a new CPMS subscription allowing the DCSP platform API access to ingest and fetch data. */
  addOrganizationSubscription: CpmsSubscription;
  /** Create a new CPMS subscription allowing the DCSP platform API access to ingest and fetch data. */
  createOrganizationSubscription: CpmsSubscription;
  /**
   * Create a new CPMS subscription allowing the DCSP platform API access to ingest and fetch data.
   * @deprecated Use createOrganizationSubscription instead
   */
  createSubscription: CpmsSubscription;
};


export type EmobCpmsSubscriptionMutations_AddOrganizationSubscriptionArgs = {
  input: AddOrganizationSubscriptionInput;
};


export type EmobCpmsSubscriptionMutations_CreateOrganizationSubscriptionArgs = {
  input: CreateOrganizationSubscriptionInput;
};


export type EmobCpmsSubscriptionMutations_CreateSubscriptionArgs = {
  actorId: Scalars['String']['input'];
  credentials: Scalars['String']['input'];
  name: Scalars['String']['input'];
  source: IngestionSource;
};

export type EmobCpmsSubscriptionQueries = {
  /** Get connection status for provided CPMS and credentials */
  credentialsConnectionStatus?: Maybe<ConnectionStatusModel>;
  /** Returns CPMS subscriptions connection status */
  subscriptionConnectionStatus?: Maybe<Array<SubscriptionConnectionStatusModel>>;
  /** Returns a list of all CPMS subscriptions */
  subscriptions: Array<CpmsSubscription>;
  /**
   * Returns a list of CPMS subscriptions for all actors found on the token used to authorize the request
   * @deprecated Use subscriptionsByOrganizations instead
   */
  subscriptionsByActors: Array<CpmsSubscription>;
  /** Returns a list of CPMS subscriptions for all organizations (and actors during migration) found on the token used to authorize the request */
  subscriptionsByOrganizations: Array<CpmsSubscription>;
};


export type EmobCpmsSubscriptionQueries_CredentialsConnectionStatusArgs = {
  credentials: Scalars['String']['input'];
  source: IngestionSource;
};


export type EmobCpmsSubscriptionQueries_SubscriptionConnectionStatusArgs = {
  subscriptionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** AMM data about the customer. */
export type EndCustomer = {
  /** [AMM] */
  ecPartyID?: Maybe<Scalars['String']['output']>;
  /** [AMM] Address line 1 used in AMM for the registered end customer. */
  endCustomerAddress1?: Maybe<Scalars['String']['output']>;
  /** [AMM] Address line 2 used in AMM for the registered end customer. */
  endCustomerAddress2?: Maybe<Scalars['String']['output']>;
  /** [AMM] Address line 3 used in AMM for the registered end customer. */
  endCustomerAddress3?: Maybe<Scalars['String']['output']>;
  /** [AMM] Address line 4 used in AMM for the registered end customer. */
  endCustomerAddress4?: Maybe<Scalars['String']['output']>;
  /** [AMM] Category used in AMM for the registered end customer. */
  endCustomerCategory?: Maybe<Scalars['String']['output']>;
  /** [AMM] Id used in AMM for the registered end customer. */
  endCustomerCode?: Maybe<Scalars['String']['output']>;
  /** [AMM] Machine alias set by the end customer. */
  endCustomerEquipmentAlias?: Maybe<Scalars['String']['output']>;
  /** [AMM] Name used in AMM for the registered end customer. */
  endCustomerName?: Maybe<Scalars['String']['output']>;
  /** [AMM] Timestamp on when the registered end customer was last updated. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export enum EnergySource {
  Diesel = 'Diesel',
  Electricity = 'Electricity',
  FuelCell = 'FuelCell',
  Hydrogen = 'Hydrogen',
  Unknown = 'Unknown'
}

export type EnergySourceFilterClass = {
  in?: InputMaybe<Array<EnergySource>>;
};

export type EngineHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** [ARI] Was the engine running at the last summary event (updates on location, fuelLevel, operating time, vehicleMode/eventType)? */
  engineOn?: Maybe<Scalars['Boolean']['output']>;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type EngineStatusQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** Data points for engine power events from ARI. */
  engineStatus: Array<EngineHistoryEvent>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type EnterSiteEventPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
  weightUnit?: InputMaybe<Scalars['String']['input']>;
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type EnterZoneEventPayloadInput = {
  chargingCapable?: InputMaybe<Scalars['Boolean']['input']>;
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  /** If entering a zone that belongs to the active flow the value START_ZONE or END_ZONE is set, otherwise null */
  materialFlowZoneType?: InputMaybe<Scalars['String']['input']>;
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
  weightUnit?: InputMaybe<Scalars['String']['input']>;
  zoneId: Scalars['ID']['input'];
  zoneType?: InputMaybe<Scalars['String']['input']>;
};

export type EqualsStringFilterClass = {
  equals?: InputMaybe<Scalars['String']['input']>;
};

export type EswArrayParameter = {
  parameterCode: Scalars['String']['output'];
  value: Array<EswArrayValueUnion>;
};

export type EswArrayValueUnion = EswNestedBitFieldValue | EswNestedBlobValue | EswNestedBooleanValue | EswNestedEnumValue | EswNestedScalarValue | EswNestedStringValue | EswNestedStructValue;

export type EswBitFieldElement = {
  enumValue: EswNestedEnumValue;
  name: EswParameterDataIdentifier;
};

export type EswBitFieldParameter = {
  bitFieldElement: Array<EswBitFieldElement>;
  parameterCode: Scalars['String']['output'];
};

export type EswBlobParameter = {
  parameterCode: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EswBooleanParameter = {
  parameterCode: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type EswEnumParameter = {
  name?: Maybe<EswParameterDataIdentifier>;
  parameterCode: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type EswNestedBitFieldValue = {
  bitFieldElement: Array<EswBitFieldElement>;
};

export type EswNestedBlobValue = {
  value: Scalars['String']['output'];
};

export type EswNestedBooleanValue = {
  value: Scalars['Boolean']['output'];
};

export type EswNestedEnumValue = {
  name?: Maybe<EswParameterDataIdentifier>;
  value: Scalars['Float']['output'];
};

export type EswNestedScalarValue = {
  unit: Scalars['String']['output'];
  unitGlobalDataRef?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type EswNestedStringValue = {
  value: Scalars['String']['output'];
};

export type EswNestedStructValue = {
  element: Array<EswStructureElementValue>;
  name?: Maybe<EswParameterDataIdentifier>;
};

export type EswParameterDataIdentifier = {
  caption?: Maybe<Scalars['String']['output']>;
  captionRef?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
};

export type EswParametersData = EswArrayParameter | EswBitFieldParameter | EswBlobParameter | EswBooleanParameter | EswEnumParameter | EswScalarParameter | EswStringParameter | EswStructParameter | EswTea2Parameter;

export type EswScalarParameter = {
  parameterCode: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  unitGlobalDataRef?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type EswStringParameter = {
  parameterCode: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EswStructParameter = {
  element: Array<EswStructureElementValue>;
  name?: Maybe<EswParameterDataIdentifier>;
  parameterCode: Scalars['String']['output'];
};

export type EswStructValueUnion = EswNestedBitFieldValue | EswNestedBlobValue | EswNestedBooleanValue | EswNestedEnumValue | EswNestedScalarValue | EswNestedStringValue;

export type EswStructureElementValue = {
  name?: Maybe<EswParameterDataIdentifier>;
  value: EswStructValueUnion;
};

export type EswTea2Parameter = {
  parameterCode: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** The event type of a transaction */
export enum EventBusTransactionTypeEnum {
  LoadTicketEvent = 'LOAD_TICKET_EVENT',
  SiteTransactionEvent = 'SITE_TRANSACTION_EVENT'
}

/** Status of the workflow */
export enum EventType {
  IdleStart = 'IdleStart',
  IdleStop = 'IdleStop',
  OperatorIdChange = 'OperatorIdChange',
  System = 'SYSTEM',
  ShiftChanged = 'ShiftChanged',
  Started = 'Started',
  Stopped = 'Stopped',
  Update = 'UPDATE',
  Verbose = 'VERBOSE'
}

export type EventTypeHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  /** [ARI] Last received event from the machine. W-ECU only. */
  eventType: EventType;
  id: Scalars['ID']['output'];
};

export type EventTypeQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** An event that describes the event type. It is derived from ARI Machine Summary data. W-ECU only. */
  eventType: Array<EventTypeHistoryEvent>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type Evse = {
  /** List of connectors that is attached to the Evse */
  connectors: Array<Connector>;
  /** Type of current provided by the Evse */
  currentType: CurrentType;
  /** ID of the Evse */
  evseId: Scalars['ID']['output'];
};

export type ExitSiteEventPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
  weightUnit?: InputMaybe<Scalars['String']['input']>;
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type ExitZoneEventPayloadInput = {
  chargingCapable?: InputMaybe<Scalars['Boolean']['input']>;
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  /** If exiting a zone that belongs to the active flow the value START_ZONE or END_ZONE is set, otherwise null */
  materialFlowZoneType?: InputMaybe<Scalars['String']['input']>;
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
  weightUnit?: InputMaybe<Scalars['String']['input']>;
  zoneId: Scalars['ID']['input'];
  zoneType?: InputMaybe<Scalars['String']['input']>;
};

export type ExtendedLocationInput = {
  enabled: Scalars['Boolean']['input'];
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type ExternalConnectionInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  externalService: FleetMachineExternalConnectionInput;
};

export type ExternalCrusherWeighersMutations = {
  triggerImport: Scalars['Boolean']['output'];
};


export type ExternalCrusherWeighersMutations_TriggerImportArgs = {
  ownerId: Scalars['String']['input'];
};

export type ExternalMaterialMapping = {
  createdAt: Scalars['DateTime']['output'];
  externalName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  materialVariant?: Maybe<MaterialVariant>;
  siteCompany?: Maybe<SiteCompany>;
  source: MaterialMappingExternalService;
  updatedAt: Scalars['DateTime']['output'];
};

export type ExternalMaterialMappingConnection = {
  edges: Array<ExternalMaterialMappingEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ExternalMaterialMappingEdge = {
  cursor: Scalars['String']['output'];
  node: ExternalMaterialMapping;
};

export type ExternalMaterialMappingFilterBy = {
  companyId?: InputMaybe<IdFilterClass>;
  createdAt?: InputMaybe<DateFilterClass>;
  externalName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<IdFilterClass>;
  materialVariantId?: InputMaybe<IdFilterClass>;
  siteMetricDefinitionId?: InputMaybe<IdFilterClass>;
  source?: InputMaybe<ExternalMaterialMappingSource>;
};

export type ExternalMaterialMappingMutations = {
  /** *Requires authentication* */
  delete: ExternalMaterialMapping;
  /** *Requires authentication* */
  update: ExternalMaterialMapping;
};


export type ExternalMaterialMappingMutations_DeleteArgs = {
  id: Scalars['ID']['input'];
};


export type ExternalMaterialMappingMutations_UpdateArgs = {
  id: Scalars['ID']['input'];
  input: ExternalMaterialUpdateInput;
};

export type ExternalMaterialMappingOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

/** Possible sources for external mappings */
export enum ExternalMaterialMappingSource {
  Ip21 = 'IP21',
  Smarttonnes = 'SMARTTONNES'
}

export type ExternalMaterialUpdateInput = {
  materialVariantId?: InputMaybe<Scalars['ID']['input']>;
};

export type ExternalMetricDefinitionsMutations = {
  triggerImport: Scalars['Boolean']['output'];
};


export type ExternalMetricDefinitionsMutations_TriggerImportArgs = {
  ownerId: Scalars['String']['input'];
};

export enum ExternalReferenceTypes {
  Flintab = 'FLINTAB',
  Loadrite = 'LOADRITE',
  OperatorApp = 'OPERATOR_APP',
  Tamtron = 'TAMTRON',
  Vei = 'VEI',
  VolvoAssist = 'VOLVO_ASSIST'
}

/** Possible types of external services */
export enum ExternalService {
  Assist = 'ASSIST',
  Flintab = 'FLINTAB',
  Hiboo = 'HIBOO',
  Hibooapi = 'HIBOOAPI',
  Ip21 = 'IP21',
  Loadrite = 'LOADRITE',
  Manuallycreated = 'MANUALLYCREATED',
  Sebadata = 'SEBADATA',
  Siteoperations = 'SITEOPERATIONS',
  Smarttonnes = 'SMARTTONNES',
  Tamtron = 'TAMTRON',
  Unknown = 'UNKNOWN',
  Vei = 'VEI'
}

export type ExternalSourceReference = {
  id: Scalars['ID']['output'];
  source: ExternalService;
};

export type FailureComponentData = {
  /** [Teacore] Failure component caption */
  caption?: Maybe<Scalars['String']['output']>;
  /** [Teacore] Reference to caption */
  captionRef?: Maybe<Scalars['String']['output']>;
  /** [Teacore] Failure component diagnostic object identifier */
  diagnosticObjectId: Scalars['String']['output'];
  /** [PI] Dtc Identifier based on the failure component identifier */
  dtcIdentifier?: Maybe<Scalars['String']['output']>;
  /** [Teacore] Failure component identifier. */
  failureComponentIdentifier?: Maybe<Scalars['String']['output']>;
  /** [Teacore] Array of Failure Type Bytes with Fault Code */
  failureTypeBytes: Array<FailureTypeByteData>;
  /** [Teacore] Standardized text */
  standardizedText?: Maybe<Scalars['String']['output']>;
};

export type FailureTypeByteData = {
  /** [Teacore] Failure component caption */
  caption?: Maybe<Scalars['String']['output']>;
  /** [Teacore] Reference to caption */
  captionRef?: Maybe<Scalars['String']['output']>;
  /** [PI] Fault Code based on dtcIdentifier and failureTypeByte */
  faultCode?: Maybe<Scalars['String']['output']>;
  /** [Teacore] Failure Type Byte value */
  value?: Maybe<Scalars['String']['output']>;
};

export type FaultCodeHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  /** List of fault codes/DTCs received in each incoming event from ARI. */
  faultCodes: Array<MachineFaultCodeHistorical>;
  id: Scalars['ID']['output'];
  /** [ARI] Timestamp of the latest fault code lastFailureTime. */
  latestFailureTime?: Maybe<Scalars['DateTime']['output']>;
};

export type FaultCodeQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** Fault code/DTC updates from ARI. */
  faultCodes: Array<FaultCodeHistoryEvent>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type File = {
  createdDateTime: Scalars['DateTime']['output'];
  fileName: Scalars['String']['output'];
  fileRelations?: Maybe<Array<FileRelation>>;
  id: Scalars['ID']['output'];
  machine: Machine;
  serviceName: Scalars['String']['output'];
};

export type FileQueries = {
  file: File;
  fileTestSupergraph: Scalars['Boolean']['output'];
};


export type FileQueries_FileArgs = {
  id: Scalars['ID']['input'];
  serviceName: Scalars['ID']['input'];
};

export type FileRelation = {
  fileId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  relationType: RelationType;
  relationValue: Scalars['String']['output'];
};

export type FleetAsset = {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<FleetAssetSource>;
  type: FleetAssetType;
  updatedAt: Scalars['DateTime']['output'];
};

/** Filter for fleet assets depending on if they are activated for data import. */
export enum FleetAssetActivatedForDataImportFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Inactive = 'INACTIVE'
}

export type FleetAssetCharger = FleetAsset & {
  activatedForDataImport: Scalars['Boolean']['output'];
  chargingStation?: Maybe<ChargingStation>;
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  source?: Maybe<FleetAssetSource>;
  type: FleetAssetType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FleetAssetChargerMutations = {
  /** *Requires authentication* */
  update: FleetAssetCharger;
};


export type FleetAssetChargerMutations_UpdateArgs = {
  id: Scalars['ID']['input'];
  input: ChargerUpdateInput;
};

export type FleetAssetConnection = {
  edges: Array<FleetAssetEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type FleetAssetEdge = {
  cursor: Scalars['String']['output'];
  node: FleetAssetNode;
};

export type FleetAssetFilterInput = {
  activatedForDataImport?: InputMaybe<FleetAssetActivatedForDataImportFilter>;
  fleetAssetSource?: InputMaybe<FleetAssetSource>;
  fleetAssets?: InputMaybe<Array<FleetAssetType>>;
  status?: InputMaybe<FleetAssetStatusFilter>;
};

export type FleetAssetFilterOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type FleetAssetMutations = {
  charger: FleetAssetChargerMutations;
  /** *Requires authentication* */
  delete: Scalars['Boolean']['output'];
  weighBridge: WeighBridgeMutations;
};


export type FleetAssetMutations_DeleteArgs = {
  id: Scalars['ID']['input'];
};

export type FleetAssetNode = {
  asset: FleetAsset;
  id: Scalars['ID']['output'];
  siteAssets: SiteAssetConnection;
};


export type FleetAssetNode_SiteAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteAssetFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteAssetOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type FleetAssetQueries = {
  /** *Requires authentication* */
  asset: FleetAssetNode;
  /** *Requires authentication* */
  assets: FleetAssetConnection;
};


export type FleetAssetQueries_AssetArgs = {
  id: Scalars['ID']['input'];
};


export type FleetAssetQueries_AssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<FleetAssetFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FleetAssetFilterOrderBy>;
  ownerId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** supported sources for fleet assets */
export enum FleetAssetSource {
  Sebadata = 'SEBADATA',
  Smarttonnes = 'SMARTTONNES',
  Unknown = 'UNKNOWN'
}

/** Filter for fleet assets depending on their status. */
export enum FleetAssetStatusFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Deprecated = 'DEPRECATED'
}

/** Supported asset types in fleet */
export enum FleetAssetType {
  Charger = 'CHARGER',
  WeighBridge = 'WEIGH_BRIDGE'
}

export type FleetAssetWeighBridge = FleetAsset & {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  scale?: Maybe<Scale>;
  source?: Maybe<FleetAssetSource>;
  type: FleetAssetType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FleetChargerQueries = {
  /** Returns a list of ChargingStations. */
  allCaretrackChargingStations: Array<Maybe<ChargingStation>>;
};

export type FleetMachine = {
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalServiceId?: Maybe<Scalars['ID']['output']>;
  externalServiceIds: Array<FleetMachineExternalServiceId>;
  externalSourceReference: ExternalSourceReference;
  id: Scalars['ID']['output'];
  /** @deprecated This field is no longer in use */
  loadriteSite?: Maybe<Scalars['String']['output']>;
  /** Get the machine permission origins for a fleet machine (Asset manager) */
  machinePermissionOrigins: Array<MachinePermissionOrigin>;
  machineStatistics: StatisticsConnection;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onboardWeighingSystem: Scalars['Boolean']['output'];
  serialNumber?: Maybe<Scalars['String']['output']>;
  siteLoadTickets: SiteLoadTicketsConnection;
  siteMachineIds: Array<Scalars['String']['output']>;
  siteMachines: Array<Maybe<SiteMachine>>;
  snapshotEquipment?: Maybe<FleetMachineSnapshotEquipment>;
  tripStatistics: StatisticsConnection;
  trips: TripConnection;
  updatedAt: Scalars['DateTime']['output'];
};


export type FleetMachine_ExternalServiceIdArgs = {
  service: AssetManagerExternalServiceInput;
};


export type FleetMachine_MachineStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: MachineStatisticsFields;
  filterBy?: InputMaybe<MachineStatisticsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: MachineStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  propertyId: MachineStatisticsId;
  to: Scalars['DateTime']['input'];
};


export type FleetMachine_SiteLoadTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteLoadTicketsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteLoadTicketsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type FleetMachine_TripStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: TripStatisticsFields;
  filterBy?: InputMaybe<TripFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: TripStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};


export type FleetMachine_TripsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<TripFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TripFilterOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type FleetMachineConnection = {
  edges: Array<FleetMachineEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type FleetMachineCreateInput = {
  externalId: Scalars['ID']['input'];
  machineOwningCompanyId: Scalars['ID']['input'];
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  name: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  siteMachineIds: Array<Scalars['String']['input']>;
  source: AssetManagerExternalServiceInput;
};

export type FleetMachineCumulativeDistance = {
  date?: Maybe<Scalars['DateTime']['output']>;
  odometer?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type FleetMachineCumulativeFuelUsed = {
  date?: Maybe<Scalars['DateTime']['output']>;
  fuelUsed?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type FleetMachineCumulativeHours = {
  date?: Maybe<Scalars['DateTime']['output']>;
  hour?: Maybe<Scalars['Float']['output']>;
  usageHours?: Maybe<Scalars['Float']['output']>;
};

export type FleetMachineCumulativeIdleHours = {
  date?: Maybe<Scalars['DateTime']['output']>;
  hours?: Maybe<Scalars['Float']['output']>;
};

export type FleetMachineCumulativeLoadCount = {
  count?: Maybe<Scalars['Boolean']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
};

export type FleetMachineCumulativePayloadTotal = {
  date?: Maybe<Scalars['DateTime']['output']>;
  payload?: Maybe<Scalars['Boolean']['output']>;
};

export type FleetMachineDailyUsage = {
  dailyUsage: FleetMachineDailyUsageData;
  day: Scalars['DateTime']['output'];
  faultCodes: Array<FleetMachineFaultCode>;
  fuelRemainings?: Maybe<Array<FleetMachineFuelRemaining>>;
  hibooMachineId: Scalars['ID']['output'];
  positions?: Maybe<Array<FleetMachinePosition>>;
  updatedDatetime: Scalars['DateTime']['output'];
};

export type FleetMachineDailyUsageData = {
  averageDailyEngineLoadFactor?: Maybe<FleetMachineDailyUsagePercentStatistic>;
  cumulativeActiveRegenerationHours?: Maybe<FleetMachineDailyUsageHourStatistic>;
  cumulativeDistance?: Maybe<FleetMachineDailyUsageDistanceStatistic>;
  cumulativeFuelUsed?: Maybe<FleetMachineDailyUsageFuelStatistic>;
  cumulativeIdleHours?: Maybe<FleetMachineDailyUsageHourStatistic>;
  cumulativeOperatingHours?: Maybe<FleetMachineDailyUsageHourStatistic>;
  cumulativePowerTakeOffHours?: Maybe<FleetMachineDailyUsageHourStatistic>;
  dailyDistance?: Maybe<FleetMachineDailyUsageDistanceStatistic>;
  date: Scalars['DateTime']['output'];
  fuelUsed?: Maybe<FleetMachineDailyUsageFuelStatistic>;
  idleHours?: Maybe<FleetMachineDailyUsageHourStatistic>;
  operatingHours?: Maybe<FleetMachineDailyUsageHourStatistic>;
  workingHours?: Maybe<FleetMachineDailyUsageHourStatistic>;
};

export type FleetMachineDailyUsageDistanceStatistic = {
  odometer?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type FleetMachineDailyUsageFuelStatistic = {
  fuelUsed?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type FleetMachineDailyUsageHourStatistic = {
  hour?: Maybe<Scalars['Float']['output']>;
};

export type FleetMachineDailyUsagePercentStatistic = {
  percent?: Maybe<Scalars['Float']['output']>;
};

export type FleetMachineEdge = {
  cursor: Scalars['String']['output'];
  node: FleetMachine;
};

export type FleetMachineEditInput = {
  externalConnections?: InputMaybe<Array<ExternalConnectionInput>>;
  siteMachineIds?: InputMaybe<PropertyStringArrayInput>;
};

export type FleetMachineEditPropertiesInput = {
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type FleetMachineEngineCondition = {
  date?: Maybe<Scalars['DateTime']['output']>;
  running?: Maybe<Scalars['Boolean']['output']>;
};

export enum FleetMachineExternalConnectionInput {
  Loadrite = 'LOADRITE',
  Tamtron = 'TAMTRON',
  Vei = 'VEI'
}

export type FleetMachineExternalServiceId = {
  externalId: Scalars['ID']['output'];
  externalService: ExternalService;
  fleetMachineId: Scalars['ID']['output'];
};

export type FleetMachineFaultCode = {
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  levelLabel?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<Scalars['Int']['output']>;
};

export type FleetMachineFilterInput = {
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  externalServices?: InputMaybe<Array<AssetManagerExternalServiceInput>>;
  siteMachineIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FleetMachineFilterOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type FleetMachineFuelRemaining = {
  date?: Maybe<Scalars['DateTime']['output']>;
  percent?: Maybe<Scalars['Float']['output']>;
};

export type FleetMachineLastPosition = {
  date?: Maybe<Scalars['DateTime']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type FleetMachineMutations = {
  /** *Requires authentication* */
  create: FleetMachine;
  /** *Requires authentication* */
  import: Array<FleetMachine>;
  /** Requires assetmanager admin scope and is used in order to resend all machines */
  resendImportedMachines: Scalars['Boolean']['output'];
  /** *Requires authentication* */
  update: FleetMachine;
  /** *Requires authentication* */
  updateMachineProperties: FleetMachine;
};


export type FleetMachineMutations_CreateArgs = {
  input: FleetMachineCreateInput;
};


export type FleetMachineMutations_ImportArgs = {
  machineOwningCompanyId: Scalars['ID']['input'];
  service: AssetManagerExternalServiceInput;
};


export type FleetMachineMutations_UpdateArgs = {
  input: FleetMachineEditInput;
  machineId: Scalars['ID']['input'];
};


export type FleetMachineMutations_UpdateMachinePropertiesArgs = {
  input: FleetMachineEditPropertiesInput;
  machineId: Scalars['ID']['input'];
};

export type FleetMachinePosition = {
  date?: Maybe<Scalars['DateTime']['output']>;
  latitude?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['Int']['output']>;
};

export type FleetMachineQueries = {
  /** *Requires authentication* */
  fleetMachine?: Maybe<FleetMachine>;
  /** *Requires authentication* */
  fleetMachines?: Maybe<FleetMachineConnection>;
};


export type FleetMachineQueries_FleetMachineArgs = {
  fleetMachineId: Scalars['ID']['input'];
};


export type FleetMachineQueries_FleetMachinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<FleetMachineFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  machineOwningCompanyId: Scalars['ID']['input'];
  orderBy?: InputMaybe<FleetMachineFilterOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type FleetMachineSnapshotEquipment = {
  cumulativeDistance?: Maybe<FleetMachineCumulativeDistance>;
  cumulativeFuelUsed?: Maybe<FleetMachineCumulativeFuelUsed>;
  cumulativeIdleHours?: Maybe<FleetMachineCumulativeIdleHours>;
  cumulativeLoadCount?: Maybe<FleetMachineCumulativeLoadCount>;
  cumulativeOperatingHours?: Maybe<FleetMachineCumulativeHours>;
  cumulativePayloadTotal?: Maybe<FleetMachineCumulativePayloadTotal>;
  cumulativeUsageHours?: Maybe<FleetMachineCumulativeHours>;
  engineCondition?: Maybe<FleetMachineEngineCondition>;
  fuelRemaining?: Maybe<FleetMachineFuelRemaining>;
  id: Scalars['ID']['output'];
  lastPosition?: Maybe<FleetMachineLastPosition>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
};

export type FleetMachineSnapshotEquipmentQueries = {
  /** *Requires authentication* */
  fleetMachineSnapshotEquipment?: Maybe<FleetMachineSnapshotEquipment>;
};


export type FleetMachineSnapshotEquipmentQueries_FleetMachineSnapshotEquipmentArgs = {
  id: Scalars['ID']['input'];
};

export type FleetMetricDefinition = {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source: MetricDefinitionSource;
  type: MetricDefinitionType;
  updatedAt: Scalars['DateTime']['output'];
};

/** Filter for fleet metrics depending on if they are activated for data import. */
export enum FleetMetricDefinitionActivatedForDataImportFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Inactive = 'INACTIVE'
}

export type FleetMetricDefinitionConnection = {
  edges: Array<FleetMetricDefinitionEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type FleetMetricDefinitionEdge = {
  cursor: Scalars['String']['output'];
  node: FleetMetricDefinitionNode;
};

export type FleetMetricDefinitionFilterInput = {
  activatedForDataImport?: InputMaybe<FleetMetricDefinitionActivatedForDataImportFilter>;
  metricDefinitionSource?: InputMaybe<MetricDefinitionSource>;
  metricDefinitions?: InputMaybe<Array<FleetMetricDefinitionInputType>>;
  status?: InputMaybe<FleetMetricDefinitionStatusFilter>;
};

export type FleetMetricDefinitionFilterOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type FleetMetricDefinitionFlowRateMass = FleetMetricDefinition & {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source: MetricDefinitionSource;
  type: MetricDefinitionType;
  updatedAt: Scalars['DateTime']['output'];
};

/** Supported metric definition types */
export enum FleetMetricDefinitionInputType {
  FlowRateMass = 'FLOW_RATE_MASS',
  LevelMass = 'LEVEL_MASS',
  LevelPercent = 'LEVEL_PERCENT',
  LevelVolume = 'LEVEL_VOLUME',
  MassFlowRate = 'MASS_FLOW_RATE',
  Material = 'MATERIAL',
  ProducedMass = 'PRODUCED_MASS',
  StatusIndicator = 'STATUS_INDICATOR'
}

export type FleetMetricDefinitionLevelMass = FleetMetricDefinition & {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source: MetricDefinitionSource;
  type: MetricDefinitionType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FleetMetricDefinitionLevelPercent = FleetMetricDefinition & {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source: MetricDefinitionSource;
  type: MetricDefinitionType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FleetMetricDefinitionLevelVolume = FleetMetricDefinition & {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source: MetricDefinitionSource;
  type: MetricDefinitionType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FleetMetricDefinitionMassFlowRate = FleetMetricDefinition & {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source: MetricDefinitionSource;
  type: MetricDefinitionType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FleetMetricDefinitionMaterial = FleetMetricDefinition & {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source: MetricDefinitionSource;
  type: MetricDefinitionType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FleetMetricDefinitionMutations = {
  /** *Requires authentication* */
  update: FleetMetricDefinitionNode;
};


export type FleetMetricDefinitionMutations_UpdateArgs = {
  input: FleetMetricDefinitionUpdateInput;
  metricDefinitionId: Scalars['ID']['input'];
};

export type FleetMetricDefinitionNode = {
  definition: FleetMetricDefinition;
  id: Scalars['ID']['output'];
  siteMetricDefinitions: SiteMetricDefinitionConnection;
};


export type FleetMetricDefinitionNode_SiteMetricDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMetricDefinitionFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMetricDefinitionOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type FleetMetricDefinitionProducedMass = FleetMetricDefinition & {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source: MetricDefinitionSource;
  type: MetricDefinitionType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FleetMetricDefinitionQueries = {
  /** *Requires authentication* */
  metricDefinition: FleetMetricDefinitionNode;
  /** *Requires authentication* */
  metricDefinitions: FleetMetricDefinitionConnection;
};


export type FleetMetricDefinitionQueries_MetricDefinitionArgs = {
  id: Scalars['ID']['input'];
};


export type FleetMetricDefinitionQueries_MetricDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<FleetMetricDefinitionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FleetMetricDefinitionFilterOrderBy>;
  ownerId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Filter for fleet metrics depending on their status. */
export enum FleetMetricDefinitionStatusFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Deprecated = 'DEPRECATED'
}

export type FleetMetricDefinitionStatusIndicator = FleetMetricDefinition & {
  activatedForDataImport: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source: MetricDefinitionSource;
  type: MetricDefinitionType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FleetMetricDefinitionUpdateInput = {
  isActivatedForDataImport?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FleetReportTemplate = {
  createdDateTime: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fleetReportTemplateProperties?: Maybe<Array<FleetReportTemplateProperty>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reportType?: Maybe<Scalars['String']['output']>;
  system: Scalars['String']['output'];
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type FleetReportTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportType?: InputMaybe<Scalars['String']['input']>;
  system?: InputMaybe<Scalars['String']['input']>;
};

export type FleetReportTemplateMutations = {
  create: FleetReportTemplate;
  delete: FleetReportTemplate;
  update: FleetReportTemplate;
};


export type FleetReportTemplateMutations_CreateArgs = {
  input: CreateFleetReportTemplateInput;
};


export type FleetReportTemplateMutations_DeleteArgs = {
  input: DeleteFleetReportTemplateInput;
};


export type FleetReportTemplateMutations_UpdateArgs = {
  input: UpdateFleetReportTemplateInput;
};

export type FleetReportTemplateProperty = {
  createdDateTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  keyName: Scalars['String']['output'];
  operator: Scalars['String']['output'];
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  value: Scalars['String']['output'];
};

export type FleetReportTemplateQueries = {
  fleetReportTemplates: Array<FleetReportTemplate>;
};


export type FleetReportTemplateQueries_FleetReportTemplatesArgs = {
  fleetReportTemplates?: InputMaybe<Array<FleetReportTemplateInput>>;
};

export type FloatFilterClass = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatNullableFilterInput = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type FlowObjectiveRateAggregate = {
  amount: Scalars['Float']['output'];
  date: Scalars['String']['output'];
};

/** ARI data to evaluate fuel consumption */
export type FuelConsumption = {
  /** [ARI] Accumulated DEF/Adblue consumption in liter. Diesel machines only. */
  totalDefConsumed?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated DEF/Adblue consumption in excessive idle mode in liter. Diesel machines only. */
  totalDefConsumedExcessiveIdle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated DEF/Adblue consumption in idle mode in liter. Diesel machines only. */
  totalDefConsumedInIdle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated DEF/Adblue consumption in work mode in liter. Diesel machines only. */
  totalDefConsumedInWork?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated fuel consumption in liter. */
  totalFuelConsumed?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated fuel consumption in excessive idle mode in liter. */
  totalFuelConsumedExcessiveIdle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated fuel consumption in idle mode in liter. */
  totalFuelConsumedInIdle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated fuel consumption in work mode in liter. */
  totalFuelConsumedInWork?: Maybe<Scalars['Float']['output']>;
  /**
   * Obsolete. Use `fuelLevel` field in Machine type instead.
   * @deprecated Obsolete. Use fuelLevel field in Machine type instead.
   */
  totalFuelLevel?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Timestamp on when the fuel consumption data was last updated. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type FuelConsumptionQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** The data points for accumulated fuel consumption events from ARI. */
  fuelConsumption: Array<TotalFuelConsumptionHistoryEvent>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type FuelLevelHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** [ARI] DEF/AdBlue level at the time of the event in percentage with 0.4% resolution. */
  defLevel?: Maybe<Scalars['Float']['output']>;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  /** [ARI] Fuel level at the time of the event in percentage with 0.4% resolution. */
  fuelLevel: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** [ARI] Accumulated number of seconds the machine has been used/running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
};

export type FuelLevelQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** The data points for current fuel level events from ARI. */
  fuelLevel: Array<FuelLevelHistoryEvent>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type GeneralInformationData = {
  /** [VDA] Sales model for machine. */
  aftermarketModel?: Maybe<Scalars['String']['output']>;
  /** [VDA] "Volvo" for VCE. */
  commercialBrand?: Maybe<Scalars['String']['output']>;
  /** [VDA] "ALL" for VCE machines. */
  countryOfOperation?: Maybe<Scalars['String']['output']>;
  /** [VDA] Marketing type for machine. */
  marketingType?: Maybe<Scalars['String']['output']>;
  /** [VDA] Refers to architecture of machines. Filled in by factory. */
  productClass?: Maybe<Scalars['String']['output']>;
  /** [VDA] Product type of machine. Unused by VCE but filled in by factory. */
  productType?: Maybe<Scalars['String']['output']>;
  /** [VDA] Tagged to test chassis: Development, Design, or Connected Services Tags. */
  vehicleTestStatus?: Maybe<Scalars['String']['output']>;
};

export type GeographyPhotoModel = {
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  siteMapId: Scalars['String']['output'];
  tileset?: Maybe<TilesetModel>;
};

export type GeographyPhotoMutations = {
  delete: GeographyPhotoModel;
  setActive: SetActiveResponse;
};


export type GeographyPhotoMutations_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type GeographyPhotoMutations_SetActiveArgs = {
  input: SetActiveGeographyPhotosInput;
};

export type GeographyPhotoQueries = {
  bySiteMapId: Array<GeographyPhotoModel>;
};


export type GeographyPhotoQueries_BySiteMapIdArgs = {
  siteMapId: Scalars['ID']['input'];
};

export type GetMaterialFlowsBySiteMapIdOptions = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  machineType?: InputMaybe<Scalars['String']['input']>;
};

export type GetMaterialFlowsByZoneIdOptions = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  machineType?: InputMaybe<Scalars['String']['input']>;
};

export type GetPointOfInterestBySiteMapIdOptions = {
  includeDeleted: Scalars['Boolean']['input'];
};

export type GetZoneMaterialVariantsByZoneIdOptions = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetZonesBySiteMapIdOptions = {
  includeDeleted: Scalars['Boolean']['input'];
};

export type GroupedMaterialTransaction = {
  materialId?: Maybe<Scalars['String']['output']>;
  siteId: Scalars['String']['output'];
  weight: UnitValue;
  zones?: Maybe<Array<GroupedMaterialTransactionZone>>;
};


export type GroupedMaterialTransaction_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GroupedMaterialTransactionData = {
  data: Array<GroupedTransactionData>;
  materialId: Scalars['String']['output'];
};

export type GroupedMaterialTransactionZone = {
  weight: UnitValue;
  zoneId?: Maybe<Scalars['String']['output']>;
};


export type GroupedMaterialTransactionZone_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GroupedOrderData = {
  materialId?: Maybe<Scalars['String']['output']>;
  siteId: Scalars['String']['output'];
  weight: UnitValue;
};


export type GroupedOrderData_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GroupedStockData = {
  modifications?: Maybe<Array<StockModification>>;
  timestamp: Scalars['DateTime']['output'];
  weight: UnitValue;
};


export type GroupedStockData_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GroupedTransactionData = {
  timestamp: Scalars['DateTime']['output'];
  weight: UnitValue;
};


export type GroupedTransactionData_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type HardwareData = {
  /** [VDA] Part number for the Hardware. */
  partNumber?: Maybe<Scalars['String']['output']>;
  /** [VDA] Serial number for Hardware. Serial number is not always filled in, especially when the hardware is changed. */
  serialNumber?: Maybe<Scalars['String']['output']>;
};

export type HaulCycleConnection = {
  edges: Array<HaulCycleEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HaulCycleEdge = {
  cursor: Scalars['String']['output'];
  node: HaulCycleModel;
};

export type HaulCycleFilter = {
  loadFilter?: InputMaybe<LoadFilterInput>;
  materialFilter?: InputMaybe<MaterialFilter>;
  targetFilter: TargetFilterRequireChassisId;
};

export type HaulCycleModel = {
  accuracy?: Maybe<Scalars['Int']['output']>;
  anonymous: Scalars['Boolean']['output'];
  bucketCount?: Maybe<Scalars['BigInt']['output']>;
  carryBack?: Maybe<Scalars['BigInt']['output']>;
  carryBackPercentage?: Maybe<Scalars['Int']['output']>;
  certifiedId?: Maybe<Scalars['String']['output']>;
  certifiedScaleInterval?: Maybe<Scalars['Int']['output']>;
  certifiedWeightUnit?: Maybe<Scalars['Int']['output']>;
  chassisId: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  cycleDistance?: Maybe<Scalars['Float']['output']>;
  cycleDuration?: Maybe<Scalars['Float']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  fuelConsumption?: Maybe<Scalars['Int']['output']>;
  groupId: Scalars['String']['output'];
  heapUuid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  loadAltitude?: Maybe<Scalars['Float']['output']>;
  loadDuration?: Maybe<Scalars['Float']['output']>;
  loadGeographicalZoneName?: Maybe<Scalars['String']['output']>;
  loadGeographicalZoneUuid?: Maybe<Scalars['String']['output']>;
  loadLatitude?: Maybe<Scalars['Float']['output']>;
  loadLongitude?: Maybe<Scalars['Float']['output']>;
  loadSelectedZoneName?: Maybe<Scalars['String']['output']>;
  loadSelectedZoneUuid?: Maybe<Scalars['String']['output']>;
  loadStopDuration?: Maybe<Scalars['Float']['output']>;
  loadTime?: Maybe<Scalars['DateTime']['output']>;
  loadTravelDistance?: Maybe<Scalars['BigInt']['output']>;
  loadTravelDuration?: Maybe<Scalars['Float']['output']>;
  localId?: Maybe<Scalars['BigInt']['output']>;
  machineHours?: Maybe<Scalars['Float']['output']>;
  materialDensity?: Maybe<Scalars['Float']['output']>;
  materialName?: Maybe<Scalars['String']['output']>;
  materialNameId?: Maybe<Scalars['String']['output']>;
  materialUuid?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  overSpeedingDuration?: Maybe<Scalars['Float']['output']>;
  pauseDuration?: Maybe<Scalars['Float']['output']>;
  payloadPercentage?: Maybe<Scalars['BigInt']['output']>;
  targetUuid: Scalars['String']['output'];
  ticketId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  unloadAltitude?: Maybe<Scalars['Float']['output']>;
  unloadDuration?: Maybe<Scalars['Float']['output']>;
  unloadGeographicalZoneName?: Maybe<Scalars['String']['output']>;
  unloadGeographicalZoneUuid?: Maybe<Scalars['String']['output']>;
  unloadLatitude?: Maybe<Scalars['Float']['output']>;
  unloadLongitude?: Maybe<Scalars['Float']['output']>;
  unloadSelectedZoneName?: Maybe<Scalars['String']['output']>;
  unloadSelectedZoneUuid?: Maybe<Scalars['String']['output']>;
  unloadStopDuration?: Maybe<Scalars['Float']['output']>;
  unloadTime?: Maybe<Scalars['DateTime']['output']>;
  unloadTravelDistance?: Maybe<Scalars['BigInt']['output']>;
  unloadTravelDuration?: Maybe<Scalars['Float']['output']>;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  weight?: Maybe<Scalars['Int']['output']>;
  workMode?: Maybe<Scalars['BigInt']['output']>;
  workOrderName?: Maybe<Scalars['String']['output']>;
};

export type HaulCycleQueries = {
  /** Fetch haulcycles data based on chassis ids. Requires authentication */
  haulcycles: HaulCycleConnection;
};


export type HaulCycleQueries_HaulcyclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<HaulCycleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<HaulCycleSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type HaulCycleSort = {
  created?: InputMaybe<SortDirection>;
  updated?: InputMaybe<SortDirection>;
};

export type HistoricalData = {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type HistoricalDataFilterInput = {
  /** The cursor to start at */
  cursor?: InputMaybe<Scalars['String']['input']>;
  /** Start date to search data for. Max 31 days interval. */
  fromDate: Scalars['DateTime']['input'];
  /** Offset of results. Ignored if cursor is provided. Should only be used in edge cases due to added cost compared to the cursor-based method, and has a limit of how big the offset is allowed. */
  skip?: InputMaybe<Scalars['Int']['input']>;
  /** Min 1, Max 100 */
  take: Scalars['Int']['input'];
  /** End date to search data for. Max 31 days interval. */
  toDate: Scalars['DateTime']['input'];
};

/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries = {
  batteryHealthStatus: BatteryHealthStatusQueryResponse;
  batteryStatus: BatteryStatusQueryResponse;
  chargeMonitoring: ChargeMonitoringQueryResponse;
  distance: DistanceQueryResponse;
  engineStatus: EngineStatusQueryResponse;
  eventType: EventTypeQueryResponse;
  faultCodes: FaultCodeQueryResponse;
  fuelConsumption: FuelConsumptionQueryResponse;
  fuelLevel: FuelLevelQueryResponse;
  location: LocationQueryResponse;
  operatingTime: OperatingTimeQueryResponse;
  production: ProductionQueryResponse;
  starterBatteryLevel: StarterBatteryLevelQueryResponse;
  utilization: UtilizationQueryResponse;
  vehicleMode: VehicleModeQueryResponse;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_BatteryHealthStatusArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_BatteryStatusArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_ChargeMonitoringArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_DistanceArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_EngineStatusArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_EventTypeArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_FaultCodesArgs = {
  extendedFilter?: InputMaybe<HistoricalFaultCodesExtendedFilterInput>;
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_FuelConsumptionArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_FuelLevelArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_LocationArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_OperatingTimeArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_ProductionArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_StarterBatteryLevelArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_UtilizationArgs = {
  filter: HistoricalDataFilterInput;
};


/** Queries for historical telematics data for the machine. */
export type HistoricalDataQueries_VehicleModeArgs = {
  filter: HistoricalDataFilterInput;
};

export type HistoricalFaultCodesExtendedFilterInput = {
  /** Use latestFailureTime instead of eventTimestamp if possible, available from Fri Aug 02 2024 02:00:00 GMT+0200 (Central European Summer Time) */
  useLatestFailureTime?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IdArrayFilterClass = {
  equals?: InputMaybe<Array<Scalars['ID']['input']>>;
  has?: InputMaybe<Scalars['ID']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['ID']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['ID']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IdFilterClass = {
  equals?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type IdAndSiteId = {
  id: Scalars['ID']['output'];
  siteId: Scalars['ID']['output'];
};

export type ImportScaleEventLog = {
  id: Scalars['ID']['output'];
  importedAt: Scalars['DateTime']['output'];
  lastPriorScaleSourceCreatedDate?: Maybe<Scalars['DateTime']['output']>;
  ownerId: Scalars['ID']['output'];
};

export type IngestedSubscriptionEvent = {
  emittedMessage?: Maybe<Scalars['JSON']['output']>;
  error?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  ingestedAt: Scalars['DateTime']['output'];
  rawMessage: RawMessage;
};

/** Which CPMS to use as source for a subscription */
export enum IngestionSource {
  Ampeco = 'Ampeco',
  Kempower = 'Kempower',
  Synops = 'Synops',
  Vecr = 'Vecr'
}

export type IntFilterClass = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilterInput = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
};

export enum InvoicingAssetUsageSource {
  /** Source service where the asset usages are coming from. */
  SiteProductivityMain = 'SITE_PRODUCTIVITY_MAIN'
}

export type InvoicingAssetUsageSourcesFilter = {
  equals?: InputMaybe<InvoicingAssetUsageSource>;
  in?: InputMaybe<Array<InvoicingAssetUsageSource>>;
  not?: InputMaybe<InvoicingAssetUsageSource>;
  notIn?: InputMaybe<Array<InvoicingAssetUsageSource>>;
};

export enum InvoicingAssetUsageVariant {
  /** Will count the amount of site machine licenses used. */
  SiteMachine = 'SITE_MACHINE'
}

export type Ip21Credentials = {
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type Ip21CredentialsMutations = {
  deleteCredentials: Scalars['Boolean']['output'];
  setCredentials: Scalars['Boolean']['output'];
};


export type Ip21CredentialsMutations_DeleteCredentialsArgs = {
  ownerId: Scalars['ID']['input'];
};


export type Ip21CredentialsMutations_SetCredentialsArgs = {
  input: SetIp21CredentialsInput;
};

export type Ip21CredentialsQueries = {
  credentials?: Maybe<Ip21Credentials>;
};


export type Ip21CredentialsQueries_CredentialsArgs = {
  ownerId: Scalars['ID']['input'];
};

export type LoadEdge = {
  cursor: Scalars['String']['output'];
  node: LoadModel;
};

export type LoadEventPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  loadTicketCorrelationId?: InputMaybe<Scalars['ID']['input']>;
  /** The source that created the slt. */
  loadTicketSource?: InputMaybe<Scalars['String']['input']>;
  loadTicketStatusCodes?: InputMaybe<Array<DeviceEventsLoadTicketStatusCode>>;
  /** The site machine id of the loading asset. */
  loadingAssetId?: InputMaybe<Scalars['ID']['input']>;
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  materialId: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
  siteLoadTicketId?: InputMaybe<Scalars['ID']['input']>;
  sourceLoadTicketId?: InputMaybe<Scalars['ID']['input']>;
  weight: Scalars['Float']['input'];
  weightUnit: Scalars['String']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type LoadFilterInput = {
  and?: InputMaybe<Array<LoadFilterInput>>;
  created?: InputMaybe<DateFilterClass>;
  loadGeographicalZoneName?: InputMaybe<StringFilterClass>;
  operator?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<LoadFilterInput>>;
  payloadPercentage?: InputMaybe<DateFilterClass>;
  targetUuid?: InputMaybe<StringFilterClass>;
  unloadGeographicalZoneName?: InputMaybe<StringFilterClass>;
  unloadTime?: InputMaybe<DateFilterClass>;
  weight?: InputMaybe<FloatFilterClass>;
};

export type LoadModel = {
  accuracy?: Maybe<Scalars['Int']['output']>;
  anonymous: Scalars['Boolean']['output'];
  bucketCount?: Maybe<Scalars['BigInt']['output']>;
  carryBack?: Maybe<Scalars['BigInt']['output']>;
  carryBackPercentage?: Maybe<Scalars['Int']['output']>;
  certifiedId?: Maybe<Scalars['String']['output']>;
  certifiedScaleInterval?: Maybe<Scalars['Int']['output']>;
  certifiedWeightUnit?: Maybe<Scalars['Int']['output']>;
  created: Scalars['DateTime']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  fuelConsumption?: Maybe<Scalars['Int']['output']>;
  groupId: Scalars['String']['output'];
  heapUuid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  loadAltitude?: Maybe<Scalars['Float']['output']>;
  loadDuration?: Maybe<Scalars['Float']['output']>;
  loadGeographicalZoneName?: Maybe<Scalars['String']['output']>;
  loadGeographicalZoneUuid?: Maybe<Scalars['String']['output']>;
  loadLatitude?: Maybe<Scalars['Float']['output']>;
  loadLongitude?: Maybe<Scalars['Float']['output']>;
  loadSelectedZoneName?: Maybe<Scalars['String']['output']>;
  loadSelectedZoneUuid?: Maybe<Scalars['String']['output']>;
  loadStopDuration?: Maybe<Scalars['Float']['output']>;
  loadTime?: Maybe<Scalars['DateTime']['output']>;
  loadTravelDistance?: Maybe<Scalars['BigInt']['output']>;
  loadTravelDuration?: Maybe<Scalars['Float']['output']>;
  localId?: Maybe<Scalars['BigInt']['output']>;
  machineHours?: Maybe<Scalars['Float']['output']>;
  materialUuid?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  overSpeedingDuration?: Maybe<Scalars['Float']['output']>;
  pauseDuration?: Maybe<Scalars['Float']['output']>;
  payloadPercentage?: Maybe<Scalars['BigInt']['output']>;
  targetUuid: Scalars['String']['output'];
  ticketId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  unloadAltitude?: Maybe<Scalars['Float']['output']>;
  unloadDuration?: Maybe<Scalars['Float']['output']>;
  unloadGeographicalZoneName?: Maybe<Scalars['String']['output']>;
  unloadGeographicalZoneUuid?: Maybe<Scalars['String']['output']>;
  unloadLatitude?: Maybe<Scalars['Float']['output']>;
  unloadLongitude?: Maybe<Scalars['Float']['output']>;
  unloadSelectedZoneName?: Maybe<Scalars['String']['output']>;
  unloadSelectedZoneUuid?: Maybe<Scalars['String']['output']>;
  unloadStopDuration?: Maybe<Scalars['Float']['output']>;
  unloadTime?: Maybe<Scalars['DateTime']['output']>;
  unloadTravelDistance?: Maybe<Scalars['BigInt']['output']>;
  unloadTravelDuration?: Maybe<Scalars['Float']['output']>;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  weight?: Maybe<Scalars['Int']['output']>;
  workMode?: Maybe<Scalars['BigInt']['output']>;
};

export type LoadTicket = {
  additionalInformation: Array<Scalars['String']['output']>;
  buckets?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use flowId instead */
  code?: Maybe<Scalars['String']['output']>;
  extraCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Removed, dont use */
  gpsPosition?: Maybe<LoadTicketGpsPosition>;
  harmonizedAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ingestedAt: Scalars['DateTime']['output'];
  /** @deprecated Use sourceLoadedToTargetId instead */
  loadedToVehicle?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use sourceSiteId instead */
  loadriteSite?: Maybe<Scalars['String']['output']>;
  /** *Requires authentication* */
  organization?: Maybe<Organization>;
  ownerId?: Maybe<Scalars['String']['output']>;
  scale: Scale;
  sourceCreatedAt: Scalars['DateTime']['output'];
  /** @deprecated Use sourceCreatedAt instead */
  sourceDeviceCreatedAt: Scalars['DateTime']['output'];
  sourceFlowId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use ingestedAt instead */
  sourceIngestionCreatedAt: Scalars['DateTime']['output'];
  sourceLoadedToTargetId?: Maybe<Scalars['String']['output']>;
  sourceScaleId: Scalars['String']['output'];
  sourceService: Scalars['String']['output'];
  sourceServiceId: Scalars['String']['output'];
  sourceSiteId?: Maybe<Scalars['String']['output']>;
  sourceTicketId: Scalars['String']['output'];
  /** Weight in the unit of the source */
  sourceWeight?: Maybe<Scalars['Float']['output']>;
  sourceWeightUnit?: Maybe<Scalars['String']['output']>;
  statusCodes: Array<Scalars['String']['output']>;
  /** @deprecated Use sourceService instead */
  ticketSource: Scalars['String']['output'];
  /** Weight in SI unit (kg) */
  weight?: Maybe<Scalars['Float']['output']>;
  weightUnit?: Maybe<Scalars['String']['output']>;
};

export type LoadTicketConnection = {
  edges: Array<LoadTicketEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoadTicketEdge = {
  cursor: Scalars['String']['output'];
  node: LoadTicket;
};

export type LoadTicketFilterBy = {
  buckets?: InputMaybe<IntFilterClass>;
  ingestedAt?: InputMaybe<DateFilterClass>;
  organizationId?: InputMaybe<IdFilterClass>;
  ownerId?: InputMaybe<IdFilterClass>;
  sourceCreatedAt?: InputMaybe<DateFilterClass>;
  sourceCreatedAtNoTimeZone?: InputMaybe<BoolFilterClass>;
  sourceFlowId?: InputMaybe<IdFilterClass>;
  sourceFromZoneId?: InputMaybe<IdFilterClass>;
  sourceLoadedToTargetId?: InputMaybe<IdFilterClass>;
  sourceMachineId?: InputMaybe<IdFilterClass>;
  sourceMaterialId?: InputMaybe<IdFilterClass>;
  sourceScaleId?: InputMaybe<IdFilterClass>;
  sourceServiceId?: InputMaybe<LoadTicketIngestionSourceInputFilterClass>;
  sourceSiteId?: InputMaybe<IdFilterClass>;
  sourceTicketId?: InputMaybe<IdFilterClass>;
  sourceToZoneId?: InputMaybe<IdFilterClass>;
  sourceWorkOrderId?: InputMaybe<IdFilterClass>;
  weight?: InputMaybe<FloatFilterClass>;
};

export type LoadTicketGpsPosition = {
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

/** Possible source services for load tickets */
export enum LoadTicketIngestionSourceInput {
  AssistIngestion = 'ASSIST_INGESTION',
  FlintabIngestion = 'FLINTAB_INGESTION',
  LoadriteIngestion = 'LOADRITE_INGESTION',
  OperatorApp = 'OPERATOR_APP',
  SebadataIngestion = 'SEBADATA_INGESTION',
  TamtronIngestion = 'TAMTRON_INGESTION',
  VeiIngestion = 'VEI_INGESTION'
}

export type LoadTicketIngestionSourceInputFilterClass = {
  equals?: InputMaybe<LoadTicketIngestionSourceInput>;
  in?: InputMaybe<Array<LoadTicketIngestionSourceInput>>;
  not?: InputMaybe<LoadTicketIngestionSourceInput>;
  notIn?: InputMaybe<Array<LoadTicketIngestionSourceInput>>;
};

export type LoadTicketOrderBy = {
  id?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type LoadTicketQueries = {
  loadTickets: LoadTicketConnection;
  /** *Requires authentication and scope for the given owner id* */
  sourceServices: Array<SourceService>;
};


export type LoadTicketQueries_LoadTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<LoadTicketFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<LoadTicketOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type LoadriteCredentialsMutations = {
  deleteCredentials: Scalars['Boolean']['output'];
  setCredentials: Scalars['Boolean']['output'];
};


export type LoadriteCredentialsMutations_DeleteCredentialsArgs = {
  ownerId: Scalars['ID']['input'];
};


export type LoadriteCredentialsMutations_SetCredentialsArgs = {
  ownerId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type LoadriteCredentialsQueries = {
  credentials?: Maybe<Scalars['String']['output']>;
};


export type LoadriteCredentialsQueries_CredentialsArgs = {
  ownerId: Scalars['ID']['input'];
};

export type LoadriteScale = {
  scale: Scalars['ID']['output'];
  uniqueScaleId: Scalars['ID']['output'];
};

export type LoadriteScaleQueries = {
  /** *Requires authentication* */
  lastImportScaleEventLog?: Maybe<ImportScaleEventLog>;
  /** *Requires authentication* */
  scales: Array<LoadriteScale>;
};


export type LoadriteScaleQueries_LastImportScaleEventLogArgs = {
  ownerId?: InputMaybe<Scalars['ID']['input']>;
};


export type LoadriteScaleQueries_ScalesArgs = {
  ownerId: Scalars['ID']['input'];
};

export type LoadriteValidationQueries = {
  /** *Requires authentication* */
  validateCredentials?: Maybe<Scalars['Boolean']['output']>;
  /** *Requires authentication* */
  validateScaleId: Scalars['Boolean']['output'];
};


export type LoadriteValidationQueries_ValidateCredentialsArgs = {
  token: Scalars['String']['input'];
};


export type LoadriteValidationQueries_ValidateScaleIdArgs = {
  scaleId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type Location = {
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LocationHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** [ARI] RPM with 0.25 rpm resolution at the time of the event. */
  engineSpeed?: Maybe<Scalars['Float']['output']>;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  heading?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** [ARI] Latitude at the time of the event. Up to 6 digits of precision. */
  latitude: Scalars['Float']['output'];
  /** [ARI] Longitude at the time of the event. Up to 6 digits of precision. */
  longitude: Scalars['Float']['output'];
  /** [ARI] Wheel-based machine speed at the time of the event, resolution: 1.0 km/h */
  speed?: Maybe<Scalars['Float']['output']>;
};

export type LocationQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** The data points for location events from ARI. */
  location: Array<LocationHistoryEvent>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

/** The main machine entity with information from PI and other teams. */
export type Machine = {
  actorMachineRelationships?: Maybe<Array<MachineActorRelationship>>;
  /** [DCSP] Relationships between machine and actors. From the actor tree service. */
  actorUserRelationships?: Maybe<Array<MachineActorUserRelationship>>;
  /**
   * [ARI] The percentage of the battery's health state. TGW3 only.
   * @deprecated Obsolete. Use batteryHealthState.batteryHealthState instead.
   */
  batteryHealthStatePercentage?: Maybe<Scalars['Int']['output']>;
  /**
   * [ARI] Timestamp on when the battery health state was last reported. TGW3 only.
   * @deprecated Obsolete. Use batteryHealthState.updateDateTime instead.
   */
  batteryHealthStatePercentageUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Battery health state. */
  batteryHealthStatus?: Maybe<BatteryHealthStatus>;
  /** [ARI] Battery status. */
  batteryStatus?: Maybe<BatteryStatus>;
  /**
   * Brand of the machine. Currently always "Volvo"
   * @deprecated Use `generalDescription.brand` instead.
   */
  brand?: Maybe<Scalars['String']['output']>;
  chainQueries: ChainQueries;
  /** [ARI] Charge monitoring data. */
  chargeMonitoring?: Maybe<ChargeMonitoring>;
  /**
   * [ARI] The current charging status of the machine. TGW3 only.
   * @deprecated Obsolete. Use chargeMonitoring.chargingStatus instead.
   */
  chargingStatus?: Maybe<ChargingStatus>;
  /** [AMM] Chassis id. Older machine identifier still used as the primary identifier in other systems. */
  chassisID?: Maybe<Scalars['String']['output']>;
  /** [AMM] */
  chassisNumber?: Maybe<Scalars['String']['output']>;
  /** [AMM] */
  chassisSeries?: Maybe<Scalars['String']['output']>;
  /** [AMM] List of components on the machine. */
  components?: Maybe<Array<MachineComponent>>;
  /**
   * [ARI] The power consumption in KiloWatt hours, during current idle state. TGW3 only.
   * @deprecated Obsolete. Use batteryState.consumptionCurrentChargeCycleWhenIdlingPower instead.
   */
  consumptionCurrentChargeCycleWhenIdlingPower?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] The power consumption in KiloWatt hours, during current work state. TGW3 only.
   * @deprecated Obsolete. Use batteryState.consumptionCurrentChargeCycleWhenWorkingPower instead.
   */
  consumptionCurrentChargeCycleWhenWorkingPower?: Maybe<Scalars['Float']['output']>;
  coverages: Array<MachineCoverage>;
  /** [AMM] Chassis id for telematics unit. Usually the same as chassisId. */
  ctChassisID?: Maybe<Scalars['String']['output']>;
  /**
   * [ARI] The percentage of the battery's level of available electricity.
   * @deprecated Obsolete. Use batteryState.currentBatteryLevel instead.
   */
  currentBatteryLevel?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] Timestamp on when battery level was last updated. TGW3 only.
   * @deprecated Obsolete. Use batteryState.updatedDateTime instead.
   */
  currentBatteryLevelUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /**
   * [ARI] The total active time, in seconds, that the battery has been used during idle state. TGW3 only.
   * @deprecated Obsolete. Use batteryState.currentChargeCycleBatteryUptimeWhenIdling instead.
   */
  currentChargeCycleBatteryUptimeWhenIdling?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] The total active time, in seconds, that the battery has been used during work state. TGW3 only.
   * @deprecated Obsolete. Use batteryState.currentChargeCycleBatteryUptimeWhenWorking instead.
   */
  currentChargeCycleBatteryUptimeWhenWorking?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] The remaining active time, in seconds, possible for the current cycle. TGW3 only.
   * @deprecated Obsolete. Use batteryState.currentChargeCycleWorkCapacityLeft instead.
   */
  currentChargeCycleWorkCapacityLeft?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] The current flow rate in watt. TGW3 only.
   * @deprecated Obsolete. Use chargeMonitoring.currentChargeRate instead.
   */
  currentChargeRate?: Maybe<Scalars['Float']['output']>;
  /** [AMM] Dealer information. */
  dealer: Dealer;
  /**
   * [AMM] CE Party id from MDHUB.
   * @deprecated Use `dealer.cePartyID` instead.
   */
  dealerCePartyID?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Party that handles warranty issues/dealer.
   * @deprecated Use `dealer.dealerID` instead.
   */
  dealerID?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Machine alias set by the dealer.
   * @deprecated Use `dealer.dealerMachineAlias` instead.
   */
  dealerMachineAlias?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Name used in AMM for the registered dealer.
   * @deprecated Use `dealer.dealerName` instead.
   */
  dealerName?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Id for the registered dealer for actor tree purposes.
   * @deprecated Use `dealer.dealerParmaID` instead.
   */
  dealerParmaID?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM]
   * @deprecated Use `dealer.warrantyArea` instead.
   */
  dealerWarrantyArea?: Maybe<Scalars['String']['output']>;
  /**
   * [ARI] Current DEF/AdBlue level in percentage with 0.4% resolution. Diesel machines only.
   * @deprecated Use `machineSummaryFuel.defLevel` instead.
   */
  defLevel?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] Accumulated odometer value in meter.
   * @deprecated Use `machineSummaryDistance.distance` instead.
   */
  distance?: Maybe<Scalars['Float']['output']>;
  electricControlUnitData: Array<MachineElectricControlUnitData>;
  /** [AMM] Information about the end customer. */
  endCustomer: EndCustomer;
  /**
   * [AMM] Address line 1 used in AMM for the registered end customer.
   * @deprecated Use `endCustomer.endCustomerAddress1` instead.
   */
  endCustomerAddress1?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Address line 2 used in AMM for the registered end customer.
   * @deprecated Use `endCustomer.endCustomerAddress2` instead.
   */
  endCustomerAddress2?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Address line 3 used in AMM for the registered end customer.
   * @deprecated Use `endCustomer.endCustomerAddress3` instead.
   */
  endCustomerAddress3?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Address line 4 used in AMM for the registered end customer.
   * @deprecated Use `endCustomer.endCustomerAddress4` instead.
   */
  endCustomerAddress4?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Category used in AMM for the registered end customer.
   * @deprecated Use `endCustomer.endCustomerCategory` instead.
   */
  endCustomerCategory?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM]
   * @deprecated Use `endCustomer.ecPartyID` instead.
   */
  endCustomerEcPartyID?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Id used in AMM for the registered end customer.
   * @deprecated Use `endCustomer.endCustomerCode` instead.
   */
  endCustomerId?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Machine alias set by the end customer.
   * @deprecated Use `endCustomer.endCustomerEquipmentAlias` instead.
   */
  endCustomerMachineAlias?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Name used in AMM for the registered end customer.
   * @deprecated Use `endCustomer.endCustomerName` instead.
   */
  endCustomerName?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] Timestamp on when the registered end customer was last updated.
   * @deprecated Use `endCustomer.updatedDateTime` instead.
   */
  endCustomerUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** [PI] Energy source of the machine based on sales model prefix. "Unknown" for non-Volvo machines. */
  energySource?: Maybe<EnergySource>;
  /**
   * [ARI] Was the engine running at the last summary event (updates on location, fuelLevel, operating time, vehicleMode/eventType)?
   * @deprecated Use `machineSummaryEvent.engineOn` instead.
   */
  engineOn?: Maybe<Scalars['Boolean']['output']>;
  /**
   * [ARI] Current RPM of the engine with 0.25 rpm resolution.
   * @deprecated Use `machineSummarySpeed.engineSpeed` instead.
   */
  engineSpeed?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] Last received event from the machine. W-ECU only.
   * @deprecated Use `machineSummaryEvent.eventType` instead.
   */
  eventType?: Maybe<EventType>;
  /** Recent fault codes/DTCs received from ARI. */
  faultCodes: Array<MachineFaultCode>;
  files: Array<File>;
  /** [ARI] Fuel consumption data. */
  fuelConsumption?: Maybe<FuelConsumption>;
  /**
   * [ARI] Current fuel level in percentage with 0.4% resolution.
   * @deprecated Use `machineSummaryFuel.fuelLevel` instead.
   */
  fuelLevel?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] Timestamp of when current fuel level and/or DEF level was updated.
   * @deprecated Use `machineSummaryFuel.updatedDateTime` instead.
   */
  fuelLevelUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** [AMM] Machine General Description. */
  generalDescription?: Maybe<MachineGeneralDescription>;
  /** [PI] Timestamp of last update of machine */
  globalUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  historicalDataQueries: HistoricalDataQueries;
  /** Internal DCSP machine id. */
  id: Scalars['ID']['output'];
  /**
   * [PI] Approximate administrative region based on latest ARI location.
   * @deprecated Use `location.latestRegion` instead.
   */
  latestAdmin?: Maybe<Scalars['String']['output']>;
  /**
   * [PI] Approximate closest city based on ARI location.
   * @deprecated Use `location.latestCity` instead.
   */
  latestCity?: Maybe<Scalars['String']['output']>;
  /**
   * [PI] Approximate country based on latest ARI location.
   * @deprecated Use `location.latestCountry` instead.
   */
  latestCountry?: Maybe<Scalars['String']['output']>;
  /**
   * [PI] ISO code of the approximate country based on latest ARI location.
   * @deprecated Use `location.latestCountryIsoCode` instead.
   */
  latestCountryIsoCode?: Maybe<Scalars['String']['output']>;
  /**
   * [ARI] Latest location latitude known in string format. Up to 6 digits of precision.
   * @deprecated Use `location.latestLatitude` instead.
   */
  latestLat?: Maybe<Scalars['String']['output']>;
  /**
   * [ARI] Latest location latitude known in float format. Up to 6 digits of precision.
   * @deprecated Use `location.latestLatitudeNumeric` instead.
   */
  latestLatNum?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] Latest location longitude known in string format. Up to 6 digits of precision.
   * @deprecated Use `location.latestLongitude` instead.
   */
  latestLng?: Maybe<Scalars['String']['output']>;
  /**
   * [ARI] Latest location longitude known in float format. Up to 6 digits of precision.
   * @deprecated Use `location.latestLongitudeNumeric` instead.
   */
  latestLngNum?: Maybe<Scalars['Float']['output']>;
  lifeCycle: MachineLifeCycle;
  /** [ARI] Machine location. */
  location?: Maybe<MachineLocation>;
  /**
   * [ARI] Timestamp of last update of location.
   * @deprecated Use `location.updatedDateTime` instead.
   */
  locationUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** [AMM] Sections from AMM that has been imported, but not exposed directly in this API. If anything in here is interesting for you, contact the PI team. */
  machineMasterDataSection?: Maybe<Array<MachineMasterDataSection>>;
  /**
   * [ARI] Accumulated number of seconds the machine has been used/running.
   * @deprecated Use `machineSummaryOperatingTime.machineOperatingTime` instead.
   */
  machineOperatingTime?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Machine summary distance. */
  machineSummaryDistance?: Maybe<MachineSummaryDistance>;
  /** [ARI] Machine summary event. */
  machineSummaryEvent?: Maybe<MachineSummaryEvent>;
  /** [ARI] Fuel information from the machine. */
  machineSummaryFuel?: Maybe<MachineSummaryFuel>;
  /** [ARI] Operating time of the machine. */
  machineSummaryOperatingTime?: Maybe<MachineSummaryOperatingTime>;
  /** [ARI] Speed information from the machine. */
  machineSummarySpeed?: Maybe<MachineSummarySpeed>;
  /** [ARI] Machine summary starter battery level. */
  machineSummaryStarterBatteryLevel?: Maybe<MachineSummaryStarterBatteryLevel>;
  /**
   * [AMM] Model from MDHUB
   * @deprecated Use `generalDescription.model` instead.
   */
  model?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM] ModelVersion from MDHUB
   * @deprecated Use `generalDescription.modelVersion` instead.
   */
  modelVersion?: Maybe<Scalars['String']['output']>;
  /**
   * Not in use, and is always empty.
   * @deprecated Not in use.
   */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * [ARI] Timestamp of last update of operating time.
   * @deprecated Use `machineSummaryOperatingTime.updatedDateTime` instead.
   */
  operatingTimeUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** [AMM] Available component options for the machine. */
  options?: Maybe<Array<MachineOption>>;
  /** [AMM] PIN17 identifier. 17 characters. Starts with "VCE" for VCE machines. Should be used as the primary identifier for machines if possible. */
  pin17?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM]
   * @deprecated Use `generalDescription.productLine` instead.
   */
  productLine?: Maybe<Scalars['String']['output']>;
  /**
   * [AMM]
   * @deprecated Use `generalDescription.productNumber` instead.
   */
  productNumber?: Maybe<Scalars['String']['output']>;
  /** [ARI] Data about how many tons and cycles a machine has produced. */
  production?: Maybe<MachineProduction>;
  /** [AMM] Type, model and generation of the machine. Unique identifier together with `serialNumber`. */
  salesModel?: Maybe<Scalars['String']['output']>;
  serialNo?: Maybe<Scalars['String']['output']>;
  /** [AMM] Specific instance of a machine of a certain `salesModel`. Unique identifier together with `salesModel` */
  serialNumber?: Maybe<Scalars['String']['output']>;
  /** [AMM] SID (Co-Pilot) chassis id. */
  sidChassisID?: Maybe<Scalars['String']['output']>;
  /** [VDA] Software configuration of the machine. */
  softwareConfiguration?: Maybe<SoftwareConfiguration>;
  /** [ARI] When did ARI last send a summary event (updates on location, fuelLevel, operating time, vehicleMode/eventType)? Use of the granular timestamps are recommended instead of this. */
  summaryUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /**
   * [AMM] The type of telematics unit that is installed on the machine. Based on component 299, compared to a set list.
   * @deprecated Use `generalDescription.telematicsUnitType`
   */
  telematicsUnitType?: Maybe<TelematicsUnitTYpe>;
  /**
   * [ARI] The total time in seconds until the battery is fully charged. TGW3 only.
   * @deprecated Obsolete. Use chargeMonitoring.timeToFullyChargedSeconds instead.
   */
  timeToFullyChargedSeconds?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] Timestamp of last update of fuel consumption data.
   * @deprecated Use `fuelConsumption.updatedDateTime` instead.
   */
  totalFuelConsumedUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /**
   * [ARI] Do not use. Timestamp of last update of current fuel level.
   * @deprecated Use `fuelLevelUpdatedDateTime` instead.
   */
  totalFuelLevelUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /**
   * [ARI] The type of charger connected to the machine. TGW3 only.
   * @deprecated Obsolete. Use chargeMonitoring.typeOfChargerConnected instead.
   */
  typeOfChargerConnected?: Maybe<ChargerType>;
  /** [ARI] Data to evaluate efficiency of the machine in different modes. */
  utilization?: Maybe<MachineUtilization>;
  /** [AMM] Vehicle id from MOM. */
  vehicleID?: Maybe<Scalars['String']['output']>;
  /**
   * [ARI] The current vehicle mode of the machine. TGW only.
   * @deprecated Obsolete. Use batteryState.vehicleMode instead.
   */
  vehicleMode?: Maybe<VehicleMode>;
  /** @deprecated Do not use. Is always empty. */
  vin?: Maybe<Scalars['String']['output']>;
};


/** The main machine entity with information from PI and other teams. */
export type Machine_FilesArgs = {
  serviceName: Scalars['String']['input'];
};

export type MachineActorRelationship = {
  actorId: Scalars['ID']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Type describing relationships between machines and actors. */
export type MachineActorUserRelationship = {
  actorId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  machineId: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type MachineBatteryStatusFilter = {
  /** [ARI] Filter machines by last known battery level */
  currentBatteryLevel?: InputMaybe<FloatFilterClass>;
};

export type MachineBelongsToInformation = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Type describing machine components. */
export type MachineComponent = {
  /** [AMM] Was the component fitted by factory? */
  fittedByFactory?: Maybe<Scalars['Boolean']['output']>;
  /** Internal component id. */
  id: Scalars['String']['output'];
  /** Reference to internal machine id. */
  machineId: Scalars['String']['output'];
  /** [AMM] Model of component. */
  model?: Maybe<Scalars['String']['output']>;
  /** [AMM] Part number of the component. */
  partNumber?: Maybe<Scalars['String']['output']>;
  /** [AMM] Serial number of the component. */
  serialNumber?: Maybe<Scalars['String']['output']>;
  /** [AMM] Type in number of component. */
  type?: Maybe<Scalars['Float']['output']>;
  /** [AMM] Description of component type. */
  typeDescription?: Maybe<Scalars['String']['output']>;
};

export type MachineConfigConnection = {
  edges: Array<MachineConfigEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MachineConfigEdge = {
  cursor: Scalars['String']['output'];
  node: MachineConfigModel;
};

export type MachineConfigFilterInput = {
  and?: InputMaybe<Array<MachineConfigFilterInput>>;
  chassisId?: InputMaybe<StringFilterClass>;
  updated?: InputMaybe<DateFilterClass>;
};

export type MachineConfigModel = {
  appType?: Maybe<Scalars['Int']['output']>;
  appVersion?: Maybe<Scalars['String']['output']>;
  chassisId: Scalars['String']['output'];
  dataAnonymous: Scalars['Boolean']['output'];
  dataEnabled?: Maybe<Scalars['Boolean']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  vehicleType?: Maybe<Scalars['Int']['output']>;
};

export type MachineConfigQueries = {
  machineConfigs: MachineConfigConnection;
};


export type MachineConfigQueries_MachineConfigsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<MachineConfigFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MachineConfigSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type MachineConfigSort = {
  chassisId?: InputMaybe<SortDirection>;
  updated?: InputMaybe<SortDirection>;
};

export type MachineConnection = {
  edges: Array<MachineEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Type describing machine coverages. */
export type MachineCoverage = {
  /** The identity of the type of the warranty. Value example: CS1 (12 months standard warranty), RMRF0 (extended warranty). */
  coverageId?: Maybe<Scalars['String']['output']>;
  /** If the coverage is active, not active or archived. */
  coverageStatus?: Maybe<Scalars['String']['output']>;
  /** Description of the coverage status. */
  coverageStatusDescription?: Maybe<Scalars['String']['output']>;
  /** Defines the type of the coverage and used for grouping of coverage. Value example: STW - standard warranties, EC-CM - extended coverage components. */
  coverageType?: Maybe<Scalars['String']['output']>;
  /** Description of the coverage type. */
  coverageTypeDescription?: Maybe<Scalars['String']['output']>;
  /** The currency code for the invoice. */
  currencyCode?: Maybe<Scalars['String']['output']>;
  /** Internal id. */
  id: Scalars['String']['output'];
  /** The invoice date for the extended coverage to Dealer. */
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  /** The invoice number for the extended coverage to Dealer. */
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  /** The amount of the invoice for the extended coverage to Dealer. */
  invoicedAmount?: Maybe<Scalars['Float']['output']>;
  /** Reference to internal machine id. */
  machineId: Scalars['String']['output'];
  /** Invoiced amount or reserved amount for the coverage. */
  reserve?: Maybe<Scalars['Float']['output']>;
  /** By whom it was updated. */
  updatedBy?: Maybe<Scalars['String']['output']>;
  /** The date of the latest update. */
  updatedDate?: Maybe<Scalars['DateTime']['output']>;
};

export type MachineEdge = {
  cursor: Scalars['String']['output'];
  node: MachineNode;
};

/** Type describing a single ECU on the machine. Currently from ARI Network Map, but will be replaced by VDA data. */
export type MachineElectricControlUnitData = {
  /** [ARI] */
  address?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Dataset 1 identifier */
  dataSet1Id?: Maybe<Scalars['String']['output']>;
  /** [ARI] Dataset 2 identifier */
  dataSet2Id?: Maybe<Scalars['String']['output']>;
  /** Internal id. */
  id: Scalars['String']['output'];
  /** Reference to internal machine id. */
  machineId: Scalars['String']['output'];
  /** [ARI] Main software identifier, not the version. */
  mainSwNumber?: Maybe<Scalars['String']['output']>;
  /** [ARI] Text description of the ECU */
  name?: Maybe<Scalars['String']['output']>;
  /** [ARI] Represents the "ECU group" by a unique id. */
  nodeFamily?: Maybe<Scalars['Int']['output']>;
  /** [ARI] A unique id representing the position of the ECU within the node type and node family. Normally not used. */
  nodePosition?: Maybe<Scalars['Int']['output']>;
  /** [ARI] A unique id representing the physical ECU within the node family */
  nodeType?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Part number of the ECU */
  partNumber?: Maybe<Scalars['String']['output']>;
  /** [ARI] ECU serial number */
  serialNumber?: Maybe<Scalars['Int']['output']>;
  /** [ARI] When ECU data was last updated. */
  updateDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type MachineFaultCode = {
  /** [ARI] If the failure is active or not (true or false). */
  active: Scalars['Boolean']['output'];
  /** First occurrence in service */
  firstOccurrence?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Timestamp of last time the failure occurrence. */
  lastFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** Last occurrence in service, or last failure time if set in alarm */
  lastOccurrence?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Accumulated number of seconds the machine has been running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Position information at the time of the fault event. */
  machinePosition?: Maybe<MachinePosition>;
  /** Recorded occurrence count in service, or occurance counter if set in alarm */
  numberOfOccurrences?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Counter of the specific fault code since last "acknowledgement". */
  occurrenceCounter?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Priority of error code, 1-3. Note: Not reliable information. */
  priority?: Maybe<Scalars['Int']['output']>;
  type: MachineFaultCodeType;
};

export type MachineFaultCodeCea2PlusDtc = MachineFaultCodeHistorical & {
  /** [ARI] If the failure is active or not (true or false). */
  active: Scalars['Boolean']['output'];
  /** [ARI] The text shown on the display in the cabin. */
  dtcDisplayValue?: Maybe<Scalars['String']['output']>;
  /** [ARI] An identifier for the text shown on the display in the cabin. */
  dtcId?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Additional information captured at error occurrence. */
  dtcSnapshot?: Maybe<Scalars['String']['output']>;
  /** [ARI] A human representation of the node number, e.g., HMIC for the HMI ECU. */
  ecuDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] The failure code. */
  failureIdentifier: Scalars['String']['output'];
  /** [ARI] Explanation of failure code. */
  failureIdentifierDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] The description of the failure type. E.g., Hardware failure. */
  failureTypeDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] Identifier for the description of the failure type (decimal value). */
  failureTypeValue?: Maybe<Scalars['String']['output']>;
  /** What type of DTC the event is. */
  faultType: AlarmType;
  /** [ARI] The first time the failure occurred. */
  firstFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Timestamp of last time the failure occurrence. */
  lastFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Longitude at the time of the fault event. */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Longitude at the time of the fault event. */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated number of seconds the machine has been running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Represents the "ECU group" by a unique ID. */
  nodeFamily?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Position of the ECU has a unique ID within the Node Type and Node Family. Normally not used. */
  nodePosition?: Maybe<Scalars['Int']['output']>;
  /** [ARI] A physical ECU within the Node Family has a unique ID. */
  nodeType?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Counter of the specific fault code since last "acknowledgement". */
  occurrenceCounter?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Priority of error code, 1-3. Note: Not reliable information. */
  priority?: Maybe<Scalars['Int']['output']>;
  /** [ARI] ECU CAN address. */
  sourceAddress: Scalars['String']['output'];
};

export type MachineFaultCodeHistorical = {
  /** [ARI] If the failure is active or not (true or false). */
  active: Scalars['Boolean']['output'];
  /** What type of DTC the event is. */
  faultType: AlarmType;
  /** [ARI] Timestamp of last time the failure occurrence. */
  lastFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Longitude at the time of the fault event. */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Longitude at the time of the fault event. */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated number of seconds the machine has been running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Counter of the specific fault code since last "acknowledgement". */
  occurrenceCounter?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Priority of error code, 1-3. Note: Not reliable information. */
  priority?: Maybe<Scalars['Int']['output']>;
};

export type MachineFaultCodeTea2Alarm = MachineFaultCodeHistorical & {
  /** [ARI] If the failure is active or not (true or false). */
  active: Scalars['Boolean']['output'];
  /** What type of DTC the event is. */
  faultType: AlarmType;
  /** [ARI] The description of the failure type. E.g., "Tank level sensor, hardware failure". */
  faultTypeDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] Type of fault code 0-15. */
  fmi?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Textual description of the Fmi. */
  fmiDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] Timestamp of last time the failure occurrence. */
  lastFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Longitude at the time of the fault event. */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Longitude at the time of the fault event. */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated number of seconds the machine has been running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Numerical code for a specific ECU (Electrical Control Unit) i.e. "128". */
  mid?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Textual description of the Mid. E.g., "Engine ECU". */
  midDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] Counter of the specific fault code since last "acknowledgement". */
  occurrenceCounter?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Type of signal. */
  pid: Scalars['Int']['output'];
  /** [ARI] Subtype of signal. 0=PPID 1=PSID 2=PID 3=SID. */
  pidType: Scalars['String']['output'];
  /** [ARI] Priority of error code, 1-3. Note: Not reliable information. */
  priority?: Maybe<Scalars['Int']['output']>;
};

export type MachineFaultCodeTea2BridgeDtc = MachineFaultCodeHistorical & {
  /** [ARI] If the failure is active or not (true or false). */
  active: Scalars['Boolean']['output'];
  /** What type of DTC the event is. */
  faultType: AlarmType;
  /** [ARI] Timestamp of the first time the failure occurred. */
  firstFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Type of fault code 0-15. */
  fmi?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Textual description of the Fmi. */
  fmiDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] Timestamp of last time the failure occurrence. */
  lastFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Longitude at the time of the fault event. */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Longitude at the time of the fault event. */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated number of seconds the machine has been running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Counter of the specific fault code since last "acknowledgement". */
  occurrenceCounter?: Maybe<Scalars['Int']['output']>;
  /** [ARI] */
  occurrenceTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Priority of error code, 1-3. Note: Not reliable information. */
  priority?: Maybe<Scalars['Int']['output']>;
  /** [ARI] ECU CAN address. */
  sourceAddress: Scalars['String']['output'];
  /** [ARI] SPN error code number. */
  suspectParameterNumber: Scalars['Int']['output'];
  /** [ARI] SPN error code. */
  suspectParameterNumberDescription?: Maybe<Scalars['String']['output']>;
};

export enum MachineFaultCodeType {
  Cea2PlusDtc = 'Cea2PlusDtc',
  Tea2Alarm = 'Tea2Alarm',
  Tea2BridgeDtc = 'Tea2BridgeDtc'
}

export type MachineFilter = {
  and?: InputMaybe<Array<MachineFilter>>;
  batteryStatus?: InputMaybe<MachineBatteryStatusFilter>;
  /** Filter machines by brand */
  brand?: InputMaybe<StringFilterClass>;
  /** Filter machines by Chassis ID */
  chassisID?: InputMaybe<StringFilterClass>;
  /** Filter machines by ctChassisID */
  ctChassisID?: InputMaybe<StringFilterClass>;
  /** Filter machines by dealer Parma ID */
  dealerParmaID?: InputMaybe<StringFilterClass>;
  /** Filter machines by end customer ID */
  endCustomerId?: InputMaybe<StringFilterClass>;
  /** Filter machines by energy source */
  energySource?: InputMaybe<EnergySourceFilterClass>;
  generalDescription?: InputMaybe<MachineGeneralDescriptionFilter>;
  /** Filter machines by last update */
  globalUpdatedDateTime?: InputMaybe<DateFilterClass>;
  /** Filter machines by internal ID */
  id?: InputMaybe<StringFilterClass>;
  /** Filter machines by latest city */
  latestCity?: InputMaybe<StringFilterClass>;
  location?: InputMaybe<MachineLocationFilter>;
  machineSummaryFuel?: InputMaybe<MachineSummaryFuelFilter>;
  machineSummaryOperatingTime?: InputMaybe<MachineSummaryOperatingTimeFilter>;
  /** Filter machines by model */
  model?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<MachineFilter>>;
  /** Filter machines by organization id:s */
  organizations?: InputMaybe<StringArrayFilterClassWithOnlyHasSome>;
  /** Filter machines by PIN17 */
  pin17?: InputMaybe<StringFilterClass>;
  /** Filter machines by salesModel */
  salesModel?: InputMaybe<StringFilterClass>;
  /** Filter machines by dealer serialNumber */
  serialNumber?: InputMaybe<StringFilterClass>;
  /** Filter machines by subscription product id:s */
  subscriptions?: InputMaybe<StringArrayFilterClassWithOnlyHasSome>;
};

export type MachineFilterInput = {
  /** Filter machines by brand */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by chassisId */
  chassisID?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by ctChassisID */
  ctChassisID?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by battery level (percent) */
  currentBatteryLevel?: InputMaybe<NumberFilterInput>;
  /** The cursor to start at */
  cursor?: InputMaybe<Scalars['ID']['input']>;
  /** Filter machines by dealer Parma ID */
  dealerParmaID?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by end customer ID */
  endCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by energy source */
  energySource?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by fuel level (percent) */
  fuelLevel?: InputMaybe<NumberFilterInput>;
  /** Filter machines by global last updated date time */
  globalUpdatedDateTime?: InputMaybe<DateTimeFilterInput>;
  /** Filter machines by id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by latest city */
  latestCity?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by latest country ISO code */
  latestCountryIsoCode?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by location updated date time */
  locationUpdatedDateTime?: InputMaybe<DateTimeFilterInput>;
  /** Filter machines by total operating time (seconds) */
  machineOperatingTime?: InputMaybe<NumberFilterInput>;
  /** Filter machines by model */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by pin17 */
  pin17?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by sales model */
  salesModel?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by serial number */
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  /** Number of machines to skip */
  skip?: InputMaybe<Scalars['Int']['input']>;
  /** Min -100, Max 100 */
  take: Scalars['Int']['input'];
  /** Filter machines by telematic unit types */
  telematicsUnitTypes?: InputMaybe<Array<TelematicsUnitTYpe>>;
};

/** Type describing machine general description. */
export type MachineGeneralDescription = {
  /** Brand of the machine. Currently always "Volvo" */
  brand?: Maybe<Scalars['String']['output']>;
  /** [AMM] Model from MDHUB */
  model?: Maybe<Scalars['String']['output']>;
  /** [AMM] ModelVersion from MDHUB */
  modelVersion?: Maybe<Scalars['String']['output']>;
  /** [AMM] */
  productLine?: Maybe<Scalars['String']['output']>;
  /** [AMM] */
  productNumber?: Maybe<Scalars['String']['output']>;
  /** [AMM] The type of telematics unit that is installed on the machine. Based on component 299, compared to a set list. */
  telematicsUnitType?: Maybe<TelematicsUnitTYpe>;
};

export type MachineGeneralDescriptionFilter = {
  /** [AMM] */
  productLine?: InputMaybe<StringFilterClass>;
};

export enum MachineHistoricalDataType {
  BatteryHealthStatus = 'batteryHealthStatus',
  BatteryStatus = 'batteryStatus',
  ChargeMonitoring = 'chargeMonitoring',
  Distance = 'distance',
  EngineOn = 'engineOn',
  EventType = 'eventType',
  FaultCodes = 'faultCodes',
  FuelLevel = 'fuelLevel',
  Location = 'location',
  OperatingTime = 'operatingTime',
  Production = 'production',
  StarterBatteryLevel = 'starterBatteryLevel',
  TotalFuelConsumption = 'totalFuelConsumption',
  Utilization = 'utilization',
  VehicleMode = 'vehicleMode'
}

export type MachineIDsInput = {
  /** Filter machines by brand */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** Get machines with chassisIDs in this list */
  chassisIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Get machines ctChassisIDs in this list */
  ctChassisID?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter machines by battery level (percent) */
  currentBatteryLevel?: InputMaybe<NumberFilterInput>;
  /** The cursor to start at */
  cursor?: InputMaybe<Scalars['ID']['input']>;
  /** Filter machines by dealer Parma ID */
  dealerParmaID?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by end customer ID */
  endCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by energy source */
  energySource?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by fuel level (percent) */
  fuelLevel?: InputMaybe<NumberFilterInput>;
  /** Filter machines by global last updated date time */
  globalUpdatedDateTime?: InputMaybe<DateTimeFilterInput>;
  /** Get machines with IDs in this list */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter machines by latest city */
  latestCity?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by latest country ISO code */
  latestCountryIsoCode?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by location updated date time */
  locationUpdatedDateTime?: InputMaybe<DateTimeFilterInput>;
  /** Filter machines by total operating time (seconds) */
  machineOperatingTime?: InputMaybe<NumberFilterInput>;
  /** Filter machines by model */
  model?: InputMaybe<Scalars['String']['input']>;
  /** Get machines with pin17 in this list */
  pin17s?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter machines by sales model */
  salesModel?: InputMaybe<Scalars['String']['input']>;
  /** Filter machines by serial number */
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  /** Number of machines to skip */
  skip?: InputMaybe<Scalars['Int']['input']>;
  /** Min -100, Max 100 */
  take: Scalars['Int']['input'];
  /** Filter machines by telematic unit types */
  telematicsUnitTypes?: InputMaybe<Array<TelematicsUnitTYpe>>;
};

/** Type describing machine life cycle. */
export type MachineLifeCycle = {
  /** The date when the key for the Product Individual was handed over from Dealer to End Customer. */
  deliveredEndUserDate?: Maybe<Scalars['DateTime']['output']>;
  /** Timestamp of when the end user delivery date was last updated. */
  deliveredEndUserUpdateDate?: Maybe<Scalars['DateTime']['output']>;
  /** The description of the Reason Rejected Code. Value examples: Stolen, Scrapped, Duplicate. */
  reasonRejectionDescription?: Maybe<Scalars['String']['output']>;
  /** Defines if the Product Individual is valid or not. Provided by Dealer or Regions. Value examples: STO, SCR, DUP */
  rejectionCode?: Maybe<Scalars['String']['output']>;
};

/** ARI machine location. */
export type MachineLocation = {
  /** [PI] Approximate closest city based on ARI location. */
  latestCity?: Maybe<Scalars['String']['output']>;
  /** [PI] Approximate country based on latest ARI location. */
  latestCountry?: Maybe<Scalars['String']['output']>;
  /** [PI] ISO code of the approximate country based on latest ARI location. */
  latestCountryIsoCode?: Maybe<Scalars['String']['output']>;
  /** [ARI] Latest location latitude known in string format. Up to 6 digits of precision. */
  latestLatitude?: Maybe<Scalars['String']['output']>;
  /** [ARI] Latest location latitude known in float format. Up to 6 digits of precision. */
  latestLatitudeNumeric?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Latest location longitude known in string format. Up to 6 digits of precision. */
  latestLongitude?: Maybe<Scalars['String']['output']>;
  /** [ARI] Latest location longitude known in float format. Up to 6 digits of precision. */
  latestLongitudeNumeric?: Maybe<Scalars['Float']['output']>;
  /** [PI] Approximate administrative region based on latest ARI location. */
  latestRegion?: Maybe<Scalars['String']['output']>;
  /** [ARI] Timestamp of last update of location. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type MachineLocationFilter = {
  /** Filter machines by latest city */
  latestCity?: InputMaybe<StringFilterClass>;
  /** Filter machines by latest country ISO code */
  latestCountryIsoCode?: InputMaybe<StringFilterClass>;
  /** Filter machines by latest latitude num */
  latestLatNum?: InputMaybe<FloatFilterClass>;
  /** Filter machines by latest longitude num */
  latestLngNum?: InputMaybe<FloatFilterClass>;
  /** Filter machines by last location update */
  updatedDateTime?: InputMaybe<DateFilterClass>;
};

export type MachineMarker = {
  id?: Maybe<Scalars['String']['output']>;
  latestLatNum?: Maybe<Scalars['Float']['output']>;
  latestLngNum?: Maybe<Scalars['Float']['output']>;
};

/** Imported sections from AMM not exposed directly in the API. If anything in here is interesting for you, contact the PI team. */
export type MachineMasterDataSection = {
  /** Internal id. */
  id: Scalars['String']['output'];
  /** Reference to internal machine id. */
  machineId: Scalars['String']['output'];
  /** [AMM] JSON data of the AMM section. */
  sectionData?: Maybe<Scalars['JSON']['output']>;
  /** [AMM] Title of AMM section. */
  sectionTitle?: Maybe<Scalars['String']['output']>;
  /** [AMM] When section was last updated. */
  updateDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type MachineNode = {
  /** [ARI] The percentage of the battery's health state. TGW3 only. */
  batteryHealthStatePercentage?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Timestamp on when the battery health state was last reported. TGW3 only. */
  batteryHealthStatePercentageUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Battery health state. */
  batteryHealthStatus?: Maybe<BatteryHealthStatus>;
  /** [ARI] Battery status. */
  batteryStatus?: Maybe<BatteryStatus>;
  /** [ARI] Charge monitoring data. */
  chargeMonitoring?: Maybe<ChargeMonitoring>;
  /** [AMM] Chassis id. Older machine identifier still used as the primary identifier in other systems. */
  chassisID?: Maybe<Scalars['String']['output']>;
  /** [AMM] */
  chassisNumber?: Maybe<Scalars['String']['output']>;
  /** [AMM] */
  chassisSeries?: Maybe<Scalars['String']['output']>;
  /** [AMM] List of components on the machine. */
  components?: Maybe<Array<MachineComponent>>;
  coverages: Array<MachineCoverage>;
  /** [AMM] Chassis id for telematics unit. Usually the same as chassisId. */
  ctChassisID?: Maybe<Scalars['String']['output']>;
  /** [ARI] The current flow rate in watt. TGW3 only. */
  currentChargeRate?: Maybe<Scalars['Float']['output']>;
  /** [AMM] Dealer information. */
  dealer: Dealer;
  /** [ARI] Data about the ECUs on the machine. Currently based on Network map data from ARI. Will be changed to data from VDA directly. */
  electricControlUnitData?: Maybe<Array<MachineElectricControlUnitData>>;
  /** [AMM] Information about the end customer. */
  endCustomer: EndCustomer;
  /** [PI] Energy source of the machine based on sales model prefix. "Unknown" for non-Volvo machines. */
  energySource?: Maybe<EnergySource>;
  /** [ARI] Fuel consumption data. */
  fuelConsumption?: Maybe<FuelConsumption>;
  /** [AMM] Machine General Description. */
  generalDescription?: Maybe<MachineGeneralDescription>;
  /** [PI] Timestamp of last update of machine */
  globalUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lifeCycle: MachineLifeCycle;
  /** [ARI] Machine location. */
  location?: Maybe<MachineLocation>;
  /** [ARI] Machine summary distance. */
  machineSummaryDistance?: Maybe<MachineSummaryDistance>;
  /** [ARI] Machine summary event. */
  machineSummaryEvent?: Maybe<MachineSummaryEvent>;
  /** [ARI] Fuel information from the machine. */
  machineSummaryFuel?: Maybe<MachineSummaryFuel>;
  /** [ARI] Operating time of the machine. */
  machineSummaryOperatingTime?: Maybe<MachineSummaryOperatingTime>;
  /** [ARI] Speed information from the machine. */
  machineSummarySpeed?: Maybe<MachineSummarySpeed>;
  /** [ARI] Machine summary starter battery level. */
  machineSummaryStarterBatteryLevel?: Maybe<MachineSummaryStarterBatteryLevel>;
  operatorMachineAssociations: Array<Maybe<OperatorMachineAssociationV3>>;
  /** [AMM] Available component options for the machine. */
  options?: Maybe<Array<MachineOption>>;
  /** [AMM] PIN17 identifier. 17 characters. Starts with "VCE" for VCE machines. Should be used as the primary identifier for machines if possible. */
  pin17?: Maybe<Scalars['String']['output']>;
  /** [ARI] Data about how many tons and cycles a machine has produced. */
  production?: Maybe<MachineProduction>;
  /** [AMM] Type, model and generation of the machine. Unique identifier together with `serialNumber`. */
  salesModel?: Maybe<Scalars['String']['output']>;
  /** [AMM] Specific instance of a machine of a certain `salesModel`. Unique identifier together with `salesModel` */
  serialNumber?: Maybe<Scalars['String']['output']>;
  /** [AMM] SID (Co-Pilot) chassis id. */
  sidChassisID?: Maybe<Scalars['String']['output']>;
  /** [ARI] Data to evaluate efficiency of the machine in different modes. */
  utilization?: Maybe<MachineUtilization>;
  /** [AMM] Vehicle id from MOM. */
  vehicleID?: Maybe<Scalars['String']['output']>;
};

/** Type describing machine component options. */
export type MachineOption = {
  /** [AMM] Date when option was updated. */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** [AMM] Description of the option. */
  description?: Maybe<Scalars['String']['output']>;
  /** [AMM] Was the component fitted by factory? */
  fittedByFactory?: Maybe<Scalars['Boolean']['output']>;
  /** Internal option id. */
  id: Scalars['String']['output'];
  /** [AMM] Identifier of the option. */
  identifier?: Maybe<Scalars['String']['output']>;
  /** Reference to internal machine id. */
  machineId: Scalars['String']['output'];
  /** [AMM] Part number of the option. */
  partNumber?: Maybe<Scalars['String']['output']>;
  /** [AMM] Quantity. */
  quantity?: Maybe<Scalars['Float']['output']>;
  /** [AMM] Serial number of the component. */
  serialNumber?: Maybe<Scalars['String']['output']>;
  /** [AMM] */
  volvoOption?: Maybe<Scalars['Boolean']['output']>;
};

export type MachineOwningCompany = {
  contactEmail?: Maybe<Scalars['String']['output']>;
  /** *Requires authentication* */
  hasServiceCredentials: Scalars['Boolean']['output'];
  hibooEmail?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isApproved: Scalars['Boolean']['output'];
  machineStatistics: StatisticsConnection;
  /** *Requires authentication* */
  managers: Array<Maybe<User>>;
  name: Scalars['String']['output'];
  /** *Requires authentication* */
  obwScales: ObwScaleConnection;
  /** *Requires authentication* */
  organization?: Maybe<Organization>;
  /** *Requires authentication* */
  serviceCredentialUsername?: Maybe<Scalars['String']['output']>;
  siteLoadTickets: SiteLoadTicketsConnection;
  /** *Requires authentication* */
  siteMetricDefinitionRelations: SiteMetricDefinitionConnection;
  /** *Requires authentication* */
  sites: Array<Site>;
  topMachineStatistics: TopStatisticsResult;
};


export type MachineOwningCompany_HasServiceCredentialsArgs = {
  service: AssetManagerExternalServiceInput;
};


export type MachineOwningCompany_MachineStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: MachineStatisticsFields;
  filterBy?: InputMaybe<MachineStatisticsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: MachineStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  propertyId: MachineStatisticsId;
  to: Scalars['DateTime']['input'];
};


export type MachineOwningCompany_ObwScalesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<ObwScaleFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ObwScaleFilterOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type MachineOwningCompany_ServiceCredentialUsernameArgs = {
  service: AssetManagerExternalServiceInput;
};


export type MachineOwningCompany_SiteLoadTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteLoadTicketsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteLoadTicketsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type MachineOwningCompany_SiteMetricDefinitionRelationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMetricDefinitionFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMetricDefinitionOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type MachineOwningCompany_TopMachineStatisticsArgs = {
  entityId: TopMachineStatisticsEntity;
  field: MachineStatisticsFields;
  filterBy?: InputMaybe<TopMachineStatisticsFilterBy>;
  from: Scalars['DateTime']['input'];
  propertyId: MachineStatisticsId;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take: TopStatisticsTake;
  to: Scalars['DateTime']['input'];
};

export type MachineOwningCompanyMutations = {
  /** *Requires authentication* */
  createManager: User;
  /** *Requires authentication* */
  deleteManager: Scalars['Boolean']['output'];
  /** *Requires authentication* */
  invite: MachineOwningCompany;
  /** *Requires authentication* */
  merge: MachineOwningCompany;
  /** *Requires authentication* */
  mergeOrCreateFromInvitation: MachineOwningCompany;
  /** *Requires authentication* */
  resendInvite: MachineOwningCompany;
  /** *Requires authentication* */
  update: MachineOwningCompany;
};


export type MachineOwningCompanyMutations_CreateManagerArgs = {
  input: CreateManagerInput;
};


export type MachineOwningCompanyMutations_DeleteManagerArgs = {
  machineOwningCompanyId: Scalars['ID']['input'];
  userId: Scalars['String']['input'];
};


export type MachineOwningCompanyMutations_InviteArgs = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  invitedByEmail: Scalars['String']['input'];
};


export type MachineOwningCompanyMutations_MergeArgs = {
  mergeFromCompanyId: Scalars['ID']['input'];
  mergeToCompanyId: Scalars['ID']['input'];
};


export type MachineOwningCompanyMutations_MergeOrCreateFromInvitationArgs = {
  input: MergeOrCreateFromInvitationInput;
};


export type MachineOwningCompanyMutations_ResendInviteArgs = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  invitedByEmail: Scalars['String']['input'];
};


export type MachineOwningCompanyMutations_UpdateArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  machineOwningCompanyId: Scalars['ID']['input'];
};

export type MachineOwningCompanyQueries = {
  /** *Requires authentication* */
  hibooSignin?: Maybe<Scalars['String']['output']>;
  /** *Requires authentication* */
  machineOwningCompanies: Array<MachineOwningCompany>;
  /** *Requires authentication* */
  machineOwningCompaniesByAuth: Array<MachineOwningCompany>;
  /** *Requires authentication* */
  machineOwningCompany?: Maybe<MachineOwningCompany>;
};


export type MachineOwningCompanyQueries_HibooSigninArgs = {
  machineOwningCompanyId: Scalars['ID']['input'];
};


export type MachineOwningCompanyQueries_MachineOwningCompaniesArgs = {
  machineOwningCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MachineOwningCompanyQueries_MachineOwningCompanyArgs = {
  machineOwningCompanyId: Scalars['ID']['input'];
};

export type MachinePermissionOrigin = {
  machinePermissionChassisId: Scalars['ID']['output'];
  origin: AssistOriginPermissionService;
  originMachineId: Scalars['ID']['output'];
};

export type MachinePosition = {
  /** [ARI] Heading at the time of the fault event. */
  heading?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Latitude at the time of the fault event. */
  latitude: Scalars['String']['output'];
  /** [ARI] Longitude at the time of the fault event. */
  longitude: Scalars['String']['output'];
  /** [ARI] Speed at the time of the fault event. */
  speed?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Timestamp of the location readout. */
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

/** ARI data about how many tons and cycles a machine has produced. For platforms that have an on-board weighing system. */
export type MachineProduction = {
  /**
   * [ARI] Accumulated number of cycles.
   * @deprecated Use `numberOfCycles` instead.
   */
  cycles?: Maybe<Scalars['Int']['output']>;
  /**
   * [ARI] Accumulated total number of cycles with overload
   * @deprecated Use `numberOfCyclesWithOverload` instead.
   */
  cyclesWithOverload?: Maybe<Scalars['Int']['output']>;
  /**
   * [ARI] Accumulated total number of load utilization per cycle.
   * @deprecated Use `numberOfLoadUtilizationPerCycle` instead.
   */
  loadUtilizationPerCycle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Maximum load (fixed number) for the machine. */
  nominalLoad?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated number of cycles. */
  numberOfCycles?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Accumulated total number of cycles with overload */
  numberOfCyclesWithOverload?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Accumulated total number of load utilization per cycle. */
  numberOfLoadUtilizationPerCycle?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] Timestamp for when the production data was last updated.
   * @deprecated Use `updatedDateTime` instead.
   */
  productionUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /**
   * [ARI] Accumulated total production weight in kilograms.
   * @deprecated Use `totalProductionWeight` instead.
   */
  totalProductionKilograms?: Maybe<Scalars['Float']['output']>;
  /**
   * [ARI] Accumulated logging time in seconds.
   * @deprecated Use `totalProductionTime` instead.
   */
  totalProductionSeconds?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Accumulated logging time in seconds. */
  totalProductionTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Accumulated total production weight in kilograms. */
  totalProductionWeight?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Timestamp for when the production data was last updated. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** Queries to get `Machine` entities */
export type MachineQueries = {
  /** Query to get a filtered list of machines. Most used to get machines by dealer or customer. */
  filteredMachines: MachineQueryResponse;
  /** Get single machine based on internal id. */
  machine?: Maybe<Machine>;
  /** Get single machine based on `chassisId`. */
  machineByChassisID?: Maybe<Machine>;
  /** Get single machine based on `pin17`. */
  machineByPIN17?: Maybe<Machine>;
  /** Get single machine based on combination of `salesModel` and `serialNumber`. */
  machineBySalesModelSerialNumber?: Maybe<Machine>;
  /** [NEW] Query to get a filtered list of machines. Mostly used to get machines by dealer or customer. */
  machines: MachineConnection;
  /** Query to get a filtered list of multiple machines based on a list of ids. PIN17, Chassis id or SalesModel/SerialNumber can be used (but only one of the id types in the same query). */
  machinesByIDs: MachineQueryResponse;
  markers: Markers;
};


/** Queries to get `Machine` entities */
export type MachineQueries_FilteredMachinesArgs = {
  filter?: InputMaybe<MachineFilterInput>;
};


/** Queries to get `Machine` entities */
export type MachineQueries_MachineArgs = {
  id: Scalars['ID']['input'];
};


/** Queries to get `Machine` entities */
export type MachineQueries_MachineByChassisIdArgs = {
  chassisID: Scalars['String']['input'];
};


/** Queries to get `Machine` entities */
export type MachineQueries_MachineByPin17Args = {
  pin17: Scalars['String']['input'];
};


/** Queries to get `Machine` entities */
export type MachineQueries_MachineBySalesModelSerialNumberArgs = {
  salesModel: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};


/** Queries to get `Machine` entities */
export type MachineQueries_MachinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<MachineFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MachineSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries to get `Machine` entities */
export type MachineQueries_MachinesByIDsArgs = {
  filter: MachineIDsInput;
};


/** Queries to get `Machine` entities */
export type MachineQueries_MarkersArgs = {
  bounds: Bounds;
  filterBy?: InputMaybe<MarkerMachineFilter>;
  zoom: Scalars['Float']['input'];
};

export type MachineQueryResponse = {
  count?: Maybe<Scalars['Int']['output']>;
  endCursor?: Maybe<Scalars['ID']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  machines: Array<Machine>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type MachineSort = {
  brand?: InputMaybe<SortDirection>;
};

/** Possible types of external service inputs */
export enum MachineStatisticsExternalServiceInput {
  Assist = 'ASSIST',
  Flintab = 'FLINTAB',
  Hiboo = 'HIBOO',
  Hibooapi = 'HIBOOAPI',
  Ip21 = 'IP21',
  Loadrite = 'LOADRITE',
  Manuallycreated = 'MANUALLYCREATED',
  Sebadata = 'SEBADATA',
  Siteoperations = 'SITEOPERATIONS',
  Smarttonnes = 'SMARTTONNES',
  Tamtron = 'TAMTRON',
  Unknown = 'UNKNOWN'
}

export enum MachineStatisticsFields {
  Value = 'VALUE'
}

export type MachineStatisticsFilterBy = {
  fleetMachineId?: InputMaybe<IdFilterClass>;
  originMachineId?: InputMaybe<IdFilterClass>;
  ownerId?: InputMaybe<IdFilterClass>;
  siteId?: InputMaybe<IdFilterClass>;
  siteMachineId?: InputMaybe<IdFilterClass>;
  source?: InputMaybe<MachineStatisticsExternalServiceInput>;
};

export enum MachineStatisticsGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum MachineStatisticsId {
  /** Amount of co2 produced based on given filter. */
  Co2 = 'CO2',
  /** Amount of distance covered based on a given filter. */
  DailyDistance = 'DAILY_DISTANCE',
  /** Amount of fuel used based on given filter. */
  FuelUsed = 'FUEL_USED',
  /** Amount of idle hours based on given filter. */
  IdleHours = 'IDLE_HOURS',
  /** Amount of operating hours based on given filter. */
  OperatingHours = 'OPERATING_HOURS',
  /** Peak daily speed based on a given filter. */
  PeakDailySpeed = 'PEAK_DAILY_SPEED',
  /** Amount of working hours based on given filter. */
  WorkingHours = 'WORKING_HOURS'
}

export type MachineStatisticsQueries = {
  /** *Requires authentication* */
  statistics: StatisticsConnection;
};


export type MachineStatisticsQueries_StatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: MachineStatisticsFields;
  filterBy?: InputMaybe<MachineStatisticsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: MachineStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  propertyId: MachineStatisticsId;
  to: Scalars['DateTime']['input'];
};

export type MachineSubscription = {
  contextId: Scalars['String']['output'];
  /** when the message was handled in dcsp */
  createdAt: Scalars['DateTime']['output'];
  product: SubscriptionProduct;
  status: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** ARI machine distance. */
export type MachineSummaryDistance = {
  /** [ARI] Accumulated odometer value in meter. */
  distance?: Maybe<Scalars['Float']['output']>;
  /** Timestamp this event occurred. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** ARI machine summary event. */
export type MachineSummaryEvent = {
  /** [ARI] Was the engine running at the last summary event (updates on location, fuelLevel, operating time, vehicleMode/eventType)? */
  engineOn?: Maybe<Scalars['Boolean']['output']>;
  /** [ARI] Last received event from the machine. W-ECU only. */
  eventType?: Maybe<EventType>;
  /** Timestamp this event occurred. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** ARI data about machine fuel. */
export type MachineSummaryFuel = {
  /** [ARI] Current DEF/AdBlue level in percentage with 0.4% resolution. Diesel machines only. */
  defLevel?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Current fuel level in percentage with 0.4% resolution. */
  fuelLevel?: Maybe<Scalars['Float']['output']>;
  /** Timestamp this event occurred. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type MachineSummaryFuelFilter = {
  /** [ARI] Filter machines by last fuel level */
  fuelLevel?: InputMaybe<FloatFilterClass>;
};

/** ARI data about machine operating time. */
export type MachineSummaryOperatingTime = {
  /** [ARI] Accumulated number of seconds the machine has been used/running. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** Timestamp this event occurred. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type MachineSummaryOperatingTimeFilter = {
  /** [ARI] Filter machines by last known machine operating time */
  machineOperatingTime?: InputMaybe<IntFilterClass>;
};

/** ARI data about engine speed. */
export type MachineSummarySpeed = {
  /** [ARI] Current RPM of the engine with 0.25 rpm resolution. */
  engineSpeed?: Maybe<Scalars['Float']['output']>;
  /** Timestamp this event occurred. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

/** ARI data about machine fuel. */
export type MachineSummaryStarterBatteryLevel = {
  /** [ARI] Current battery level of the 12v starter battery in percentage. */
  starterBatteryLevel?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Timestamp of when starter battery level was last updated. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type MachineTypePermissionModel = {
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  machineTypeId: Scalars['String']['output'];
  zoneId: Scalars['String']['output'];
  zonePermissionType: ZonePermissionTypeModel;
  zonePermissionTypeId: Scalars['String']['output'];
};

export type MachineTypePermissionMutations = {
  create: MachineTypePermissionModel;
  delete: MachineTypePermissionModel;
  updateForZone: Array<MachineTypePermissionModel>;
};


export type MachineTypePermissionMutations_CreateArgs = {
  input: CreateMachineTypePermissionInput;
};


export type MachineTypePermissionMutations_DeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MachineTypePermissionMutations_UpdateForZoneArgs = {
  inputs: Array<CreateMachineTypePermissionInput>;
  zoneId: Scalars['ID']['input'];
};

export type MachineTypePermissionQueries = {
  byId: MachineTypePermissionModel;
  byZoneId: Array<MachineTypePermissionModel>;
};


export type MachineTypePermissionQueries_ByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MachineTypePermissionQueries_ByZoneIdArgs = {
  zoneId: Scalars['ID']['input'];
};

/** ARI data to evaluate efficiency of the machine in different modes. */
export type MachineUtilization = {
  /** [ARI] Accumulated total time in seconds the machine has been in excessive idle (over 5 minutes). TGW3 only. */
  totalTimeInExcessiveIdleSeconds?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated total time in seconds the machine has been in idle mode. W-ECU and TGW2.1 only. */
  totalTimeInIdleSeconds?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated total time in seconds the machine has been in wait idle mode. TGW3 only. */
  totalTimeInWaitIdleSeconds?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated total time in seconds the machine has been in work mode. */
  totalTimeInWorkSeconds?: Maybe<Scalars['Float']['output']>;
  /** Timestamp this event occurred. */
  updatedDateTime?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Obsolete. Timestamp this event occurred.
   * @deprecated Obsolete. Use `updatedDateTime` instead.
   */
  utilizationUpdatedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type MainSoftware = {
  /** [VDA] Caption for Software. */
  caption?: Maybe<Scalars['String']['output']>;
  /** [VDA] Caption Reference for Software. */
  captionReference?: Maybe<Scalars['String']['output']>;
  /** [VDA] Compression method for Software. */
  compressionMethod?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Encryption method for Software. */
  encryptionMethod?: Maybe<Scalars['Float']['output']>;
  /** [Teacore] Failure components data */
  failureComponents: Array<FailureComponentData>;
  /** [VDA] Indicator if isPreReleaseVerificationData. */
  isPreReleaseVerificationData?: Maybe<Scalars['Boolean']['output']>;
  /** [VDA] Issue for Software. */
  issue?: Maybe<Scalars['String']['output']>;
  /** [VDA] Identifier for documentation in Software Configurator */
  nodeTemplateDocumentNumber?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Indicator if nonProgrammable. */
  nonProgrammable?: Maybe<Scalars['Boolean']['output']>;
  /** [VDA] Offset address for Software. */
  offsetAddress?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Part number for the Software in TEA2PlusNode. */
  partNumber?: Maybe<Scalars['String']['output']>;
  /** [VDA] Software download signature for Software. */
  softwareDownloadSignature?: Maybe<Scalars['String']['output']>;
  /** [VDA] Stage for Software. */
  stage?: Maybe<Scalars['String']['output']>;
};

/** [Teacore] Data Information about Main Software */
export type MainSoftwareData = {
  /** [Teacore] Main Software caption */
  caption?: Maybe<Scalars['String']['output']>;
  /** [Teacore] Main Software caption reference */
  captionRef?: Maybe<Scalars['String']['output']>;
  /** [Teacore] Failure components */
  failureComponents: Array<FailureComponentData>;
  /** [Teacore] Is pre release verification */
  isPreReleaseVerification: Scalars['Boolean']['output'];
  /** [Teacore] Main Software issue */
  issue?: Maybe<Scalars['String']['output']>;
  partNumber: Scalars['String']['output'];
  /** [Teacore] Main Software stage */
  stage?: Maybe<Scalars['String']['output']>;
};

/** Queries to get `MainSoftware` entities */
export type MainSoftwareQueries = {
  /** Get main software list by sales model */
  mainSoftwareListBySalesModel: Array<MainSoftwareData>;
};


/** Queries to get `MainSoftware` entities */
export type MainSoftwareQueries_MainSoftwareListBySalesModelArgs = {
  salesModel: Scalars['String']['input'];
};

export type ManualLoadTicketPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  duration?: InputMaybe<Scalars['Float']['input']>;
  durationUnit?: InputMaybe<Scalars['String']['input']>;
  endTimestamp?: InputMaybe<Scalars['String']['input']>;
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowEndZoneId: Scalars['ID']['input'];
  materialFlowId: Scalars['ID']['input'];
  materialFlowStartZoneId: Scalars['ID']['input'];
  materialId: Scalars['String']['input'];
  numberOfBuckets?: InputMaybe<Scalars['Float']['input']>;
  siteId: Scalars['ID']['input'];
  startTimestamp?: InputMaybe<Scalars['String']['input']>;
  transporterSiteMachineId?: InputMaybe<Scalars['ID']['input']>;
  weight: Scalars['Float']['input'];
  weightUnit: Scalars['String']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type Marker = ClusterMarker | MachineMarker;

export type MarkerMachineFilter = {
  and?: InputMaybe<Array<MarkerMachineFilter>>;
  batteryStatus?: InputMaybe<MachineBatteryStatusFilter>;
  /** Filter machines by brand */
  brand?: InputMaybe<StringFilterClass>;
  /** Filter machines by Chassis ID */
  chassisID?: InputMaybe<StringFilterClass>;
  /** Filter machines by ctChassisID */
  ctChassisID?: InputMaybe<StringFilterClass>;
  /** Filter machines by dealer Parma ID */
  dealerParmaID?: InputMaybe<StringFilterClass>;
  /** Filter machines by end customer ID */
  endCustomerId?: InputMaybe<StringFilterClass>;
  /** Filter machines by energy source */
  energySource?: InputMaybe<EnergySourceFilterClass>;
  generalDescription?: InputMaybe<MachineGeneralDescriptionFilter>;
  /** Filter machines by last update */
  globalUpdatedDateTime?: InputMaybe<DateFilterClass>;
  /** Filter machines by internal ID */
  id?: InputMaybe<StringFilterClass>;
  /** Filter machines by latest city */
  latestCity?: InputMaybe<StringFilterClass>;
  location?: InputMaybe<MarkerMachineLocationFilter>;
  machineSummaryFuel?: InputMaybe<MachineSummaryFuelFilter>;
  machineSummaryOperatingTime?: InputMaybe<MachineSummaryOperatingTimeFilter>;
  /** Filter machines by model */
  model?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<MarkerMachineFilter>>;
  /** Filter machines by organization id:s */
  organizations?: InputMaybe<StringArrayFilterClassWithOnlyHasSome>;
  /** Filter machines by PIN17 */
  pin17?: InputMaybe<StringFilterClass>;
  /** Filter machines by salesModel */
  salesModel?: InputMaybe<StringFilterClass>;
  /** Filter machines by dealer serialNumber */
  serialNumber?: InputMaybe<StringFilterClass>;
  /** Filter machines by subscription product id:s */
  subscriptions?: InputMaybe<StringArrayFilterClassWithOnlyHasSome>;
};

export type MarkerMachineLocationFilter = {
  /** Filter machines by latest city */
  latestCity?: InputMaybe<StringFilterClass>;
  /** Filter machines by latest country ISO code */
  latestCountryIsoCode?: InputMaybe<StringFilterClass>;
  /** Filter machines by last location update */
  updatedDateTime?: InputMaybe<DateFilterClass>;
};

export type Markers = {
  markers: Array<Marker>;
  totalCount: Scalars['Float']['output'];
};

export type MaterialConnection = {
  edges: Array<MaterialEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MaterialEdge = {
  cursor: Scalars['String']['output'];
  node: MaterialModel;
};

export type MaterialFamily = {
  companyId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  materialTypes: Array<MaterialType>;
  name: Scalars['String']['output'];
  shortname?: Maybe<Scalars['String']['output']>;
};

export type MaterialFamilyMutations = {
  /** *Requires authentication* */
  create: MaterialFamily;
  /** *Requires authentication* */
  update: MaterialFamily;
};


export type MaterialFamilyMutations_CreateArgs = {
  input: CreateMaterialFamilyInput;
};


export type MaterialFamilyMutations_UpdateArgs = {
  input: EditMaterialFamilyInput;
  materialFamilyId: Scalars['ID']['input'];
};

export type MaterialFamilyQueries = {
  byCompanyId: Array<MaterialFamily>;
  materialFamiliesBySiteId: Array<MaterialFamily>;
};


export type MaterialFamilyQueries_ByCompanyIdArgs = {
  companyId: Scalars['ID']['input'];
};


export type MaterialFamilyQueries_MaterialFamiliesBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type MaterialFilter = {
  and?: InputMaybe<Array<MaterialFilter>>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<MaterialFilter>>;
  uuid?: InputMaybe<StringFilterClass>;
};

export type MaterialFlowAssignedEventPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type MaterialFlowExternalReferenceModel = {
  id: Scalars['ID']['output'];
  materialFlowId: Scalars['ID']['output'];
  type: ExternalReferenceTypes;
  value: Scalars['String']['output'];
};

export type MaterialFlowModel = {
  assignedMachines: Array<SiteMachine>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalReferences?: Maybe<Array<MaterialFlowExternalReferenceModel>>;
  fromZoneId: Scalars['String']['output'];
  hasDynamicMaterial: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  materialVariant: MaterialVariant;
  materialVariantId?: Maybe<Scalars['String']['output']>;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  toZoneId: Scalars['String']['output'];
};

export type MaterialFlowMutations = {
  /** @deprecated Use create in siteMaterialFlow instead. */
  create: MaterialFlowModel;
  /** @deprecated Use delete in siteMaterialFlow instead. */
  delete: MaterialFlowModel;
  /** @deprecated Use update in siteMaterialFlow instead. */
  update: MaterialFlowModel;
};


export type MaterialFlowMutations_CreateArgs = {
  input: CreateMaterialFlowInput;
};


export type MaterialFlowMutations_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type MaterialFlowMutations_UpdateArgs = {
  input: UpdateMaterialFlowInput;
};

export type MaterialFlowObjectiveAnalyticsQueries = {
  objectiveSummery: Array<FlowObjectiveRateAggregate>;
};


export type MaterialFlowObjectiveAnalyticsQueries_ObjectiveSummeryArgs = {
  materialFlowId: Scalars['String']['input'];
  resolution: DailyScaleTimeUnit;
  siteId: Scalars['String']['input'];
  timeRangeInput: TimeRangeInput;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type MaterialFlowObjectiveFilterInput = {
  amount?: InputMaybe<IntNullableFilterInput>;
  endDate?: InputMaybe<DateTimeNullableFilterInput>;
  interval?: InputMaybe<MaterialFlowObjectiveInterval>;
  startDate?: InputMaybe<DateTimeNullableFilterInput>;
  status?: InputMaybe<MaterialFlowObjectiveStatusEnum>;
};

/** Interval of the Material Flow Objective */
export enum MaterialFlowObjectiveInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Workweek = 'WORKWEEK'
}

export type MaterialFlowObjectiveMutation = {
  create?: Maybe<MaterialFlowObjectiveOutput>;
  delete: Scalars['Boolean']['output'];
  update: MaterialFlowObjectiveOutput;
};


export type MaterialFlowObjectiveMutation_CreateArgs = {
  data: CreateMaterialFlowObjectiveDto;
};


export type MaterialFlowObjectiveMutation_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type MaterialFlowObjectiveMutation_UpdateArgs = {
  data: UpdateMaterialFlowObjectiveDto;
  id: Scalars['String']['input'];
};

export type MaterialFlowObjectiveOutput = {
  /** _basisDays = amount/interval_ e.g: if interval is week `basisDays = amount/7` or if interval is day `basisDays = amount/1` */
  basisDays: Scalars['Float']['output'];
  deleted: Scalars['Boolean']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  interval: MaterialFlowObjectiveInterval;
  materialFlowId: Scalars['String']['output'];
  originalAmount?: Maybe<Scalars['Float']['output']>;
  originalUnit?: Maybe<Scalars['String']['output']>;
  siteId: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  status: MaterialFlowObjectiveStatusEnum;
  weight: UnitValue;
};


export type MaterialFlowObjectiveOutput_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type MaterialFlowObjectiveQueries = {
  /** This will resolve the a list of material objectives where `endDate` is null or have passed today. The list is ordered by `startDate` asc so the first objective is the current objective. */
  activeObjective: Array<MaterialFlowObjectiveOutput>;
  analytics: MaterialFlowObjectiveAnalyticsQueries;
  objectives: Array<MaterialFlowObjectiveOutput>;
  /** This will resolve the a list of material objectives where `endDate` is null or have passed today. The list is ordered by `startDate` asc so the first objective is the current objective. */
  objectivesWithinInterval: Array<MaterialFlowObjectiveOutput>;
};


export type MaterialFlowObjectiveQueries_ActiveObjectiveArgs = {
  materialFlowId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};


export type MaterialFlowObjectiveQueries_ObjectivesArgs = {
  filter?: InputMaybe<MaterialFlowObjectiveFilterInput>;
  materialFlowId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};


export type MaterialFlowObjectiveQueries_ObjectivesWithinIntervalArgs = {
  TimeRangeInput: TimeRangeInput;
  materialFlowId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

/** Status of the Material Flow Objective */
export enum MaterialFlowObjectiveStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type MaterialFlowQueries = {
  byId: MaterialFlowModel;
  bySiteMapId: Array<MaterialFlowModel>;
  byZoneId: Array<MaterialFlowModel>;
};


export type MaterialFlowQueries_ByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MaterialFlowQueries_BySiteMapIdArgs = {
  options?: InputMaybe<GetMaterialFlowsBySiteMapIdOptions>;
  siteMapId: Scalars['ID']['input'];
};


export type MaterialFlowQueries_ByZoneIdArgs = {
  byZoneId: Scalars['ID']['input'];
  options?: InputMaybe<GetMaterialFlowsByZoneIdOptions>;
};

export enum MaterialFlowType {
  PoiToZone = 'POI_TO_ZONE',
  ZoneToPoi = 'ZONE_TO_POI',
  ZoneToZone = 'ZONE_TO_ZONE'
}

export type MaterialFlowTypeFilterClass = {
  equals?: InputMaybe<MaterialFlowType>;
  in?: InputMaybe<Array<MaterialFlowType>>;
  not?: InputMaybe<MaterialFlowType>;
  notIn?: InputMaybe<Array<MaterialFlowType>>;
};

export type MaterialFlowUnassignedEventPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

/** Possible types of external services */
export enum MaterialMappingExternalService {
  Ip21 = 'IP21',
  Smarttonnes = 'SMARTTONNES'
}

export type MaterialModel = {
  anonymous: Scalars['Boolean']['output'];
  categoryUuid?: Maybe<Scalars['String']['output']>;
  density: Scalars['Float']['output'];
  groupId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isCategory?: Maybe<Scalars['Boolean']['output']>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  isWaste?: Maybe<Scalars['Boolean']['output']>;
  latest?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  nameId: Scalars['String']['output'];
  rev: Scalars['BigInt']['output'];
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo: Scalars['DateTime']['output'];
};

export type MaterialQueries = {
  /** Fetch materials based on chassis ids or target uuid. Requires authentication */
  Material: MaterialConnection;
};


export type MaterialQueries_MaterialArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<MaterialFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MaterialSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type MaterialSort = {
  name?: InputMaybe<SortDirection>;
  updated?: InputMaybe<SortDirection>;
  uuid?: InputMaybe<SortDirection>;
};

export type MaterialThreshold = {
  /** Date and time when object was created. */
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  materialId: Scalars['String']['output'];
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
  siteId: Scalars['String']['output'];
  /** Date and time when the object was updated last time. */
  updatedAt: Scalars['Timestamp']['output'];
};

export type MaterialThresholdMutation = {
  create: MaterialThreshold;
  delete: Scalars['Boolean']['output'];
  update: MaterialThreshold;
  upsert: MaterialThreshold;
};


export type MaterialThresholdMutation_CreateArgs = {
  data: CreateMaterialThresholdInput;
};


export type MaterialThresholdMutation_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type MaterialThresholdMutation_UpdateArgs = {
  data: UpdateMaterialThresholdInput;
  id: Scalars['String']['input'];
};


export type MaterialThresholdMutation_UpsertArgs = {
  data: UpdateMaterialThresholdInput;
  id: Scalars['String']['input'];
};

export type MaterialThresholdQuery = {
  materialThreshold: MaterialThreshold;
  materialThresholds: Array<MaterialThreshold>;
};


export type MaterialThresholdQuery_MaterialThresholdArgs = {
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};


export type MaterialThresholdQuery_MaterialThresholdsArgs = {
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
};

export type MaterialTransaction = {
  comment?: Maybe<Scalars['String']['output']>;
  correlationId?: Maybe<Scalars['String']['output']>;
  /** Date and time when object was created. */
  createdAt: Scalars['Timestamp']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  eventBusName?: Maybe<Scalars['String']['output']>;
  eventBusType?: Maybe<EventBusTransactionTypeEnum>;
  eventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  materialId: Scalars['String']['output'];
  materialVariant: MaterialVariant;
  modified: Scalars['Boolean']['output'];
  modifiedAmount?: Maybe<UnitValue>;
  orderItem?: Maybe<OrderItem>;
  orderItemId?: Maybe<Scalars['String']['output']>;
  originalAmount?: Maybe<Scalars['Float']['output']>;
  originalUnit?: Maybe<Scalars['String']['output']>;
  siteId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  transactionType: TransactionTypeEnum;
  /** Date and time when the object was updated last time. */
  updatedAt: Scalars['Timestamp']['output'];
  weight: UnitValue;
  zoneId?: Maybe<Scalars['String']['output']>;
};


export type MaterialTransaction_ModifiedAmountArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};


export type MaterialTransaction_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type MaterialTransactionAnalyticsQuery = {
  delivery: DeliveryAnalyticsQuery;
  movement: MovementAnalyticsQuery;
  order: OrderAnalyticsQuery;
  production: ProductionAnalyticsQuery;
  refreshMaterialTransactionSummary: Scalars['Boolean']['output'];
  source: SourceAnalyticsQuery;
  stock: StockAnalyticsQuery;
};

export type MaterialTransactionEdge = {
  cursor: Scalars['String']['output'];
  node: MaterialTransaction;
};

export type MaterialTransactionFilter = {
  amount?: InputMaybe<FloatNullableFilterInput>;
  comment?: InputMaybe<StringFilterInput>;
  correlationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimeNullableFilterInput>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  eventBusName?: InputMaybe<StringFilterInput>;
  eventBusType?: InputMaybe<EventBusTransactionTypeEnum>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  materialId?: InputMaybe<Scalars['String']['input']>;
  modified?: InputMaybe<Scalars['Boolean']['input']>;
  modifiedAmount?: InputMaybe<Scalars['Float']['input']>;
  orderItemId?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<DateTimeNullableFilterInput>;
  transactionType?: InputMaybe<Array<TransactionTypeEnum>>;
  updatedAt?: InputMaybe<DateTimeNullableFilterInput>;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type MaterialTransactionMutation = {
  adjustTransaction: MaterialTransaction;
  create: MaterialTransaction;
  delete: Scalars['Boolean']['output'];
  update: MaterialTransaction;
};


export type MaterialTransactionMutation_AdjustTransactionArgs = {
  amount: Scalars['Float']['input'];
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
  unitType: Scalars['String']['input'];
  zoneId?: InputMaybe<Scalars['String']['input']>;
};


export type MaterialTransactionMutation_CreateArgs = {
  data: CreateMaterialTransactionInput;
};


export type MaterialTransactionMutation_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type MaterialTransactionMutation_UpdateArgs = {
  data: UpdateMaterialTransactionInput;
  id: Scalars['String']['input'];
};

export type MaterialTransactionQuery = {
  analytics: MaterialTransactionAnalyticsQuery;
  materialTransactionsByCustomer: Array<MaterialTransaction>;
  transaction: MaterialTransaction;
  transactions: MaterialTransactionsConnection;
};


export type MaterialTransactionQuery_MaterialTransactionsByCustomerArgs = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MaterialTransactionFilter>;
};


export type MaterialTransactionQuery_TransactionArgs = {
  id: Scalars['String']['input'];
};


export type MaterialTransactionQuery_TransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<MaterialTransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MaterialTransactionSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type MaterialTransactionSort = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  materialId?: InputMaybe<SortDirection>;
  timestamp?: InputMaybe<SortDirection>;
  transactionType?: InputMaybe<SortDirection>;
  zoneId?: InputMaybe<SortDirection>;
};

export type MaterialTransactionsConnection = {
  edges: Array<MaterialTransactionEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MaterialType = {
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  materialFamily: MaterialFamily;
  materialVariants: Array<MaterialVariant>;
  name: Scalars['String']['output'];
  shortname?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MaterialTypeMutations = {
  create: MaterialType;
  update: MaterialType;
};


export type MaterialTypeMutations_CreateArgs = {
  input: CreateMaterialTypeInput;
};


export type MaterialTypeMutations_UpdateArgs = {
  input: EditMaterialTypeInput;
  materialTypeId: Scalars['ID']['input'];
};

export type MaterialTypeQueries = {
  materialTypesByFamilyId: Array<MaterialType>;
};


export type MaterialTypeQueries_MaterialTypesByFamilyIdArgs = {
  materialFamilyId: Scalars['ID']['input'];
};

export type MaterialVariant = {
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  materialType: MaterialType;
  name: Scalars['String']['output'];
  shortname?: Maybe<Scalars['String']['output']>;
};

export type MaterialVariantMutations = {
  create: MaterialVariant;
  update: MaterialVariant;
};


export type MaterialVariantMutations_CreateArgs = {
  input: CreateMaterialVariantInput;
};


export type MaterialVariantMutations_UpdateArgs = {
  input: EditMaterialVariantInput;
  materialVariantId: Scalars['ID']['input'];
};

export type MaterialVariantQueries = {
  materialVariant?: Maybe<MaterialVariant>;
  materialVariants: Array<MaterialVariant>;
  materialVariantsByTypeId: Array<MaterialVariant>;
};


export type MaterialVariantQueries_MaterialVariantArgs = {
  materialVariantId: Scalars['ID']['input'];
};


export type MaterialVariantQueries_MaterialVariantsArgs = {
  materialVariantIds: Array<Scalars['ID']['input']>;
};


export type MaterialVariantQueries_MaterialVariantsByTypeIdArgs = {
  materialTypeId: Scalars['ID']['input'];
};

export type MergeFromInvitationInput = {
  invitationId: Scalars['ID']['input'];
  mergeToCompanyId: Scalars['ID']['input'];
};

export type MergeOrCreateFromInvitationInput = {
  createFromInvitationInput?: InputMaybe<CreateFromInvitationInput>;
  mergeFromInvitationInput?: InputMaybe<MergeFromInvitationInput>;
};

/** supported sources for metric definitions */
export enum MetricDefinitionSource {
  Ip21 = 'IP21',
  Smarttonnes = 'SMARTTONNES'
}

/** Supported asset types in fleet */
export enum MetricDefinitionType {
  FlowRateMass = 'FLOW_RATE_MASS',
  LevelMass = 'LEVEL_MASS',
  LevelPercent = 'LEVEL_PERCENT',
  LevelVolume = 'LEVEL_VOLUME',
  MassFlowRate = 'MASS_FLOW_RATE',
  Material = 'MATERIAL',
  ProducedMass = 'PRODUCED_MASS',
  StatusIndicator = 'STATUS_INDICATOR'
}

export type MovementAnalyticsQuery = {
  movementFromHistory: Array<GroupedTransactionData>;
  movementToHistory: Array<GroupedTransactionData>;
};


export type MovementAnalyticsQuery_MovementFromHistoryArgs = {
  accumulate?: Scalars['Boolean']['input'];
  generateEmptyDays?: Scalars['Boolean']['input'];
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  timePeriodInput: TimePeriodInput;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};


export type MovementAnalyticsQuery_MovementToHistoryArgs = {
  accumulate?: Scalars['Boolean']['input'];
  generateEmptyDays?: Scalars['Boolean']['input'];
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  timePeriodInput: TimePeriodInput;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  actorUsers: UserActorMutations;
  actors: ActorMutations;
  applications: ApplicationMutations;
  /** *Requires authentication* */
  assetManagerInvitations: AssetManagerInvitationMutations;
  assist: AssistMutations;
  clients: ClientMutations;
  credentials: CredentialsMutations;
  csvImport: CsvImportMutations;
  customer: CustomerMutation;
  emobChargers: EmobChargerMutations;
  emobCpmsSubscriptions: EmobCpmsSubscriptionMutations;
  externalMaterialMappings: ExternalMaterialMappingMutations;
  /** *Requires authentication* */
  fleetAssets: FleetAssetMutations;
  /** *Requires authentication* */
  fleetMachines: FleetMachineMutations;
  /** *Requires authentication* */
  fleetMetricDefinitions: FleetMetricDefinitionMutations;
  fleetReportTemplates: FleetReportTemplateMutations;
  geographyPhotos: GeographyPhotoMutations;
  ip21Credentials: Ip21CredentialsMutations;
  loadriteCredentials: LoadriteCredentialsMutations;
  /** *Requires authentication* */
  machineOwningCompanies: MachineOwningCompanyMutations;
  machineTypePermissions: MachineTypePermissionMutations;
  /** *Requires authentication* */
  materialFamilies: MaterialFamilyMutations;
  materialFlowObjective: MaterialFlowObjectiveMutation;
  /** @deprecated Use siteMaterialFlows instead. */
  materialFlows: MaterialFlowMutations;
  materialThreshold: MaterialThresholdMutation;
  materialTransaction: MaterialTransactionMutation;
  materialTypes: MaterialTypeMutations;
  materialVariants: MaterialVariantMutations;
  operatorChargerAssociations: OperatorChargerAssociationMutations;
  operatorChargers: OperatorChargerMutations;
  operatorMachineAssociations: OperatorMachineAssociationMutations;
  operatorMachines: OperatorMachineMutations;
  order: OrderMutation;
  organizations: OrganizationMutations;
  pointsOfInterest: PointOfInterestMutations;
  scopes: ScopeMutations;
  shortcuts: ShortcutMutations;
  siteAssets: SiteAssetMutations;
  siteAssistant: SiteAssistantMutation;
  siteCompanies: SiteCompanyMutations;
  /** *Requires authentication* */
  siteConfigurations: SiteConfigurationMutations;
  siteDevice: SiteDeviceMutation;
  siteDeviceEventMutations: SiteDeviceEventMutations;
  /** *Requires authentication* */
  siteMachines: SiteMachineMutations;
  siteMaps: SiteMapMutations;
  siteMaterialFlows: SiteMaterialFlowMutations;
  siteMetricDefinitions: SiteMetricDefinitionMutations;
  /** *Requires authentication* */
  siteOperators: SiteOperatorMutations;
  siteSupportTickets: SiteSupportTicketMutations;
  siteTablets: SiteTabletMutations;
  /** *Requires authentication* */
  siteUsers: SiteUserMutations;
  /** *Requires authentication* */
  sites: SiteMutations;
  smarttonnesCredentials: SmarttonnesCredentialsMutations;
  /** @deprecated Replaced by smarttonnesExternalMetricDefinitions */
  smarttonnesExternalCrusherWeighers: ExternalCrusherWeighersMutations;
  smarttonnesExternalMetricDefinitions: ExternalMetricDefinitionsMutations;
  subscriptionEvents: SubscriptionEventMutations;
  tamtronCredentials: TamtronCredentialsMutations;
  userprofiles: UserProfileMutations;
  users: UserMutations;
  workflowExecutions: WorkflowExecutionMutations;
  zones: ZoneMutations;
};

export type Node = {
  context: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nodeOptions: Scalars['JSON']['output'];
  nodeType: NodeType;
  status: WorkflowStatus;
};

export type NodeNameData = {
  /** [VDA] Part number for the ECUHardware. */
  nodeFamily?: Maybe<Scalars['String']['output']>;
  /** [VDA] Part number for the ECUHardware. */
  nodeType?: Maybe<Scalars['String']['output']>;
  /** [VDA] Values for NodeNames's ParentNode. */
  parentNode?: Maybe<ParentNodeData>;
};

export type NodeTemplate = {
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nodeOptions: Scalars['JSON']['output'];
  nodeType: NodeType;
};

/** Type of node */
export enum NodeType {
  Assetmanagerprovisioning = 'ASSETMANAGERPROVISIONING',
  Assetsubscription = 'ASSETSUBSCRIPTION',
  Checkcontext = 'CHECKCONTEXT',
  Contextcondition = 'CONTEXTCONDITION',
  Emptynode = 'EMPTYNODE',
  Ensureauthuser = 'ENSUREAUTHUSER',
  Ensureorganization = 'ENSUREORGANIZATION',
  Eticket = 'ETICKET',
  Machine = 'MACHINE',
  Manual = 'MANUAL',
  Notification = 'NOTIFICATION',
  Serviceconfigurator = 'SERVICECONFIGURATOR',
  Servicemaster = 'SERVICEMASTER',
  Serviceprovisioning = 'SERVICEPROVISIONING',
  Siteoperationsprovisioning = 'SITEOPERATIONSPROVISIONING',
  Siteoperationsprovisioningv2 = 'SITEOPERATIONSPROVISIONINGV2',
  Subscriptionprovisioning = 'SUBSCRIPTIONPROVISIONING',
  Taskmanager = 'TASKMANAGER',
  Telematicscondition = 'TELEMATICSCONDITION',
  Validateauthserviceuser = 'VALIDATEAUTHSERVICEUSER'
}

export type NumberFilterInput = {
  /** equal to */
  equals?: InputMaybe<Scalars['Float']['input']>;
  /** greater than */
  gt?: InputMaybe<Scalars['Float']['input']>;
  /** greater than or equal to */
  gte?: InputMaybe<Scalars['Float']['input']>;
  /** lower than */
  lt?: InputMaybe<Scalars['Float']['input']>;
  /** lower than or equal to */
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type ObwScale = {
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  fleetMachine?: Maybe<FleetMachine>;
  id: Scalars['ID']['output'];
  ownerId: Scalars['ID']['output'];
  scaleId: Scalars['ID']['output'];
  source: ObwScaleSource;
  updatedAt: Scalars['DateTime']['output'];
};

export type ObwScaleConnection = {
  edges: Array<ObwScaleEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ObwScaleEdge = {
  cursor: Scalars['String']['output'];
  node: ObwScale;
};

export type ObwScaleFilterBy = {
  ownerId?: InputMaybe<IdFilterClass>;
  scaleId?: InputMaybe<IdFilterClass>;
  source?: InputMaybe<ObwScaleSourceInputFilterClass>;
};

export type ObwScaleFilterOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export enum ObwScaleSource {
  Loadrite = 'LOADRITE',
  Tamtron = 'TAMTRON',
  Vei = 'VEI'
}

export type ObwScaleSourceInputFilterClass = {
  equals?: InputMaybe<ObwScalesObwScaleSourceInput>;
  in?: InputMaybe<Array<ObwScalesObwScaleSourceInput>>;
  not?: InputMaybe<ObwScalesObwScaleSourceInput>;
  notIn?: InputMaybe<Array<ObwScalesObwScaleSourceInput>>;
};

export enum ObwScalesObwScaleSourceInput {
  Loadrite = 'LOADRITE',
  Tamtron = 'TAMTRON',
  Vei = 'VEI'
}

export type OffloadPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  loadTicketCorrelationId?: InputMaybe<Scalars['ID']['input']>;
  loadTicketStatusCodes?: InputMaybe<Array<DeviceEventsLoadTicketStatusCode>>;
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  materialId: Scalars['String']['input'];
  /** The latitude where the load event occured */
  originLatitude?: InputMaybe<Scalars['Float']['input']>;
  /** The longitude where the load event occured */
  originLongitude?: InputMaybe<Scalars['Float']['input']>;
  /** Zone registered during load */
  originZoneId?: InputMaybe<Scalars['ID']['input']>;
  /** The weight recorded during load */
  originalLoadedWeight?: InputMaybe<Scalars['Float']['input']>;
  siteId: Scalars['ID']['input'];
  siteLoadTicketId?: InputMaybe<Scalars['ID']['input']>;
  sourceLoadTicketId?: InputMaybe<Scalars['ID']['input']>;
  weight: Scalars['Float']['input'];
  weightUnit: Scalars['String']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type OffloadWarningPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  materialId: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
  weight: Scalars['Float']['input'];
  weightUnit: Scalars['String']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type OperatingTimeHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** [ARI] Accumulated number of seconds the machine has been used/running at the time of the event. */
  machineOperatingTime: Scalars['Int']['output'];
};

export type OperatingTimeQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** The data points for accumulated machine operating time events from ARI. */
  operatingTime: Array<OperatingTimeHistoryEvent>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

export type OperatorCharger = {
  chargingStation?: Maybe<ChargingStation>;
  id: Scalars['ID']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  operatorNotificationSettingProperties?: Maybe<Array<OperatorNotificationSettingProperty>>;
};

export type OperatorChargerAssociation = {
  chargingStations?: Maybe<Array<ChargingStation>>;
  email: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type OperatorChargerAssociationEdge = {
  cursor: Scalars['String']['output'];
  node: OperatorChargerAssociationV2;
};

export type OperatorChargerAssociationFilter = {
  and?: InputMaybe<Array<OperatorChargerAssociationFilter>>;
  id?: InputMaybe<IdFilterClass>;
  or?: InputMaybe<Array<OperatorChargerAssociationFilter>>;
  stationInternalId?: InputMaybe<StringFilterClass>;
  userId?: InputMaybe<StringFilterClass>;
};

export type OperatorChargerAssociationMutations = {
  createMany: OperatorChargerAssociation;
  createManyByEmail: OperatorChargerAssociation;
  deleteMany: Scalars['Boolean']['output'];
  replayAll: Scalars['Boolean']['output'];
  replayManyByUserId: Scalars['Boolean']['output'];
};


export type OperatorChargerAssociationMutations_CreateManyArgs = {
  input: CreateManyOperatorChargerAssociationInput;
};


export type OperatorChargerAssociationMutations_CreateManyByEmailArgs = {
  input: CreateManyOperatorChargerAssociationWithEmailInput;
};


export type OperatorChargerAssociationMutations_DeleteManyArgs = {
  input: DeleteManyOperatorChargerAssociationInput;
};


export type OperatorChargerAssociationMutations_ReplayManyByUserIdArgs = {
  userId: Scalars['String']['input'];
};

export type OperatorChargerAssociationQueries = {
  operatorChargerAssociations: OperatorChargerAssociationsConnection;
};


export type OperatorChargerAssociationQueries_OperatorChargerAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<OperatorChargerAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OperatorChargerAssociationSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type OperatorChargerAssociationSort = {
  id?: InputMaybe<SortDirection>;
  stationInternalId?: InputMaybe<SortDirection>;
  userId?: InputMaybe<SortDirection>;
};

export type OperatorChargerAssociationV2 = {
  id: Scalars['ID']['output'];
  stationInternalId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type OperatorChargerAssociationsConnection = {
  edges: Array<OperatorChargerAssociationEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type OperatorChargerEdge = {
  cursor: Scalars['String']['output'];
  node: OperatorCharger;
};

export type OperatorChargerFilter = {
  and?: InputMaybe<Array<OperatorChargerFilter>>;
  id?: InputMaybe<IdFilterClass>;
  nickname?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<OperatorChargerFilter>>;
  stationInternalId?: InputMaybe<StringFilterClass>;
};

export type OperatorChargerMutations = {
  editOperatorCharger: OperatorCharger;
  editOperatorChargerNotificationSettings: OperatorCharger;
};


export type OperatorChargerMutations_EditOperatorChargerArgs = {
  input: UpdateOperatorChargerInput;
};


export type OperatorChargerMutations_EditOperatorChargerNotificationSettingsArgs = {
  input: UpdateOperatorChargerNotificationSettingInput;
};

export type OperatorChargerQueries = {
  operatorCharger: OperatorCharger;
  operatorChargers: OperatorChargersConnection;
};


export type OperatorChargerQueries_OperatorChargerArgs = {
  stationInternalId: Scalars['String']['input'];
};


export type OperatorChargerQueries_OperatorChargersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<OperatorChargerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OperatorChargerSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type OperatorChargerSort = {
  id?: InputMaybe<SortDirection>;
  nickname?: InputMaybe<SortDirection>;
  stationInternalId?: InputMaybe<SortDirection>;
};

export type OperatorChargersConnection = {
  edges: Array<OperatorChargerEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type OperatorMachine = {
  id: Scalars['ID']['output'];
  machine?: Maybe<Machine>;
  nickname?: Maybe<Scalars['String']['output']>;
  operatorNotificationSettingProperties?: Maybe<Array<OperatorNotificationSettingProperty>>;
};

export type OperatorMachineAssociation = {
  email: Scalars['String']['output'];
  machines?: Maybe<Array<Machine>>;
  userId: Scalars['String']['output'];
};

export type OperatorMachineAssociationEdge = {
  cursor: Scalars['String']['output'];
  node: OperatorMachineAssociationV2;
};

export type OperatorMachineAssociationFilter = {
  and?: InputMaybe<Array<OperatorMachineAssociationFilter>>;
  id?: InputMaybe<IdFilterClass>;
  or?: InputMaybe<Array<OperatorMachineAssociationFilter>>;
  pin17?: InputMaybe<StringFilterClass>;
  userId?: InputMaybe<StringFilterClass>;
};

export type OperatorMachineAssociationMutations = {
  createMany: OperatorMachineAssociation;
  createManyByEmail: OperatorMachineAssociation;
  deleteMany: Scalars['Boolean']['output'];
  replayAll: Scalars['Boolean']['output'];
  replayManyByUserId: Scalars['Boolean']['output'];
};


export type OperatorMachineAssociationMutations_CreateManyArgs = {
  input: CreateManyOperatorMachineAssociationInput;
};


export type OperatorMachineAssociationMutations_CreateManyByEmailArgs = {
  input: CreateManyOperatorMachineAssociationWithEmailInput;
};


export type OperatorMachineAssociationMutations_DeleteManyArgs = {
  input: DeleteManyOperatorMachineAssociationInput;
};


export type OperatorMachineAssociationMutations_ReplayManyByUserIdArgs = {
  userId: Scalars['String']['input'];
};

export type OperatorMachineAssociationQueries = {
  operatorMachineAssociations: OperatorMachineAssociationsConnection;
};


export type OperatorMachineAssociationQueries_OperatorMachineAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<OperatorMachineAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OperatorMachineAssociationSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type OperatorMachineAssociationSort = {
  id?: InputMaybe<SortDirection>;
  pin17?: InputMaybe<SortDirection>;
  userId?: InputMaybe<SortDirection>;
};

export type OperatorMachineAssociationV2 = {
  id: Scalars['ID']['output'];
  pin17: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type OperatorMachineAssociationV3 = {
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pin17: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type OperatorMachineAssociationsConnection = {
  edges: Array<OperatorMachineAssociationEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type OperatorMachineEdge = {
  cursor: Scalars['String']['output'];
  node: OperatorMachine;
};

export type OperatorMachineFilter = {
  and?: InputMaybe<Array<OperatorMachineFilter>>;
  id?: InputMaybe<IdFilterClass>;
  nickname?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<OperatorMachineFilter>>;
  pin17?: InputMaybe<StringFilterClass>;
};

export type OperatorMachineMutations = {
  editOperatorMachine: OperatorMachine;
  editOperatorMachineNotificationSettings: OperatorMachine;
};


export type OperatorMachineMutations_EditOperatorMachineArgs = {
  input: UpdateOperatorMachineInput;
};


export type OperatorMachineMutations_EditOperatorMachineNotificationSettingsArgs = {
  input: UpdateOperatorMachineNotificationSettingInput;
};

export type OperatorMachineQueries = {
  operatorMachine: OperatorMachine;
  operatorMachines: OperatorMachinesConnection;
};


export type OperatorMachineQueries_OperatorMachineArgs = {
  pin17: Scalars['String']['input'];
};


export type OperatorMachineQueries_OperatorMachinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<OperatorMachineFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OperatorMachineSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type OperatorMachineSort = {
  id?: InputMaybe<SortDirection>;
  nickname?: InputMaybe<SortDirection>;
  pin17?: InputMaybe<SortDirection>;
};

export type OperatorMachinesConnection = {
  edges: Array<OperatorMachineEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type OperatorNotificationSettingProperty = {
  id: Scalars['ID']['output'];
  keyName: Scalars['String']['output'];
  notificationTypes: Array<OperatorNotificationSettingType>;
  operator: OperatorType;
  value: Scalars['String']['output'];
};

export enum OperatorNotificationSettingType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export enum OperatorType {
  Equals = 'EQUALS',
  Gt = 'GT',
  Gte = 'GTE',
  Lt = 'LT',
  Lte = 'LTE',
  Not = 'NOT'
}

export type Order = {
  /** Date and time when object was created. */
  createdAt: Scalars['Timestamp']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orderItems: Array<OrderItem>;
  orderedAt?: Maybe<Scalars['DateTime']['output']>;
  projectName: Scalars['String']['output'];
  siteId: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  status: OrderStatusTypeEnum;
  /** Date and time when the object was updated last time. */
  updatedAt: Scalars['Timestamp']['output'];
};

export type OrderAnalyticsQuery = {
  orderLeftWeight: Array<GroupedOrderData>;
  orderWeight: Array<GroupedOrderData>;
};


export type OrderAnalyticsQuery_OrderLeftWeightArgs = {
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
};


export type OrderAnalyticsQuery_OrderWeightArgs = {
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
};

export type OrderCreateInput = {
  OrderItems: Array<OrderItemCreateInput>;
  customerId: Scalars['String']['input'];
  orderedAt?: InputMaybe<Scalars['DateTime']['input']>;
  projectName: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type OrderFilter = {
  containsMaterialId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimeNullableFilterInput>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  /** use null to filter true or false */
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  orderedAt?: InputMaybe<DateTimeNullableFilterInput>;
  projectName?: InputMaybe<StringFilterInput>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatusTypeEnum>;
  updatedAt?: InputMaybe<DateTimeNullableFilterInput>;
};

export type OrderItem = {
  /** Date and time when object was created. */
  createdAt: Scalars['Timestamp']['output'];
  deleted: Scalars['Boolean']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  materialId: Scalars['String']['output'];
  materialValuePrice: Scalars['Float']['output'];
  materialVariant: MaterialVariant;
  order: Order;
  orderDeliveredExpectedWeight: UnitValue;
  orderDeliveredWeight: UnitValue;
  orderDeliveryHistory: Array<GroupedTransactionData>;
  orderDeviationt?: Maybe<Scalars['Float']['output']>;
  orderId: Scalars['String']['output'];
  originalAmount?: Maybe<Scalars['Float']['output']>;
  originalUnit?: Maybe<Scalars['String']['output']>;
  pricePerTonne?: Maybe<Scalars['Float']['output']>;
  startDate: Scalars['DateTime']['output'];
  /** Date and time when the object was updated last time. */
  updatedAt: Scalars['Timestamp']['output'];
  weight: UnitValue;
};


export type OrderItem_OrderDeliveredExpectedWeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};


export type OrderItem_OrderDeliveredWeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};


export type OrderItem_OrderDeliveryHistoryArgs = {
  accumulate?: Scalars['Boolean']['input'];
  generateEmptyDays?: Scalars['Boolean']['input'];
  timeUnit?: TimeUnitEnum;
  timezone?: Scalars['Timezone']['input'];
};


export type OrderItem_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type OrderItemCreateInput = {
  amount: Scalars['Float']['input'];
  endDate: Scalars['DateTime']['input'];
  materialId?: InputMaybe<Scalars['String']['input']>;
  pricePerTonne?: InputMaybe<Scalars['Float']['input']>;
  startDate: Scalars['DateTime']['input'];
  unitType: Scalars['String']['input'];
};

export type OrderItemUpdateInput = {
  amount: Scalars['Float']['input'];
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  materialId?: InputMaybe<Scalars['String']['input']>;
  pricePerTonne?: InputMaybe<Scalars['Float']['input']>;
  startDate: Scalars['DateTime']['input'];
  unitType: Scalars['String']['input'];
};

export type OrderMutation = {
  create: Order;
  delete: Scalars['Boolean']['output'];
  orderItem: Scalars['Boolean']['output'];
  update: Order;
};


export type OrderMutation_CreateArgs = {
  data: OrderCreateInput;
};


export type OrderMutation_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type OrderMutation_OrderItemArgs = {
  id: Scalars['String']['input'];
};


export type OrderMutation_UpdateArgs = {
  data: OrderUpdateInput;
  id: Scalars['String']['input'];
};

export type OrderQueries = {
  order: Order;
  orders: Array<Order>;
};


export type OrderQueries_OrderArgs = {
  id: Scalars['String']['input'];
};


export type OrderQueries_OrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
};

/** The type of a delivery plan status */
export enum OrderStatusTypeEnum {
  Active = 'ACTIVE',
  Closed = 'CLOSED'
}

export type OrderUpdateInput = {
  OrderItems?: InputMaybe<Array<OrderItemUpdateInput>>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  orderedAt?: InputMaybe<Scalars['DateTime']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatusTypeEnum>;
};

export type Organization = {
  assetRelationships: OrganizationAssetRelationshipConnection;
  childOrganizationRelationships: Array<OrganizationOrganizationRelationship>;
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use externalIds instead */
  externalId: Scalars['String']['output'];
  externalIds: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** Type of organizations */
  organizationType?: Maybe<OrganizationType>;
  parentOrganizationRelationships: Array<OrganizationOrganizationRelationship>;
  siteRelationships: Array<OrganizationSiteRelationship>;
  source: Scalars['String']['output'];
  subscriptions: Array<SubscriptionResponse>;
  userRelationships: Array<OrganizationUserRelationship>;
};


export type Organization_AssetRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<OrganizationAssetRelationshipFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrganizationAssetRelationshipSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationAssetRelationship = {
  assetRelationshipType: AssetRelationshipType;
  assetType: AssetType;
  createdAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalId: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['ID']['output'];
  source: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationAssetRelationshipConnection = {
  edges: Array<OrganizationAssetRelationshipEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type OrganizationAssetRelationshipEdge = {
  cursor: Scalars['String']['output'];
  node: OrganizationAssetRelationship;
};

export type OrganizationAssetRelationshipFilter = {
  and?: InputMaybe<Array<OrganizationAssetRelationshipFilter>>;
  assetRelationshipType?: InputMaybe<StringFilterClass>;
  assetType?: InputMaybe<StringFilterClass>;
  externalId?: InputMaybe<StringFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  internalId?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<OrganizationAssetRelationshipFilter>>;
  organizationId?: InputMaybe<IdFilterClass>;
  organizationType?: InputMaybe<StringFilterClass>;
  source?: InputMaybe<StringFilterClass>;
};

export type OrganizationAssetRelationshipSort = {
  externalId?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type OrganizationConnection = {
  edges: Array<OrganizationEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type OrganizationEdge = {
  cursor: Scalars['String']['output'];
  node: Organization;
};

export type OrganizationFilter = {
  and?: InputMaybe<Array<OrganizationFilter>>;
  externalId?: InputMaybe<StringFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<OrganizationFilter>>;
  organizationType?: InputMaybe<StringFilterClass>;
  source?: InputMaybe<StringFilterClass>;
};

export type OrganizationFilterInput = {
  externalId?: InputMaybe<StringFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  name?: InputMaybe<StringFilterClass>;
};

export type OrganizationHierarchy = {
  children: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parents: Scalars['JSON']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type OrganizationMutations = {
  create: Organization;
  createOrganizationAssetRelationship: OrganizationAssetRelationship;
  createOrganizationOrganizationRelationship: OrganizationOrganizationRelationship;
  createOrganizationSiteRelationship: OrganizationSiteRelationship;
  createOrganizationUserRelationship: OrganizationUserRelationship;
  delete: Scalars['Boolean']['output'];
  deleteOrganizationAssetRelationship: Scalars['Boolean']['output'];
  deleteOrganizationOrganizationRelationship: Scalars['Boolean']['output'];
  deleteOrganizationSiteRelationship: Scalars['Boolean']['output'];
  deleteOrganizationUserRelationship: Scalars['Boolean']['output'];
  update: Organization;
  updateOrganizationAssetRelationship: OrganizationAssetRelationship;
  updateOrganizationOrganizationRelationship: OrganizationOrganizationRelationship;
  updateOrganizationSiteRelationship: OrganizationSiteRelationship;
  updateOrganizationUserRelationship: OrganizationUserRelationship;
};


export type OrganizationMutations_CreateArgs = {
  input: CreateOrganizationInput;
};


export type OrganizationMutations_CreateOrganizationAssetRelationshipArgs = {
  input: CreateOrganizationAssetRelationshipInput;
};


export type OrganizationMutations_CreateOrganizationOrganizationRelationshipArgs = {
  input: CreateOrganizationOrganizationRelationshipInput;
};


export type OrganizationMutations_CreateOrganizationSiteRelationshipArgs = {
  input: CreateOrganizationSiteRelationshipInput;
};


export type OrganizationMutations_CreateOrganizationUserRelationshipArgs = {
  input: CreateOrganizationUserRelationshipInput;
};


export type OrganizationMutations_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type OrganizationMutations_DeleteOrganizationAssetRelationshipArgs = {
  id: Scalars['String']['input'];
};


export type OrganizationMutations_DeleteOrganizationOrganizationRelationshipArgs = {
  id: Scalars['String']['input'];
};


export type OrganizationMutations_DeleteOrganizationSiteRelationshipArgs = {
  id: Scalars['String']['input'];
};


export type OrganizationMutations_DeleteOrganizationUserRelationshipArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationMutations_UpdateArgs = {
  input: UpdateOrganizationInput;
};


export type OrganizationMutations_UpdateOrganizationAssetRelationshipArgs = {
  input: UpdateOrganizationAssetRelationshipInput;
};


export type OrganizationMutations_UpdateOrganizationOrganizationRelationshipArgs = {
  input: UpdateOrganizationOrganizationRelationshipInput;
};


export type OrganizationMutations_UpdateOrganizationSiteRelationshipArgs = {
  input: UpdateOrganizationSiteRelationshipInput;
};


export type OrganizationMutations_UpdateOrganizationUserRelationshipArgs = {
  input: UpdateOrganizationUserRelationshipInput;
};

export type OrganizationOrganizationRelationship = {
  childOrganization: Organization;
  childOrganizationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  organizationRelationshipType: OrganizationRelationshipType;
  parentOrganization: Organization;
  parentOrganizationId: Scalars['ID']['output'];
  source: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationQueries = {
  childOrganizationsForCurrentUser: Array<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  organizationHierarchy: OrganizationHierarchy;
  organizations: OrganizationConnection;
};


export type OrganizationQueries_ChildOrganizationsForCurrentUserArgs = {
  includeParents?: Scalars['Boolean']['input'];
  organizationIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type OrganizationQueries_OrganizationArgs = {
  filter: OrganizationFilterInput;
};


export type OrganizationQueries_OrganizationHierarchyArgs = {
  organizationId: Scalars['String']['input'];
};


export type OrganizationQueries_OrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrganizationSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Type of relationship between organizations */
export enum OrganizationRelationshipType {
  AssignedDealer = 'AssignedDealer',
  Dealer = 'Dealer',
  EndCustomer = 'EndCustomer',
  FleetManagementGroup = 'FleetManagementGroup',
  SubDealer = 'SubDealer',
  SubOrganization = 'SubOrganization',
  WarrantyDealer = 'WarrantyDealer'
}

export type OrganizationSiteRelationship = {
  createdAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['ID']['output'];
  siteId: Scalars['ID']['output'];
  siteRelationshipType: SiteRelationshipType;
  source: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationSort = {
  externalId?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

/** Type of organization */
export enum OrganizationType {
  DealerL1 = 'DealerL1',
  DealerL2 = 'DealerL2',
  DealerL3 = 'DealerL3',
  EndCustomer = 'EndCustomer',
  FleetManagementGroup = 'FleetManagementGroup',
  NotSpecified = 'NotSpecified'
}

export type OrganizationUserRelationship = {
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['ID']['output'];
  source: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  user: User;
  userId: Scalars['ID']['output'];
  userRelationshipType: UserRelationshipType;
};

export type PageCursor = {
  cursor: Scalars['String']['output'];
  isCurrent: Scalars['Boolean']['output'];
  page: Scalars['Float']['output'];
};

export type PageCursors = {
  around: Array<PageCursor>;
  first?: Maybe<PageCursor>;
  last?: Maybe<PageCursor>;
};

export type PageInfo = {
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ParentNodeData = {
  /** [VDA] Part number for the ECUHardware. */
  nodeFamily?: Maybe<Scalars['String']['output']>;
  /** [VDA] Part number for the ECUHardware. */
  nodeType?: Maybe<Scalars['String']['output']>;
  /** [VDA] Indicator if Root Node. */
  rootNode?: Maybe<Scalars['String']['output']>;
};

export type Plugin = {
  name: Scalars['String']['output'];
  nodeType: NodeType;
};

export type PointOfInterestModel = {
  coordinates: Array<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pointOfInterestType: PointOfInterestTypeModel;
  siteMetricDefinitions: Array<SiteMetricDefinition>;
  status: PointOfInterestStatus;
};

export type PointOfInterestMutations = {
  create: PointOfInterestModel;
  delete: PointOfInterestModel;
  update: PointOfInterestModel;
};


export type PointOfInterestMutations_CreateArgs = {
  input: CreatePointOfInterestInput;
};


export type PointOfInterestMutations_DeleteArgs = {
  id: Scalars['ID']['input'];
};


export type PointOfInterestMutations_UpdateArgs = {
  input: UpdatePointOfInterestInput;
};

export type PointOfInterestQueries = {
  bySiteMapId: Array<PointOfInterestModel>;
};


export type PointOfInterestQueries_BySiteMapIdArgs = {
  options?: InputMaybe<GetPointOfInterestBySiteMapIdOptions>;
  siteMapId: Scalars['ID']['input'];
};

export enum PointOfInterestStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED'
}

export type PointOfInterestTypeModel = {
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type PointOfInterestTypeQueries = {
  all: Array<PointOfInterestTypeModel>;
  byId: PointOfInterestTypeModel;
};


export type PointOfInterestTypeQueries_ByIdArgs = {
  id: Scalars['ID']['input'];
};

export type ProductionAnalyticsQuery = {
  productionHistory: Array<GroupedTransactionData>;
  productionLogHistory: Array<DailyAmount>;
};


export type ProductionAnalyticsQuery_ProductionHistoryArgs = {
  accumulate?: Scalars['Boolean']['input'];
  generateEmptyDays?: Scalars['Boolean']['input'];
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  timePeriodInput: TimePeriodInput;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};


export type ProductionAnalyticsQuery_ProductionLogHistoryArgs = {
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  timePeriodInput: TimePeriodInput;
  transactionType: TransactionTypeEnum;
};

export type ProductionHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  /** [ARI] Accumulated total number of cycles. */
  cycles?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Accumulated total number of cycles with overload. */
  cyclesWithOverload?: Maybe<Scalars['Int']['output']>;
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** [ARI] Accumulated total number of load utilization per cycle. */
  loadUtilizationPerCycle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Maximum load (fixed number) for the machine. */
  nominalLoad?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated total production weight in kilograms. */
  totalProductionKilograms: Scalars['Float']['output'];
  /** [ARI] Accumulated logging time in seconds. */
  totalProductionSeconds: Scalars['Int']['output'];
};

export type ProductionQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** The data points for production events for machines with on-board weighing systems from ARI. */
  production: Array<ProductionHistoryEvent>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

/** Supported asset types. */
export enum ProductivityMainMetricDefinitionTypeInput {
  FlowRateMass = 'FLOW_RATE_MASS',
  LevelMass = 'LEVEL_MASS',
  LevelPercent = 'LEVEL_PERCENT',
  LevelVolume = 'LEVEL_VOLUME',
  MassFlowRate = 'MASS_FLOW_RATE',
  Material = 'MATERIAL',
  ProducedMass = 'PRODUCED_MASS',
  StatusIndicator = 'STATUS_INDICATOR'
}

export enum PropertyEditMode {
  Add = 'ADD',
  Remove = 'REMOVE',
  Set = 'SET'
}

export type PropertyStringArrayInput = {
  mode: PropertyEditMode;
  source: AssetManagerExternalServiceInput;
  value: Array<Scalars['String']['input']>;
};

/** status handling for subscription events */
export enum ProvisioningStatus {
  Cancelled = 'CANCELLED',
  Error = 'ERROR',
  Expired = 'EXPIRED',
  Finished = 'FINISHED',
  FinishedEarly = 'FINISHED_EARLY',
  New = 'NEW',
  Pending = 'PENDING',
  Triggered = 'TRIGGERED',
  UnsupportedProduct = 'UNSUPPORTED_PRODUCT'
}

export type Query = {
  /** Fetch material based on filter. Requires authentication. */
  Materials: MaterialQueries;
  actorMachines: ActorMachineQueries;
  actorUsers: ActorUserQueries;
  actors: ActorQueries;
  applications: ApplicationQueries;
  /** *Requires authentication* */
  assetManagerInvitations: AssetManagerInvitationQueries;
  assist: AssistQueries;
  /** Fetch bucket data based on filter. Requires authentication. */
  buckets: BucketQueries;
  careTrackMachines: CareTrackMachineQueries;
  clients: ClientQueries;
  credentials: CredentialsQueries;
  customer: CustomerQueries;
  /** @deprecated This query will be replaced by new operator services */
  emobAssetTypes: EmobAssetTypeQueries;
  /** @deprecated This query will be replaced by new operator services */
  emobAssets: EmobAssetQueries;
  emobChargerExampleApp: EmobChargerExampleApp;
  emobChargers: EmobChargerQueries;
  emobCpmsSubscriptions: EmobCpmsSubscriptionQueries;
  files: FileQueries;
  /** *Requires authentication* */
  fleetAssets: FleetAssetQueries;
  fleetChargers: FleetChargerQueries;
  /** *Requires authentication* */
  fleetMachineSnapshotEquipments: FleetMachineSnapshotEquipmentQueries;
  /** *Requires authentication* */
  fleetMachines: FleetMachineQueries;
  /** *Requires authentication* */
  fleetMetricDefinitions: FleetMetricDefinitionQueries;
  fleetReportTemplates: FleetReportTemplateQueries;
  geographyPhotos: GeographyPhotoQueries;
  /** Fetch haulcycles based on filter. Requires authentication. */
  haulcycles: HaulCycleQueries;
  ip21Credentials: Ip21CredentialsQueries;
  /** *Requires authentication* */
  loadTickets: LoadTicketQueries;
  loadriteCredentials: LoadriteCredentialsQueries;
  /** *Requires authentication* */
  loadriteScales: LoadriteScaleQueries;
  /** *Requires authentication* */
  loadriteValidations: LoadriteValidationQueries;
  /** Fetch machine configurations based on chassis ids. Requires authentication */
  machineConfigs: MachineConfigQueries;
  /** *Requires authentication* */
  machineOwningCompanies: MachineOwningCompanyQueries;
  machineStatistics: MachineStatisticsQueries;
  machineTypePermissions: MachineTypePermissionQueries;
  /** Queries to get `Machine` entities */
  machines: MachineQueries;
  /** Queries to get `MainSoftware` entities */
  mainSoftware: MainSoftwareQueries;
  materialFamilies: MaterialFamilyQueries;
  materialFlowObjective: MaterialFlowObjectiveQueries;
  /** @deprecated Use siteMaterialFlows instead. */
  materialFlows: MaterialFlowQueries;
  materialThreshold: MaterialThresholdQuery;
  materialTransaction: MaterialTransactionQuery;
  materialTypes: MaterialTypeQueries;
  materialVariants: MaterialVariantQueries;
  operatorChargerAssociations: OperatorChargerAssociationQueries;
  operatorChargers: OperatorChargerQueries;
  operatorMachineAssociations: OperatorMachineAssociationQueries;
  operatorMachines: OperatorMachineQueries;
  order: OrderQueries;
  organizations: OrganizationQueries;
  pointOfInterestTypes: PointOfInterestTypeQueries;
  pointsOfInterest: PointOfInterestQueries;
  scopes: ScopeQueries;
  settings: SettingQueries;
  shortcuts: ShortcutQueries;
  siteAssistant: SiteAssistantResolver;
  /** *Requires authentication* */
  siteCompanies: SiteCompanyQueries;
  /** *Requires authentication* */
  siteConfigurations: SiteConfigurationQueries;
  siteDevice: SiteDeviceResolver;
  siteDeviceEventQueries: SiteDeviceEventQueries;
  siteLoadTickets: SiteLoadTicketQueries;
  siteMachineTypes: SiteMachineTypeQueries;
  /** *Requires authentication* */
  siteMachines: SiteMachineQueries;
  siteMaps: SiteMapQueries;
  siteMaterialFlows: SiteMaterialFlowQueries;
  siteMetricData: SiteMetricDataQueries;
  siteMetricDefinitions: SiteMetricDefinitionQueries;
  siteOperatorMachines: SiteOperatorMachineQueries;
  /** *Requires authentication* */
  siteOperators: SiteOperatorQueries;
  /** *Requires authentication* */
  siteTablets: SiteTabletQueries;
  /** *Requires authentication* */
  siteUnconnectedEntities: SiteUnconnectedEntityQueries;
  /** *Requires authentication* */
  siteUsers: SiteUserQueries;
  /** *Requires authentication* */
  sites: SiteQueries;
  smarttonnesCredentials: SmarttonnesCredentialsQueries;
  subscriptionEvents: SubscriptionEventQueries;
  subscriptions: SubscriptionQueries;
  tamtronCredentials: TamtronCredentialsQueries;
  /** *Requires authentication* */
  tamtronScales: TamtronScaleQueries;
  /** *Requires authentication* */
  tamtronValidations: TamtronValidationQueries;
  /** Fetch targets based on filter. Requires authentication. */
  targets: TargetQueries;
  /** Dummy queries used for E2E testing purposes. */
  test: TestQueries;
  /** *Requires authentication* */
  trips: TripQueries;
  units: UnitQueries;
  userprofiles: UserProfileQueries;
  users: UserQueries;
  /** *Requires authentication* */
  veiScales: VeiScaleQueries;
  workflowExecutions: WorkflowExecutionQueries;
  workflowTemplates: WorkflowTemplateQueries;
  zoneMaterialBalance: ZoneMaterialBalanceQueries;
  zonePermissionTypes: ZonePermissionTypeQueries;
  zoneTypes: ZoneTypeQueries;
  zones: ZoneQueries;
};

/** sets the query mode sensitive or insensitive */
export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RawMessage = {
  id: Scalars['ID']['output'];
  ingestedAt: Scalars['DateTimeISO']['output'];
  ingestedContent: Scalars['String']['output'];
};

export type ReceivedLoadTicketEventPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  loadTicketCorrelationId: Scalars['ID']['input'];
  loadTicketSource: Scalars['String']['input'];
  loadingAssetId: Scalars['ID']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId: Scalars['ID']['input'];
  materialId: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
  siteLoadTicketId: Scalars['ID']['input'];
  sourceLoadTicketId: Scalars['ID']['input'];
  weight: Scalars['Float']['input'];
  weightUnit: Scalars['String']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

/** What relationship the file has to `relationValue` */
export enum RelationType {
  Actor = 'ACTOR',
  Machine = 'MACHINE',
  Tag = 'TAG',
  User = 'USER'
}

export type RequestReconnectResponse = {
  token: Scalars['String']['output'];
};

export type RestrictedZonePayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type Scale = {
  id: Scalars['ID']['output'];
  scaleType: ScaleType;
  sourceService: SourceService;
};

/** Possible types of scales */
export enum ScaleType {
  /** OBW */
  Obw = 'OBW',
  /** WEIGH_BRIDGE */
  WeighBridge = 'WEIGH_BRIDGE'
}

export type Scope = {
  clients: Array<ScopeClientRelation>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  scope: Scalars['ID']['output'];
  users: Array<ScopeUserRelation>;
};

export type ScopeClientRelation = {
  clientId: Scalars['ID']['output'];
};

export type ScopeMutations = {
  create: Scope;
  delete: Scalars['Boolean']['output'];
  update: Scope;
};


export type ScopeMutations_CreateArgs = {
  input: CreateScopeInput;
};


export type ScopeMutations_DeleteArgs = {
  scope: Scalars['String']['input'];
};


export type ScopeMutations_UpdateArgs = {
  input: UpdateScopeInput;
};

export type ScopeQueries = {
  scopes: Array<Scope>;
};


export type ScopeQueries_ScopesArgs = {
  input?: InputMaybe<ScopesInput>;
};

export type ScopeUserRelation = {
  userId: Scalars['ID']['output'];
};

export type ScopesFilter = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ScopesInput = {
  filter: ScopesFilter;
};

export type SearchFilterInput = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ServiceCredential = {
  credentials: CredentialsUnion;
  externalService: ExternalService;
  parentId: Scalars['ID']['output'];
};

export type ServiceProduct = {
  connectedServices: Array<ConnectedServiceClass>;
  serviceProductId: Scalars['String']['output'];
};

export type SetActiveGeographyPhotosInput = {
  geographyPhotoIds: Array<Scalars['ID']['input']>;
  siteMapId: Scalars['String']['input'];
};

export type SetActiveResponse = {
  mapboxStyleId: Scalars['String']['output'];
};

export type SetIp21CredentialsInput = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  ownerId: Scalars['ID']['input'];
};

export type Setting = {
  createdAt: Scalars['DateTime']['output'];
  defaultValue: Scalars['JSON']['output'];
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  options: Scalars['JSON']['output'];
  order: Scalars['Float']['output'];
  scopes: Array<Scalars['String']['output']>;
  tab: Scalars['String']['output'];
};

export type SettingQueries = {
  forCurrentUser: Array<Setting>;
};

export type Shortcut = {
  category: Category;
  createdAt: Scalars['DateTime']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  scopesCriteria: Array<Scalars['String']['output']>;
  sortOrder: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  urlCriteria: Array<Scalars['String']['output']>;
};

export type ShortcutMutations = {
  create: Shortcut;
  delete: Scalars['Boolean']['output'];
  update: Shortcut;
};


export type ShortcutMutations_CreateArgs = {
  input: CreateShortcutInput;
};


export type ShortcutMutations_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type ShortcutMutations_UpdateArgs = {
  input: UpdateShortcutInput;
};

export type ShortcutQueries = {
  shortcut: Shortcut;
  shortcuts: Array<Shortcut>;
  shortcutsByCurrentUser: Array<Shortcut>;
};


export type ShortcutQueries_ShortcutArgs = {
  id: Scalars['String']['input'];
};


export type ShortcutQueries_ShortcutsByCurrentUserArgs = {
  url: Scalars['String']['input'];
};

export type Site = {
  activeMachines: Array<SiteMachine>;
  allowedMachineTypes: Array<SiteMachineType>;
  /** *Requires authentication* */
  assetManagerInvitations: AssetManagerInvitationConnection;
  assetOwningCompanies: Array<Maybe<MachineOwningCompany>>;
  /** *Requires authentication* */
  assets: SiteAssetConnection;
  company: SiteCompany;
  companyStatus?: Maybe<Scalars['String']['output']>;
  configuration: SiteConfiguration;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  machineOwningCompanyIds: Array<Scalars['String']['output']>;
  machineStatistics: StatisticsConnection;
  /** @deprecated Use machinesV2 instead */
  machines: Array<SiteMachine>;
  machinesV2: SiteMachineConnection;
  map?: Maybe<SiteMapModel>;
  materialFamilies: Array<MaterialFamily>;
  name: Scalars['String']['output'];
  siteLoadTickets: SiteLoadTicketsConnection;
  /** *Requires authentication* */
  siteMetricDefinitions: SiteMetricDefinitionConnection;
  siteOperators: Array<SiteOperator>;
  tabletDevices: Array<SiteTabletDevice>;
  timeZone: Scalars['String']['output'];
  topMachineStatistics: TopStatisticsResult;
  topSiteLoadTicketStatistics: TopStatisticsResult;
  tripStatistics: StatisticsConnection;
  trips: TripConnection;
  updatedAt: Scalars['DateTime']['output'];
  users: SiteUserConnection;
};


export type Site_AssetManagerInvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<AssetManagerInvitationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AssetManagerInvitationOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type Site_AssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteAssetFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteAssetOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type Site_MachineStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: MachineStatisticsFields;
  filterBy?: InputMaybe<MachineStatisticsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: MachineStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  propertyId: MachineStatisticsId;
  to: Scalars['DateTime']['input'];
};


export type Site_MachinesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMachinesFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMachinesOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type Site_SiteLoadTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteLoadTicketsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteLoadTicketsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type Site_SiteMetricDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMetricDefinitionFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMetricDefinitionOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type Site_TopMachineStatisticsArgs = {
  entityId: TopMachineStatisticsEntity;
  field: MachineStatisticsFields;
  filterBy?: InputMaybe<TopMachineStatisticsFilterBy>;
  from: Scalars['DateTime']['input'];
  propertyId: MachineStatisticsId;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take: TopStatisticsTake;
  to: Scalars['DateTime']['input'];
};


export type Site_TopSiteLoadTicketStatisticsArgs = {
  entityId: TopSiteLoadTicketStatisticsEntity;
  field: TopSiteLoadTicketsStatisticsNumericFields;
  filterBy?: InputMaybe<TopSiteLoadTicketStatisticsFilterBy>;
  from: Scalars['DateTime']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take: TopStatisticsTake;
  to: Scalars['DateTime']['input'];
};


export type Site_TripStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: TripStatisticsFields;
  filterBy?: InputMaybe<TripFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: TripStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};


export type Site_TripsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<TripFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TripFilterOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type Site_UsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<UserOnSiteFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteUserFilterOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteAsset = {
  createdAt: Scalars['DateTime']['output'];
  deprecated: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fleetAsset?: Maybe<FleetAssetNode>;
  id: Scalars['ID']['output'];
  machineOwningCompany: MachineOwningCompany;
  name?: Maybe<Scalars['String']['output']>;
  site: Site;
  type: SiteAssetType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SiteAssetConnection = {
  edges: Array<SiteAssetEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteAssetEdge = {
  cursor: Scalars['String']['output'];
  node: SiteAsset;
};

export type SiteAssetFilterBy = {
  assetId?: InputMaybe<IdFilterClass>;
  assetOwnerId?: InputMaybe<IdFilterClass>;
  deprecated?: InputMaybe<BoolFilterClass>;
  siteId?: InputMaybe<IdFilterClass>;
  type?: InputMaybe<SiteAssetTypeInputFilterClass>;
};

export type SiteAssetMutations = {
  /** *Requires authentication* */
  createOrUpdate: SiteAsset;
  /** *Requires authentication* */
  disconnectSite: Scalars['Boolean']['output'];
};


export type SiteAssetMutations_CreateOrUpdateArgs = {
  input: CreateOrUpdateSiteAssetInput;
};


export type SiteAssetMutations_DisconnectSiteArgs = {
  assetId: Scalars['ID']['input'];
  assetOwnerId: Scalars['ID']['input'];
};

export type SiteAssetOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

/** Supported asset types in site */
export enum SiteAssetType {
  Charger = 'CHARGER',
  WeighBridge = 'WEIGH_BRIDGE'
}

/** Supported asset types in site */
export enum SiteAssetTypeInput {
  Charger = 'CHARGER',
  WeighBridge = 'WEIGH_BRIDGE'
}

export type SiteAssetTypeInputFilterClass = {
  equals?: InputMaybe<SiteAssetTypeInput>;
  in?: InputMaybe<Array<SiteAssetTypeInput>>;
  not?: InputMaybe<SiteAssetTypeInput>;
  notIn?: InputMaybe<Array<SiteAssetTypeInput>>;
};

export type SiteAssistantMutation = {
  minimumVersion: Scalars['String']['output'];
};


export type SiteAssistantMutation_MinimumVersionArgs = {
  minimumVersion: Scalars['String']['input'];
};

export type SiteAssistantResolver = {
  minimumVersion: Scalars['String']['output'];
};

export enum SiteCompaniesConnectionLevelFilter {
  All = 'ALL',
  CompanyAdmin = 'COMPANY_ADMIN',
  SiteAdmin = 'SITE_ADMIN'
}

export type SiteCompany = {
  allowedLicenseCount: Scalars['Int']['output'];
  companyLicenses: Array<Scalars['String']['output']>;
  currentlyUsedLicenses: Scalars['Int']['output'];
  externalMaterialMappings: ExternalMaterialMappingConnection;
  id: Scalars['ID']['output'];
  licenseUsages?: Maybe<SiteCompanyLicenseUsage>;
  machines: Array<SiteMachine>;
  materialFamilies: Array<MaterialFamily>;
  name: Scalars['String']['output'];
  /** @deprecated Use `allowedLicenseCount` instead - this on is no longer valid */
  numberOfAllowedLicenses: Scalars['Int']['output'];
  /** @deprecated Use `allowedLicenseCount` instead - this on is no longer valid */
  numberOfAllowedMachines: Scalars['Int']['output'];
  /** @deprecated Use `licenseUsages` instead - this on is no longer valid */
  numberOfUsedMachineLicenses: Scalars['Int']['output'];
  /** *Requires authentication* */
  organization?: Maybe<Organization>;
  sites: Array<Site>;
  status?: Maybe<Scalars['String']['output']>;
  users: SiteUserConnection;
};


export type SiteCompany_ExternalMaterialMappingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<ExternalMaterialMappingFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExternalMaterialMappingOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteCompany_UsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<CompanyUserOnSiteFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteUserFilterOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteCompanyConnection = {
  edges: Array<SiteCompanyEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteCompanyEdge = {
  cursor: Scalars['String']['output'];
  node: SiteCompany;
};

export type SiteCompanyFilterBy = {
  companyLicenses?: InputMaybe<Array<SiteCompanyLicenseInput>>;
  companyStatus?: InputMaybe<CompanyStatus>;
  /** All retrieves both companies connected by site and admin, while SITE_ADMIN retrieves only companies connected by site and COMPANY_ADMIN retrieves only companies connected by admin. */
  connectionLevel?: InputMaybe<SiteCompaniesConnectionLevelFilter>;
  /** Include all sites that contain the given string. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Possible types of license inputs */
export enum SiteCompanyLicenseInput {
  Advanced = 'ADVANCED',
  Demo = 'DEMO',
  Flow = 'FLOW',
  Start = 'START',
  StockpileStandalone = 'STOCKPILE_STANDALONE'
}

export type SiteCompanyLicenseUsage = {
  assetUsageStatistics: StatisticsConnection;
  companyId: Scalars['ID']['output'];
  companyLicenseUsageStatistics: StatisticsConnection;
};


export type SiteCompanyLicenseUsage_AssetUsageStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  assetUsageType: InvoicingAssetUsageVariant;
  before?: InputMaybe<Scalars['String']['input']>;
  field: AssetUsageStatisticsField;
  filterBy?: InputMaybe<AssetUsageStatisticsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: AssetUsageStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};


export type SiteCompanyLicenseUsage_CompanyLicenseUsageStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: CompanyLicenseUsageStatisticsField;
  filterBy?: InputMaybe<CompanyLicenseUsageStatisticsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: CompanyLicenseUsageStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};

export type SiteCompanyMutations = {
  /** *Requires authentication* */
  updateAssetLicenseCount: SiteCompany;
};


export type SiteCompanyMutations_UpdateAssetLicenseCountArgs = {
  companyId: Scalars['ID']['input'];
  input: UpdateSiteCompanyLicenseInput;
};

export type SiteCompanyOrderBy = {
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type SiteCompanyQueries = {
  /** *Requires authentication* */
  allCompanies: SiteCompanyConnection;
  /** *Requires authentication* */
  companies: SiteCompanyConnection;
  /**
   * Get the company connected to the authenticated user or companies connected to user and site related companies. *Requires authentication*
   * @deprecated This endpoint is deprecated, use siteCompanies.companies instead.
   */
  companiesByUserAuth: Array<SiteCompany>;
  /** *Requires authentication* */
  company?: Maybe<SiteCompany>;
};


export type SiteCompanyQueries_AllCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteCompanyFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteCompanyOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteCompanyQueries_CompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteCompanyFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteCompanyOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteCompanyQueries_CompaniesByUserAuthArgs = {
  filter?: InputMaybe<CompanyByUserAuthFilterInput>;
};


export type SiteCompanyQueries_CompanyArgs = {
  companyId: Scalars['ID']['input'];
};

export type SiteConfiguration = {
  appSounds: ApplicationSounds;
  autoLoad: Scalars['Boolean']['output'];
  autoLoadTime: ConfigurationValueWithUnit;
  autoOffload: Scalars['Boolean']['output'];
  autoOffloadTime: ConfigurationValueWithUnit;
  /** @deprecated SiteConfig does not have createdAt anymore */
  createdAt: Scalars['DateTime']['output'];
  currencyUnit: SiteUnit;
  distanceUnit: SiteUnit;
  extendedLocation: Scalars['Boolean']['output'];
  fluidVolumeUnit: SiteUnit;
  id: Scalars['ID']['output'];
  site: Site;
  siteId: Scalars['ID']['output'];
  speedUnit: SiteUnit;
  speedingLeeway: ConfigurationValueWithUnit;
  speedingSettings: SpeedingSettings;
  temperatureUnit: SiteUnit;
  /** @deprecated SiteConfig does not have updatedAt anymore */
  updatedAt: Scalars['DateTime']['output'];
  volumeUnit: SiteUnit;
  weightUnit: SiteUnit;
};

export type SiteConfigurationMutations = {
  /** *Requires authentication* */
  update: SiteConfiguration;
};


export type SiteConfigurationMutations_UpdateArgs = {
  input: UpdateSiteConfigurationInput;
  siteId: Scalars['ID']['input'];
};

export type SiteConfigurationQueries = {
  /**
   * *Requires authentication*
   * @deprecated This one is deprecated. Please use site.config
   */
  configurationBySiteId?: Maybe<SiteConfiguration>;
  /**
   * *Requires authentication - works with operator token also*
   * @deprecated This one is deprecated. Please use site.config
   */
  siteConfigurationsBySiteId?: Maybe<SiteConfiguration>;
};


export type SiteConfigurationQueries_ConfigurationBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};


export type SiteConfigurationQueries_SiteConfigurationsBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type SiteConnection = {
  edges: Array<SiteEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteCreateGuestUserInput = {
  email: Scalars['String']['input'];
  expiryDate: Scalars['DateTime']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Min length of name 2 */
  username: Scalars['String']['input'];
};

export type SiteDeviceEvent = {
  /** Id generated by the device */
  correlationId: Scalars['ID']['output'];
  /** Event created in ISO Datetime */
  createdAt: Scalars['DateTime']['output'];
  /** Event created in Unix time */
  createdAtUnix: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  payload: SiteDeviceEventPayload;
  source: Scalars['String']['output'];
  status: SiteDeviceEventStatus;
  statusMessage: Scalars['String']['output'];
  /** Event stored in ISO Datetime */
  storedAt: Scalars['DateTime']['output'];
  type: SiteDeviceEventType;
  version: Scalars['String']['output'];
};

export type SiteDeviceEventEdge = {
  cursor: Scalars['String']['output'];
  node: SiteDeviceEvent;
};

export type SiteDeviceEventFilterInput = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  machineId?: InputMaybe<Scalars['ID']['input']>;
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<SiteDeviceEventStatus>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  types?: InputMaybe<Array<SiteDeviceEventType>>;
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteDeviceEventMutations = {
  createAppStartupEvent: SiteDeviceEvent;
  createDriverAssignedEvent: SiteDeviceEvent;
  createEnterSiteEvent: SiteDeviceEvent;
  createEnterZoneEvent: SiteDeviceEvent;
  createExitSiteEvent: SiteDeviceEvent;
  createExitZoneEvent: SiteDeviceEvent;
  createLoadEvent: SiteDeviceEvent;
  createManualLoadTicketEvent: SiteDeviceEvent;
  createMaterialFlowAssignedEvent: SiteDeviceEvent;
  createMaterialFlowUnassignedEvent: SiteDeviceEvent;
  createOffloadEvent: SiteDeviceEvent;
  createOffloadWarningEvent: SiteDeviceEvent;
  createReceivedLoadTicketEvent: SiteDeviceEvent;
  createRestrictedZoneEvent: SiteDeviceEvent;
  createSpeedingEvent: SiteDeviceEvent;
  createUndoLoadEvent: SiteDeviceEvent;
  delete: Scalars['String']['output'];
};


export type SiteDeviceEventMutations_CreateAppStartupEventArgs = {
  input: CreateAppStartupEventInput;
};


export type SiteDeviceEventMutations_CreateDriverAssignedEventArgs = {
  input: CreateDriverAssignedEventInput;
};


export type SiteDeviceEventMutations_CreateEnterSiteEventArgs = {
  input: CreateEnterSiteEventInput;
};


export type SiteDeviceEventMutations_CreateEnterZoneEventArgs = {
  input: CreateEnterZoneEventInput;
};


export type SiteDeviceEventMutations_CreateExitSiteEventArgs = {
  input: CreateExitSiteEventInput;
};


export type SiteDeviceEventMutations_CreateExitZoneEventArgs = {
  input: CreateExitZoneEventInput;
};


export type SiteDeviceEventMutations_CreateLoadEventArgs = {
  input: CreateLoadEventInput;
};


export type SiteDeviceEventMutations_CreateManualLoadTicketEventArgs = {
  input: CreateManualLoadTicketEventInput;
};


export type SiteDeviceEventMutations_CreateMaterialFlowAssignedEventArgs = {
  input: CreateMaterialFlowAssignedEventInput;
};


export type SiteDeviceEventMutations_CreateMaterialFlowUnassignedEventArgs = {
  input: CreateMaterialFlowUnassignedEventInput;
};


export type SiteDeviceEventMutations_CreateOffloadEventArgs = {
  input: CreateOffloadEventInput;
};


export type SiteDeviceEventMutations_CreateOffloadWarningEventArgs = {
  input: CreateOffloadWarningEventInput;
};


export type SiteDeviceEventMutations_CreateReceivedLoadTicketEventArgs = {
  input: CreateReceivedLoadTicketEventInput;
};


export type SiteDeviceEventMutations_CreateRestrictedZoneEventArgs = {
  input: CreateRestrictedZoneEventInput;
};


export type SiteDeviceEventMutations_CreateSpeedingEventArgs = {
  input: CreateSpeedingEventInput;
};


export type SiteDeviceEventMutations_CreateUndoLoadEventArgs = {
  input: CreateUndoLoadEventInput;
};


export type SiteDeviceEventMutations_DeleteArgs = {
  id: Scalars['ID']['input'];
};

export type SiteDeviceEventPayload = {
  chargingCapable?: Maybe<Scalars['Boolean']['output']>;
  currentSpeed?: Maybe<Scalars['Float']['output']>;
  deviceId: Scalars['ID']['output'];
  driver?: Maybe<SiteOperator>;
  driverId: Scalars['ID']['output'];
  /** The duration of the load, used when the load ticket is split into multiple buckets. */
  duration?: Maybe<Scalars['Float']['output']>;
  /** The unit of the duration */
  durationUnit?: Maybe<Scalars['String']['output']>;
  /** The end timestamp of the load ticket, used when the load ticket is split into multiple buckets. */
  endTimestamp?: Maybe<Scalars['String']['output']>;
  heading: Scalars['Float']['output'];
  isInsideSiteBoundary?: Maybe<Scalars['Boolean']['output']>;
  latitude: Scalars['Float']['output'];
  loadTicketCorrelationId?: Maybe<Scalars['ID']['output']>;
  /** The source that created the slt. */
  loadTicketSource?: Maybe<Scalars['String']['output']>;
  loadTicketStatusCodes?: Maybe<Array<DeviceEventsLoadTicketStatusCode>>;
  loadingAssetId?: Maybe<Scalars['ID']['output']>;
  longitude: Scalars['Float']['output'];
  machine?: Maybe<SiteMachine>;
  machineId: Scalars['ID']['output'];
  material?: Maybe<MaterialVariant>;
  materialFlow?: Maybe<MaterialFlowModel>;
  materialFlowEndZoneId?: Maybe<Scalars['ID']['output']>;
  materialFlowId?: Maybe<Scalars['ID']['output']>;
  materialFlowStartZoneId?: Maybe<Scalars['ID']['output']>;
  /** If entering or exiting a zone that belongs to the active flow the value START_ZONE or END_ZONE is set, otherwise null */
  materialFlowZoneType?: Maybe<Scalars['String']['output']>;
  materialId?: Maybe<Scalars['String']['output']>;
  /** The number of buckets */
  numberOfBuckets?: Maybe<Scalars['Float']['output']>;
  /** (Offload event) The latitude where the load event occured */
  originLatitude?: Maybe<Scalars['Float']['output']>;
  /** (Offload event) The longitude where the load event occured */
  originLongitude?: Maybe<Scalars['Float']['output']>;
  /** Zone registered during load */
  originZoneId?: Maybe<Scalars['ID']['output']>;
  /** (Offload event) The weight recorded during load */
  originalLoadedWeight?: Maybe<Scalars['Float']['output']>;
  site?: Maybe<Site>;
  siteId: Scalars['ID']['output'];
  siteLoadTicketId?: Maybe<Scalars['ID']['output']>;
  sourceLoadTicketId?: Maybe<Scalars['ID']['output']>;
  speedLimit?: Maybe<Scalars['Float']['output']>;
  speedUnit?: Maybe<Scalars['String']['output']>;
  /** The start timestamp of the load ticket, used when the load ticket is split into multiple buckets. */
  startTimestamp?: Maybe<Scalars['String']['output']>;
  /** The site machine that is meant to receive the manual load ticket */
  transporterSiteMachineId?: Maybe<Scalars['ID']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weightUnit?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<ZoneModel>;
  zoneId?: Maybe<Scalars['ID']['output']>;
  zoneType?: Maybe<Scalars['String']['output']>;
};

export type SiteDeviceEventQueries = {
  siteDeviceEvent?: Maybe<SiteDeviceEvent>;
  siteDeviceEvents: SiteDeviceEventsConnection;
  siteDeviceEventsStatistics: StatisticsConnection;
  siteDeviceMachineEvents: SiteDeviceEventsConnection;
  siteDeviceMaterialFlowEvents: SiteDeviceEventsConnection;
  siteDeviceZoneEvents: SiteDeviceEventsConnection;
  siteMachineState?: Maybe<SiteMachineState>;
};


export type SiteDeviceEventQueries_SiteDeviceEventArgs = {
  id: Scalars['ID']['input'];
};


export type SiteDeviceEventQueries_SiteDeviceEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteDeviceEventFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteDeviceEventSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['ID']['input'];
};


export type SiteDeviceEventQueries_SiteDeviceEventsStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: DeviceEventsStatisticsFields;
  filterBy?: InputMaybe<SiteDeviceEventFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: DeviceEventsStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['ID']['input'];
  to: Scalars['DateTime']['input'];
};


export type SiteDeviceEventQueries_SiteDeviceMachineEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteDeviceMachineEventFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  machineId: Scalars['String']['input'];
  orderBy?: InputMaybe<SiteDeviceEventSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
};


export type SiteDeviceEventQueries_SiteDeviceMaterialFlowEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteDeviceMaterialFlowEventFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  materialFlowId: Scalars['String']['input'];
  orderBy?: InputMaybe<SiteDeviceEventSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
};


export type SiteDeviceEventQueries_SiteDeviceZoneEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteDeviceZoneEventFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteDeviceEventSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteId: Scalars['String']['input'];
  zoneId: Scalars['String']['input'];
};


export type SiteDeviceEventQueries_SiteMachineStateArgs = {
  machineId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type SiteDeviceEventSort = {
  /** Defaults to DESC */
  createdAt?: InputMaybe<SortDirection>;
};

export enum SiteDeviceEventStatus {
  Error = 'ERROR',
  Ok = 'OK',
  Warning = 'WARNING'
}

export enum SiteDeviceEventType {
  /** Application state after startup */
  AppStartup = 'APP_STARTUP',
  /** Driver assigned */
  DriverAssigned = 'DRIVER_ASSIGNED',
  /** Entered site boundary */
  EnterSite = 'ENTER_SITE',
  /** Entered zone */
  EnterZone = 'ENTER_ZONE',
  /** Exited site boundary */
  ExitSite = 'EXIT_SITE',
  /** Exited zone */
  ExitZone = 'EXIT_ZONE',
  /** Weight added to the machine */
  MachineLoaded = 'MACHINE_LOADED',
  /** Weight removed from the machine */
  MachineOffloaded = 'MACHINE_OFFLOADED',
  /** Machine undo load */
  MachineUndoLoad = 'MACHINE_UNDO_LOAD',
  /** Machine created manual load ticket */
  ManualLoadTicket = 'MANUAL_LOAD_TICKET',
  /** Material flow assigned */
  MaterialFlowAssigned = 'MATERIAL_FLOW_ASSIGNED',
  /** Material flow unassigned */
  MaterialFlowUnassigned = 'MATERIAL_FLOW_UNASSIGNED',
  /** Machine received site load ticket */
  ReceivedLoadTicket = 'RECEIVED_LOAD_TICKET',
  /** Machine in restricted zone */
  RestrictedZone = 'RESTRICTED_ZONE',
  /** Machine speeding */
  Speeding = 'SPEEDING',
  /** Weight about to be removed from the machine */
  WrongOffloadZone = 'WRONG_OFFLOAD_ZONE'
}

export type SiteDeviceEventsConnection = {
  edges: Array<SiteDeviceEventEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteDeviceMachineEventFilterInput = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<SiteDeviceEventStatus>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  types?: InputMaybe<Array<SiteDeviceEventType>>;
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteDeviceMaterialFlowEventFilterInput = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  machineId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<SiteDeviceEventStatus>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  types?: InputMaybe<Array<SiteDeviceEventType>>;
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteDeviceMutation = {
  minimumVersion: Scalars['String']['output'];
};


export type SiteDeviceMutation_MinimumVersionArgs = {
  minimumVersion: Scalars['String']['input'];
};

export type SiteDeviceResolver = {
  minimumVersion: Scalars['String']['output'];
};

export type SiteDeviceZoneEventFilterInput = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  machineId?: InputMaybe<Scalars['ID']['input']>;
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<SiteDeviceEventStatus>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  types?: InputMaybe<Array<SiteDeviceEventType>>;
};

export type SiteEdge = {
  cursor: Scalars['String']['output'];
  node: Site;
};

export type SiteFilterBy = {
  companyStatus?: InputMaybe<CompanyStatus>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Include all sites that contain the given string. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SiteGuestUserUpdateInput = {
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  sendEmailReminder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Min length of name 2 */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type SiteLoadTicket = {
  additionalInformation: Array<Scalars['String']['output']>;
  /** Correlation id that can be tracked across systems */
  correlationId: Scalars['String']['output'];
  /** The DateTime the ticket was created in source system (event date) */
  createdAt: Scalars['DateTime']['output'];
  /** Returns true if the createAt datetime was created without timezone. E.g. 2021-01-01T00:00:00 */
  createdAtNoTimeZone: Scalars['Boolean']['output'];
  /** @deprecated Use 'loader.fleetMachineId' instead */
  fleetMachineId?: Maybe<Scalars['ID']['output']>;
  flowId?: Maybe<Scalars['String']['output']>;
  fromZoneId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  loader?: Maybe<SiteLoadTicketMachineReference>;
  /** @deprecated Use 'loader.machineOwningCompanyId' instead */
  machineOwningCompanyId?: Maybe<Scalars['ID']['output']>;
  materialVariantId?: Maybe<Scalars['String']['output']>;
  /** *Requires authentication* */
  organization?: Maybe<Organization>;
  siteId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use 'loader.siteMachineId' instead */
  siteMachineId?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<ExternalService>;
  /** The original source ticket from harmonize */
  sourceTicket: LoadTicket;
  statusCodes: Array<Scalars['String']['output']>;
  /** The DateTime this ticket was stored in site load ticket service */
  storedAt: Scalars['DateTime']['output'];
  toZoneId?: Maybe<Scalars['String']['output']>;
  transporter?: Maybe<SiteLoadTicketMachineReference>;
  /** The DateTime this ticket was created/updated */
  updatedAt: Scalars['DateTime']['output'];
  weight?: Maybe<UnitValue>;
};


export type SiteLoadTicket_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type SiteLoadTicketGroupBySourceFlowId = {
  groups: Array<SiteLoadTicketGroupBySourceFlowIdGroup>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SiteLoadTicketGroupBySourceFlowIdGroup = {
  count: Scalars['Int']['output'];
  firstTicket: Scalars['DateTime']['output'];
  lastTicket: Scalars['DateTime']['output'];
  scaleType?: Maybe<Scalars['ID']['output']>;
  source: Scalars['ID']['output'];
  sourceTicketFlowId?: Maybe<Scalars['ID']['output']>;
};

export type SiteLoadTicketMachineReference = {
  fleetMachine?: Maybe<FleetMachine>;
  machineOwningCompany?: Maybe<MachineOwningCompany>;
  siteMachine?: Maybe<SiteMachine>;
};

export type SiteLoadTicketQueries = {
  groupBySourceFlowId: SiteLoadTicketGroupBySourceFlowId;
  siteLoadTicket?: Maybe<SiteLoadTicket>;
  siteLoadTickets: SiteLoadTicketsConnection;
  statistics: StatisticsConnection;
};


export type SiteLoadTicketQueries_GroupBySourceFlowIdArgs = {
  filterBy?: InputMaybe<SiteLoadTicketsFilterBy>;
  from: Scalars['DateTime']['input'];
  orderBy?: InputMaybe<SiteLoadTicketsGroupByFlowIdOrderBy>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};


export type SiteLoadTicketQueries_SiteLoadTicketArgs = {
  id: Scalars['ID']['input'];
};


export type SiteLoadTicketQueries_SiteLoadTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteLoadTicketsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteLoadTicketsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteLoadTicketQueries_StatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: SiteLoadTicketsStatisticsFields;
  filterBy?: InputMaybe<SiteLoadTicketsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: StatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};

export type SiteLoadTicketsConnection = {
  edges: Array<SiteLoadTicketsEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteLoadTicketsEdge = {
  cursor: Scalars['String']['output'];
  node: SiteLoadTicket;
};

export type SiteLoadTicketsFilterBy = {
  correlationId?: InputMaybe<Scalars['String']['input']>;
  fleetMachineId?: InputMaybe<Scalars['String']['input']>;
  flowId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  fromZoneId?: InputMaybe<Scalars['ID']['input']>;
  machineOwningCompanyId?: InputMaybe<Scalars['ID']['input']>;
  materialVariantId?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  scaleType?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  siteMachineId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  sourceFlowId?: InputMaybe<Scalars['String']['input']>;
  sourceMachineId?: InputMaybe<Scalars['String']['input']>;
  sourceMaterialId?: InputMaybe<Scalars['String']['input']>;
  sourceScaleId?: InputMaybe<Scalars['String']['input']>;
  sourceSiteId?: InputMaybe<Scalars['String']['input']>;
  sourceWorkOrderId?: InputMaybe<Scalars['String']['input']>;
  statusCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  toZoneId?: InputMaybe<Scalars['String']['input']>;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type SiteLoadTicketsGroupByFlowIdOrderBy = {
  count?: InputMaybe<SortDirection>;
  sourceTicketFlowId?: InputMaybe<SortDirection>;
};

export type SiteLoadTicketsOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export enum SiteLoadTicketsStatisticsFields {
  LoaderFleetMachineId = 'LOADER_FLEET_MACHINE_ID',
  Weight = 'WEIGHT'
}

export type SiteMachine = {
  companyStatus?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Max length of description 20 chars */
  description?: Maybe<Scalars['String']['output']>;
  fleetMachine?: Maybe<FleetMachine>;
  /** The id of the site machine */
  id: Scalars['ID']['output'];
  isDeprecated: Scalars['Boolean']['output'];
  loadCapacity: SiteUnitValue;
  loadriteExternalId?: Maybe<Scalars['ID']['output']>;
  machineOwningCompany?: Maybe<MachineOwningCompany>;
  machineOwningCompanyId?: Maybe<Scalars['ID']['output']>;
  /** Requires machine owning company scope */
  machineSiteAndCompanyConnection: SiteMachineCompanyAndSiteInformationModel;
  machineStatistics: StatisticsConnection;
  machineType: SiteMachineType;
  machineTypeId: Scalars['ID']['output'];
  manualLoadTickets: Scalars['Boolean']['output'];
  /** Min length of name 2 */
  name: Scalars['String']['output'];
  /** The short id for the machine, mainly used for websocket traffic */
  shortId: Scalars['ID']['output'];
  site: Site;
  siteLoadTickets: SiteLoadTicketsConnection;
  tabletDevice?: Maybe<SiteTabletDevice>;
  tamtronExternalId?: Maybe<Scalars['ID']['output']>;
  trackerModeEnabled: Scalars['Boolean']['output'];
  tripStatistics: StatisticsConnection;
  trips: TripConnection;
  updatedAt: Scalars['DateTime']['output'];
};


export type SiteMachine_LoadCapacityArgs = {
  options?: InputMaybe<SiteUnitValueInput>;
};


export type SiteMachine_MachineStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: MachineStatisticsFields;
  filterBy?: InputMaybe<MachineStatisticsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: MachineStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  propertyId: MachineStatisticsId;
  to: Scalars['DateTime']['input'];
};


export type SiteMachine_SiteLoadTicketsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteLoadTicketsFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteLoadTicketsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteMachine_TripStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: TripStatisticsFields;
  filterBy?: InputMaybe<TripFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: TripStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};


export type SiteMachine_TripsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<TripFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TripFilterOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteMachineCompanyAndSiteInformationModel = {
  /** Name and id of connected company */
  companyInformation: MachineBelongsToInformation;
  /** Name and id of connected site */
  siteInformation: MachineBelongsToInformation;
};

export type SiteMachineConnection = {
  edges: Array<SiteMachineEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteMachineEdge = {
  cursor: Scalars['String']['output'];
  node: SiteMachine;
};

export type SiteMachineFilterInput = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  machineTypeId?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  /** Min 0, Max 100 */
  take?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteMachineMutations = {
  /** *Requires authentication* */
  create: SiteMachine;
  /** *Requires authentication* */
  delete: SiteMachine;
  /** *Requires authentication* */
  restore: SiteMachine;
  /** *Requires authentication* */
  update: SiteMachine;
};


export type SiteMachineMutations_CreateArgs = {
  input: CreateSiteMachineInput;
};


export type SiteMachineMutations_DeleteArgs = {
  machineId: Scalars['ID']['input'];
};


export type SiteMachineMutations_RestoreArgs = {
  machineId: Scalars['ID']['input'];
};


export type SiteMachineMutations_UpdateArgs = {
  input: EditSiteMachineInput;
  machineId: Scalars['ID']['input'];
};

export type SiteMachineQueries = {
  /** *Requires authentication* */
  machine?: Maybe<SiteMachine>;
  /** *Requires authentication* */
  machines: SiteMachineConnection;
  /** *Requires authentication* */
  machinesBySiteId: Array<SiteMachine>;
  /** *Requires authentication either * */
  siteMachinesByMachineOwningCompanyId: Array<SiteMachine>;
};


export type SiteMachineQueries_MachineArgs = {
  machineId: Scalars['ID']['input'];
};


export type SiteMachineQueries_MachinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMachinesFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMachinesOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteMachineQueries_MachinesBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};


export type SiteMachineQueries_SiteMachinesByMachineOwningCompanyIdArgs = {
  filter?: InputMaybe<SiteMachineFilterInput>;
  machineOwningCompanyId: Scalars['ID']['input'];
};

export type SiteMachineState = {
  loadTicketCorrelationId?: Maybe<Scalars['ID']['output']>;
  loadTicketStatusCodes?: Maybe<Array<DeviceEventsLoadTicketStatusCode>>;
  machineId: Scalars['ID']['output'];
  materialFlowId?: Maybe<Scalars['ID']['output']>;
  materialId?: Maybe<Scalars['String']['output']>;
  operatorId?: Maybe<Scalars['ID']['output']>;
  /** The latitude where the load event occured */
  originLatitude?: Maybe<Scalars['Float']['output']>;
  /** The longitude where the load event occured */
  originLongitude?: Maybe<Scalars['Float']['output']>;
  originZoneId?: Maybe<Scalars['ID']['output']>;
  siteId: Scalars['ID']['output'];
  siteLoadTicketId?: Maybe<Scalars['ID']['output']>;
  sourceLoadTicketId?: Maybe<Scalars['ID']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weightUnit?: Maybe<Scalars['String']['output']>;
};

export type SiteMachineType = {
  id: Scalars['ID']['output'];
  machineType: Scalars['String']['output'];
};

export type SiteMachineTypeQueries = {
  machineType?: Maybe<SiteMachineType>;
  machineTypes?: Maybe<Array<SiteMachineType>>;
};


export type SiteMachineTypeQueries_MachineTypeArgs = {
  machineTypeId: Scalars['ID']['input'];
};

export type SiteMachinesFilterBy = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  deprecationStatus?: InputMaybe<SiteMachinesStateFilter>;
  deviceConnection?: InputMaybe<DeviceConnectionStateFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  machineOwningCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  machineTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  manualLoadTickets?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  shortIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SiteMachinesOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export enum SiteMachinesStateFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Deprecated = 'DEPRECATED'
}

export type SiteMapModel = {
  baseSpeed?: Maybe<Scalars['Float']['output']>;
  boundary: Array<Array<Array<Scalars['Float']['output']>>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  geographyPhotos: Array<GeographyPhotoModel>;
  id: Scalars['ID']['output'];
  mapboxStyleId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced by "siteMaterialFlows" */
  materialFlows: Array<MaterialFlowModel>;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  pointsOfInterest: Array<PointOfInterestModel>;
  siteId: Scalars['String']['output'];
  siteMaterialFlows: SiteMaterialFlowConnection;
  zones: Array<ZoneModel>;
};


export type SiteMapModel_MaterialFlowsArgs = {
  options?: InputMaybe<GetMaterialFlowsBySiteMapIdOptions>;
};


export type SiteMapModel_PointsOfInterestArgs = {
  options?: InputMaybe<GetPointOfInterestBySiteMapIdOptions>;
};


export type SiteMapModel_SiteMaterialFlowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMaterialFlowFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMaterialFlowSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteMapModel_ZonesArgs = {
  options?: InputMaybe<GetZonesBySiteMapIdOptions>;
};

export type SiteMapMutations = {
  create: SiteMapModel;
  update: SiteMapModel;
};


export type SiteMapMutations_CreateArgs = {
  input: CreateSiteMapInput;
};


export type SiteMapMutations_UpdateArgs = {
  input: UpdateSiteMapInput;
};

export type SiteMapQueries = {
  /** @deprecated Replaced by "sitesbyGeoLocation" */
  byGeoLocation?: Maybe<IdAndSiteId>;
  /** @deprecated Replaced by "site" */
  bySiteId?: Maybe<SiteMapModel>;
  site?: Maybe<SiteMapModel>;
  sitesbyGeoLocation?: Maybe<Array<IdAndSiteId>>;
};


export type SiteMapQueries_ByGeoLocationArgs = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};


export type SiteMapQueries_BySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};


export type SiteMapQueries_SiteArgs = {
  siteId: Scalars['ID']['input'];
};


export type SiteMapQueries_SitesbyGeoLocationArgs = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type SiteMaterialFlow = {
  assignedMachines: Array<SiteMachine>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalReferences?: Maybe<Array<SiteMaterialFlowExternalReference>>;
  fromId: Scalars['String']['output'];
  hasDynamicMaterial: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  materialVariant?: Maybe<MaterialVariant>;
  materialVariantId?: Maybe<Scalars['String']['output']>;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  siteMetricDefinitions: Array<SiteMetricDefinition>;
  toId: Scalars['String']['output'];
  type: MaterialFlowType;
};

export type SiteMaterialFlowConnection = {
  edges: Array<SiteMaterialFlowEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteMaterialFlowEdge = {
  cursor: Scalars['String']['output'];
  node: SiteMaterialFlow;
};

export type SiteMaterialFlowExternalReference = {
  id: Scalars['ID']['output'];
  materialFlowId: Scalars['ID']['output'];
  type: ExternalReferenceTypes;
  value: Scalars['String']['output'];
};

export type SiteMaterialFlowFilter = {
  isDeleted?: InputMaybe<BoolFilterClass>;
  machineTypeId?: InputMaybe<IdFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  siteMapId?: InputMaybe<IdFilterClass>;
  siteMetricDefinitionIds?: InputMaybe<IdArrayFilterClass>;
  type?: InputMaybe<MaterialFlowTypeFilterClass>;
};

export type SiteMaterialFlowMutations = {
  create: SiteMaterialFlow;
  delete: SiteMaterialFlow;
  update: SiteMaterialFlow;
};


export type SiteMaterialFlowMutations_CreateArgs = {
  input: CreateSiteMaterialFlowInput;
};


export type SiteMaterialFlowMutations_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type SiteMaterialFlowMutations_UpdateArgs = {
  input: UpdateSiteMaterialFlowInput;
};

export type SiteMaterialFlowQueries = {
  siteMaterialFlow: SiteMaterialFlow;
  siteMaterialFlows: SiteMaterialFlowConnection;
};


export type SiteMaterialFlowQueries_SiteMaterialFlowArgs = {
  id: Scalars['ID']['input'];
};


export type SiteMaterialFlowQueries_SiteMaterialFlowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMaterialFlowFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMaterialFlowSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
  siteMapId: Scalars['ID']['input'];
};

export type SiteMaterialFlowSort = {
  createdAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type SiteMetricData = {
  createdAt: Scalars['DateTime']['output'];
  externalMetricDefinitionId: Scalars['String']['output'];
  fleetMetricDefinitionId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  materialVariantId?: Maybe<Scalars['String']['output']>;
  metricType?: Maybe<Scalars['String']['output']>;
  originCreatedAt: Scalars['DateTime']['output'];
  ownerId: Scalars['String']['output'];
  siteCompanyId?: Maybe<Scalars['String']['output']>;
  siteId?: Maybe<Scalars['String']['output']>;
  siteMetricDefinitionId: Scalars['String']['output'];
  sourceService: SiteMetricDataSourceService;
  statusIndicator?: Maybe<SiteMetricDataStatusIndicator>;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type SiteMetricDataConnection = {
  edges: Array<SiteMetricDataEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteMetricDataEdge = {
  cursor: Scalars['String']['output'];
  node: SiteMetricData;
};

export type SiteMetricDataFilter = {
  originCreatedAt?: InputMaybe<DateFilterClass>;
  siteMetricDefinitionId?: InputMaybe<IdFilterClass>;
  sourceService?: InputMaybe<SiteMetricDataSourceServiceFilterClass>;
};

export type SiteMetricDataQueries = {
  /** first defaults to 10 */
  siteMetricData: SiteMetricDataConnection;
};


export type SiteMetricDataQueries_SiteMetricDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMetricDataFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMetricDataSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteMetricDataSort = {
  originCreatedAt?: InputMaybe<SortDirection>;
};

export enum SiteMetricDataSourceService {
  Ip21 = 'IP21',
  Smarttonnes = 'SMARTTONNES'
}

export type SiteMetricDataSourceServiceFilterClass = {
  equals?: InputMaybe<SiteMetricDataSourceService>;
  in?: InputMaybe<Array<SiteMetricDataSourceService>>;
  not?: InputMaybe<SiteMetricDataSourceService>;
  notIn?: InputMaybe<Array<SiteMetricDataSourceService>>;
};

export enum SiteMetricDataStatusIndicator {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type SiteMetricDefinition = {
  createdAt: Scalars['DateTime']['output'];
  currentSiteMetricData?: Maybe<SiteMetricData>;
  deprecated: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  machineOwningCompany?: Maybe<MachineOwningCompany>;
  materialFlow?: Maybe<SiteMaterialFlow>;
  materialVariant?: Maybe<MaterialVariant>;
  metricDefinition: FleetMetricDefinitionNode;
  name?: Maybe<Scalars['String']['output']>;
  site: Site;
  siteMetricData: SiteMetricDataConnection;
  type: MetricDefinitionType;
};


export type SiteMetricDefinition_SiteMetricDataArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMetricDataFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMetricDataSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteMetricDefinitionConnection = {
  edges: Array<SiteMetricDefinitionEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteMetricDefinitionEdge = {
  cursor: Scalars['String']['output'];
  node: SiteMetricDefinition;
};

export enum SiteMetricDefinitionFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  Deprecated = 'DEPRECATED'
}

export type SiteMetricDefinitionFilterBy = {
  metricDefinitionId?: InputMaybe<Scalars['String']['input']>;
  metricDefinitionOwnerId?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SiteMetricDefinitionFilter>;
  types?: InputMaybe<Array<ProductivityMainMetricDefinitionTypeInput>>;
};

export type SiteMetricDefinitionMutations = {
  /** *Requires authentication* */
  createOrUpdate: SiteMetricDefinition;
  /** *Requires authentication* */
  disconnectSite: Scalars['Boolean']['output'];
};


export type SiteMetricDefinitionMutations_CreateOrUpdateArgs = {
  input: CreateOrUpdateSiteMetricDefinitionInput;
};


export type SiteMetricDefinitionMutations_DisconnectSiteArgs = {
  metricDefinitionId: Scalars['ID']['input'];
  metricDefinitionOwnerId: Scalars['ID']['input'];
};

export type SiteMetricDefinitionOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type SiteMetricDefinitionQueries = {
  /** *Requires authentication* */
  siteMetricDefinition?: Maybe<SiteMetricDefinition>;
  /** *Requires authentication* */
  siteMetricDefinitions: SiteMetricDefinitionConnection;
};


export type SiteMetricDefinitionQueries_SiteMetricDefinitionArgs = {
  id: Scalars['ID']['input'];
};


export type SiteMetricDefinitionQueries_SiteMetricDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteMetricDefinitionFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteMetricDefinitionOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteMutations = {
  /** *Requires authentication* */
  create: Site;
  /** *Requires authentication* */
  createAllowedMachineTypes?: Maybe<Site>;
  /** Used to retrigger site operations site event with all sites. *Requires authentication* */
  resendSitesCreated: Scalars['Boolean']['output'];
  /** *Requires authentication* */
  update: Site;
  /** Set the site as active. *Requires authentication* */
  updateActiveState: Site;
};


export type SiteMutations_CreateArgs = {
  input: CreateSiteInput;
};


export type SiteMutations_CreateAllowedMachineTypesArgs = {
  input: AllowedMachineTypesInput;
};


export type SiteMutations_UpdateArgs = {
  input: UpdateSiteInput;
  siteId: Scalars['ID']['input'];
};


export type SiteMutations_UpdateActiveStateArgs = {
  isActive: Scalars['Boolean']['input'];
  siteId: Scalars['ID']['input'];
};

export type SiteOperator = {
  employmentNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  site: Site;
};

export type SiteOperatorMachine = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  loadCapacity: SiteUnitValue;
  machineTypeId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  siteId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type SiteOperatorMachine_LoadCapacityArgs = {
  options?: InputMaybe<SiteUnitValueInput>;
};

export type SiteOperatorMachineQueries = {
  operatorMachine?: Maybe<SiteOperatorMachine>;
  operatorMachinesBySiteId: Array<SiteOperatorMachine>;
};


export type SiteOperatorMachineQueries_OperatorMachineArgs = {
  operatorSiteMachineId: Scalars['ID']['input'];
};


export type SiteOperatorMachineQueries_OperatorMachinesBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type SiteOperatorMutations = {
  /** *Requires authentication* */
  create: SiteOperator;
  /** *Requires authentication* */
  delete: SiteOperator;
  update: SiteOperator;
};


export type SiteOperatorMutations_CreateArgs = {
  input: CreateOperatorInput;
};


export type SiteOperatorMutations_DeleteArgs = {
  operatorId: Scalars['ID']['input'];
};


export type SiteOperatorMutations_UpdateArgs = {
  input: EditOperatorInput;
  operatorId: Scalars['ID']['input'];
};

export type SiteOperatorQueries = {
  /** *Requires authentication* */
  operatorsBySiteId: Array<SiteOperator>;
};


export type SiteOperatorQueries_OperatorsBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type SiteOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type SiteQueries = {
  /** *Requires authentication* */
  allSites: SiteConnection;
  /** Get site by Id. *Requires authentication* */
  site?: Maybe<Site>;
  /** Get site by location. *Requires authentication* */
  siteByLocation?: Maybe<Site>;
  /**
   * Get all sites the authenticated user has access to. *Requires authentication*
   * @deprecated This endpoint is deprecated, use sites.sitesV2 instead.
   */
  sites: Array<Site>;
  /** *Requires authentication* */
  sitesV2: SiteConnection;
};


export type SiteQueries_AllSitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteQueries_SiteArgs = {
  siteId: Scalars['ID']['input'];
};


export type SiteQueries_SiteByLocationArgs = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};


export type SiteQueries_SitesArgs = {
  includeGuestUserSites?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SiteQueries_SitesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SiteFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Type of relationship between org and site */
export enum SiteRelationshipType {
  Operates = 'Operates',
  OwnsMachine = 'OwnsMachine',
  OwnsSite = 'OwnsSite'
}

export type SiteSupportTicketMutations = {
  create: Scalars['Boolean']['output'];
};


export type SiteSupportTicketMutations_CreateArgs = {
  description: Scalars['String']['input'];
  shortDescription: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type SiteTabletCredentials = {
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type SiteTabletDevice = {
  appStatus?: Maybe<Scalars['String']['output']>;
  appVersion?: Maybe<Scalars['String']['output']>;
  batteryLevel?: Maybe<Scalars['Float']['output']>;
  /** A unique three letter **temporary** code. When the tablet is created it automatically gets a code. */
  code?: Maybe<Scalars['ID']['output']>;
  companyStatus?: Maybe<Scalars['String']['output']>;
  /** *Requires authentication either with operator token or standard token* */
  configuration: SiteTabletDeviceConfiguration;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastCheckin?: Maybe<Scalars['DateTime']['output']>;
  /** *Requires authentication either with operator token or standard token* */
  machine?: Maybe<SiteMachine>;
  /** *Requires authentication either with operator token or standard token* */
  name: Scalars['String']['output'];
  reconnectState?: Maybe<TabletReconnectState>;
  site: Site;
  siteId: Scalars['String']['output'];
  tabletState: TabletDeviceState;
  /** This property will only exist upon creating tablets from operator app */
  token?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uptime?: Maybe<Scalars['Int']['output']>;
};

export type SiteTabletDeviceConfiguration = {
  extendedLocation: Scalars['Boolean']['output'];
  isLoadingAsset: Scalars['Boolean']['output'];
  manualLoadTickets: Scalars['Boolean']['output'];
  trackerModeEnabled: Scalars['Boolean']['output'];
};

export type SiteTabletMutations = {
  createByLocation: SiteTabletDevice;
  createBySiteId: SiteTabletDevice;
  /** *Requires authentication* */
  delete: SiteTabletDevice;
  /** Disconnect the device from the machine. The device will go into confirmed state and a new short code for the device will be created. *Requires authentication* */
  disconnect: SiteTabletDevice;
  requestReconnect: RequestReconnectResponse;
  /** *Requires authentication* */
  update: SiteTabletDevice;
};


export type SiteTabletMutations_CreateByLocationArgs = {
  input: CreateSiteTabletDeviceInput;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};


export type SiteTabletMutations_CreateBySiteIdArgs = {
  input: CreateSiteTabletDeviceInput;
  siteId: Scalars['ID']['input'];
};


export type SiteTabletMutations_DeleteArgs = {
  tabletDeviceId: Scalars['ID']['input'];
};


export type SiteTabletMutations_DisconnectArgs = {
  machineId: Scalars['ID']['input'];
  tabletDeviceId: Scalars['ID']['input'];
};


export type SiteTabletMutations_RequestReconnectArgs = {
  identifierCode: Scalars['String']['input'];
  tabletDeviceId: Scalars['ID']['input'];
};


export type SiteTabletMutations_UpdateArgs = {
  input: EditSiteTabletDeviceInput;
  tabletDeviceId: Scalars['ID']['input'];
};

export type SiteTabletQueries = {
  /** *Requires authentication* */
  allTabletDevices: Array<SiteTabletDevice>;
  /** *Requires token generated by Site Operations* */
  credentials: SiteTabletCredentials;
  locationSites: Array<Site>;
  /** *Unprotected on base level, but subtypes requires auth* */
  tabletDevice?: Maybe<SiteTabletDevice>;
  /** *Requires authentication* */
  tabletDevicesBySiteId: Array<SiteTabletDevice>;
};


export type SiteTabletQueries_CredentialsArgs = {
  token: Scalars['String']['input'];
};


export type SiteTabletQueries_LocationSitesArgs = {
  identifierCode: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};


export type SiteTabletQueries_TabletDeviceArgs = {
  tabletDeviceId: Scalars['ID']['input'];
};


export type SiteTabletQueries_TabletDevicesBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type SiteUnconnectedEntities = {
  machines: Array<SiteMachine>;
  tabletDevices: Array<SiteTabletDevice>;
};

export type SiteUnconnectedEntityQueries = {
  /** Get all unconnected machines and devices for a given site. *Requires authentication* */
  unconnectedEntitiesBySiteId?: Maybe<SiteUnconnectedEntities>;
};


export type SiteUnconnectedEntityQueries_UnconnectedEntitiesBySiteIdArgs = {
  siteId: Scalars['ID']['input'];
};

export type SiteUnit = {
  /** The id to use when formatting numbers using js number format */
  formatId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  system: Scalars['String']['output'];
  type: UnitType;
};

export type SiteUnitFilterInput = {
  type: UnitType;
};

export type SiteUnitFormatterInput = {
  /** 'Format value using given locale. Defaults to http header 'Accept-Language' and if not present 'en'. */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Format value using given style, see Numberformat options for more info. Default value unit */
  style?: InputMaybe<Scalars['String']['input']>;
  /** The unit formatting style to use in unit formatting. The default is "short". */
  unitDisplay?: InputMaybe<Scalars['String']['input']>;
};

export type SiteUnitValue = {
  formattedValue: Scalars['String']['output'];
  rawValue: Scalars['Float']['output'];
  unit: SiteUnit;
  unitId: Scalars['String']['output'];
};


export type SiteUnitValue_FormattedValueArgs = {
  options?: InputMaybe<SiteUnitFormatterInput>;
};

export type SiteUnitValueInput = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type SiteUser = {
  companies: Array<SiteCompany>;
  companyIds: Array<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  hasAcceptedTermsAndConditions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  sites: Array<Site>;
};


export type SiteUser_CompaniesArgs = {
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SiteUser_CompanyIdsArgs = {
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteUserConnection = {
  edges: Array<SiteUserEdge>;
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SiteUserEdge = {
  cursor: Scalars['String']['output'];
  node: User;
};

export type SiteUserFilterInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Only include connected users, defaults to true */
  isConnected?: Scalars['Boolean']['input'];
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteUserFilterOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type SiteUserMutations = {
  /** *Requires authentication* */
  acceptTermsAndConditions: Scalars['Boolean']['output'];
  /** *Requires authentication* */
  create: SiteUser;
  /** *Requires authentication* */
  createGuestUser: User;
  /** *Requires authentication* */
  delete: SiteUser;
  /** *Requires authentication* */
  deleteGuestUser: Scalars['Boolean']['output'];
  /** *Requires authentication* */
  disconnect: SiteUser;
  /** *Requires authentication* */
  sendGuestUserInvitationReminder: Scalars['Boolean']['output'];
  /** *Requires authentication* */
  update: SiteUser;
  /** *Requires authentication* */
  updateGuestUser: User;
};


export type SiteUserMutations_AcceptTermsAndConditionsArgs = {
  accepted: Scalars['Boolean']['input'];
  userEmail: Scalars['String']['input'];
};


export type SiteUserMutations_CreateArgs = {
  input: CreateSiteUserInput;
};


export type SiteUserMutations_CreateGuestUserArgs = {
  input: SiteCreateGuestUserInput;
  siteId: Scalars['ID']['input'];
};


export type SiteUserMutations_DeleteArgs = {
  siteId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type SiteUserMutations_DeleteGuestUserArgs = {
  siteId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type SiteUserMutations_DisconnectArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type SiteUserMutations_SendGuestUserInvitationReminderArgs = {
  siteId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type SiteUserMutations_UpdateArgs = {
  input: EditSiteUserInput;
  userId: Scalars['ID']['input'];
};


export type SiteUserMutations_UpdateGuestUserArgs = {
  input: SiteGuestUserUpdateInput;
  siteId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type SiteUserQueries = {
  /** *Requires authentication* */
  user?: Maybe<SiteUser>;
  /** *Requires authentication* */
  userByAuth?: Maybe<SiteUser>;
  /** *Requires authentication* */
  users: Array<SiteUser>;
};


export type SiteUserQueries_UserArgs = {
  userId: Scalars['ID']['input'];
};


export type SiteUserQueries_UsersArgs = {
  filter: SiteUserFilterInput;
};

export type SiteUserSettings = {
  displayName?: Maybe<Scalars['String']['output']>;
  guestUserState?: Maybe<SiteUserState>;
  machineType?: Maybe<SiteMachineType>;
};

export type SiteUserState = {
  expirationDate: Scalars['DateTime']['output'];
  status: SiteUserStatus;
};

export enum SiteUserStatus {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED'
}

export type SmarttonnesCredentialsMutations = {
  deleteCredentials: Scalars['Boolean']['output'];
  setCredentials: Scalars['Boolean']['output'];
};


export type SmarttonnesCredentialsMutations_DeleteCredentialsArgs = {
  ownerId: Scalars['ID']['input'];
};


export type SmarttonnesCredentialsMutations_SetCredentialsArgs = {
  ownerId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type SmarttonnesCredentialsQueries = {
  credentials?: Maybe<Scalars['String']['output']>;
};


export type SmarttonnesCredentialsQueries_CredentialsArgs = {
  ownerId: Scalars['ID']['input'];
};

/** [VDA] Data Information about the ECUs on the machine */
export type SoftwareConfiguration = {
  /** [VDA] ESWParameters enable a function within the machine. Related to controls that monitor the machines. */
  eswParameters?: Maybe<Array<EswParametersData>>;
  /** [VDA] General Information pertaining to a machine. */
  generalInformation?: Maybe<GeneralInformationData>;
  /** [VDA] Information for Tea2 Nodes. */
  tea2?: Maybe<Tea2Data>;
  /** [VDA] Information for Tea2Plus Nodes. */
  tea2Plus?: Maybe<Tea2PlusData>;
  /** [VDA] Variants are the options on a particular machine. */
  variants?: Maybe<Array<VariantData>>;
};

export type SoftwareData = {
  /** [VDA] Compression method for Software. */
  compressionMethod?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Encryption method for Software. */
  encryptionMethod?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Indicator if isPreReleaseVerificationData. */
  isPreReleaseVerificationData?: Maybe<Scalars['Boolean']['output']>;
  /** [VDA] Identifier for documentation in Software Configurator */
  nodeTemplateDocumentNumber?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Indicator if nonProgrammable. */
  nonProgrammable?: Maybe<Scalars['Boolean']['output']>;
  /** [VDA] Offset address for Software. */
  offsetAddress?: Maybe<Scalars['Float']['output']>;
  /** [VDA] Part number for the Software in TEA2PlusNode. */
  partNumber?: Maybe<Scalars['String']['output']>;
  /** [VDA] Software download signature for Software. */
  softwareDownloadSignature?: Maybe<Scalars['String']['output']>;
};

/** Sort direction */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SourceAnalyticsQuery = {
  sourcematerialHistory: Array<GroupedTransactionData>;
};


export type SourceAnalyticsQuery_SourcematerialHistoryArgs = {
  accumulate?: Scalars['Boolean']['input'];
  generateEmptyDays?: Scalars['Boolean']['input'];
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  timePeriodInput: TimePeriodInput;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type SourceService = {
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SpeedingInterval = {
  from: ConfigurationValueWithUnit;
  speedingThresholdType: SpeedingType;
  /** The allowed percentage over the speed limit before speeding event is registred */
  threshold: ConfigurationValueWithUnit;
  to: ConfigurationValueWithUnit;
};

export type SpeedingIntervalThresholdInput = {
  /** The allowed m/s over max threshold */
  high: Scalars['Float']['input'];
  /** The allowed m/s over max threshold */
  low: Scalars['Float']['input'];
  /** The allowed m/s over max threshold */
  medium: Scalars['Float']['input'];
  unit: Scalars['String']['input'];
};

export type SpeedingPayloadInput = {
  currentSpeed: Scalars['Float']['input'];
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['ID']['input'];
  speedLimit: Scalars['Float']['input'];
  speedUnit: Scalars['String']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
  weightUnit?: InputMaybe<Scalars['String']['input']>;
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type SpeedingSettings = {
  intervals: Array<SpeedingInterval>;
};

/** Defines the type of speeding threshold. */
export enum SpeedingType {
  ThresholdFast = 'ThresholdFast',
  ThresholdMedium = 'ThresholdMedium',
  ThresholdSlow = 'ThresholdSlow'
}

export type StarterBatteryLevelHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** [ARI] Starter battery level in percentage at the time of the event. */
  starterBatteryLevel: Scalars['Float']['output'];
};

export type StarterBatteryLevelQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
  /** The data points for starter battery level events from ARI. */
  starterBatteryLevel: Array<StarterBatteryLevelHistoryEvent>;
};

export type StatisticsConnection = {
  edges: Array<StatisticsEdge>;
  /** @deprecated Will always be null, kept for schema compatibility reasons */
  pageCursors?: Maybe<PageCursors>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type StatisticsEdge = {
  cursor: Scalars['String']['output'];
  node: StatisticsNodeUnion;
};

export enum StatisticsGranularity {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type StatisticsNodeUnion = StatisticsNumericNode | StatisticsNumericTypeNode | StatisticsUniqueValueNode;

export type StatisticsNumericNode = {
  avg: UnitValue;
  count: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  field: Scalars['String']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  sum: UnitValue;
};


export type StatisticsNumericNode_AvgArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsNumericNode_SumArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type StatisticsNumericTypeNode = {
  avg: UnitTypeValue;
  count: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  field: Scalars['String']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  sum: UnitTypeValue;
};

export type StatisticsUniqueValueCountNode = {
  count: Scalars['Int']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type StatisticsUniqueValueNode = {
  count: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  field: Scalars['String']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  values: Array<StatisticsUniqueValueCountNode>;
};

export type StockAnalyticsQuery = {
  availableStock: Array<AvailableStock>;
  currentStock: Array<GroupedMaterialTransaction>;
  stockHistory: Array<GroupedStockData>;
  stockProduction: Array<GroupedTransactionData>;
  stockProductionForMaterials: Array<GroupedMaterialTransactionData>;
};


export type StockAnalyticsQuery_AvailableStockArgs = {
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
};


export type StockAnalyticsQuery_CurrentStockArgs = {
  materialId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
};


export type StockAnalyticsQuery_StockHistoryArgs = {
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  timePeriodInput: TimePeriodInput;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};


export type StockAnalyticsQuery_StockProductionArgs = {
  accumulate?: Scalars['Boolean']['input'];
  generateEmptyDays?: Scalars['Boolean']['input'];
  materialId: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  timePeriodInput: TimePeriodInput;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};


export type StockAnalyticsQuery_StockProductionForMaterialsArgs = {
  accumulate?: Scalars['Boolean']['input'];
  generateEmptyDays?: Scalars['Boolean']['input'];
  materialIds: Array<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  timePeriodInput: TimePeriodInput;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type StockModification = {
  createdBy?: Maybe<Scalars['String']['output']>;
  modifiedAt: Scalars['DateTime']['output'];
  weight?: Maybe<UnitValue>;
  zoneId?: Maybe<Scalars['String']['output']>;
};


export type StockModification_WeightArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type StringArrayFilterClass = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StringArrayFilterClassWithOnlyHasSome = {
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** String Case Mode */
export enum StringCaseMode {
  Default = 'DEFAULT',
  Insensitive = 'INSENSITIVE'
}

export type StringFilterClass = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  mode?: InputMaybe<StringCaseMode>;
  not?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilterInput = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** Asset type inside Subscription */
export type SubscriptionAsset = {
  OEM: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  model: Scalars['String']['output'];
  name: Scalars['String']['output'];
  serial: Scalars['String']['output'];
};

export type SubscriptionConnection = {
  edges: Array<SubscriptionEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionConnectionStatusModel = {
  /** Status of the subscription connection */
  status: Scalars['String']['output'];
  /** Idenifier for a subscription */
  subscriptionId: Scalars['String']['output'];
};

export type SubscriptionEdge = {
  cursor: Scalars['String']['output'];
  node: SubscriptionResponse;
};

/** Activation and cancellation events for subscriptions */
export type SubscriptionEvent = {
  action: Scalars['String']['output'];
  asset?: Maybe<SubscriptionAsset>;
  chassisId?: Maybe<Scalars['String']['output']>;
  contextId: Scalars['ID']['output'];
  /** when the message was consumed from SMP */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ingestedSubscriptionEvent?: Maybe<IngestedSubscriptionEvent>;
  invoicePartyCustomerId?: Maybe<Scalars['String']['output']>;
  machineId?: Maybe<Scalars['String']['output']>;
  manuallyOverriddenAt?: Maybe<Scalars['DateTime']['output']>;
  manuallyOverriddenBy?: Maybe<Scalars['String']['output']>;
  manuallyOverriddenStatus?: Maybe<Scalars['Boolean']['output']>;
  manuallyOverriddenStatusMessage?: Maybe<Scalars['String']['output']>;
  pin17?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  serviceProducts: Array<ServiceProduct>;
  source: Scalars['String']['output'];
  /** possible values: FINISHED, ERROR, PENDING, EXPIRED, UNSUPPORTED_PRODUCT, null */
  status?: Maybe<ProvisioningStatus>;
  subscriberCustomerId: Scalars['String']['output'];
  subscriberOrganizationId?: Maybe<Scalars['String']['output']>;
  subscriberOrganizationName?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['String']['output'];
  workflowExecution?: Maybe<WorkflowExecution>;
};

export type SubscriptionEventConnection = {
  edges: Array<SubscriptionEventEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionEventEdge = {
  cursor: Scalars['String']['output'];
  node: SubscriptionEvent;
};

export type SubscriptionEventFilter = {
  action?: InputMaybe<StringFilterClass>;
  and?: InputMaybe<Array<SubscriptionEventFilter>>;
  assetId?: InputMaybe<StringFilterClass>;
  assetModel?: InputMaybe<StringFilterClass>;
  assetName?: InputMaybe<StringFilterClass>;
  assetOEM?: InputMaybe<StringFilterClass>;
  assetSerial?: InputMaybe<StringFilterClass>;
  chassisId?: InputMaybe<StringFilterClass>;
  contextId?: InputMaybe<StringFilterClass>;
  createdAt?: InputMaybe<DateFilterClass>;
  invoicePartyCustomerId?: InputMaybe<StringFilterClass>;
  machineId?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<SubscriptionEventFilter>>;
  pin17?: InputMaybe<StringFilterClass>;
  productId?: InputMaybe<StringFilterClass>;
  /** options: FINISHED, ERROR, PENDING, EXPIRED, UNSUPPORTED_PRODUCT */
  status?: InputMaybe<StringFilterClass>;
  subscriberCustomerId?: InputMaybe<StringFilterClass>;
  subscriberOrganizationId?: InputMaybe<StringFilterClass>;
  subscriberOrganizationName?: InputMaybe<StringFilterClass>;
  subscriptionId?: InputMaybe<StringFilterClass>;
};

export type SubscriptionEventMutations = {
  _key?: Maybe<Scalars['String']['output']>;
  overrideProvisioningStatus: SubscriptionEvent;
  rerun: Scalars['Boolean']['output'];
  resetOverriddenProvisioningStatus: SubscriptionEvent;
};


export type SubscriptionEventMutations_OverrideProvisioningStatusArgs = {
  contextId: Scalars['String']['input'];
  message: Scalars['String']['input'];
  status: Scalars['String']['input'];
};


export type SubscriptionEventMutations_RerunArgs = {
  correlationId: Scalars['String']['input'];
};


export type SubscriptionEventMutations_ResetOverriddenProvisioningStatusArgs = {
  contextId: Scalars['String']['input'];
};

export type SubscriptionEventQueries = {
  _key?: Maybe<Scalars['String']['output']>;
  byContextId: SubscriptionEvent;
  paginated: SubscriptionEventConnection;
};


export type SubscriptionEventQueries_ByContextIdArgs = {
  contextId: Scalars['String']['input'];
};


export type SubscriptionEventQueries_PaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SubscriptionEventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionEventSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SubscriptionEventSort = {
  action?: InputMaybe<SortDirection>;
  chassisId?: InputMaybe<SortDirection>;
  contextId?: InputMaybe<SortDirection>;
  /** Defaults to DESC */
  createdAt?: InputMaybe<SortDirection>;
  invoicePartyCustomerId?: InputMaybe<SortDirection>;
  machineId?: InputMaybe<SortDirection>;
  pin17?: InputMaybe<SortDirection>;
  productId?: InputMaybe<SortDirection>;
  source?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  subscriberCustomerId?: InputMaybe<SortDirection>;
  subscriptionId?: InputMaybe<SortDirection>;
};

export type SubscriptionFilter = {
  and?: InputMaybe<Array<SubscriptionFilter>>;
  assetId?: InputMaybe<StringFilterClass>;
  assetModel?: InputMaybe<StringFilterClass>;
  assetName?: InputMaybe<StringFilterClass>;
  assetOEM?: InputMaybe<StringFilterClass>;
  assetSerial?: InputMaybe<StringFilterClass>;
  chassisId?: InputMaybe<StringFilterClass>;
  contextId?: InputMaybe<StringFilterClass>;
  createdAt?: InputMaybe<DateFilterClass>;
  invoicePartyCustomerId?: InputMaybe<StringFilterClass>;
  machineId?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<SubscriptionFilter>>;
  pin17?: InputMaybe<StringFilterClass>;
  productId?: InputMaybe<StringFilterClass>;
  /** options: FINISHED, ERROR, PENDING, EXPIRED, UNSUPPORTED_PRODUCT */
  status?: InputMaybe<StringFilterClass>;
  subscriberCustomerId?: InputMaybe<StringFilterClass>;
  subscriberOrganizationId?: InputMaybe<StringFilterClass>;
  subscriberOrganizationName?: InputMaybe<StringFilterClass>;
  subscriptionId?: InputMaybe<StringFilterClass>;
};

export type SubscriptionPaginationEventConnection = {
  edges: Array<SubscriptionPaginationEventEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionPaginationEventEdge = {
  cursor: Scalars['String']['output'];
  node: IngestedSubscriptionEvent;
};

export type SubscriptionPaginationEventFilter = {
  and?: InputMaybe<Array<SubscriptionPaginationEventFilter>>;
  createdDateTime?: InputMaybe<DateFilterClass>;
  id?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<SubscriptionPaginationEventFilter>>;
  subscriptionId?: InputMaybe<EqualsStringFilterClass>;
};

export type SubscriptionPaginationEventSort = {
  createdDateTime?: InputMaybe<SortDirection>;
};

export type SubscriptionProduct = {
  connectedServices: Array<ConnectedService>;
  name: Scalars['String']['output'];
};

export type SubscriptionQueries = {
  _key?: Maybe<Scalars['String']['output']>;
  byMachineChassisId: SubscriptionQueriesResponse;
  byMachinePin17: SubscriptionQueriesResponse;
  bySubscriptionId: SubscriptionResponse;
  ingestedEvents: SubscriptionPaginationEventConnection;
  paginated: SubscriptionConnection;
};


export type SubscriptionQueries_ByMachineChassisIdArgs = {
  chassisId: Scalars['String']['input'];
};


export type SubscriptionQueries_ByMachinePin17Args = {
  pin17: Scalars['String']['input'];
};


export type SubscriptionQueries_BySubscriptionIdArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type SubscriptionQueries_IngestedEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SubscriptionPaginationEventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionPaginationEventSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type SubscriptionQueries_PaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<SubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SubscriptionSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SubscriptionQueriesResponse = {
  subscriptions: Array<MachineSubscription>;
};

export type SubscriptionResponse = {
  asset?: Maybe<SubscriptionAsset>;
  chassisId?: Maybe<Scalars['String']['output']>;
  contextId: Scalars['ID']['output'];
  /** when the subscription was activated */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invoicePartyCustomerId?: Maybe<Scalars['String']['output']>;
  machineId?: Maybe<Scalars['String']['output']>;
  pin17?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  serviceProducts: Array<ServiceProduct>;
  source: Scalars['String']['output'];
  /** possible values: FINISHED, ERROR, PENDING, EXPIRED, UNSUPPORTED_PRODUCT, null */
  status?: Maybe<ProvisioningStatus>;
  subscriberCustomerId: Scalars['String']['output'];
  subscriberOrganizationId?: Maybe<Scalars['String']['output']>;
  subscriberOrganizationName?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['String']['output'];
};

export type SubscriptionSort = {
  chassisId?: InputMaybe<SortDirection>;
  /** Defaults to ASC */
  contextId?: InputMaybe<SortDirection>;
  /** Defaults to DESC */
  createdAt?: InputMaybe<SortDirection>;
  invoicePartyCustomerId?: InputMaybe<SortDirection>;
  machineId?: InputMaybe<SortDirection>;
  pin17?: InputMaybe<SortDirection>;
  productId?: InputMaybe<SortDirection>;
  source?: InputMaybe<SortDirection>;
  subscriberCustomerId?: InputMaybe<SortDirection>;
  subscriptionId?: InputMaybe<SortDirection>;
};

export type SupportedTemplate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deadline?: Maybe<Scalars['Int']['output']>;
  defaultContext: Scalars['JSON']['output'];
  description: Scalars['String']['output'];
  forProducts: Array<Scalars['String']['output']>;
  from: Array<Scalars['String']['output']>;
  history: Array<TemplateHistory>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  revision: Scalars['Int']['output'];
  triggers: Array<Scalars['String']['output']>;
};

/** Possible states for a tablet */
export enum TabletDeviceState {
  Confirmed = 'CONFIRMED',
  Connected = 'CONNECTED',
  Guest = 'GUEST',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

/** Possible states for a tablet reconnect */
export enum TabletReconnectState {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Pending = 'PENDING'
}

export type TamtronCredentialsMutations = {
  deleteCredentials: Scalars['Boolean']['output'];
  setCredentials: Scalars['Boolean']['output'];
};


export type TamtronCredentialsMutations_DeleteCredentialsArgs = {
  ownerId: Scalars['ID']['input'];
};


export type TamtronCredentialsMutations_SetCredentialsArgs = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  ownerId: Scalars['ID']['input'];
};

export type TamtronCredentialsQueries = {
  credentials?: Maybe<Scalars['String']['output']>;
};


export type TamtronCredentialsQueries_CredentialsArgs = {
  ownerId: Scalars['ID']['input'];
};

export type TamtronImportScaleLog = {
  id: Scalars['ID']['output'];
  importedAt: Scalars['DateTime']['output'];
  ownerId: Scalars['ID']['output'];
};

export type TamtronScale = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ownerId: Scalars['ID']['output'];
  serialNumber: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TamtronScaleQueries = {
  /** *Requires authentication* */
  lastImportScaleLog?: Maybe<TamtronImportScaleLog>;
  /** *Requires authentication* */
  scales: Array<TamtronScale>;
};


export type TamtronScaleQueries_LastImportScaleLogArgs = {
  ownerId?: InputMaybe<Scalars['ID']['input']>;
};


export type TamtronScaleQueries_ScalesArgs = {
  ownerId: Scalars['ID']['input'];
};

export type TamtronValidationQueries = {
  /** *Requires authentication* */
  validateCredentials?: Maybe<Scalars['Boolean']['output']>;
};


export type TamtronValidationQueries_ValidateCredentialsArgs = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
};

export type TargetConnection = {
  edges: Array<TargetEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TargetEdge = {
  cursor: Scalars['String']['output'];
  node: TargetModel;
};

export type TargetFilter = {
  and?: InputMaybe<Array<TargetFilter>>;
  chassisId?: InputMaybe<StringFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  operatorName?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<TargetFilter>>;
  uuid?: InputMaybe<StringFilterClass>;
};

export type TargetFilterRequireChassisId = {
  and?: InputMaybe<Array<TargetFilter>>;
  chassisId: StringFilterClass;
  name?: InputMaybe<StringFilterClass>;
  operatorName?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<TargetFilter>>;
  uuid?: InputMaybe<StringFilterClass>;
};

export type TargetModel = {
  anonymous: Scalars['Boolean']['output'];
  baseType?: Maybe<Scalars['Int']['output']>;
  capacity?: Maybe<Scalars['BigInt']['output']>;
  chassisId?: Maybe<Scalars['String']['output']>;
  compartmentNo?: Maybe<Scalars['BigInt']['output']>;
  id: Scalars['ID']['output'];
  materialUuid?: Maybe<Scalars['String']['output']>;
  parentUuid?: Maybe<Scalars['String']['output']>;
  rev: Scalars['BigInt']['output'];
  subType?: Maybe<Scalars['Int']['output']>;
  trainCompartments?: Maybe<Scalars['BigInt']['output']>;
  updated: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo: Scalars['DateTime']['output'];
};

export type TargetQueries = {
  /** Fetch targets based on chassis ids or target uuid. Requires authentication */
  targets: TargetConnection;
};


export type TargetQueries_TargetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<TargetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TargetSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type TargetSort = {
  chassisId?: InputMaybe<SortDirection>;
  updated?: InputMaybe<SortDirection>;
};

export type Tea2Alarm = MachineFaultCode & {
  /** [ARI] If the failure is active or not (true or false). */
  active: Scalars['Boolean']['output'];
  /** [ARI] The description of the failure type. E.g., "Tank level sensor, hardware failure". */
  faultTypeDescription?: Maybe<Scalars['String']['output']>;
  /** First occurrence in service */
  firstOccurrence?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Type of fault code 0-15. */
  fmi?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Textual description of the Fmi. */
  fmiDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] Timestamp of last time the failure occurrence. */
  lastFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** Last occurrence in service, or last failure time if set in alarm */
  lastOccurrence?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Accumulated number of seconds the machine has been running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Position information at the time of the fault event. */
  machinePosition?: Maybe<MachinePosition>;
  /** [ARI] Numerical code for a specific ECU (Electrical Control Unit) i.e. "128". */
  mid?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Textual description of the Mid. E.g., "Engine ECU". */
  midDescription?: Maybe<Scalars['String']['output']>;
  /** Recorded occurrence count in service, or occurance counter if set in alarm */
  numberOfOccurrences?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Counter of the specific fault code since last "acknowledgement". */
  occurrenceCounter?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Type of signal. */
  pid: Scalars['Int']['output'];
  /** [ARI] Subtype of signal. 0=PPID 1=PSID 2=PID 3=SID. */
  pidType: Scalars['String']['output'];
  /** [ARI] Priority of error code, 1-3. Note: Not reliable information. */
  priority?: Maybe<Scalars['Int']['output']>;
  type: MachineFaultCodeType;
};

export type Tea2BridgeDtc = MachineFaultCode & {
  /** [ARI] If the failure is active or not (true or false). */
  active: Scalars['Boolean']['output'];
  /** [ARI] Timestamp of the first time the failure occurred. */
  firstFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** First occurrence in service */
  firstOccurrence?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Type of fault code 0-15. */
  fmi?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Textual description of the Fmi. */
  fmiDescription?: Maybe<Scalars['String']['output']>;
  /** [ARI] Timestamp of last time the failure occurrence. */
  lastFailureTime?: Maybe<Scalars['DateTime']['output']>;
  /** Last occurrence in service, or last failure time if set in alarm */
  lastOccurrence?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Accumulated number of seconds the machine has been running at the time of the event. */
  machineOperatingTime?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Position information at the time of the fault event. */
  machinePosition?: Maybe<MachinePosition>;
  /** Recorded occurrence count in service, or occurance counter if set in alarm */
  numberOfOccurrences?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Counter of the specific fault code since last "acknowledgement". */
  occurrenceCounter?: Maybe<Scalars['Int']['output']>;
  /** [ARI] */
  occurrenceTime?: Maybe<Scalars['DateTime']['output']>;
  /** [ARI] Priority of error code, 1-3. Note: Not reliable information. */
  priority?: Maybe<Scalars['Int']['output']>;
  /** [ARI] ECU CAN address. */
  sourceAddress: Scalars['String']['output'];
  /** [ARI] SPN error code number. */
  suspectParameterNumber: Scalars['Int']['output'];
  /** [ARI] SPN error code. */
  suspectParameterNumberDescription?: Maybe<Scalars['String']['output']>;
  type: MachineFaultCodeType;
};

export type Tea2Data = {
  /** [VDA] Values for Tea2 Nodes. */
  nodes?: Maybe<Array<Tea2NodeData>>;
};

export type Tea2NodeData = {
  /** [VDA] Values for Complementary Softwares. */
  complementarySoftwares?: Maybe<Array<BaseData>>;
  /** [VDA] Values for Data Set 1. */
  dataSet1?: Maybe<BaseData>;
  /** [VDA] Values for Data Set 2. */
  dataSet2?: Maybe<BaseData>;
  /** [VDA] Values for Downloader. */
  downloader?: Maybe<BaseData>;
  /** [VDA] Values for ECU Hardware Data. */
  ecuHardware?: Maybe<EcuHardware>;
  /** [VDA] Values for Field Number. */
  fieldNumber?: Maybe<Scalars['String']['output']>;
  /** [VDA] Values for Main Software. */
  mainSoftware?: Maybe<BaseData>;
  /** [VDA] Values for MID. */
  mid?: Maybe<Scalars['String']['output']>;
  /** [VDA] Values for Node Template Document Number. */
  nodeTemplateDocumentNumber?: Maybe<Scalars['String']['output']>;
  /** [VDA] Values for Sub Complementary Softwares. */
  subComplementarySoftwares?: Maybe<Array<BaseData>>;
  /** [VDA] Values for Sub Data Sets. */
  subDataSets?: Maybe<Array<BaseData>>;
  /** [VDA] Values for Sub Softwares. */
  subSoftwares?: Maybe<Array<BaseData>>;
};

export type Tea2PlusData = {
  /** [VDA] Values for Tea2Plus Nodes. */
  nodes?: Maybe<Array<Tea2PlusNodeData>>;
};

export type Tea2PlusNodeData = {
  /** [VDA] Values for Complementary Softwares. */
  complementarySoftwares?: Maybe<Array<SoftwareData>>;
  /** [VDA] Values for Datas. */
  datas?: Maybe<Array<Data>>;
  /** [VDA] Values for ECU Hardware Data. */
  ecuHardware?: Maybe<HardwareData>;
  /** [VDA] Values for Main Software. */
  mainSoftware?: Maybe<MainSoftware>;
  /** [VDA] Values for ParentNodes. */
  nodeName?: Maybe<NodeNameData>;
  /** [VDA] Values for ECU Hardware Data. */
  nodePath?: Maybe<Scalars['String']['output']>;
  /** [VDA] Values for Node TEA2Plus. Default is "Production" */
  nodeState?: Maybe<Scalars['String']['output']>;
  /** [VDA] Identifier for documentation for node in Software Configurator */
  nodeTemplateDocumentNumber: Scalars['Float']['output'];
  /** [VDA] Value for Secondary Bootloader. */
  secondaryBootloader?: Maybe<SoftwareData>;
  /** [VDA] Values for ECU Hardware Data. */
  subHardwares?: Maybe<Array<HardwareData>>;
};

export enum TelematicsUnitTYpe {
  Other = 'Other',
  Tgw = 'TGW',
  Wecu = 'WECU',
  Unknown = 'unknown'
}

export type TemplateHistory = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  revision: Scalars['Int']['output'];
};

/** Dummy entity used for E2E testing purposes. */
export type Test = {
  test: Scalars['Boolean']['output'];
};

/** Dummy queries used for E2E testing purposes. */
export type TestQueries = {
  /** Dummy field used for E2E testing purposes. */
  test: Test;
};

export type TilesetModel = {
  bounds: Array<Scalars['Float']['output']>;
  center: Array<Scalars['Float']['output']>;
  fillzoom: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  maxzoom: Scalars['Float']['output'];
  minzoom: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  scheme: Scalars['String']['output'];
  tiles: Scalars['String']['output'];
};

export type TimePeriodInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  timeUnit?: InputMaybe<TimeUnitEnum>;
  timezone?: Scalars['Timezone']['input'];
};

export type TimeRangeInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

/** The type of TimeUnit */
export enum TimeUnitEnum {
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week',
  Year = 'Year'
}

export type Token = {
  value: Scalars['String']['output'];
};

export enum TopMachineStatisticsEntity {
  FleetMachineId = 'FLEET_MACHINE_ID',
  OwnerId = 'OWNER_ID',
  SiteId = 'SITE_ID',
  SiteMachineId = 'SITE_MACHINE_ID'
}

export type TopMachineStatisticsFilterBy = {
  fleetMachineId?: InputMaybe<IdFilterClass>;
  ownerId?: InputMaybe<IdFilterClass>;
  siteId?: InputMaybe<IdFilterClass>;
  siteMachineId?: InputMaybe<IdFilterClass>;
};

export enum TopSiteLoadTicketStatisticsEntity {
  FlowId = 'FLOW_ID',
  FromZoneId = 'FROM_ZONE_ID',
  LoaderFleetMachineId = 'LOADER_FLEET_MACHINE_ID',
  LoaderMachineOwningCompanyId = 'LOADER_MACHINE_OWNING_COMPANY_ID',
  LoaderOperatorId = 'LOADER_OPERATOR_ID',
  LoaderSiteMachineId = 'LOADER_SITE_MACHINE_ID',
  MaterialVariantId = 'MATERIAL_VARIANT_ID',
  SiteId = 'SITE_ID',
  ToZoneId = 'TO_ZONE_ID',
  TransporterFleetMachineId = 'TRANSPORTER_FLEET_MACHINE_ID',
  TransporterMachineOwningCompanyId = 'TRANSPORTER_MACHINE_OWNING_COMPANY_ID',
  TransporterOperatorId = 'TRANSPORTER_OPERATOR_ID',
  TransporterSiteMachineId = 'TRANSPORTER_SITE_MACHINE_ID'
}

export type TopSiteLoadTicketStatisticsFilterBy = {
  fleetMachineId?: InputMaybe<IdFilterClass>;
  siteId?: InputMaybe<IdFilterClass>;
  siteMachineId?: InputMaybe<IdFilterClass>;
};

export enum TopSiteLoadTicketsStatisticsNumericFields {
  Weight = 'WEIGHT'
}

export type TopStatisticsNodeUnion = TopStatisticsNumericNode;

export type TopStatisticsNumericNode = {
  avg: UnitValue;
  count: Scalars['Int']['output'];
  entityId: Scalars['String']['output'];
  field: Scalars['String']['output'];
  label: Scalars['String']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  sum: UnitValue;
};


export type TopStatisticsNumericNode_AvgArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};


export type TopStatisticsNumericNode_SumArgs = {
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type TopStatisticsResult = {
  /** Indicates if there are any more results with the current filter provided. */
  hasMoreResults: Scalars['Boolean']['output'];
  /** The top results. */
  nodes: Array<TopStatisticsNodeUnion>;
};

export enum TopStatisticsTake {
  Fifteen = 'FIFTEEN',
  Five = 'FIVE',
  Ten = 'TEN',
  Twenty = 'TWENTY'
}

export type TotalFuelConsumptionHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** [ARI] Accumulated DEF/Adblue consumption in liter at the time of the event. Diesel machines only. */
  totalDefConsumption?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated DEF/Adblue consumption in excessive idle mode in liter at the time of the event. Diesel machines only. */
  totalDefConsumptionExcessiveIdle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated DEF/Adblue consumption in idle mode in liter at the time of the event. Diesel machines only. */
  totalDefConsumptionInIdle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated DEF/Adblue consumption in work mode in liter at the time of the event. Diesel machines only. */
  totalDefConsumptionInWork?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated fuel consumption in liter at the time of the event. */
  totalFuelConsumption?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated fuel consumption in excessive idle mode in liter at the time of the event. */
  totalFuelConsumptionExcessiveIdle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated fuel consumption in idle mode in liter at the time of the event. */
  totalFuelConsumptionInIdle?: Maybe<Scalars['Float']['output']>;
  /** [ARI] Accumulated fuel consumption in work mode in liter at the time of the event. */
  totalFuelConsumptionInWork?: Maybe<Scalars['Float']['output']>;
};

/** The type of a transaction */
export enum TransactionTypeEnum {
  Delivery = 'DELIVERY',
  InventoryCheck = 'INVENTORY_CHECK',
  MovementFrom = 'MOVEMENT_FROM',
  MovementTo = 'MOVEMENT_TO',
  Production = 'PRODUCTION',
  Sourcematerial = 'SOURCEMATERIAL'
}

export type Trip = {
  createdAt: Scalars['DateTime']['output'];
  endZone: ZoneModel;
  endZoneEnterTime: Scalars['DateTime']['output'];
  fleetMachine?: Maybe<FleetMachine>;
  id: Scalars['ID']['output'];
  isReturnTrip: Scalars['Boolean']['output'];
  loadedWeight?: Maybe<Scalars['Float']['output']>;
  loadedWeightUnit?: Maybe<Scalars['String']['output']>;
  materialFlow: MaterialFlowModel;
  originCreatedAt: Scalars['DateTime']['output'];
  site: Site;
  siteMachine: SiteMachine;
  siteOperator: SiteOperator;
  siteTabletDevice: SiteTabletDevice;
  startZone: ZoneModel;
  startZoneExitTime: Scalars['DateTime']['output'];
  timeSpent: Scalars['Float']['output'];
  timeSpentUnit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TripConnection = {
  edges: Array<TripEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TripEdge = {
  cursor: Scalars['String']['output'];
  node: Trip;
};

export type TripFilterBy = {
  createdAt?: InputMaybe<DateFilterClass>;
  deviceId?: InputMaybe<IdFilterClass>;
  driverId?: InputMaybe<IdFilterClass>;
  endZoneId?: InputMaybe<IdFilterClass>;
  fleetMachineId?: InputMaybe<IdFilterClass>;
  isReturnTrip?: InputMaybe<Scalars['Boolean']['input']>;
  materialFlowId?: InputMaybe<IdFilterClass>;
  siteId?: InputMaybe<IdFilterClass>;
  siteMachineId?: InputMaybe<IdFilterClass>;
  startZoneId?: InputMaybe<IdFilterClass>;
  tripId?: InputMaybe<IdFilterClass>;
};

export type TripFilterOrderBy = {
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type TripQueries = {
  /** *Requires authentication* */
  statistics: StatisticsConnection;
  /** *Requires authentication* */
  trips: TripConnection;
};


export type TripQueries_StatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  field: TripStatisticsFields;
  filterBy?: InputMaybe<TripFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from: Scalars['DateTime']['input'];
  granularity: TripStatisticsGranularity;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  to: Scalars['DateTime']['input'];
};


export type TripQueries_TripsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<TripFilterBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TripFilterOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum TripStatisticsFields {
  TimeSpent = 'TIME_SPENT'
}

export enum TripStatisticsGranularity {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type UndoLoadPayloadInput = {
  deviceId: Scalars['ID']['input'];
  driverId: Scalars['ID']['input'];
  heading: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  loadTicketCorrelationId?: InputMaybe<Scalars['ID']['input']>;
  longitude: Scalars['Float']['input'];
  machineId: Scalars['ID']['input'];
  materialFlowId?: InputMaybe<Scalars['ID']['input']>;
  materialId: Scalars['String']['input'];
  /** Zone registered during load */
  originZoneId?: InputMaybe<Scalars['ID']['input']>;
  siteId: Scalars['ID']['input'];
  siteLoadTicketId?: InputMaybe<Scalars['ID']['input']>;
  sourceLoadTicketId?: InputMaybe<Scalars['ID']['input']>;
  weight: Scalars['Float']['input'];
  weightUnit: Scalars['String']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type UnitDescription = {
  abbr: Scalars['String']['output'];
  measure: Scalars['String']['output'];
  plural: Scalars['String']['output'];
  singular: Scalars['String']['output'];
  system: Scalars['String']['output'];
};

export type UnitQueries = {
  units: Array<SiteUnit>;
};


export type UnitQueries_UnitsArgs = {
  filter: SiteUnitFilterInput;
};

/** Types of units */
export enum UnitType {
  Currency = 'CURRENCY',
  FluidVolume = 'FLUID_VOLUME',
  Length = 'LENGTH',
  Mass = 'MASS',
  Speed = 'SPEED',
  Temperature = 'TEMPERATURE',
  Time = 'TIME',
  Volume = 'VOLUME'
}

export type UnitTypeValue = {
  type: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type UnitValue = {
  autoFormatted: Scalars['String']['output'];
  formatted: Scalars['String']['output'];
  possibleUnits: Array<Scalars['String']['output']>;
  toBest?: Maybe<UnitValue>;
  unit: Scalars['String']['output'];
  unitDescription: UnitDescription;
  value: Scalars['Float']['output'];
};


export type UnitValue_AutoFormattedArgs = {
  cutOffNumber?: InputMaybe<Scalars['Int']['input']>;
  exclude?: InputMaybe<Array<Scalars['String']['input']>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  maximumFractionDigits?: InputMaybe<Scalars['Int']['input']>;
  maximumSignificantDigits?: InputMaybe<Scalars['Int']['input']>;
  minimumFractionDigits?: InputMaybe<Scalars['Int']['input']>;
  minimumIntegerDigits?: InputMaybe<Scalars['Int']['input']>;
  minimumSignificantDigits?: InputMaybe<Scalars['Int']['input']>;
  preferredUnit?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  system?: InputMaybe<Scalars['String']['input']>;
  toBest?: InputMaybe<Scalars['Boolean']['input']>;
  unitDisplay?: InputMaybe<Scalars['String']['input']>;
};


export type UnitValue_FormattedArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  maximumFractionDigits?: InputMaybe<Scalars['Int']['input']>;
  maximumSignificantDigits?: InputMaybe<Scalars['Int']['input']>;
  minimumFractionDigits?: InputMaybe<Scalars['Int']['input']>;
  minimumIntegerDigits?: InputMaybe<Scalars['Int']['input']>;
  minimumSignificantDigits?: InputMaybe<Scalars['Int']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  unitDisplay?: InputMaybe<Scalars['String']['input']>;
};


export type UnitValue_ToBestArgs = {
  cutOffNumber?: InputMaybe<Scalars['Int']['input']>;
  exclude?: InputMaybe<Array<Scalars['String']['input']>>;
  system?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApplicationInput = {
  applicationId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAssetManagerInvitationInput = {
  deniedReason?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClientInput = {
  clientId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateFleetReportTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fleetReportTemplateProperties?: InputMaybe<Array<CreateFleetReportTemplatePropertyInput>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  reportType?: InputMaybe<Scalars['String']['input']>;
  system: Scalars['String']['input'];
};

export type UpdateMachineOwningCompanyConnectionsInput = {
  editMode: EditMode;
  machineOwningCompanyIds: Array<Scalars['String']['input']>;
};

export type UpdateMaterialFlowInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalReferences?: InputMaybe<Array<CreateOrUpdateMaterialFlowExternalReferenceInput>>;
  fromZoneId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  materialVariantId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  toZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMaterialFlowObjectiveDto = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  basisDays?: InputMaybe<Scalars['Float']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  interval?: InputMaybe<MaterialFlowObjectiveInterval>;
  materialFlowId?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MaterialFlowObjectiveStatusEnum>;
  unitType: Scalars['String']['input'];
};

export type UpdateMaterialThresholdInput = {
  materialId: Scalars['String']['input'];
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
  siteId: Scalars['String']['input'];
};

export type UpdateMaterialTransactionInput = {
  amount: Scalars['Float']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  correlationId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  eventBusName?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['String']['input'];
  modified?: InputMaybe<Scalars['Boolean']['input']>;
  modifiedAmount?: InputMaybe<Scalars['Float']['input']>;
  orderItemId?: InputMaybe<Scalars['String']['input']>;
  siteId: Scalars['String']['input'];
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  transactionType: TransactionTypeEnum;
  unitType: Scalars['String']['input'];
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOperatorChargerInput = {
  nickname?: InputMaybe<Scalars['String']['input']>;
  stationInternalId: Scalars['String']['input'];
};

export type UpdateOperatorChargerNotificationSettingInput = {
  operatorNotificationSettingProperties: Array<UpdateOperatorNotificationSettingPropertyInput>;
  stationInternalId: Scalars['String']['input'];
};

export type UpdateOperatorMachineInput = {
  nickname?: InputMaybe<Scalars['String']['input']>;
  pin17: Scalars['String']['input'];
};

export type UpdateOperatorMachineNotificationSettingInput = {
  operatorNotificationSettingProperties: Array<UpdateOperatorNotificationSettingPropertyInput>;
  pin17: Scalars['String']['input'];
};

export type UpdateOperatorNotificationSettingPropertyInput = {
  keyName: Scalars['String']['input'];
  notificationTypes: Array<OperatorNotificationSettingType>;
  operator: OperatorType;
  value: Scalars['String']['input'];
};

export type UpdateOrganizationAssetRelationshipInput = {
  /** Type of relationship between the organization and the user */
  assetRelationshipType?: InputMaybe<AssetRelationshipType>;
  /** End date of the relationship, if not set no end date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** ExternalID of the asset */
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** Source of the relationship */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Start date of the relationship, if not set it active */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateOrganizationInput = {
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  /** Type of organizations */
  organizationType?: InputMaybe<OrganizationType>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationOrganizationRelationshipInput = {
  /** End date of the relationship, if not set no end date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  /** Type of relationship between the organizations */
  organizationRelationshipType: OrganizationRelationshipType;
  /** Source of the relationship */
  source: Scalars['String']['input'];
  /** Start date of the relationship, if not set it active */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateOrganizationSiteRelationshipInput = {
  /** End date of the relationship, if not set no end date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  /** Name of the site */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Type of relationship between the organization and the site */
  siteRelationshipType?: InputMaybe<SiteRelationshipType>;
  /** Source of the relationship */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Start date of the relationship, if not set it active */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateOrganizationUserRelationshipInput = {
  /** End date of the relationship, if not set no end date */
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** ExternalID of the user */
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** Source of the relationship */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Start date of the relationship, if not set it active */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Type of relationship between the organization and the user */
  userRelationshipType?: InputMaybe<UserRelationshipType>;
};

export type UpdatePointOfInterestInput = {
  data: UpdatePointOfInterestInputData;
  id: Scalars['String']['input'];
};

export type UpdatePointOfInterestInputData = {
  coordinates?: InputMaybe<Array<Scalars['Float']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pointOfInterestTypeId?: InputMaybe<Scalars['String']['input']>;
  siteMetricDefinitionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScopeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  newScope: Scalars['String']['input'];
  scope: Scalars['String']['input'];
};

export type UpdateSettingInput = {
  defaultValue: Scalars['JSON']['input'];
  group: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  options: Scalars['JSON']['input'];
  order: Scalars['Float']['input'];
  scopes: Array<Scalars['String']['input']>;
  tab: Scalars['String']['input'];
};

export type UpdateShortcutInput = {
  category?: InputMaybe<Category>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  scopesCriteria?: InputMaybe<Array<Scalars['String']['input']>>;
  sortOrder?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  urlCriteria?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateSiteCompanyLicenseInput = {
  amount: Scalars['Int']['input'];
};

export type UpdateSiteConfigurationInput = {
  appSounds?: InputMaybe<ApplicationSounds>;
  autoLoad?: InputMaybe<Scalars['Boolean']['input']>;
  autoLoadTime?: InputMaybe<ConfigurationValueWithUnitInput>;
  autoOffload?: InputMaybe<Scalars['Boolean']['input']>;
  autoOffloadTime?: InputMaybe<ConfigurationValueWithUnitInput>;
  currencyUnitId?: InputMaybe<Scalars['String']['input']>;
  distanceUnitId?: InputMaybe<Scalars['String']['input']>;
  extendedLocation?: InputMaybe<ExtendedLocationInput>;
  fluidVolumeUnitId?: InputMaybe<Scalars['String']['input']>;
  speedUnitId?: InputMaybe<Scalars['String']['input']>;
  speedingIntervalThresholds?: InputMaybe<SpeedingIntervalThresholdInput>;
  speedingLeeway?: InputMaybe<ConfigurationValueWithUnitInput>;
  temperatureUnitId?: InputMaybe<Scalars['String']['input']>;
  volumeUnitId?: InputMaybe<Scalars['String']['input']>;
  weightUnitId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSiteInput = {
  connectionsInput?: InputMaybe<UpdateMachineOwningCompanyConnectionsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSiteMapInput = {
  baseSpeed?: InputMaybe<Scalars['Float']['input']>;
  boundary?: InputMaybe<Array<Array<Array<Scalars['Float']['input']>>>>;
  id: Scalars['String']['input'];
  mapboxStyleId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSiteMaterialFlowInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalReferences?: InputMaybe<Array<CreateOrUpdateSiteMaterialFlowExternalReferenceInput>>;
  fromId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  materialVariantId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteMetricDefinitionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MaterialFlowType>;
};

export type UpdateUserInput = {
  applicationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
  source?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UpdateZoneInput = {
  data: UpdateZoneInputData;
  id: Scalars['String']['input'];
};

export type UpdateZoneInputData = {
  chargers?: InputMaybe<Array<CreateOrUpdateZoneChargerInput>>;
  connectedChargers?: InputMaybe<Array<CreateOrUpdateZoneConnectedChargerInput>>;
  coordinates?: InputMaybe<Array<Array<Array<Scalars['Float']['input']>>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  materialVariantIds?: InputMaybe<Array<Scalars['String']['input']>>;
  maxSpeedKmh?: InputMaybe<Scalars['Int']['input']>;
  maxSpeedMs?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  narrowLane?: InputMaybe<Scalars['Boolean']['input']>;
  weighBridge?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpsertUserProfileInput = {
  values: Scalars['JSON']['input'];
};

export type User = {
  applications: Array<UserApplication>;
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  /** DCSP domain ID of a `User` */
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  machineMarkers: Markers;
  machines: MachineConnection;
  name: Scalars['String']['output'];
  organizationIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  organizations?: Maybe<OrganizationConnection>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  scopes: Array<Scalars['String']['output']>;
  siteCompanies: Array<SiteCompany>;
  siteUserSettings: SiteUserSettings;
  sites: Array<Site>;
  source?: Maybe<Scalars['String']['output']>;
  userActorRelationships: Array<UserActorRelationship>;
  userProfile: UserProfile;
  username: Scalars['String']['output'];
};


export type User_MachineMarkersArgs = {
  bounds: Bounds;
  filterBy?: InputMaybe<UserMarkerFilter>;
  zoom: Scalars['Float']['input'];
};


export type User_MachinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<UserMachineFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MachineSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type User_OrganizationIdsArgs = {
  filterByOrgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type User_OrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<UserOrganizationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isCurrentUser?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrganizationSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type User_SiteCompaniesArgs = {
  isAdmin: Scalars['Boolean']['input'];
};


export type User_SiteUserSettingsArgs = {
  siteId: Scalars['ID']['input'];
};


export type User_SitesArgs = {
  isAdmin: Scalars['Boolean']['input'];
};

export type UserActorInput = {
  actorId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserActorMutations = {
  upsertActorRelationships: User;
};


export type UserActorMutations_UpsertActorRelationshipsArgs = {
  input: UserActorUpsertInput;
};

export type UserActorRelationship = {
  actor: Actor;
  actorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UserActorUpsertInput = {
  actorIds: Array<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UserApplication = {
  applicationId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  scopes: Array<Scalars['String']['output']>;
};

export type UserApplicationsInput = {
  applications: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserEdge = {
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserEdgeV2 = {
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserFilter = {
  emailContains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserFilterInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UserFilterV2 = {
  and?: InputMaybe<Array<UserFilterV2>>;
  email?: InputMaybe<StringFilterClass>;
  id?: InputMaybe<IdFilterClass>;
  or?: InputMaybe<Array<UserFilterV2>>;
  scopes?: InputMaybe<StringArrayFilterClass>;
  username?: InputMaybe<StringFilterClass>;
};

export type UserMachineFilter = {
  and?: InputMaybe<Array<UserMachineFilter>>;
  batteryStatus?: InputMaybe<MachineBatteryStatusFilter>;
  /** Filter machines by brand */
  brand?: InputMaybe<StringFilterClass>;
  /** Filter machines by Chassis ID */
  chassisID?: InputMaybe<StringFilterClass>;
  /** Filter machines by ctChassisID */
  ctChassisID?: InputMaybe<StringFilterClass>;
  /** Filter machines by dealer Parma ID */
  dealerParmaID?: InputMaybe<StringFilterClass>;
  /** Filter machines by end customer ID */
  endCustomerId?: InputMaybe<StringFilterClass>;
  /** Filter machines by energy source */
  energySource?: InputMaybe<EnergySourceFilterClass>;
  generalDescription?: InputMaybe<MachineGeneralDescriptionFilter>;
  /** Filter machines by last update */
  globalUpdatedDateTime?: InputMaybe<DateFilterClass>;
  /** Filter machines by internal ID */
  id?: InputMaybe<StringFilterClass>;
  /** Filter machines by latest city */
  latestCity?: InputMaybe<StringFilterClass>;
  location?: InputMaybe<MachineLocationFilter>;
  machineSummaryFuel?: InputMaybe<MachineSummaryFuelFilter>;
  machineSummaryOperatingTime?: InputMaybe<MachineSummaryOperatingTimeFilter>;
  /** Filter machines by model */
  model?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<UserMachineFilter>>;
  /** Filter machines by PIN17 */
  pin17?: InputMaybe<StringFilterClass>;
  /** Filter machines by salesModel */
  salesModel?: InputMaybe<StringFilterClass>;
  /** Filter machines by dealer serialNumber */
  serialNumber?: InputMaybe<StringFilterClass>;
  /** Filter machines by subscription product id:s */
  subscriptions?: InputMaybe<StringArrayFilterClassWithOnlyHasSome>;
};

export type UserMarkerFilter = {
  and?: InputMaybe<Array<UserMarkerFilter>>;
  batteryStatus?: InputMaybe<MachineBatteryStatusFilter>;
  /** Filter machines by brand */
  brand?: InputMaybe<StringFilterClass>;
  /** Filter machines by Chassis ID */
  chassisID?: InputMaybe<StringFilterClass>;
  /** Filter machines by ctChassisID */
  ctChassisID?: InputMaybe<StringFilterClass>;
  /** Filter machines by dealer Parma ID */
  dealerParmaID?: InputMaybe<StringFilterClass>;
  /** Filter machines by end customer ID */
  endCustomerId?: InputMaybe<StringFilterClass>;
  /** Filter machines by energy source */
  energySource?: InputMaybe<EnergySourceFilterClass>;
  generalDescription?: InputMaybe<MachineGeneralDescriptionFilter>;
  /** Filter machines by last update */
  globalUpdatedDateTime?: InputMaybe<DateFilterClass>;
  /** Filter machines by internal ID */
  id?: InputMaybe<StringFilterClass>;
  /** Filter machines by latest city */
  latestCity?: InputMaybe<StringFilterClass>;
  location?: InputMaybe<MarkerMachineLocationFilter>;
  machineSummaryFuel?: InputMaybe<MachineSummaryFuelFilter>;
  machineSummaryOperatingTime?: InputMaybe<MachineSummaryOperatingTimeFilter>;
  /** Filter machines by model */
  model?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<UserMarkerFilter>>;
  /** Filter machines by PIN17 */
  pin17?: InputMaybe<StringFilterClass>;
  /** Filter machines by salesModel */
  salesModel?: InputMaybe<StringFilterClass>;
  /** Filter machines by dealer serialNumber */
  serialNumber?: InputMaybe<StringFilterClass>;
  /** Filter machines by subscription product id:s */
  subscriptions?: InputMaybe<StringArrayFilterClassWithOnlyHasSome>;
};

export type UserMutations = {
  /** @deprecated Only used for internal testing purposes */
  addUserActor: Scalars['Boolean']['output'];
  addUserApplications: User;
  addUserScopes: User;
  create: User;
  delete: Scalars['Boolean']['output'];
  deleteUserApplications: User;
  deleteUserScopes: User;
  /** @deprecated Only used for internal testing purposes */
  removeUserActor: Scalars['Boolean']['output'];
  update: User;
};


export type UserMutations_AddUserActorArgs = {
  input: UserActorInput;
};


export type UserMutations_AddUserApplicationsArgs = {
  input: UserApplicationsInput;
};


export type UserMutations_AddUserScopesArgs = {
  input: UserScopesInput;
};


export type UserMutations_CreateArgs = {
  input: CreateUserInput;
};


export type UserMutations_DeleteArgs = {
  id: Scalars['String']['input'];
};


export type UserMutations_DeleteUserApplicationsArgs = {
  input: UserApplicationsInput;
};


export type UserMutations_DeleteUserScopesArgs = {
  input: UserScopesInput;
};


export type UserMutations_RemoveUserActorArgs = {
  input: UserActorInput;
};


export type UserMutations_UpdateArgs = {
  input: UpdateUserInput;
};

export type UserOnSiteFilterInput = {
  isGuestUser?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserOrganizationFilter = {
  and?: InputMaybe<Array<UserOrganizationFilter>>;
  externalId?: InputMaybe<StringFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<UserOrganizationFilter>>;
  organizationType?: InputMaybe<StringFilterClass>;
  source?: InputMaybe<StringFilterClass>;
};

export type UserProfile = {
  createdAt: Scalars['DateTime']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  user_id: Scalars['ID']['output'];
  values: Scalars['JSON']['output'];
};

export type UserProfileMutations = {
  upsert: UserProfile;
};


export type UserProfileMutations_UpsertArgs = {
  input: UpsertUserProfileInput;
};

export type UserProfileQueries = {
  userprofile: UserProfile;
};

export type UserQueries = {
  /** Get current user based from token */
  currentUser?: Maybe<User>;
  user?: Maybe<User>;
  users: UsersConnection;
  usersByFilter: UsersConnectionV2;
};


export type UserQueries_UserArgs = {
  id: Scalars['ID']['input'];
};


export type UserQueries_UsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type UserQueries_UsersByFilterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<UserFilterV2>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserSortV2>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Type of relationship between org and user */
export enum UserRelationshipType {
  Dealer = 'Dealer',
  EndCustomer = 'EndCustomer',
  Operator = 'Operator'
}

export type UserScopesInput = {
  scopes: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserSort = {
  id?: InputMaybe<SortDirection>;
  username?: InputMaybe<SortDirection>;
};

export type UserSortV2 = {
  id?: InputMaybe<SortDirection>;
  username?: InputMaybe<SortDirection>;
};

export type UsernamePassword = {
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type UsersConnection = {
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type UsersConnectionV2 = {
  edges: Array<UserEdgeV2>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type UtilizationHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** [ARI] Accumulated total time in seconds the machine has been in excessive idle (over 5 minutes). TGW3 only. */
  totalTimeInExcessiveIdle?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Accumulated total time in seconds the machine has been in idle mode. W-ECU and TGW2.1 only. */
  totalTimeInIdle?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Accumulated total time in seconds the machine has been in wait idle mode. TGW3 only. */
  totalTimeInWaitIdle?: Maybe<Scalars['Int']['output']>;
  /** [ARI] Accumulated total time in seconds the machine has been in work mode. */
  totalTimeInWork?: Maybe<Scalars['Int']['output']>;
};

export type UtilizationQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
  /** The data points for utilization events from ARI to evaluate efficiency. */
  utilization: Array<UtilizationHistoryEvent>;
};

export type VeiScaleQueries = {
  /** *Requires authentication* Replays scale events, optionally from a specific timestamp */
  replay: Scalars['Int']['output'];
};


export type VeiScaleQueries_ReplayArgs = {
  fromTimestamp?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VariantData = {
  /** [VDA] Family ID for Variant. */
  familyId?: Maybe<Scalars['String']['output']>;
  /** [VDA] Identifier for Variant option. */
  variantId?: Maybe<Scalars['String']['output']>;
};

export enum VehicleMode {
  Accessory = 'Accessory',
  Cranking = 'Cranking',
  Hibernate = 'Hibernate',
  Living = 'Living',
  Parked = 'Parked',
  PreRunning = 'PreRunning',
  Running = 'Running'
}

export type VehicleModeHistoryEvent = HistoricalData & {
  chassisId: Scalars['String']['output'];
  /** Timestamp when the event was registered in the service. */
  createdAt: Scalars['DateTime']['output'];
  dataType: MachineHistoricalDataType;
  /** Timestamp when the event happened on the machine. */
  eventTimestamp: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** [ARI] The vehicle mode of the machine at the time of the event. TGW only. */
  vehicleMode: VehicleMode;
};

export type VehicleModeQueryResponse = {
  /** Number of total results of the query. Has a performance cost to get, so avoid requesting this field when paging through the results. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Cursor to be used to request the next page of results. */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** If there are more results to be fetched. Use `endCursor` as the `cursor` on the next request to get the next page. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** Number of results in a page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** Cursor the response starts at. The same as the input cursor if used. */
  startCursor?: Maybe<Scalars['ID']['output']>;
  /** An event that describes the vehicle mode. It is derived from ARI Machine Summary data and ARI Electric Machine Battery Status data. */
  vehicleMode: Array<VehicleModeHistoryEvent>;
};

export type WeighBridgeMutations = {
  /** *Requires authentication* */
  update: FleetAssetWeighBridge;
};


export type WeighBridgeMutations_UpdateArgs = {
  id: Scalars['ID']['input'];
  input: WeighBridgeUpdateInput;
};

export type WeighBridgeUpdateInput = {
  activatedForDataImport?: InputMaybe<Scalars['Boolean']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkflowEvent = {
  created: Scalars['DateTime']['output'];
  eventData: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: EventType;
};

export type WorkflowExecution = {
  createdAt: Scalars['DateTime']['output'];
  events: Array<WorkflowEvent>;
  executionContext: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nodes: Array<Node>;
  status: WorkflowStatus;
  workflowTemplate?: Maybe<WorkflowTemplate>;
};

export type WorkflowExecutionConnection = {
  edges: Array<WorkflowExecutionEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type WorkflowExecutionEdge = {
  cursor: Scalars['String']['output'];
  node: WorkflowExecution;
};

export type WorkflowExecutionFilter = {
  and?: InputMaybe<Array<WorkflowExecutionFilter>>;
  createdAt?: InputMaybe<DateFilterClass>;
  id?: InputMaybe<StringFilterClass>;
  name?: InputMaybe<StringFilterClass>;
  or?: InputMaybe<Array<WorkflowExecutionFilter>>;
  subscriptionId?: InputMaybe<StringFilterClass>;
};

export type WorkflowExecutionMutations = {
  completeManualNode: Scalars['Boolean']['output'];
  createWorkflowExecution: WorkflowExecution;
  createWorkflowExecutionByProduct: WorkflowExecution;
  rollbackWorkflowExecution: Scalars['Boolean']['output'];
};


export type WorkflowExecutionMutations_CompleteManualNodeArgs = {
  context: Scalars['JSON']['input'];
  nodeId: Scalars['String']['input'];
  workflowExecutionId: Scalars['String']['input'];
};


export type WorkflowExecutionMutations_CreateWorkflowExecutionArgs = {
  context: Scalars['JSON']['input'];
  createWorkflowInput: CreateWorkflowExecutionInput;
};


export type WorkflowExecutionMutations_CreateWorkflowExecutionByProductArgs = {
  createWorkflowInput: CreateWorkflowExecution;
};


export type WorkflowExecutionMutations_RollbackWorkflowExecutionArgs = {
  targetNodeId?: InputMaybe<Scalars['String']['input']>;
  workflowExecutionId: Scalars['String']['input'];
};

export type WorkflowExecutionQueries = {
  workflowExecution: WorkflowExecution;
  workflowExecutionConnection: WorkflowExecutionConnection;
  workflowExecutionEvents: Array<WorkflowEvent>;
};


export type WorkflowExecutionQueries_WorkflowExecutionArgs = {
  id: Scalars['String']['input'];
};


export type WorkflowExecutionQueries_WorkflowExecutionConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<WorkflowExecutionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkflowExecutionSort>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type WorkflowExecutionQueries_WorkflowExecutionEventsArgs = {
  workflowExecutionId: Scalars['String']['input'];
};

export type WorkflowExecutionSort = {
  /** Defaults to DESC */
  createdAt?: InputMaybe<SortDirection>;
  /** Defaults to ASC */
  id?: InputMaybe<SortDirection>;
};

/** This status applies to all aspects related to workflows. */
export enum WorkflowStatus {
  Cancelled = 'CANCELLED',
  Error = 'ERROR',
  Expired = 'EXPIRED',
  Finished = 'FINISHED',
  FinishedEarly = 'FINISHED_EARLY',
  New = 'NEW',
  Pending = 'PENDING',
  Triggered = 'TRIGGERED'
}

export type WorkflowTemplate = {
  deadline?: Maybe<Scalars['Int']['output']>;
  defaultContext: Scalars['JSON']['output'];
  description: Scalars['String']['output'];
  forProducts: Array<Scalars['String']['output']>;
  from: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nodes: Array<NodeTemplate>;
  triggers: Array<Scalars['String']['output']>;
};

export type WorkflowTemplateQueries = {
  plugins: Array<Plugin>;
  supportedTemplate: Array<SupportedTemplate>;
  workflowTemplate: WorkflowTemplate;
  workflowTemplates: Array<WorkflowTemplate>;
};


export type WorkflowTemplateQueries_WorkflowTemplateArgs = {
  id: Scalars['String']['input'];
};

export type ZoneChargerModel = {
  description?: Maybe<Scalars['String']['output']>;
  emobAssetId?: Maybe<Scalars['String']['output']>;
  emobAssetTypeId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  slots: Array<ChargerConnectorTypes>;
};

export type ZoneConnectedChargerModel = {
  charger: ChargingStation;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  stationInternalId: Scalars['String']['output'];
};

export type ZoneMaterialBalanceFindByZoneIdsOptions = {
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  onlyCurrentBalance?: InputMaybe<Scalars['Boolean']['input']>;
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ZoneMaterialBalanceModel = {
  amount: Scalars['Float']['output'];
  correlationId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  zoneMaterialVariant: ZoneMaterialVariantModel;
};

export type ZoneMaterialBalanceQueries = {
  byZoneId: Array<ZoneMaterialBalanceModel>;
};


export type ZoneMaterialBalanceQueries_ByZoneIdArgs = {
  options?: InputMaybe<ZoneMaterialBalanceFindByZoneIdsOptions>;
  zoneId: Scalars['ID']['input'];
};

export type ZoneMaterialVariantModel = {
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  materialVariant: MaterialVariant;
  materialVariantId: Scalars['String']['output'];
};

export type ZoneModel = {
  chargers: Array<ZoneChargerModel>;
  connectedChargers: Array<ZoneConnectedChargerModel>;
  coordinates: Array<Array<Array<Scalars['Float']['output']>>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  machineTypePermissions: Array<MachineTypePermissionModel>;
  materialBalances: Array<ZoneMaterialBalanceModel>;
  /** @deprecated Use zoneMaterialVariants instead */
  materialVariants: Array<ZoneMaterialVariantModel>;
  maxSpeedKmh?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Replaced by maxSpeedKmh (value is in kmh and not ms) */
  maxSpeedMs?: Maybe<Scalars['Int']['output']>;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  narrowLane?: Maybe<Scalars['Boolean']['output']>;
  weighBridge?: Maybe<Scalars['Boolean']['output']>;
  zoneMaterialVariants: Array<ZoneMaterialVariantModel>;
  zoneType: ZoneTypeModel;
};


export type ZoneModel_MaterialBalancesArgs = {
  options?: InputMaybe<ZoneMaterialBalanceFindByZoneIdsOptions>;
};


export type ZoneModel_MaterialVariantsArgs = {
  options?: InputMaybe<GetZoneMaterialVariantsByZoneIdOptions>;
};


export type ZoneModel_ZoneMaterialVariantsArgs = {
  options?: InputMaybe<GetZoneMaterialVariantsByZoneIdOptions>;
};


export type ZoneModel_ZoneTypeArgs = {
  machineTypeId?: InputMaybe<Scalars['String']['input']>;
};

export type ZoneMutations = {
  create: ZoneModel;
  delete: ZoneModel;
  update: ZoneModel;
};


export type ZoneMutations_CreateArgs = {
  input: CreateZoneInput;
};


export type ZoneMutations_DeleteArgs = {
  id: Scalars['ID']['input'];
};


export type ZoneMutations_UpdateArgs = {
  input: UpdateZoneInput;
};

export type ZonePermissionTypeModel = {
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type ZonePermissionTypeQueries = {
  all: Array<ZonePermissionTypeModel>;
  byId: ZonePermissionTypeModel;
};


export type ZonePermissionTypeQueries_ByIdArgs = {
  id: Scalars['ID']['input'];
};

export type ZoneQueries = {
  bySiteMapId: Array<ZoneModel>;
};


export type ZoneQueries_BySiteMapIdArgs = {
  options?: InputMaybe<GetZonesBySiteMapIdOptions>;
  siteMapId: Scalars['ID']['input'];
};

export type ZoneTypeModel = {
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: ZoneTypes;
};

export type ZoneTypeQueries = {
  all: Array<ZoneTypeModel>;
  byId: ZoneTypeModel;
};


export type ZoneTypeQueries_ByIdArgs = {
  id: Scalars['ID']['input'];
};

export enum ZoneTypes {
  LoadingZone = 'LOADING_ZONE',
  MaterialZone = 'MATERIAL_ZONE',
  OffLoadingZone = 'OFF_LOADING_ZONE',
  OnAndOffLoadingZone = 'ON_AND_OFF_LOADING_ZONE',
  ParkingZone = 'PARKING_ZONE',
  RestrictedZone = 'RESTRICTED_ZONE',
  SiteBoundary = 'SITE_BOUNDARY',
  SpeedZone = 'SPEED_ZONE',
  TrafficZone = 'TRAFFIC_ZONE'
}

export type QueryZoneEventsVariables = Exact<{
  siteIdSLT: Scalars['ID']['input'];
  siteIdDE: Scalars['String']['input'];
  zoneId: Scalars['String']['input'];
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  filterBy?: InputMaybe<SiteDeviceZoneEventFilterInput>;
  orderBySLT?: InputMaybe<SiteLoadTicketsOrderBy>;
  orderByDE: SiteDeviceEventSort;
  cursorSLT?: InputMaybe<Scalars['String']['input']>;
  cursorDE?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  system: Scalars['String']['input'];
}>;


export type QueryZoneEvents = { siteLoadTickets: { siteLoadTickets: { totalCount?: number | null, pageInfo: { endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ node: { id: string, materialVariantId?: string | null, fromZoneId?: string | null, toZoneId?: string | null, flowId?: string | null, createdAt: any, weight?: { value: number, unit: string, toBest?: { formatted: string } | null } | null, sourceTicket: { sourceService: string }, transporter?: { siteMachine?: { id: string, name: string } | null } | null, loader?: { siteMachine?: { id: string, name: string } | null } | null } }> } }, siteDeviceEventQueries: { siteDeviceZoneEvents: { pageInfo: { endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ cursor: string, node: { id: string, type: SiteDeviceEventType, status: SiteDeviceEventStatus, statusMessage: string, source: string, createdAt: any, createdAtUnix: number, payload: { materialFlowId?: string | null, driverId: string, machineId: string, materialId?: string | null, weight?: number | null, weightUnit?: string | null, zoneId?: string | null, latitude: number, longitude: number, heading: number, speedLimit?: number | null, currentSpeed?: number | null, speedUnit?: string | null } } }> } } };

export type QueryFlowEventsVariables = Exact<{
  siteId: Scalars['ID']['input'];
  flowId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  filterBy?: InputMaybe<SiteDeviceEventFilterInput>;
  orderBySLT?: InputMaybe<SiteLoadTicketsOrderBy>;
  orderByDE: SiteDeviceEventSort;
  cursorSLT?: InputMaybe<Scalars['String']['input']>;
  cursorDE?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  system: Scalars['String']['input'];
}>;


export type QueryFlowEvents = { siteLoadTickets: { siteLoadTickets: { totalCount?: number | null, pageInfo: { endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ cursor: string, node: { id: string, materialVariantId?: string | null, fromZoneId?: string | null, toZoneId?: string | null, flowId?: string | null, createdAt: any, weight?: { value: number, unit: string, toBest?: { formatted: string } | null } | null, sourceTicket: { sourceService: string }, transporter?: { siteMachine?: { id: string, name: string } | null } | null, loader?: { siteMachine?: { id: string, name: string } | null } | null } }> } }, siteDeviceEventQueries: { siteDeviceEvents: { pageInfo: { endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ cursor: string, node: { id: string, type: SiteDeviceEventType, status: SiteDeviceEventStatus, statusMessage: string, source: string, createdAt: any, createdAtUnix: number, payload: { materialFlowId?: string | null, driverId: string, machineId: string, materialId?: string | null, weight?: number | null, weightUnit?: string | null, zoneId?: string | null, latitude: number, longitude: number, heading: number, speedLimit?: number | null, currentSpeed?: number | null, speedUnit?: string | null } } }> } } };

export type FragmentMachineTypePermission = { id: string, machineTypeId: string, zonePermissionType: { id: string, type: string, description: string } };

export type FragmentZonePermissionType = { id: string, type: string, description: string };

export type QueryMachineTypePermissionsVariables = Exact<{
  zoneId: Scalars['ID']['input'];
}>;


export type QueryMachineTypePermissions = { machineTypePermissions: { byZoneId: Array<{ id: string, machineTypeId: string, zonePermissionType: { id: string, type: string, description: string } }> } };

export type QueryZonePermissionTypesVariables = Exact<{ [key: string]: never; }>;


export type QueryZonePermissionTypes = { zonePermissionTypes: { all: Array<{ id: string, type: string, description: string }> } };

export type FragmentExternalMaterialMapping = { id: string, externalName: string, source: MaterialMappingExternalService, materialVariant?: { id: string, name: string } | null };

export type ExternalMaterialMappingsVariables = Exact<{
  companyId: Scalars['ID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExternalMaterialMappings = { siteCompanies: { company?: { id: string, externalMaterialMappings: { pageInfo: { endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ node: { id: string, externalName: string, source: MaterialMappingExternalService, materialVariant?: { id: string, name: string } | null } }> } } | null } };

export type SiteMaterialVariantsVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type SiteMaterialVariants = { siteCompanies: { company?: { id: string, materialFamilies: Array<{ id: string, name: string, materialTypes: Array<{ id: string, name: string, materialVariants: Array<{ id: string, name: string }> }> }> } | null } };

export type UpdateExternalMaterialMappingVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ExternalMaterialUpdateInput;
}>;


export type UpdateExternalMaterialMapping = { externalMaterialMappings: { update: { id: string } } };

export type FragmentUnassignedLoadTicketGroup = { count: number, scaleType?: string | null, source: string, sourceTicketFlowId?: string | null, lastTicket: any };

export type QueryUnassignedSiteLoadTicketsVariables = Exact<{
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
  filterBy?: InputMaybe<SiteLoadTicketsFilterBy>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SiteLoadTicketsGroupByFlowIdOrderBy>;
}>;


export type QueryUnassignedSiteLoadTickets = { siteLoadTickets: { groupBySourceFlowId: { hasNextPage: boolean, groups: Array<{ count: number, scaleType?: string | null, source: string, sourceTicketFlowId?: string | null, lastTicket: any }> } } };

export type FragmentMaterialVariantProcess = { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } };

export type FragmentPointOfInterestForSiteMaterialFlow = { id: string, name: string, status: PointOfInterestStatus, description: string, siteMetricDefinitions: Array<{ id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, materialVariant?: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } | null, currentSiteMetricData?: { id: string, sourceService: SiteMetricDataSourceService, statusIndicator?: SiteMetricDataStatusIndicator | null, value: string, unit: string, originCreatedAt: any } | null }>, pointOfInterestType: { id: string, type: string } };

export type FragmentSiteMaterialFlowWithMetricData = { id: string, name: string, type: MaterialFlowType, toId: string, fromId: string, siteMetricDefinitions: Array<{ id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, currentSiteMetricData?: { id: string, sourceService: SiteMetricDataSourceService, statusIndicator?: SiteMetricDataStatusIndicator | null, value: string, unit: string, originCreatedAt: any } | null, materialVariant?: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } | null }>, materialVariant?: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } | null };

export type FragmentSiteMetricDefinitionData = { id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, currentSiteMetricData?: { id: string, sourceService: SiteMetricDataSourceService, statusIndicator?: SiteMetricDataStatusIndicator | null, value: string, unit: string, originCreatedAt: any } | null, materialVariant?: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } | null };

export type QuerySiteMaterialFlowsWithMetricDataVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type QuerySiteMaterialFlowsWithMetricData = { siteMaps: { site?: { id: string, siteMaterialFlows: { edges: Array<{ node: { id: string, name: string, type: MaterialFlowType, toId: string, fromId: string, siteMetricDefinitions: Array<{ id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, currentSiteMetricData?: { id: string, sourceService: SiteMetricDataSourceService, statusIndicator?: SiteMetricDataStatusIndicator | null, value: string, unit: string, originCreatedAt: any } | null, materialVariant?: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } | null }>, materialVariant?: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } | null } }> }, pointsOfInterest: Array<{ id: string, name: string, status: PointOfInterestStatus, description: string, siteMetricDefinitions: Array<{ id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, materialVariant?: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } | null, currentSiteMetricData?: { id: string, sourceService: SiteMetricDataSourceService, statusIndicator?: SiteMetricDataStatusIndicator | null, value: string, unit: string, originCreatedAt: any } | null }>, pointOfInterestType: { id: string, type: string } }> } | null } };

export type FragmentGeographyPhotos = { id: string, isActive: boolean, name?: string | null, createdAt: any };

export type FragmentMachine = { id: string, shortId: string, name: string, description?: string | null, createdAt: any, updatedAt: any, isDeprecated: boolean, fleetMachine?: { id: string, serialNumber?: string | null, snapshotEquipment?: { id: string, make?: string | null, model?: string | null } | null } | null, machineType: { id: string, machineType: string } };

export type FragmentMachineType = { id: string, machineType: string };

export type FragmentMaterialBalance = { id: string, amount: number, createdAt: any, zoneMaterialVariant: { id: string, materialVariantId: string } };

export type FragmentMaterialFamily = { id: string, name: string, description: string };

export type FragmentMaterialFamilyWithMaterialTypes = { id: string, name: string, description: string, materialTypes: Array<{ id: string, name: string, description: string, materialVariants: Array<{ id: string, name: string, description: string, isDeleted: boolean, materialType: { id: string, name: string, description: string, materialFamily: { id: string, name: string, description: string } } }> }> };

export type FragmentMaterialFlows = { id: string, type: MaterialFlowType, toId: string, fromId: string, materialVariantId?: string | null, name: string, description?: string | null, hasDynamicMaterial: boolean, siteMetricDefinitions: Array<{ id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, materialVariant?: { id: string } | null, currentSiteMetricData?: { id: string, value: string, metricType?: string | null } | null }>, assignedMachines: Array<{ id: string, name: string, machineType: { id: string, machineType: string } }>, externalReferences?: Array<{ id: string, value: string, type: ExternalReferenceTypes }> | null };

export type FragmentAssignedMachines = { id: string, name: string, machineType: { id: string, machineType: string } };

export type FragmentAssignedMachinesMachineType = { id: string, machineType: string };

export type FragmentMaterialTypeWithMaterialVariants = { id: string, name: string, description: string, materialVariants: Array<{ id: string, name: string, description: string, isDeleted: boolean, materialType: { id: string, name: string, description: string, materialFamily: { id: string, name: string, description: string } } }> };

export type FragmentMaterialTypeWithMaterialFamily = { id: string, name: string, description: string, materialFamily: { id: string, name: string, description: string } };

export type FragmentMaterialVariant = { id: string, name: string, description: string, isDeleted: boolean };

export type FragmentMaterialVariantWithMaterialType = { id: string, name: string, description: string, isDeleted: boolean, materialType: { id: string, name: string, description: string, materialFamily: { id: string, name: string, description: string } } };

export type FragmentObjectiveSummary = { amount: number, date: string };

export type FragmentPointsOfInterest = { id: string, name: string, description: string, coordinates: Array<number>, status: PointOfInterestStatus, pointOfInterestType: { id: string, type: string, description: string }, siteMetricDefinitions: Array<{ id: string, name?: string | null }> };

export type FragmentPointOfInterestType = { id: string, type: string, description: string };

export type FragmentSite = { id: string, name: string, company: { id: string }, allowedMachineTypes: Array<{ id: string, machineType: string }> };

export type FragmentSiteConfiguration = { id: string, speedUnit: { id: string, formatId: string, type: UnitType, name: string }, weightUnit: { id: string, formatId: string, type: UnitType, name: string }, volumeUnit: { id: string, formatId: string, type: UnitType, name: string }, distanceUnit: { id: string, formatId: string, type: UnitType, name: string }, fluidVolumeUnit: { id: string, formatId: string, type: UnitType, name: string } };

export type FragmentSiteDeviceEvents = { node: { id: string, type: SiteDeviceEventType, status: SiteDeviceEventStatus, statusMessage: string, source: string, createdAt: any, createdAtUnix: number, payload: { materialFlowId?: string | null, driverId: string, machineId: string, materialId?: string | null, weight?: number | null, weightUnit?: string | null, zoneId?: string | null, latitude: number, longitude: number, heading: number, speedLimit?: number | null, currentSpeed?: number | null, speedUnit?: string | null } } };

export type FragmentSiteDeviceEvent = { id: string, type: SiteDeviceEventType, status: SiteDeviceEventStatus, statusMessage: string, source: string, createdAt: any, createdAtUnix: number, payload: { materialFlowId?: string | null, driverId: string, machineId: string, materialId?: string | null, weight?: number | null, weightUnit?: string | null, zoneId?: string | null, latitude: number, longitude: number, heading: number, speedLimit?: number | null, currentSpeed?: number | null, speedUnit?: string | null } };

export type FragmentSiteDevicePayload = { materialFlowId?: string | null, driverId: string, machineId: string, materialId?: string | null, weight?: number | null, weightUnit?: string | null, zoneId?: string | null, latitude: number, longitude: number, heading: number, speedLimit?: number | null, currentSpeed?: number | null, speedUnit?: string | null };

export type FragmentSiteLoadTicket = { id: string, materialVariantId?: string | null, fromZoneId?: string | null, toZoneId?: string | null, flowId?: string | null, createdAt: any, weight?: { value: number, unit: string, toBest?: { formatted: string } | null } | null, sourceTicket: { sourceService: string }, transporter?: { siteMachine?: { id: string, name: string } | null } | null, loader?: { siteMachine?: { id: string, name: string } | null } | null };

export type FragmentSiteMap = { id: string, siteId: string, boundary: Array<Array<Array<number>>>, mapboxStyleId?: string | null, baseSpeed?: number | null, createdAt: any, modifiedAt: any };

export type FragmentSiteMetricDefinition = { id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, deprecated: boolean, materialFlow?: { id: string } | null };

export type FragmentTrip = { id: string, isReturnTrip: boolean, loadedWeight?: number | null, loadedWeightUnit?: string | null, timeSpent: number, timeSpentUnit: string, startZone: { coordinates: Array<Array<Array<number>>>, id: string, name: string }, endZone: { coordinates: Array<Array<Array<number>>>, id: string, name: string } };

export type FragmentUnit = { id: string, formatId: string, type: UnitType, name: string };

export type FragmentZone = { id: string, name: string, coordinates: Array<Array<Array<number>>>, description: string, maxSpeedMs?: number | null, narrowLane?: boolean | null, weighBridge?: boolean | null, zoneType: { id: string, type: ZoneTypes, description: string }, zoneMaterialVariants: Array<{ id: string, materialVariantId: string, materialVariant: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } }>, chargers: Array<{ id: string, slots: Array<ChargerConnectorTypes>, description?: string | null, emobAssetId?: string | null }>, materialBalances: Array<{ id: string, amount: number, createdAt: any, zoneMaterialVariant: { id: string, materialVariantId: string } }> };

export type FragmentZoneChargers = { id: string, slots: Array<ChargerConnectorTypes>, description?: string | null, emobAssetId?: string | null };

export type FragmentZoneMaterialVariant = { id: string, materialVariantId: string, materialVariant: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } };

export type FragmentZoneType = { id: string, type: ZoneTypes, description: string };

export type MutateAdjustTransactionVariables = Exact<{
  siteId: Scalars['String']['input'];
  materialId: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  timestamp: Scalars['DateTime']['input'];
  zoneId?: InputMaybe<Scalars['String']['input']>;
}>;


export type MutateAdjustTransaction = { materialTransaction: { adjustTransaction: { id: string } } };

export type MutateGeographyPhotosSetActiveVariables = Exact<{
  input: SetActiveGeographyPhotosInput;
}>;


export type MutateGeographyPhotosSetActive = { geographyPhotos: { setActive: { mapboxStyleId: string } } };

export type MutateGeographyPhotosDeleteVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type MutateGeographyPhotosDelete = { geographyPhotos: { delete: { id: string } } };

export type MutateMachineTypePermissionsCreateVariables = Exact<{
  input: CreateMachineTypePermissionInput;
}>;


export type MutateMachineTypePermissionsCreate = { machineTypePermissions: { create: { id: string } } };

export type MutateMachineTypePermissionsUpdateForZoneVariables = Exact<{
  zoneId: Scalars['ID']['input'];
  inputs: Array<CreateMachineTypePermissionInput> | CreateMachineTypePermissionInput;
}>;


export type MutateMachineTypePermissionsUpdateForZone = { machineTypePermissions: { updateForZone: Array<{ id: string }> } };

export type MutateMachineTypePermissionDeleteVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MutateMachineTypePermissionDelete = { machineTypePermissions: { delete: { id: string } } };

export type MutateMaterialFlowsCreateVariables = Exact<{
  input: CreateSiteMaterialFlowInput;
}>;


export type MutateMaterialFlowsCreate = { siteMaterialFlows: { create: { id: string, type: MaterialFlowType, toId: string, fromId: string, materialVariantId?: string | null, name: string, description?: string | null, hasDynamicMaterial: boolean, siteMetricDefinitions: Array<{ id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, materialVariant?: { id: string } | null, currentSiteMetricData?: { id: string, value: string, metricType?: string | null } | null }>, assignedMachines: Array<{ id: string, name: string, machineType: { id: string, machineType: string } }>, externalReferences?: Array<{ id: string, value: string, type: ExternalReferenceTypes }> | null } } };

export type MutateMaterialFlowsUpdateVariables = Exact<{
  input: UpdateSiteMaterialFlowInput;
}>;


export type MutateMaterialFlowsUpdate = { siteMaterialFlows: { update: { id: string, type: MaterialFlowType, toId: string, fromId: string, materialVariantId?: string | null, name: string, description?: string | null, hasDynamicMaterial: boolean, siteMetricDefinitions: Array<{ id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, materialVariant?: { id: string } | null, currentSiteMetricData?: { id: string, value: string, metricType?: string | null } | null }>, assignedMachines: Array<{ id: string, name: string, machineType: { id: string, machineType: string } }>, externalReferences?: Array<{ id: string, value: string, type: ExternalReferenceTypes }> | null } } };

export type MutateMaterialFlowsDeleteVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type MutateMaterialFlowsDelete = { siteMaterialFlows: { delete: { id: string } } };

export type MutatePointOfInterestCreateVariables = Exact<{
  input: CreatePointOfInterestInput;
}>;


export type MutatePointOfInterestCreate = { pointsOfInterest: { create: { id: string, name: string, description: string, coordinates: Array<number>, status: PointOfInterestStatus, pointOfInterestType: { id: string, type: string, description: string }, siteMetricDefinitions: Array<{ id: string, name?: string | null }> } } };

export type MutatePointOfInterestUpdateVariables = Exact<{
  input: UpdatePointOfInterestInput;
}>;


export type MutatePointOfInterestUpdate = { pointsOfInterest: { update: { id: string, name: string, description: string, coordinates: Array<number>, status: PointOfInterestStatus, pointOfInterestType: { id: string, type: string, description: string }, siteMetricDefinitions: Array<{ id: string, name?: string | null }> } } };

export type MutatePointOfInterestDeleteVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MutatePointOfInterestDelete = { pointsOfInterest: { delete: { id: string } } };

export type MutateSiteMapsCreateVariables = Exact<{
  input: CreateSiteMapInput;
}>;


export type MutateSiteMapsCreate = { siteMaps: { create: { id: string, siteId: string, boundary: Array<Array<Array<number>>>, createdAt: any, modifiedAt: any } } };

export type MutateSiteMapsUpdateVariables = Exact<{
  input: UpdateSiteMapInput;
}>;


export type MutateSiteMapsUpdate = { siteMaps: { update: { id: string, siteId: string, boundary: Array<Array<Array<number>>>, baseSpeed?: number | null, createdAt: any, modifiedAt: any } } };

export type MutateZoneCreateVariables = Exact<{
  input: CreateZoneInput;
}>;


export type MutateZoneCreate = { zones: { create: { id: string, name: string, coordinates: Array<Array<Array<number>>>, description: string, maxSpeedMs?: number | null, narrowLane?: boolean | null, weighBridge?: boolean | null, zoneType: { id: string, type: ZoneTypes, description: string }, zoneMaterialVariants: Array<{ id: string, materialVariantId: string, materialVariant: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } }>, chargers: Array<{ id: string, slots: Array<ChargerConnectorTypes>, description?: string | null, emobAssetId?: string | null }>, materialBalances: Array<{ id: string, amount: number, createdAt: any, zoneMaterialVariant: { id: string, materialVariantId: string } }> } } };

export type MutateZoneUpdateVariables = Exact<{
  input: UpdateZoneInput;
}>;


export type MutateZoneUpdate = { zones: { update: { id: string, name: string, coordinates: Array<Array<Array<number>>>, description: string, maxSpeedMs?: number | null, narrowLane?: boolean | null, weighBridge?: boolean | null, zoneType: { id: string, type: ZoneTypes, description: string }, zoneMaterialVariants: Array<{ id: string, materialVariantId: string, materialVariant: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } }>, chargers: Array<{ id: string, slots: Array<ChargerConnectorTypes>, description?: string | null, emobAssetId?: string | null }>, materialBalances: Array<{ id: string, amount: number, createdAt: any, zoneMaterialVariant: { id: string, materialVariantId: string } }> } } };

export type MutateZoneDeleteVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MutateZoneDelete = { zones: { delete: { id: string } } };

export type QueryFlowObjectivesVariables = Exact<{
  siteId: Scalars['String']['input'];
  materialFlowId: Scalars['String']['input'];
  timeRangeInput: TimeRangeInput;
  resolution: DailyScaleTimeUnit;
  timeZone?: InputMaybe<Scalars['String']['input']>;
}>;


export type QueryFlowObjectives = { materialFlowObjective: { analytics: { objectiveSummery: Array<{ amount: number, date: string }> } } };

export type QueryMaterialFlowsBySiteMapIdVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type QueryMaterialFlowsBySiteMapId = { siteMaps: { site?: { id: string, siteMaterialFlows: { edges: Array<{ node: { id: string, type: MaterialFlowType, toId: string, fromId: string, materialVariantId?: string | null, name: string, description?: string | null, hasDynamicMaterial: boolean, siteMetricDefinitions: Array<{ id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, materialVariant?: { id: string } | null, currentSiteMetricData?: { id: string, value: string, metricType?: string | null } | null }>, assignedMachines: Array<{ id: string, name: string, machineType: { id: string, machineType: string } }>, externalReferences?: Array<{ id: string, value: string, type: ExternalReferenceTypes }> | null } }> } } | null } };

export type QueryImageUploadsBySiteIdVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type QueryImageUploadsBySiteId = { siteMaps: { site?: { id: string, mapboxStyleId?: string | null, geographyPhotos: Array<{ id: string, isActive: boolean, name?: string | null, createdAt: any }> } | null } };

export type QueryMachineVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryMachine = { siteMachines: { machine?: { id: string, shortId: string, name: string, description?: string | null, createdAt: any, updatedAt: any, isDeprecated: boolean, fleetMachine?: { id: string, serialNumber?: string | null, snapshotEquipment?: { id: string, make?: string | null, model?: string | null } | null } | null, machineType: { id: string, machineType: string } } | null } };

export type QueryMachineTypesVariables = Exact<{ [key: string]: never; }>;


export type QueryMachineTypes = { siteMachineTypes: { machineTypes?: Array<{ id: string, machineType: string }> | null } };

export type QueryMachinesBySiteIdVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type QueryMachinesBySiteId = { siteMachines: { machinesBySiteId: Array<{ id: string, shortId: string, name: string, description?: string | null, createdAt: any, updatedAt: any, isDeprecated: boolean, fleetMachine?: { id: string, serialNumber?: string | null, snapshotEquipment?: { id: string, make?: string | null, model?: string | null } | null } | null, machineType: { id: string, machineType: string } }> } };

export type QueryMaterialBalanceByZoneIdVariables = Exact<{
  zoneId: Scalars['ID']['input'];
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type QueryMaterialBalanceByZoneId = { zoneMaterialBalance: { byZoneId: Array<{ id: string, amount: number, createdAt: any, zoneMaterialVariant: { id: string, materialVariantId: string } }> } };

export type QueryMaterialFamiliesBySiteIdVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type QueryMaterialFamiliesBySiteId = { materialFamilies: { materialFamiliesBySiteId: Array<{ id: string, name: string, description: string, materialTypes: Array<{ id: string, name: string, description: string, materialVariants: Array<{ id: string, name: string, description: string, isDeleted: boolean, materialType: { id: string, name: string, description: string, materialFamily: { id: string, name: string, description: string } } }> }> }> } };

export type QueryMaterialVariantsByIdVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type QueryMaterialVariantsById = { materialVariants: { materialVariant?: { id: string, name: string, description: string, isDeleted: boolean, materialType: { id: string, name: string, description: string, materialFamily: { id: string, name: string, description: string } } } | null } };

export type QueryZoneMaterialVariantsBySiteMapIdVariables = Exact<{
  siteMapId: Scalars['ID']['input'];
}>;


export type QueryZoneMaterialVariantsBySiteMapId = { zones: { bySiteMapId: Array<{ id: string, zoneMaterialVariants: Array<{ id: string, materialVariantId: string, materialVariant: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } }> }> } };

export type QueryPointOfInterestTypesVariables = Exact<{ [key: string]: never; }>;


export type QueryPointOfInterestTypes = { pointOfInterestTypes: { all: Array<{ id: string, type: string, description: string }> } };

export type QuerySiteBySiteIdVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type QuerySiteBySiteId = { sites: { site?: { id: string, name: string, company: { id: string }, allowedMachineTypes: Array<{ id: string, machineType: string }> } | null } };

export type QuerySiteConfigurationsBySiteIdVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type QuerySiteConfigurationsBySiteId = { sites: { site?: { id: string, configuration: { id: string, speedUnit: { id: string, formatId: string, type: UnitType, name: string }, weightUnit: { id: string, formatId: string, type: UnitType, name: string }, volumeUnit: { id: string, formatId: string, type: UnitType, name: string }, distanceUnit: { id: string, formatId: string, type: UnitType, name: string }, fluidVolumeUnit: { id: string, formatId: string, type: UnitType, name: string } } } | null } };

export type QuerySiteDeviceEventsVariables = Exact<{
  siteId: Scalars['ID']['input'];
  filterBy?: InputMaybe<SiteDeviceEventFilterInput>;
  orderBy: SiteDeviceEventSort;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type QuerySiteDeviceEvents = { siteDeviceEventQueries: { siteDeviceEvents: { pageInfo: { endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ cursor: string, node: { id: string, type: SiteDeviceEventType, status: SiteDeviceEventStatus, statusMessage: string, source: string, createdAt: any, createdAtUnix: number, payload: { materialFlowId?: string | null, driverId: string, machineId: string, materialId?: string | null, weight?: number | null, weightUnit?: string | null, zoneId?: string | null, latitude: number, longitude: number, heading: number, speedLimit?: number | null, currentSpeed?: number | null, speedUnit?: string | null } } }> } } };

export type QuerySiteDeviceMachineEventsVariables = Exact<{
  siteId: Scalars['String']['input'];
  machineId: Scalars['String']['input'];
  filterBy?: InputMaybe<SiteDeviceMachineEventFilterInput>;
  orderBy: SiteDeviceEventSort;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
}>;


export type QuerySiteDeviceMachineEvents = { siteDeviceEventQueries: { siteDeviceMachineEvents: { pageInfo: { hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ cursor: string, node: { id: string, type: SiteDeviceEventType, status: SiteDeviceEventStatus, statusMessage: string, source: string, createdAt: any, createdAtUnix: number, payload: { materialFlowId?: string | null, driverId: string, machineId: string, materialId?: string | null, weight?: number | null, weightUnit?: string | null, zoneId?: string | null, latitude: number, longitude: number, heading: number, speedLimit?: number | null, currentSpeed?: number | null, speedUnit?: string | null } } }> } } };

export type QueryQuerySiteDeviceMaterialFlowEventsVariables = Exact<{
  siteId: Scalars['String']['input'];
  materialFlowId: Scalars['String']['input'];
  filterBy?: InputMaybe<SiteDeviceMaterialFlowEventFilterInput>;
  orderBy?: InputMaybe<SiteDeviceEventSort>;
}>;


export type QueryQuerySiteDeviceMaterialFlowEvents = { siteDeviceEventQueries: { siteDeviceMaterialFlowEvents: { edges: Array<{ cursor: string, node: { id: string, type: SiteDeviceEventType, status: SiteDeviceEventStatus, statusMessage: string, source: string, createdAt: any, createdAtUnix: number, payload: { materialFlowId?: string | null, driverId: string, machineId: string, materialId?: string | null, weight?: number | null, weightUnit?: string | null, zoneId?: string | null, latitude: number, longitude: number, heading: number, speedLimit?: number | null, currentSpeed?: number | null, speedUnit?: string | null } } }> } } };

export type QuerySiteLoadTicketsVariables = Exact<{
  siteId: Scalars['ID']['input'];
  flowId?: InputMaybe<Scalars['String']['input']>;
  fromZoneId?: InputMaybe<Scalars['ID']['input']>;
  toZoneId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<SiteLoadTicketsOrderBy>;
  system: Scalars['String']['input'];
}>;


export type QuerySiteLoadTickets = { siteLoadTickets: { siteLoadTickets: { totalCount?: number | null, pageInfo: { endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ node: { id: string, materialVariantId?: string | null, fromZoneId?: string | null, toZoneId?: string | null, flowId?: string | null, createdAt: any, weight?: { value: number, unit: string, toBest?: { formatted: string } | null } | null, sourceTicket: { sourceService: string }, transporter?: { siteMachine?: { id: string, name: string } | null } | null, loader?: { siteMachine?: { id: string, name: string } | null } | null } }> } } };

export type QuerySiteLoadTicketsStatisticsVariables = Exact<{
  granularity: StatisticsGranularity;
  field: SiteLoadTicketsStatisticsFields;
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  filterBy?: InputMaybe<SiteLoadTicketsFilterBy>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type QuerySiteLoadTicketsStatistics = { siteLoadTickets: { statistics: { totalCount?: number | null, pageInfo: { endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ node: { date: any, sum: { value: number, unit: string } } }> } } };

export type QuerySiteMapBySiteIdVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type QuerySiteMapBySiteId = { siteMaps: { site?: { id: string, siteId: string, boundary: Array<Array<Array<number>>>, mapboxStyleId?: string | null, baseSpeed?: number | null, createdAt: any, modifiedAt: any } | null } };

export type QuerySiteMetricDefinitionsVariables = Exact<{
  filterBy?: InputMaybe<SiteMetricDefinitionFilterBy>;
  siteId: Scalars['ID']['input'];
}>;


export type QuerySiteMetricDefinitions = { sites: { site?: { siteMetricDefinitions: { edges: Array<{ node: { id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, deprecated: boolean, materialFlow?: { id: string } | null } }> } } | null } };

export type QuerySiteOperatorsVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type QuerySiteOperators = { siteOperators: { operatorsBySiteId: Array<{ id: string, name: string, phoneNumber: string }> } };

export type QueryTripsVariables = Exact<{
  filterBy?: InputMaybe<TripFilterBy>;
  orderBy?: InputMaybe<TripFilterOrderBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type QueryTrips = { trips: { trips: { totalCount?: number | null, edges: Array<{ cursor: string, node: { id: string, isReturnTrip: boolean, loadedWeight?: number | null, loadedWeightUnit?: string | null, timeSpent: number, timeSpentUnit: string, startZone: { coordinates: Array<Array<Array<number>>>, id: string, name: string }, endZone: { coordinates: Array<Array<Array<number>>>, id: string, name: string } } }>, pageInfo: { hasNextPage: boolean, endCursor?: string | null } } } };

export type QueryZoneTypesAllVariables = Exact<{ [key: string]: never; }>;


export type QueryZoneTypesAll = { zoneTypes: { all: Array<{ id: string, type: ZoneTypes }> } };

export type QueryZonesPoisAndFlowsVariables = Exact<{
  siteMapId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
}>;


export type QueryZonesPoisAndFlows = { zones: { bySiteMapId: Array<{ id: string, name: string, coordinates: Array<Array<Array<number>>>, description: string, maxSpeedMs?: number | null, narrowLane?: boolean | null, weighBridge?: boolean | null, zoneType: { id: string, type: ZoneTypes, description: string }, zoneMaterialVariants: Array<{ id: string, materialVariantId: string, materialVariant: { id: string, name: string, materialType: { id: string, name: string, materialFamily: { id: string, name: string } } } }>, chargers: Array<{ id: string, slots: Array<ChargerConnectorTypes>, description?: string | null, emobAssetId?: string | null }>, materialBalances: Array<{ id: string, amount: number, createdAt: any, zoneMaterialVariant: { id: string, materialVariantId: string } }> }> }, pointsOfInterest: { bySiteMapId: Array<{ id: string, name: string, description: string, coordinates: Array<number>, status: PointOfInterestStatus, pointOfInterestType: { id: string, type: string, description: string }, siteMetricDefinitions: Array<{ id: string, name?: string | null }> }> }, siteMaps: { site?: { id: string, siteMaterialFlows: { edges: Array<{ node: { id: string, type: MaterialFlowType, toId: string, fromId: string, materialVariantId?: string | null, name: string, description?: string | null, hasDynamicMaterial: boolean, siteMetricDefinitions: Array<{ id: string, name?: string | null, description?: string | null, type: MetricDefinitionType, materialVariant?: { id: string } | null, currentSiteMetricData?: { id: string, value: string, metricType?: string | null } | null }>, assignedMachines: Array<{ id: string, name: string, machineType: { id: string, machineType: string } }>, externalReferences?: Array<{ id: string, value: string, type: ExternalReferenceTypes }> | null } }> } } | null } };

export const FragmentZonePermissionType = gql`
    fragment FragmentZonePermissionType on ZonePermissionTypeModel {
  id
  type
  description
}
    `;
export const FragmentMachineTypePermission = gql`
    fragment FragmentMachineTypePermission on MachineTypePermissionModel {
  id
  machineTypeId
  zonePermissionType {
    ...FragmentZonePermissionType
  }
}
    ${FragmentZonePermissionType}`;
export const FragmentExternalMaterialMapping = gql`
    fragment FragmentExternalMaterialMapping on ExternalMaterialMapping {
  id
  externalName
  source
  materialVariant {
    id
    name
  }
}
    `;
export const FragmentUnassignedLoadTicketGroup = gql`
    fragment FragmentUnassignedLoadTicketGroup on SiteLoadTicketGroupBySourceFlowIdGroup {
  count
  scaleType
  source
  sourceTicketFlowId
  lastTicket
}
    `;
export const FragmentSiteMetricDefinitionData = gql`
    fragment FragmentSiteMetricDefinitionData on SiteMetricDefinition {
  id
  name
  description
  type
  currentSiteMetricData {
    id
    sourceService
    statusIndicator
    value
    unit
    originCreatedAt
  }
  materialVariant {
    id
    name
    materialType {
      id
      name
      materialFamily {
        id
        name
      }
    }
  }
}
    `;
export const FragmentMaterialVariantProcess = gql`
    fragment FragmentMaterialVariantProcess on MaterialVariant {
  id
  name
  materialType {
    id
    name
    materialFamily {
      id
      name
    }
  }
}
    `;
export const FragmentPointOfInterestForSiteMaterialFlow = gql`
    fragment FragmentPointOfInterestForSiteMaterialFlow on PointOfInterestModel {
  id
  name
  status
  description
  siteMetricDefinitions {
    ...FragmentSiteMetricDefinitionData
    materialVariant {
      ...FragmentMaterialVariantProcess
    }
  }
  pointOfInterestType {
    id
    type
  }
}
    ${FragmentSiteMetricDefinitionData}
${FragmentMaterialVariantProcess}`;
export const FragmentSiteMaterialFlowWithMetricData = gql`
    fragment FragmentSiteMaterialFlowWithMetricData on SiteMaterialFlow {
  id
  name
  type
  toId
  fromId
  siteMetricDefinitions {
    ...FragmentSiteMetricDefinitionData
  }
  materialVariant {
    ...FragmentMaterialVariantProcess
  }
}
    ${FragmentSiteMetricDefinitionData}
${FragmentMaterialVariantProcess}`;
export const FragmentGeographyPhotos = gql`
    fragment FragmentGeographyPhotos on GeographyPhotoModel {
  id
  isActive
  name
  createdAt
}
    `;
export const FragmentMachineType = gql`
    fragment FragmentMachineType on SiteMachineType {
  id
  machineType
}
    `;
export const FragmentMachine = gql`
    fragment FragmentMachine on SiteMachine {
  id
  shortId
  name
  description
  createdAt
  updatedAt
  isDeprecated
  fleetMachine {
    id
    serialNumber
    snapshotEquipment {
      id
      make
      model
    }
  }
  machineType {
    ...FragmentMachineType
  }
}
    ${FragmentMachineType}`;
export const FragmentMaterialFamily = gql`
    fragment FragmentMaterialFamily on MaterialFamily {
  id
  name
  description
}
    `;
export const FragmentMaterialTypeWithMaterialFamily = gql`
    fragment FragmentMaterialTypeWithMaterialFamily on MaterialType {
  id
  name
  description
  materialFamily {
    ...FragmentMaterialFamily
  }
}
    ${FragmentMaterialFamily}`;
export const FragmentMaterialVariantWithMaterialType = gql`
    fragment FragmentMaterialVariantWithMaterialType on MaterialVariant {
  id
  name
  description
  isDeleted
  materialType {
    ...FragmentMaterialTypeWithMaterialFamily
  }
}
    ${FragmentMaterialTypeWithMaterialFamily}`;
export const FragmentMaterialTypeWithMaterialVariants = gql`
    fragment FragmentMaterialTypeWithMaterialVariants on MaterialType {
  id
  name
  description
  materialVariants {
    ...FragmentMaterialVariantWithMaterialType
  }
}
    ${FragmentMaterialVariantWithMaterialType}`;
export const FragmentMaterialFamilyWithMaterialTypes = gql`
    fragment FragmentMaterialFamilyWithMaterialTypes on MaterialFamily {
  id
  name
  description
  materialTypes {
    ...FragmentMaterialTypeWithMaterialVariants
  }
}
    ${FragmentMaterialTypeWithMaterialVariants}`;
export const FragmentAssignedMachinesMachineType = gql`
    fragment FragmentAssignedMachinesMachineType on SiteMachineType {
  id
  machineType
}
    `;
export const FragmentAssignedMachines = gql`
    fragment FragmentAssignedMachines on SiteMachine {
  id
  name
  machineType {
    ...FragmentAssignedMachinesMachineType
  }
}
    ${FragmentAssignedMachinesMachineType}`;
export const FragmentMaterialFlows = gql`
    fragment FragmentMaterialFlows on SiteMaterialFlow {
  id
  type
  toId
  fromId
  materialVariantId
  name
  description
  siteMetricDefinitions {
    id
    name
    description
    type
    materialVariant {
      id
    }
    currentSiteMetricData {
      id
      value
      metricType
    }
  }
  assignedMachines {
    ...FragmentAssignedMachines
  }
  externalReferences {
    id
    value
    type
  }
  hasDynamicMaterial
}
    ${FragmentAssignedMachines}`;
export const FragmentMaterialVariant = gql`
    fragment FragmentMaterialVariant on MaterialVariant {
  id
  name
  description
  isDeleted
}
    `;
export const FragmentObjectiveSummary = gql`
    fragment FragmentObjectiveSummary on FlowObjectiveRateAggregate {
  amount
  date
}
    `;
export const FragmentPointOfInterestType = gql`
    fragment FragmentPointOfInterestType on PointOfInterestTypeModel {
  id
  type
  description
}
    `;
export const FragmentPointsOfInterest = gql`
    fragment FragmentPointsOfInterest on PointOfInterestModel {
  id
  name
  description
  coordinates
  status
  pointOfInterestType {
    ...FragmentPointOfInterestType
  }
  siteMetricDefinitions {
    id
    name
  }
}
    ${FragmentPointOfInterestType}`;
export const FragmentSite = gql`
    fragment FragmentSite on Site {
  id
  name
  company {
    id
  }
  allowedMachineTypes {
    ...FragmentMachineType
  }
}
    ${FragmentMachineType}`;
export const FragmentUnit = gql`
    fragment FragmentUnit on SiteUnit {
  id
  formatId
  type
  name
}
    `;
export const FragmentSiteConfiguration = gql`
    fragment FragmentSiteConfiguration on SiteConfiguration {
  id
  speedUnit {
    ...FragmentUnit
  }
  weightUnit {
    ...FragmentUnit
  }
  volumeUnit {
    ...FragmentUnit
  }
  distanceUnit {
    ...FragmentUnit
  }
  fluidVolumeUnit {
    ...FragmentUnit
  }
}
    ${FragmentUnit}`;
export const FragmentSiteDevicePayload = gql`
    fragment FragmentSiteDevicePayload on SiteDeviceEventPayload {
  materialFlowId
  driverId
  machineId
  materialId
  weight
  weightUnit
  zoneId
  latitude
  longitude
  heading
  speedLimit
  currentSpeed
  speedUnit
}
    `;
export const FragmentSiteDeviceEvent = gql`
    fragment FragmentSiteDeviceEvent on SiteDeviceEvent {
  id
  type
  status
  statusMessage
  source
  createdAt
  createdAtUnix
  payload {
    ...FragmentSiteDevicePayload
  }
}
    ${FragmentSiteDevicePayload}`;
export const FragmentSiteDeviceEvents = gql`
    fragment FragmentSiteDeviceEvents on SiteDeviceEventEdge {
  node {
    ...FragmentSiteDeviceEvent
  }
}
    ${FragmentSiteDeviceEvent}`;
export const FragmentSiteLoadTicket = gql`
    fragment FragmentSiteLoadTicket on SiteLoadTicket {
  id
  materialVariantId
  fromZoneId
  toZoneId
  flowId
  createdAt
  weight {
    value
    unit
    toBest(system: $system) {
      formatted
    }
  }
  sourceTicket {
    sourceService
  }
  transporter {
    siteMachine {
      id
      name
    }
  }
  loader {
    siteMachine {
      id
      name
    }
  }
}
    `;
export const FragmentSiteMap = gql`
    fragment FragmentSiteMap on SiteMapModel {
  id
  siteId
  boundary
  mapboxStyleId
  baseSpeed
  createdAt
  modifiedAt
}
    `;
export const FragmentSiteMetricDefinition = gql`
    fragment FragmentSiteMetricDefinition on SiteMetricDefinition {
  id
  name
  description
  type
  deprecated
  materialFlow {
    id
  }
}
    `;
export const FragmentTrip = gql`
    fragment FragmentTrip on Trip {
  id
  startZone {
    coordinates
    id
    name
  }
  endZone {
    coordinates
    id
    name
  }
  isReturnTrip
  loadedWeight
  loadedWeightUnit
  timeSpent
  timeSpentUnit
}
    `;
export const FragmentZoneType = gql`
    fragment FragmentZoneType on ZoneTypeModel {
  id
  type
  description
}
    `;
export const FragmentZoneMaterialVariant = gql`
    fragment FragmentZoneMaterialVariant on ZoneMaterialVariantModel {
  id
  materialVariantId
  materialVariant {
    id
    name
    materialType {
      id
      name
      materialFamily {
        id
        name
      }
    }
  }
}
    `;
export const FragmentZoneChargers = gql`
    fragment FragmentZoneChargers on ZoneChargerModel {
  id
  slots
  description
  emobAssetId
}
    `;
export const FragmentMaterialBalance = gql`
    fragment FragmentMaterialBalance on ZoneMaterialBalanceModel {
  id
  amount
  zoneMaterialVariant {
    id
    materialVariantId
  }
  createdAt
}
    `;
export const FragmentZone = gql`
    fragment FragmentZone on ZoneModel {
  id
  name
  coordinates
  name
  description
  maxSpeedMs
  narrowLane
  weighBridge
  zoneType {
    ...FragmentZoneType
  }
  zoneMaterialVariants {
    ...FragmentZoneMaterialVariant
  }
  chargers {
    ...FragmentZoneChargers
  }
  materialBalances(options: {onlyCurrentBalance: true}) {
    ...FragmentMaterialBalance
  }
}
    ${FragmentZoneType}
${FragmentZoneMaterialVariant}
${FragmentZoneChargers}
${FragmentMaterialBalance}`;
export const QueryZoneEventsDocument = gql`
    query QueryZoneEvents($siteIdSLT: ID!, $siteIdDE: String!, $zoneId: String!, $from: DateTime, $to: DateTime, $filterBy: SiteDeviceZoneEventFilterInput, $orderBySLT: SiteLoadTicketsOrderBy, $orderByDE: SiteDeviceEventSort!, $cursorSLT: String, $cursorDE: String, $first: Int!, $system: String!) {
  siteLoadTickets {
    siteLoadTickets(
      filterBy: {siteId: $siteIdSLT, from: $from, to: $to, zoneId: $zoneId}
      orderBy: $orderBySLT
      first: $first
      after: $cursorSLT
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...FragmentSiteLoadTicket
        }
      }
    }
  }
  siteDeviceEventQueries {
    siteDeviceZoneEvents(
      siteId: $siteIdDE
      zoneId: $zoneId
      filterBy: $filterBy
      orderBy: $orderByDE
      first: $first
      after: $cursorDE
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        ...FragmentSiteDeviceEvents
        cursor
      }
    }
  }
}
    ${FragmentSiteLoadTicket}
${FragmentSiteDeviceEvents}`;

/**
 * __useQueryZoneEvents__
 *
 * To run a query within a React component, call `useQueryZoneEvents` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoneEvents` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoneEvents({
 *   variables: {
 *      siteIdSLT: // value for 'siteIdSLT'
 *      siteIdDE: // value for 'siteIdDE'
 *      zoneId: // value for 'zoneId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filterBy: // value for 'filterBy'
 *      orderBySLT: // value for 'orderBySLT'
 *      orderByDE: // value for 'orderByDE'
 *      cursorSLT: // value for 'cursorSLT'
 *      cursorDE: // value for 'cursorDE'
 *      first: // value for 'first'
 *      system: // value for 'system'
 *   },
 * });
 */
export function useQueryZoneEvents(baseOptions: Apollo.QueryHookOptions<QueryZoneEvents, QueryZoneEventsVariables> & ({ variables: QueryZoneEventsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryZoneEvents, QueryZoneEventsVariables>(QueryZoneEventsDocument, options);
      }
export function useQueryZoneEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryZoneEvents, QueryZoneEventsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryZoneEvents, QueryZoneEventsVariables>(QueryZoneEventsDocument, options);
        }
export function useQueryZoneEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryZoneEvents, QueryZoneEventsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryZoneEvents, QueryZoneEventsVariables>(QueryZoneEventsDocument, options);
        }
export type QueryZoneEventsHookResult = ReturnType<typeof useQueryZoneEvents>;
export type QueryZoneEventsLazyQueryHookResult = ReturnType<typeof useQueryZoneEventsLazyQuery>;
export type QueryZoneEventsSuspenseQueryHookResult = ReturnType<typeof useQueryZoneEventsSuspenseQuery>;
export type QueryZoneEventsQueryResult = Apollo.QueryResult<QueryZoneEvents, QueryZoneEventsVariables>;
export const QueryFlowEventsDocument = gql`
    query QueryFlowEvents($siteId: ID!, $flowId: String, $from: DateTime, $to: DateTime, $filterBy: SiteDeviceEventFilterInput, $orderBySLT: SiteLoadTicketsOrderBy, $orderByDE: SiteDeviceEventSort!, $cursorSLT: String, $cursorDE: String, $first: Int!, $system: String!) {
  siteLoadTickets {
    siteLoadTickets(
      filterBy: {siteId: $siteId, flowId: $flowId, from: $from, to: $to}
      orderBy: $orderBySLT
      first: $first
      after: $cursorSLT
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...FragmentSiteLoadTicket
        }
        cursor
      }
    }
  }
  siteDeviceEventQueries {
    siteDeviceEvents(
      siteId: $siteId
      filterBy: $filterBy
      orderBy: $orderByDE
      first: $first
      after: $cursorDE
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        ...FragmentSiteDeviceEvents
        cursor
      }
    }
  }
}
    ${FragmentSiteLoadTicket}
${FragmentSiteDeviceEvents}`;

/**
 * __useQueryFlowEvents__
 *
 * To run a query within a React component, call `useQueryFlowEvents` and pass it any options that fit your needs.
 * When your component renders, `useQueryFlowEvents` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFlowEvents({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      flowId: // value for 'flowId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filterBy: // value for 'filterBy'
 *      orderBySLT: // value for 'orderBySLT'
 *      orderByDE: // value for 'orderByDE'
 *      cursorSLT: // value for 'cursorSLT'
 *      cursorDE: // value for 'cursorDE'
 *      first: // value for 'first'
 *      system: // value for 'system'
 *   },
 * });
 */
export function useQueryFlowEvents(baseOptions: Apollo.QueryHookOptions<QueryFlowEvents, QueryFlowEventsVariables> & ({ variables: QueryFlowEventsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFlowEvents, QueryFlowEventsVariables>(QueryFlowEventsDocument, options);
      }
export function useQueryFlowEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFlowEvents, QueryFlowEventsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFlowEvents, QueryFlowEventsVariables>(QueryFlowEventsDocument, options);
        }
export function useQueryFlowEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryFlowEvents, QueryFlowEventsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryFlowEvents, QueryFlowEventsVariables>(QueryFlowEventsDocument, options);
        }
export type QueryFlowEventsHookResult = ReturnType<typeof useQueryFlowEvents>;
export type QueryFlowEventsLazyQueryHookResult = ReturnType<typeof useQueryFlowEventsLazyQuery>;
export type QueryFlowEventsSuspenseQueryHookResult = ReturnType<typeof useQueryFlowEventsSuspenseQuery>;
export type QueryFlowEventsQueryResult = Apollo.QueryResult<QueryFlowEvents, QueryFlowEventsVariables>;
export const QueryMachineTypePermissionsDocument = gql`
    query QueryMachineTypePermissions($zoneId: ID!) {
  machineTypePermissions {
    byZoneId(zoneId: $zoneId) {
      ...FragmentMachineTypePermission
    }
  }
}
    ${FragmentMachineTypePermission}`;

/**
 * __useQueryMachineTypePermissions__
 *
 * To run a query within a React component, call `useQueryMachineTypePermissions` and pass it any options that fit your needs.
 * When your component renders, `useQueryMachineTypePermissions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMachineTypePermissions({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function useQueryMachineTypePermissions(baseOptions: Apollo.QueryHookOptions<QueryMachineTypePermissions, QueryMachineTypePermissionsVariables> & ({ variables: QueryMachineTypePermissionsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryMachineTypePermissions, QueryMachineTypePermissionsVariables>(QueryMachineTypePermissionsDocument, options);
      }
export function useQueryMachineTypePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryMachineTypePermissions, QueryMachineTypePermissionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryMachineTypePermissions, QueryMachineTypePermissionsVariables>(QueryMachineTypePermissionsDocument, options);
        }
export function useQueryMachineTypePermissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryMachineTypePermissions, QueryMachineTypePermissionsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryMachineTypePermissions, QueryMachineTypePermissionsVariables>(QueryMachineTypePermissionsDocument, options);
        }
export type QueryMachineTypePermissionsHookResult = ReturnType<typeof useQueryMachineTypePermissions>;
export type QueryMachineTypePermissionsLazyQueryHookResult = ReturnType<typeof useQueryMachineTypePermissionsLazyQuery>;
export type QueryMachineTypePermissionsSuspenseQueryHookResult = ReturnType<typeof useQueryMachineTypePermissionsSuspenseQuery>;
export type QueryMachineTypePermissionsQueryResult = Apollo.QueryResult<QueryMachineTypePermissions, QueryMachineTypePermissionsVariables>;
export const QueryZonePermissionTypesDocument = gql`
    query QueryZonePermissionTypes {
  zonePermissionTypes {
    all {
      ...FragmentZonePermissionType
    }
  }
}
    ${FragmentZonePermissionType}`;

/**
 * __useQueryZonePermissionTypes__
 *
 * To run a query within a React component, call `useQueryZonePermissionTypes` and pass it any options that fit your needs.
 * When your component renders, `useQueryZonePermissionTypes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZonePermissionTypes({
 *   variables: {
 *   },
 * });
 */
export function useQueryZonePermissionTypes(baseOptions?: Apollo.QueryHookOptions<QueryZonePermissionTypes, QueryZonePermissionTypesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryZonePermissionTypes, QueryZonePermissionTypesVariables>(QueryZonePermissionTypesDocument, options);
      }
export function useQueryZonePermissionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryZonePermissionTypes, QueryZonePermissionTypesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryZonePermissionTypes, QueryZonePermissionTypesVariables>(QueryZonePermissionTypesDocument, options);
        }
export function useQueryZonePermissionTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryZonePermissionTypes, QueryZonePermissionTypesVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryZonePermissionTypes, QueryZonePermissionTypesVariables>(QueryZonePermissionTypesDocument, options);
        }
export type QueryZonePermissionTypesHookResult = ReturnType<typeof useQueryZonePermissionTypes>;
export type QueryZonePermissionTypesLazyQueryHookResult = ReturnType<typeof useQueryZonePermissionTypesLazyQuery>;
export type QueryZonePermissionTypesSuspenseQueryHookResult = ReturnType<typeof useQueryZonePermissionTypesSuspenseQuery>;
export type QueryZonePermissionTypesQueryResult = Apollo.QueryResult<QueryZonePermissionTypes, QueryZonePermissionTypesVariables>;
export const ExternalMaterialMappingsDocument = gql`
    query ExternalMaterialMappings($companyId: ID!, $after: String) {
  siteCompanies {
    company(companyId: $companyId) {
      id
      externalMaterialMappings(after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...FragmentExternalMaterialMapping
          }
        }
      }
    }
  }
}
    ${FragmentExternalMaterialMapping}`;

/**
 * __useExternalMaterialMappings__
 *
 * To run a query within a React component, call `useExternalMaterialMappings` and pass it any options that fit your needs.
 * When your component renders, `useExternalMaterialMappings` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalMaterialMappings({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useExternalMaterialMappings(baseOptions: Apollo.QueryHookOptions<ExternalMaterialMappings, ExternalMaterialMappingsVariables> & ({ variables: ExternalMaterialMappingsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalMaterialMappings, ExternalMaterialMappingsVariables>(ExternalMaterialMappingsDocument, options);
      }
export function useExternalMaterialMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalMaterialMappings, ExternalMaterialMappingsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalMaterialMappings, ExternalMaterialMappingsVariables>(ExternalMaterialMappingsDocument, options);
        }
export function useExternalMaterialMappingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ExternalMaterialMappings, ExternalMaterialMappingsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExternalMaterialMappings, ExternalMaterialMappingsVariables>(ExternalMaterialMappingsDocument, options);
        }
export type ExternalMaterialMappingsHookResult = ReturnType<typeof useExternalMaterialMappings>;
export type ExternalMaterialMappingsLazyQueryHookResult = ReturnType<typeof useExternalMaterialMappingsLazyQuery>;
export type ExternalMaterialMappingsSuspenseQueryHookResult = ReturnType<typeof useExternalMaterialMappingsSuspenseQuery>;
export type ExternalMaterialMappingsQueryResult = Apollo.QueryResult<ExternalMaterialMappings, ExternalMaterialMappingsVariables>;
export const SiteMaterialVariantsDocument = gql`
    query SiteMaterialVariants($companyId: ID!) {
  siteCompanies {
    company(companyId: $companyId) {
      id
      materialFamilies {
        id
        name
        materialTypes {
          id
          name
          materialVariants {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSiteMaterialVariants__
 *
 * To run a query within a React component, call `useSiteMaterialVariants` and pass it any options that fit your needs.
 * When your component renders, `useSiteMaterialVariants` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteMaterialVariants({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSiteMaterialVariants(baseOptions: Apollo.QueryHookOptions<SiteMaterialVariants, SiteMaterialVariantsVariables> & ({ variables: SiteMaterialVariantsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteMaterialVariants, SiteMaterialVariantsVariables>(SiteMaterialVariantsDocument, options);
      }
export function useSiteMaterialVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteMaterialVariants, SiteMaterialVariantsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteMaterialVariants, SiteMaterialVariantsVariables>(SiteMaterialVariantsDocument, options);
        }
export function useSiteMaterialVariantsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SiteMaterialVariants, SiteMaterialVariantsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SiteMaterialVariants, SiteMaterialVariantsVariables>(SiteMaterialVariantsDocument, options);
        }
export type SiteMaterialVariantsHookResult = ReturnType<typeof useSiteMaterialVariants>;
export type SiteMaterialVariantsLazyQueryHookResult = ReturnType<typeof useSiteMaterialVariantsLazyQuery>;
export type SiteMaterialVariantsSuspenseQueryHookResult = ReturnType<typeof useSiteMaterialVariantsSuspenseQuery>;
export type SiteMaterialVariantsQueryResult = Apollo.QueryResult<SiteMaterialVariants, SiteMaterialVariantsVariables>;
export const UpdateExternalMaterialMappingDocument = gql`
    mutation UpdateExternalMaterialMapping($id: ID!, $input: ExternalMaterialUpdateInput!) {
  externalMaterialMappings {
    update(id: $id, input: $input) {
      id
    }
  }
}
    `;
export type UpdateExternalMaterialMappingMutationFn = Apollo.MutationFunction<UpdateExternalMaterialMapping, UpdateExternalMaterialMappingVariables>;

/**
 * __useUpdateExternalMaterialMapping__
 *
 * To run a mutation, you first call `useUpdateExternalMaterialMapping` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExternalMaterialMapping` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExternalMaterialMapping, { data, loading, error }] = useUpdateExternalMaterialMapping({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExternalMaterialMapping(baseOptions?: Apollo.MutationHookOptions<UpdateExternalMaterialMapping, UpdateExternalMaterialMappingVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExternalMaterialMapping, UpdateExternalMaterialMappingVariables>(UpdateExternalMaterialMappingDocument, options);
      }
export type UpdateExternalMaterialMappingHookResult = ReturnType<typeof useUpdateExternalMaterialMapping>;
export type UpdateExternalMaterialMappingMutationResult = Apollo.MutationResult<UpdateExternalMaterialMapping>;
export type UpdateExternalMaterialMappingMutationOptions = Apollo.BaseMutationOptions<UpdateExternalMaterialMapping, UpdateExternalMaterialMappingVariables>;
export const QueryUnassignedSiteLoadTicketsDocument = gql`
    query QueryUnassignedSiteLoadTickets($from: DateTime!, $to: DateTime!, $take: Int, $filterBy: SiteLoadTicketsFilterBy, $skip: Int, $orderBy: SiteLoadTicketsGroupByFlowIdOrderBy) {
  siteLoadTickets {
    groupBySourceFlowId(
      from: $from
      to: $to
      take: $take
      filterBy: $filterBy
      skip: $skip
      orderBy: $orderBy
    ) {
      hasNextPage
      groups {
        ...FragmentUnassignedLoadTicketGroup
      }
    }
  }
}
    ${FragmentUnassignedLoadTicketGroup}`;

/**
 * __useQueryUnassignedSiteLoadTickets__
 *
 * To run a query within a React component, call `useQueryUnassignedSiteLoadTickets` and pass it any options that fit your needs.
 * When your component renders, `useQueryUnassignedSiteLoadTickets` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUnassignedSiteLoadTickets({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      take: // value for 'take'
 *      filterBy: // value for 'filterBy'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useQueryUnassignedSiteLoadTickets(baseOptions: Apollo.QueryHookOptions<QueryUnassignedSiteLoadTickets, QueryUnassignedSiteLoadTicketsVariables> & ({ variables: QueryUnassignedSiteLoadTicketsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryUnassignedSiteLoadTickets, QueryUnassignedSiteLoadTicketsVariables>(QueryUnassignedSiteLoadTicketsDocument, options);
      }
export function useQueryUnassignedSiteLoadTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryUnassignedSiteLoadTickets, QueryUnassignedSiteLoadTicketsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryUnassignedSiteLoadTickets, QueryUnassignedSiteLoadTicketsVariables>(QueryUnassignedSiteLoadTicketsDocument, options);
        }
export function useQueryUnassignedSiteLoadTicketsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryUnassignedSiteLoadTickets, QueryUnassignedSiteLoadTicketsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryUnassignedSiteLoadTickets, QueryUnassignedSiteLoadTicketsVariables>(QueryUnassignedSiteLoadTicketsDocument, options);
        }
export type QueryUnassignedSiteLoadTicketsHookResult = ReturnType<typeof useQueryUnassignedSiteLoadTickets>;
export type QueryUnassignedSiteLoadTicketsLazyQueryHookResult = ReturnType<typeof useQueryUnassignedSiteLoadTicketsLazyQuery>;
export type QueryUnassignedSiteLoadTicketsSuspenseQueryHookResult = ReturnType<typeof useQueryUnassignedSiteLoadTicketsSuspenseQuery>;
export type QueryUnassignedSiteLoadTicketsQueryResult = Apollo.QueryResult<QueryUnassignedSiteLoadTickets, QueryUnassignedSiteLoadTicketsVariables>;
export const QuerySiteMaterialFlowsWithMetricDataDocument = gql`
    query QuerySiteMaterialFlowsWithMetricData($siteId: ID!) {
  siteMaps {
    site(siteId: $siteId) {
      id
      siteMaterialFlows(filterBy: {type: {in: [POI_TO_ZONE, ZONE_TO_POI]}}) {
        edges {
          node {
            ...FragmentSiteMaterialFlowWithMetricData
          }
        }
      }
      pointsOfInterest {
        ...FragmentPointOfInterestForSiteMaterialFlow
      }
    }
  }
}
    ${FragmentSiteMaterialFlowWithMetricData}
${FragmentPointOfInterestForSiteMaterialFlow}`;

/**
 * __useQuerySiteMaterialFlowsWithMetricData__
 *
 * To run a query within a React component, call `useQuerySiteMaterialFlowsWithMetricData` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteMaterialFlowsWithMetricData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteMaterialFlowsWithMetricData({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQuerySiteMaterialFlowsWithMetricData(baseOptions: Apollo.QueryHookOptions<QuerySiteMaterialFlowsWithMetricData, QuerySiteMaterialFlowsWithMetricDataVariables> & ({ variables: QuerySiteMaterialFlowsWithMetricDataVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteMaterialFlowsWithMetricData, QuerySiteMaterialFlowsWithMetricDataVariables>(QuerySiteMaterialFlowsWithMetricDataDocument, options);
      }
export function useQuerySiteMaterialFlowsWithMetricDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteMaterialFlowsWithMetricData, QuerySiteMaterialFlowsWithMetricDataVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteMaterialFlowsWithMetricData, QuerySiteMaterialFlowsWithMetricDataVariables>(QuerySiteMaterialFlowsWithMetricDataDocument, options);
        }
export function useQuerySiteMaterialFlowsWithMetricDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteMaterialFlowsWithMetricData, QuerySiteMaterialFlowsWithMetricDataVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteMaterialFlowsWithMetricData, QuerySiteMaterialFlowsWithMetricDataVariables>(QuerySiteMaterialFlowsWithMetricDataDocument, options);
        }
export type QuerySiteMaterialFlowsWithMetricDataHookResult = ReturnType<typeof useQuerySiteMaterialFlowsWithMetricData>;
export type QuerySiteMaterialFlowsWithMetricDataLazyQueryHookResult = ReturnType<typeof useQuerySiteMaterialFlowsWithMetricDataLazyQuery>;
export type QuerySiteMaterialFlowsWithMetricDataSuspenseQueryHookResult = ReturnType<typeof useQuerySiteMaterialFlowsWithMetricDataSuspenseQuery>;
export type QuerySiteMaterialFlowsWithMetricDataQueryResult = Apollo.QueryResult<QuerySiteMaterialFlowsWithMetricData, QuerySiteMaterialFlowsWithMetricDataVariables>;
export const MutateAdjustTransactionDocument = gql`
    mutation MutateAdjustTransaction($siteId: String!, $materialId: String!, $amount: Float!, $timestamp: DateTime!, $zoneId: String) {
  materialTransaction {
    adjustTransaction(
      siteId: $siteId
      materialId: $materialId
      amount: $amount
      timestamp: $timestamp
      zoneId: $zoneId
      unitType: "kg"
    ) {
      id
    }
  }
}
    `;
export type MutateAdjustTransactionMutationFn = Apollo.MutationFunction<MutateAdjustTransaction, MutateAdjustTransactionVariables>;

/**
 * __useMutateAdjustTransaction__
 *
 * To run a mutation, you first call `useMutateAdjustTransaction` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateAdjustTransaction` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateAdjustTransaction, { data, loading, error }] = useMutateAdjustTransaction({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      materialId: // value for 'materialId'
 *      amount: // value for 'amount'
 *      timestamp: // value for 'timestamp'
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function useMutateAdjustTransaction(baseOptions?: Apollo.MutationHookOptions<MutateAdjustTransaction, MutateAdjustTransactionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateAdjustTransaction, MutateAdjustTransactionVariables>(MutateAdjustTransactionDocument, options);
      }
export type MutateAdjustTransactionHookResult = ReturnType<typeof useMutateAdjustTransaction>;
export type MutateAdjustTransactionMutationResult = Apollo.MutationResult<MutateAdjustTransaction>;
export type MutateAdjustTransactionMutationOptions = Apollo.BaseMutationOptions<MutateAdjustTransaction, MutateAdjustTransactionVariables>;
export const MutateGeographyPhotosSetActiveDocument = gql`
    mutation MutateGeographyPhotosSetActive($input: SetActiveGeographyPhotosInput!) {
  geographyPhotos {
    setActive(input: $input) {
      mapboxStyleId
    }
  }
}
    `;
export type MutateGeographyPhotosSetActiveMutationFn = Apollo.MutationFunction<MutateGeographyPhotosSetActive, MutateGeographyPhotosSetActiveVariables>;

/**
 * __useMutateGeographyPhotosSetActive__
 *
 * To run a mutation, you first call `useMutateGeographyPhotosSetActive` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateGeographyPhotosSetActive` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateGeographyPhotosSetActive, { data, loading, error }] = useMutateGeographyPhotosSetActive({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutateGeographyPhotosSetActive(baseOptions?: Apollo.MutationHookOptions<MutateGeographyPhotosSetActive, MutateGeographyPhotosSetActiveVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateGeographyPhotosSetActive, MutateGeographyPhotosSetActiveVariables>(MutateGeographyPhotosSetActiveDocument, options);
      }
export type MutateGeographyPhotosSetActiveHookResult = ReturnType<typeof useMutateGeographyPhotosSetActive>;
export type MutateGeographyPhotosSetActiveMutationResult = Apollo.MutationResult<MutateGeographyPhotosSetActive>;
export type MutateGeographyPhotosSetActiveMutationOptions = Apollo.BaseMutationOptions<MutateGeographyPhotosSetActive, MutateGeographyPhotosSetActiveVariables>;
export const MutateGeographyPhotosDeleteDocument = gql`
    mutation MutateGeographyPhotosDelete($id: String!) {
  geographyPhotos {
    delete(id: $id) {
      id
    }
  }
}
    `;
export type MutateGeographyPhotosDeleteMutationFn = Apollo.MutationFunction<MutateGeographyPhotosDelete, MutateGeographyPhotosDeleteVariables>;

/**
 * __useMutateGeographyPhotosDelete__
 *
 * To run a mutation, you first call `useMutateGeographyPhotosDelete` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateGeographyPhotosDelete` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateGeographyPhotosDelete, { data, loading, error }] = useMutateGeographyPhotosDelete({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMutateGeographyPhotosDelete(baseOptions?: Apollo.MutationHookOptions<MutateGeographyPhotosDelete, MutateGeographyPhotosDeleteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateGeographyPhotosDelete, MutateGeographyPhotosDeleteVariables>(MutateGeographyPhotosDeleteDocument, options);
      }
export type MutateGeographyPhotosDeleteHookResult = ReturnType<typeof useMutateGeographyPhotosDelete>;
export type MutateGeographyPhotosDeleteMutationResult = Apollo.MutationResult<MutateGeographyPhotosDelete>;
export type MutateGeographyPhotosDeleteMutationOptions = Apollo.BaseMutationOptions<MutateGeographyPhotosDelete, MutateGeographyPhotosDeleteVariables>;
export const MutateMachineTypePermissionsCreateDocument = gql`
    mutation MutateMachineTypePermissionsCreate($input: CreateMachineTypePermissionInput!) {
  machineTypePermissions {
    create(input: $input) {
      id
    }
  }
}
    `;
export type MutateMachineTypePermissionsCreateMutationFn = Apollo.MutationFunction<MutateMachineTypePermissionsCreate, MutateMachineTypePermissionsCreateVariables>;

/**
 * __useMutateMachineTypePermissionsCreate__
 *
 * To run a mutation, you first call `useMutateMachineTypePermissionsCreate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateMachineTypePermissionsCreate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateMachineTypePermissionsCreate, { data, loading, error }] = useMutateMachineTypePermissionsCreate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutateMachineTypePermissionsCreate(baseOptions?: Apollo.MutationHookOptions<MutateMachineTypePermissionsCreate, MutateMachineTypePermissionsCreateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateMachineTypePermissionsCreate, MutateMachineTypePermissionsCreateVariables>(MutateMachineTypePermissionsCreateDocument, options);
      }
export type MutateMachineTypePermissionsCreateHookResult = ReturnType<typeof useMutateMachineTypePermissionsCreate>;
export type MutateMachineTypePermissionsCreateMutationResult = Apollo.MutationResult<MutateMachineTypePermissionsCreate>;
export type MutateMachineTypePermissionsCreateMutationOptions = Apollo.BaseMutationOptions<MutateMachineTypePermissionsCreate, MutateMachineTypePermissionsCreateVariables>;
export const MutateMachineTypePermissionsUpdateForZoneDocument = gql`
    mutation MutateMachineTypePermissionsUpdateForZone($zoneId: ID!, $inputs: [CreateMachineTypePermissionInput!]!) {
  machineTypePermissions {
    updateForZone(zoneId: $zoneId, inputs: $inputs) {
      id
    }
  }
}
    `;
export type MutateMachineTypePermissionsUpdateForZoneMutationFn = Apollo.MutationFunction<MutateMachineTypePermissionsUpdateForZone, MutateMachineTypePermissionsUpdateForZoneVariables>;

/**
 * __useMutateMachineTypePermissionsUpdateForZone__
 *
 * To run a mutation, you first call `useMutateMachineTypePermissionsUpdateForZone` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateMachineTypePermissionsUpdateForZone` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateMachineTypePermissionsUpdateForZone, { data, loading, error }] = useMutateMachineTypePermissionsUpdateForZone({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useMutateMachineTypePermissionsUpdateForZone(baseOptions?: Apollo.MutationHookOptions<MutateMachineTypePermissionsUpdateForZone, MutateMachineTypePermissionsUpdateForZoneVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateMachineTypePermissionsUpdateForZone, MutateMachineTypePermissionsUpdateForZoneVariables>(MutateMachineTypePermissionsUpdateForZoneDocument, options);
      }
export type MutateMachineTypePermissionsUpdateForZoneHookResult = ReturnType<typeof useMutateMachineTypePermissionsUpdateForZone>;
export type MutateMachineTypePermissionsUpdateForZoneMutationResult = Apollo.MutationResult<MutateMachineTypePermissionsUpdateForZone>;
export type MutateMachineTypePermissionsUpdateForZoneMutationOptions = Apollo.BaseMutationOptions<MutateMachineTypePermissionsUpdateForZone, MutateMachineTypePermissionsUpdateForZoneVariables>;
export const MutateMachineTypePermissionDeleteDocument = gql`
    mutation MutateMachineTypePermissionDelete($id: ID!) {
  machineTypePermissions {
    delete(id: $id) {
      id
    }
  }
}
    `;
export type MutateMachineTypePermissionDeleteMutationFn = Apollo.MutationFunction<MutateMachineTypePermissionDelete, MutateMachineTypePermissionDeleteVariables>;

/**
 * __useMutateMachineTypePermissionDelete__
 *
 * To run a mutation, you first call `useMutateMachineTypePermissionDelete` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateMachineTypePermissionDelete` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateMachineTypePermissionDelete, { data, loading, error }] = useMutateMachineTypePermissionDelete({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMutateMachineTypePermissionDelete(baseOptions?: Apollo.MutationHookOptions<MutateMachineTypePermissionDelete, MutateMachineTypePermissionDeleteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateMachineTypePermissionDelete, MutateMachineTypePermissionDeleteVariables>(MutateMachineTypePermissionDeleteDocument, options);
      }
export type MutateMachineTypePermissionDeleteHookResult = ReturnType<typeof useMutateMachineTypePermissionDelete>;
export type MutateMachineTypePermissionDeleteMutationResult = Apollo.MutationResult<MutateMachineTypePermissionDelete>;
export type MutateMachineTypePermissionDeleteMutationOptions = Apollo.BaseMutationOptions<MutateMachineTypePermissionDelete, MutateMachineTypePermissionDeleteVariables>;
export const MutateMaterialFlowsCreateDocument = gql`
    mutation MutateMaterialFlowsCreate($input: CreateSiteMaterialFlowInput!) {
  siteMaterialFlows {
    create(input: $input) {
      ...FragmentMaterialFlows
    }
  }
}
    ${FragmentMaterialFlows}`;
export type MutateMaterialFlowsCreateMutationFn = Apollo.MutationFunction<MutateMaterialFlowsCreate, MutateMaterialFlowsCreateVariables>;

/**
 * __useMutateMaterialFlowsCreate__
 *
 * To run a mutation, you first call `useMutateMaterialFlowsCreate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateMaterialFlowsCreate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateMaterialFlowsCreate, { data, loading, error }] = useMutateMaterialFlowsCreate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutateMaterialFlowsCreate(baseOptions?: Apollo.MutationHookOptions<MutateMaterialFlowsCreate, MutateMaterialFlowsCreateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateMaterialFlowsCreate, MutateMaterialFlowsCreateVariables>(MutateMaterialFlowsCreateDocument, options);
      }
export type MutateMaterialFlowsCreateHookResult = ReturnType<typeof useMutateMaterialFlowsCreate>;
export type MutateMaterialFlowsCreateMutationResult = Apollo.MutationResult<MutateMaterialFlowsCreate>;
export type MutateMaterialFlowsCreateMutationOptions = Apollo.BaseMutationOptions<MutateMaterialFlowsCreate, MutateMaterialFlowsCreateVariables>;
export const MutateMaterialFlowsUpdateDocument = gql`
    mutation MutateMaterialFlowsUpdate($input: UpdateSiteMaterialFlowInput!) {
  siteMaterialFlows {
    update(input: $input) {
      ...FragmentMaterialFlows
    }
  }
}
    ${FragmentMaterialFlows}`;
export type MutateMaterialFlowsUpdateMutationFn = Apollo.MutationFunction<MutateMaterialFlowsUpdate, MutateMaterialFlowsUpdateVariables>;

/**
 * __useMutateMaterialFlowsUpdate__
 *
 * To run a mutation, you first call `useMutateMaterialFlowsUpdate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateMaterialFlowsUpdate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateMaterialFlowsUpdate, { data, loading, error }] = useMutateMaterialFlowsUpdate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutateMaterialFlowsUpdate(baseOptions?: Apollo.MutationHookOptions<MutateMaterialFlowsUpdate, MutateMaterialFlowsUpdateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateMaterialFlowsUpdate, MutateMaterialFlowsUpdateVariables>(MutateMaterialFlowsUpdateDocument, options);
      }
export type MutateMaterialFlowsUpdateHookResult = ReturnType<typeof useMutateMaterialFlowsUpdate>;
export type MutateMaterialFlowsUpdateMutationResult = Apollo.MutationResult<MutateMaterialFlowsUpdate>;
export type MutateMaterialFlowsUpdateMutationOptions = Apollo.BaseMutationOptions<MutateMaterialFlowsUpdate, MutateMaterialFlowsUpdateVariables>;
export const MutateMaterialFlowsDeleteDocument = gql`
    mutation MutateMaterialFlowsDelete($id: String!) {
  siteMaterialFlows {
    delete(id: $id) {
      id
    }
  }
}
    `;
export type MutateMaterialFlowsDeleteMutationFn = Apollo.MutationFunction<MutateMaterialFlowsDelete, MutateMaterialFlowsDeleteVariables>;

/**
 * __useMutateMaterialFlowsDelete__
 *
 * To run a mutation, you first call `useMutateMaterialFlowsDelete` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateMaterialFlowsDelete` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateMaterialFlowsDelete, { data, loading, error }] = useMutateMaterialFlowsDelete({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMutateMaterialFlowsDelete(baseOptions?: Apollo.MutationHookOptions<MutateMaterialFlowsDelete, MutateMaterialFlowsDeleteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateMaterialFlowsDelete, MutateMaterialFlowsDeleteVariables>(MutateMaterialFlowsDeleteDocument, options);
      }
export type MutateMaterialFlowsDeleteHookResult = ReturnType<typeof useMutateMaterialFlowsDelete>;
export type MutateMaterialFlowsDeleteMutationResult = Apollo.MutationResult<MutateMaterialFlowsDelete>;
export type MutateMaterialFlowsDeleteMutationOptions = Apollo.BaseMutationOptions<MutateMaterialFlowsDelete, MutateMaterialFlowsDeleteVariables>;
export const MutatePointOfInterestCreateDocument = gql`
    mutation MutatePointOfInterestCreate($input: CreatePointOfInterestInput!) {
  pointsOfInterest {
    create(input: $input) {
      ...FragmentPointsOfInterest
    }
  }
}
    ${FragmentPointsOfInterest}`;
export type MutatePointOfInterestCreateMutationFn = Apollo.MutationFunction<MutatePointOfInterestCreate, MutatePointOfInterestCreateVariables>;

/**
 * __useMutatePointOfInterestCreate__
 *
 * To run a mutation, you first call `useMutatePointOfInterestCreate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutatePointOfInterestCreate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutatePointOfInterestCreate, { data, loading, error }] = useMutatePointOfInterestCreate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutatePointOfInterestCreate(baseOptions?: Apollo.MutationHookOptions<MutatePointOfInterestCreate, MutatePointOfInterestCreateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutatePointOfInterestCreate, MutatePointOfInterestCreateVariables>(MutatePointOfInterestCreateDocument, options);
      }
export type MutatePointOfInterestCreateHookResult = ReturnType<typeof useMutatePointOfInterestCreate>;
export type MutatePointOfInterestCreateMutationResult = Apollo.MutationResult<MutatePointOfInterestCreate>;
export type MutatePointOfInterestCreateMutationOptions = Apollo.BaseMutationOptions<MutatePointOfInterestCreate, MutatePointOfInterestCreateVariables>;
export const MutatePointOfInterestUpdateDocument = gql`
    mutation MutatePointOfInterestUpdate($input: UpdatePointOfInterestInput!) {
  pointsOfInterest {
    update(input: $input) {
      ...FragmentPointsOfInterest
    }
  }
}
    ${FragmentPointsOfInterest}`;
export type MutatePointOfInterestUpdateMutationFn = Apollo.MutationFunction<MutatePointOfInterestUpdate, MutatePointOfInterestUpdateVariables>;

/**
 * __useMutatePointOfInterestUpdate__
 *
 * To run a mutation, you first call `useMutatePointOfInterestUpdate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutatePointOfInterestUpdate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutatePointOfInterestUpdate, { data, loading, error }] = useMutatePointOfInterestUpdate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutatePointOfInterestUpdate(baseOptions?: Apollo.MutationHookOptions<MutatePointOfInterestUpdate, MutatePointOfInterestUpdateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutatePointOfInterestUpdate, MutatePointOfInterestUpdateVariables>(MutatePointOfInterestUpdateDocument, options);
      }
export type MutatePointOfInterestUpdateHookResult = ReturnType<typeof useMutatePointOfInterestUpdate>;
export type MutatePointOfInterestUpdateMutationResult = Apollo.MutationResult<MutatePointOfInterestUpdate>;
export type MutatePointOfInterestUpdateMutationOptions = Apollo.BaseMutationOptions<MutatePointOfInterestUpdate, MutatePointOfInterestUpdateVariables>;
export const MutatePointOfInterestDeleteDocument = gql`
    mutation MutatePointOfInterestDelete($id: ID!) {
  pointsOfInterest {
    delete(id: $id) {
      id
    }
  }
}
    `;
export type MutatePointOfInterestDeleteMutationFn = Apollo.MutationFunction<MutatePointOfInterestDelete, MutatePointOfInterestDeleteVariables>;

/**
 * __useMutatePointOfInterestDelete__
 *
 * To run a mutation, you first call `useMutatePointOfInterestDelete` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutatePointOfInterestDelete` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutatePointOfInterestDelete, { data, loading, error }] = useMutatePointOfInterestDelete({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMutatePointOfInterestDelete(baseOptions?: Apollo.MutationHookOptions<MutatePointOfInterestDelete, MutatePointOfInterestDeleteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutatePointOfInterestDelete, MutatePointOfInterestDeleteVariables>(MutatePointOfInterestDeleteDocument, options);
      }
export type MutatePointOfInterestDeleteHookResult = ReturnType<typeof useMutatePointOfInterestDelete>;
export type MutatePointOfInterestDeleteMutationResult = Apollo.MutationResult<MutatePointOfInterestDelete>;
export type MutatePointOfInterestDeleteMutationOptions = Apollo.BaseMutationOptions<MutatePointOfInterestDelete, MutatePointOfInterestDeleteVariables>;
export const MutateSiteMapsCreateDocument = gql`
    mutation MutateSiteMapsCreate($input: CreateSiteMapInput!) {
  siteMaps {
    create(input: $input) {
      id
      siteId
      boundary
      createdAt
      modifiedAt
    }
  }
}
    `;
export type MutateSiteMapsCreateMutationFn = Apollo.MutationFunction<MutateSiteMapsCreate, MutateSiteMapsCreateVariables>;

/**
 * __useMutateSiteMapsCreate__
 *
 * To run a mutation, you first call `useMutateSiteMapsCreate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateSiteMapsCreate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateSiteMapsCreate, { data, loading, error }] = useMutateSiteMapsCreate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutateSiteMapsCreate(baseOptions?: Apollo.MutationHookOptions<MutateSiteMapsCreate, MutateSiteMapsCreateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateSiteMapsCreate, MutateSiteMapsCreateVariables>(MutateSiteMapsCreateDocument, options);
      }
export type MutateSiteMapsCreateHookResult = ReturnType<typeof useMutateSiteMapsCreate>;
export type MutateSiteMapsCreateMutationResult = Apollo.MutationResult<MutateSiteMapsCreate>;
export type MutateSiteMapsCreateMutationOptions = Apollo.BaseMutationOptions<MutateSiteMapsCreate, MutateSiteMapsCreateVariables>;
export const MutateSiteMapsUpdateDocument = gql`
    mutation MutateSiteMapsUpdate($input: UpdateSiteMapInput!) {
  siteMaps {
    update(input: $input) {
      id
      siteId
      boundary
      baseSpeed
      createdAt
      modifiedAt
    }
  }
}
    `;
export type MutateSiteMapsUpdateMutationFn = Apollo.MutationFunction<MutateSiteMapsUpdate, MutateSiteMapsUpdateVariables>;

/**
 * __useMutateSiteMapsUpdate__
 *
 * To run a mutation, you first call `useMutateSiteMapsUpdate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateSiteMapsUpdate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateSiteMapsUpdate, { data, loading, error }] = useMutateSiteMapsUpdate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutateSiteMapsUpdate(baseOptions?: Apollo.MutationHookOptions<MutateSiteMapsUpdate, MutateSiteMapsUpdateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateSiteMapsUpdate, MutateSiteMapsUpdateVariables>(MutateSiteMapsUpdateDocument, options);
      }
export type MutateSiteMapsUpdateHookResult = ReturnType<typeof useMutateSiteMapsUpdate>;
export type MutateSiteMapsUpdateMutationResult = Apollo.MutationResult<MutateSiteMapsUpdate>;
export type MutateSiteMapsUpdateMutationOptions = Apollo.BaseMutationOptions<MutateSiteMapsUpdate, MutateSiteMapsUpdateVariables>;
export const MutateZoneCreateDocument = gql`
    mutation MutateZoneCreate($input: CreateZoneInput!) {
  zones {
    create(input: $input) {
      ...FragmentZone
    }
  }
}
    ${FragmentZone}`;
export type MutateZoneCreateMutationFn = Apollo.MutationFunction<MutateZoneCreate, MutateZoneCreateVariables>;

/**
 * __useMutateZoneCreate__
 *
 * To run a mutation, you first call `useMutateZoneCreate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateZoneCreate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateZoneCreate, { data, loading, error }] = useMutateZoneCreate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutateZoneCreate(baseOptions?: Apollo.MutationHookOptions<MutateZoneCreate, MutateZoneCreateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateZoneCreate, MutateZoneCreateVariables>(MutateZoneCreateDocument, options);
      }
export type MutateZoneCreateHookResult = ReturnType<typeof useMutateZoneCreate>;
export type MutateZoneCreateMutationResult = Apollo.MutationResult<MutateZoneCreate>;
export type MutateZoneCreateMutationOptions = Apollo.BaseMutationOptions<MutateZoneCreate, MutateZoneCreateVariables>;
export const MutateZoneUpdateDocument = gql`
    mutation MutateZoneUpdate($input: UpdateZoneInput!) {
  zones {
    update(input: $input) {
      ...FragmentZone
    }
  }
}
    ${FragmentZone}`;
export type MutateZoneUpdateMutationFn = Apollo.MutationFunction<MutateZoneUpdate, MutateZoneUpdateVariables>;

/**
 * __useMutateZoneUpdate__
 *
 * To run a mutation, you first call `useMutateZoneUpdate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateZoneUpdate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateZoneUpdate, { data, loading, error }] = useMutateZoneUpdate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMutateZoneUpdate(baseOptions?: Apollo.MutationHookOptions<MutateZoneUpdate, MutateZoneUpdateVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateZoneUpdate, MutateZoneUpdateVariables>(MutateZoneUpdateDocument, options);
      }
export type MutateZoneUpdateHookResult = ReturnType<typeof useMutateZoneUpdate>;
export type MutateZoneUpdateMutationResult = Apollo.MutationResult<MutateZoneUpdate>;
export type MutateZoneUpdateMutationOptions = Apollo.BaseMutationOptions<MutateZoneUpdate, MutateZoneUpdateVariables>;
export const MutateZoneDeleteDocument = gql`
    mutation MutateZoneDelete($id: ID!) {
  zones {
    delete(id: $id) {
      id
    }
  }
}
    `;
export type MutateZoneDeleteMutationFn = Apollo.MutationFunction<MutateZoneDelete, MutateZoneDeleteVariables>;

/**
 * __useMutateZoneDelete__
 *
 * To run a mutation, you first call `useMutateZoneDelete` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateZoneDelete` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateZoneDelete, { data, loading, error }] = useMutateZoneDelete({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMutateZoneDelete(baseOptions?: Apollo.MutationHookOptions<MutateZoneDelete, MutateZoneDeleteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateZoneDelete, MutateZoneDeleteVariables>(MutateZoneDeleteDocument, options);
      }
export type MutateZoneDeleteHookResult = ReturnType<typeof useMutateZoneDelete>;
export type MutateZoneDeleteMutationResult = Apollo.MutationResult<MutateZoneDelete>;
export type MutateZoneDeleteMutationOptions = Apollo.BaseMutationOptions<MutateZoneDelete, MutateZoneDeleteVariables>;
export const QueryFlowObjectivesDocument = gql`
    query QueryFlowObjectives($siteId: String!, $materialFlowId: String!, $timeRangeInput: TimeRangeInput!, $resolution: DailyScaleTimeUnit!, $timeZone: String) {
  materialFlowObjective {
    analytics {
      objectiveSummery(
        siteId: $siteId
        materialFlowId: $materialFlowId
        timeRangeInput: $timeRangeInput
        resolution: $resolution
        timeZone: $timeZone
      ) {
        ...FragmentObjectiveSummary
      }
    }
  }
}
    ${FragmentObjectiveSummary}`;

/**
 * __useQueryFlowObjectives__
 *
 * To run a query within a React component, call `useQueryFlowObjectives` and pass it any options that fit your needs.
 * When your component renders, `useQueryFlowObjectives` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFlowObjectives({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      materialFlowId: // value for 'materialFlowId'
 *      timeRangeInput: // value for 'timeRangeInput'
 *      resolution: // value for 'resolution'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useQueryFlowObjectives(baseOptions: Apollo.QueryHookOptions<QueryFlowObjectives, QueryFlowObjectivesVariables> & ({ variables: QueryFlowObjectivesVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFlowObjectives, QueryFlowObjectivesVariables>(QueryFlowObjectivesDocument, options);
      }
export function useQueryFlowObjectivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFlowObjectives, QueryFlowObjectivesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFlowObjectives, QueryFlowObjectivesVariables>(QueryFlowObjectivesDocument, options);
        }
export function useQueryFlowObjectivesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryFlowObjectives, QueryFlowObjectivesVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryFlowObjectives, QueryFlowObjectivesVariables>(QueryFlowObjectivesDocument, options);
        }
export type QueryFlowObjectivesHookResult = ReturnType<typeof useQueryFlowObjectives>;
export type QueryFlowObjectivesLazyQueryHookResult = ReturnType<typeof useQueryFlowObjectivesLazyQuery>;
export type QueryFlowObjectivesSuspenseQueryHookResult = ReturnType<typeof useQueryFlowObjectivesSuspenseQuery>;
export type QueryFlowObjectivesQueryResult = Apollo.QueryResult<QueryFlowObjectives, QueryFlowObjectivesVariables>;
export const QueryMaterialFlowsBySiteMapIdDocument = gql`
    query QueryMaterialFlowsBySiteMapId($siteId: ID!) {
  siteMaps {
    site(siteId: $siteId) {
      id
      siteMaterialFlows {
        edges {
          node {
            ...FragmentMaterialFlows
          }
        }
      }
    }
  }
}
    ${FragmentMaterialFlows}`;

/**
 * __useQueryMaterialFlowsBySiteMapId__
 *
 * To run a query within a React component, call `useQueryMaterialFlowsBySiteMapId` and pass it any options that fit your needs.
 * When your component renders, `useQueryMaterialFlowsBySiteMapId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMaterialFlowsBySiteMapId({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQueryMaterialFlowsBySiteMapId(baseOptions: Apollo.QueryHookOptions<QueryMaterialFlowsBySiteMapId, QueryMaterialFlowsBySiteMapIdVariables> & ({ variables: QueryMaterialFlowsBySiteMapIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryMaterialFlowsBySiteMapId, QueryMaterialFlowsBySiteMapIdVariables>(QueryMaterialFlowsBySiteMapIdDocument, options);
      }
export function useQueryMaterialFlowsBySiteMapIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryMaterialFlowsBySiteMapId, QueryMaterialFlowsBySiteMapIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryMaterialFlowsBySiteMapId, QueryMaterialFlowsBySiteMapIdVariables>(QueryMaterialFlowsBySiteMapIdDocument, options);
        }
export function useQueryMaterialFlowsBySiteMapIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryMaterialFlowsBySiteMapId, QueryMaterialFlowsBySiteMapIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryMaterialFlowsBySiteMapId, QueryMaterialFlowsBySiteMapIdVariables>(QueryMaterialFlowsBySiteMapIdDocument, options);
        }
export type QueryMaterialFlowsBySiteMapIdHookResult = ReturnType<typeof useQueryMaterialFlowsBySiteMapId>;
export type QueryMaterialFlowsBySiteMapIdLazyQueryHookResult = ReturnType<typeof useQueryMaterialFlowsBySiteMapIdLazyQuery>;
export type QueryMaterialFlowsBySiteMapIdSuspenseQueryHookResult = ReturnType<typeof useQueryMaterialFlowsBySiteMapIdSuspenseQuery>;
export type QueryMaterialFlowsBySiteMapIdQueryResult = Apollo.QueryResult<QueryMaterialFlowsBySiteMapId, QueryMaterialFlowsBySiteMapIdVariables>;
export const QueryImageUploadsBySiteIdDocument = gql`
    query QueryImageUploadsBySiteId($siteId: ID!) {
  siteMaps {
    site(siteId: $siteId) {
      id
      mapboxStyleId
      geographyPhotos {
        ...FragmentGeographyPhotos
      }
    }
  }
}
    ${FragmentGeographyPhotos}`;

/**
 * __useQueryImageUploadsBySiteId__
 *
 * To run a query within a React component, call `useQueryImageUploadsBySiteId` and pass it any options that fit your needs.
 * When your component renders, `useQueryImageUploadsBySiteId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryImageUploadsBySiteId({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQueryImageUploadsBySiteId(baseOptions: Apollo.QueryHookOptions<QueryImageUploadsBySiteId, QueryImageUploadsBySiteIdVariables> & ({ variables: QueryImageUploadsBySiteIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryImageUploadsBySiteId, QueryImageUploadsBySiteIdVariables>(QueryImageUploadsBySiteIdDocument, options);
      }
export function useQueryImageUploadsBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryImageUploadsBySiteId, QueryImageUploadsBySiteIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryImageUploadsBySiteId, QueryImageUploadsBySiteIdVariables>(QueryImageUploadsBySiteIdDocument, options);
        }
export function useQueryImageUploadsBySiteIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryImageUploadsBySiteId, QueryImageUploadsBySiteIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryImageUploadsBySiteId, QueryImageUploadsBySiteIdVariables>(QueryImageUploadsBySiteIdDocument, options);
        }
export type QueryImageUploadsBySiteIdHookResult = ReturnType<typeof useQueryImageUploadsBySiteId>;
export type QueryImageUploadsBySiteIdLazyQueryHookResult = ReturnType<typeof useQueryImageUploadsBySiteIdLazyQuery>;
export type QueryImageUploadsBySiteIdSuspenseQueryHookResult = ReturnType<typeof useQueryImageUploadsBySiteIdSuspenseQuery>;
export type QueryImageUploadsBySiteIdQueryResult = Apollo.QueryResult<QueryImageUploadsBySiteId, QueryImageUploadsBySiteIdVariables>;
export const QueryMachineDocument = gql`
    query QueryMachine($id: ID!) {
  siteMachines {
    machine(machineId: $id) {
      ...FragmentMachine
    }
  }
}
    ${FragmentMachine}`;

/**
 * __useQueryMachine__
 *
 * To run a query within a React component, call `useQueryMachine` and pass it any options that fit your needs.
 * When your component renders, `useQueryMachine` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMachine({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryMachine(baseOptions: Apollo.QueryHookOptions<QueryMachine, QueryMachineVariables> & ({ variables: QueryMachineVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryMachine, QueryMachineVariables>(QueryMachineDocument, options);
      }
export function useQueryMachineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryMachine, QueryMachineVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryMachine, QueryMachineVariables>(QueryMachineDocument, options);
        }
export function useQueryMachineSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryMachine, QueryMachineVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryMachine, QueryMachineVariables>(QueryMachineDocument, options);
        }
export type QueryMachineHookResult = ReturnType<typeof useQueryMachine>;
export type QueryMachineLazyQueryHookResult = ReturnType<typeof useQueryMachineLazyQuery>;
export type QueryMachineSuspenseQueryHookResult = ReturnType<typeof useQueryMachineSuspenseQuery>;
export type QueryMachineQueryResult = Apollo.QueryResult<QueryMachine, QueryMachineVariables>;
export const QueryMachineTypesDocument = gql`
    query QueryMachineTypes {
  siteMachineTypes {
    machineTypes {
      ...FragmentMachineType
    }
  }
}
    ${FragmentMachineType}`;

/**
 * __useQueryMachineTypes__
 *
 * To run a query within a React component, call `useQueryMachineTypes` and pass it any options that fit your needs.
 * When your component renders, `useQueryMachineTypes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMachineTypes({
 *   variables: {
 *   },
 * });
 */
export function useQueryMachineTypes(baseOptions?: Apollo.QueryHookOptions<QueryMachineTypes, QueryMachineTypesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryMachineTypes, QueryMachineTypesVariables>(QueryMachineTypesDocument, options);
      }
export function useQueryMachineTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryMachineTypes, QueryMachineTypesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryMachineTypes, QueryMachineTypesVariables>(QueryMachineTypesDocument, options);
        }
export function useQueryMachineTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryMachineTypes, QueryMachineTypesVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryMachineTypes, QueryMachineTypesVariables>(QueryMachineTypesDocument, options);
        }
export type QueryMachineTypesHookResult = ReturnType<typeof useQueryMachineTypes>;
export type QueryMachineTypesLazyQueryHookResult = ReturnType<typeof useQueryMachineTypesLazyQuery>;
export type QueryMachineTypesSuspenseQueryHookResult = ReturnType<typeof useQueryMachineTypesSuspenseQuery>;
export type QueryMachineTypesQueryResult = Apollo.QueryResult<QueryMachineTypes, QueryMachineTypesVariables>;
export const QueryMachinesBySiteIdDocument = gql`
    query QueryMachinesBySiteId($siteId: ID!) {
  siteMachines {
    machinesBySiteId(siteId: $siteId) {
      ...FragmentMachine
    }
  }
}
    ${FragmentMachine}`;

/**
 * __useQueryMachinesBySiteId__
 *
 * To run a query within a React component, call `useQueryMachinesBySiteId` and pass it any options that fit your needs.
 * When your component renders, `useQueryMachinesBySiteId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMachinesBySiteId({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQueryMachinesBySiteId(baseOptions: Apollo.QueryHookOptions<QueryMachinesBySiteId, QueryMachinesBySiteIdVariables> & ({ variables: QueryMachinesBySiteIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryMachinesBySiteId, QueryMachinesBySiteIdVariables>(QueryMachinesBySiteIdDocument, options);
      }
export function useQueryMachinesBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryMachinesBySiteId, QueryMachinesBySiteIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryMachinesBySiteId, QueryMachinesBySiteIdVariables>(QueryMachinesBySiteIdDocument, options);
        }
export function useQueryMachinesBySiteIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryMachinesBySiteId, QueryMachinesBySiteIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryMachinesBySiteId, QueryMachinesBySiteIdVariables>(QueryMachinesBySiteIdDocument, options);
        }
export type QueryMachinesBySiteIdHookResult = ReturnType<typeof useQueryMachinesBySiteId>;
export type QueryMachinesBySiteIdLazyQueryHookResult = ReturnType<typeof useQueryMachinesBySiteIdLazyQuery>;
export type QueryMachinesBySiteIdSuspenseQueryHookResult = ReturnType<typeof useQueryMachinesBySiteIdSuspenseQuery>;
export type QueryMachinesBySiteIdQueryResult = Apollo.QueryResult<QueryMachinesBySiteId, QueryMachinesBySiteIdVariables>;
export const QueryMaterialBalanceByZoneIdDocument = gql`
    query QueryMaterialBalanceByZoneId($zoneId: ID!, $fromDate: DateTime, $toDate: DateTime) {
  zoneMaterialBalance {
    byZoneId(zoneId: $zoneId, options: {fromDate: $fromDate, toDate: $toDate}) {
      ...FragmentMaterialBalance
    }
  }
}
    ${FragmentMaterialBalance}`;

/**
 * __useQueryMaterialBalanceByZoneId__
 *
 * To run a query within a React component, call `useQueryMaterialBalanceByZoneId` and pass it any options that fit your needs.
 * When your component renders, `useQueryMaterialBalanceByZoneId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMaterialBalanceByZoneId({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useQueryMaterialBalanceByZoneId(baseOptions: Apollo.QueryHookOptions<QueryMaterialBalanceByZoneId, QueryMaterialBalanceByZoneIdVariables> & ({ variables: QueryMaterialBalanceByZoneIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryMaterialBalanceByZoneId, QueryMaterialBalanceByZoneIdVariables>(QueryMaterialBalanceByZoneIdDocument, options);
      }
export function useQueryMaterialBalanceByZoneIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryMaterialBalanceByZoneId, QueryMaterialBalanceByZoneIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryMaterialBalanceByZoneId, QueryMaterialBalanceByZoneIdVariables>(QueryMaterialBalanceByZoneIdDocument, options);
        }
export function useQueryMaterialBalanceByZoneIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryMaterialBalanceByZoneId, QueryMaterialBalanceByZoneIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryMaterialBalanceByZoneId, QueryMaterialBalanceByZoneIdVariables>(QueryMaterialBalanceByZoneIdDocument, options);
        }
export type QueryMaterialBalanceByZoneIdHookResult = ReturnType<typeof useQueryMaterialBalanceByZoneId>;
export type QueryMaterialBalanceByZoneIdLazyQueryHookResult = ReturnType<typeof useQueryMaterialBalanceByZoneIdLazyQuery>;
export type QueryMaterialBalanceByZoneIdSuspenseQueryHookResult = ReturnType<typeof useQueryMaterialBalanceByZoneIdSuspenseQuery>;
export type QueryMaterialBalanceByZoneIdQueryResult = Apollo.QueryResult<QueryMaterialBalanceByZoneId, QueryMaterialBalanceByZoneIdVariables>;
export const QueryMaterialFamiliesBySiteIdDocument = gql`
    query QueryMaterialFamiliesBySiteId($siteId: ID!) {
  materialFamilies {
    materialFamiliesBySiteId(siteId: $siteId) {
      ...FragmentMaterialFamilyWithMaterialTypes
    }
  }
}
    ${FragmentMaterialFamilyWithMaterialTypes}`;

/**
 * __useQueryMaterialFamiliesBySiteId__
 *
 * To run a query within a React component, call `useQueryMaterialFamiliesBySiteId` and pass it any options that fit your needs.
 * When your component renders, `useQueryMaterialFamiliesBySiteId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMaterialFamiliesBySiteId({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQueryMaterialFamiliesBySiteId(baseOptions: Apollo.QueryHookOptions<QueryMaterialFamiliesBySiteId, QueryMaterialFamiliesBySiteIdVariables> & ({ variables: QueryMaterialFamiliesBySiteIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryMaterialFamiliesBySiteId, QueryMaterialFamiliesBySiteIdVariables>(QueryMaterialFamiliesBySiteIdDocument, options);
      }
export function useQueryMaterialFamiliesBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryMaterialFamiliesBySiteId, QueryMaterialFamiliesBySiteIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryMaterialFamiliesBySiteId, QueryMaterialFamiliesBySiteIdVariables>(QueryMaterialFamiliesBySiteIdDocument, options);
        }
export function useQueryMaterialFamiliesBySiteIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryMaterialFamiliesBySiteId, QueryMaterialFamiliesBySiteIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryMaterialFamiliesBySiteId, QueryMaterialFamiliesBySiteIdVariables>(QueryMaterialFamiliesBySiteIdDocument, options);
        }
export type QueryMaterialFamiliesBySiteIdHookResult = ReturnType<typeof useQueryMaterialFamiliesBySiteId>;
export type QueryMaterialFamiliesBySiteIdLazyQueryHookResult = ReturnType<typeof useQueryMaterialFamiliesBySiteIdLazyQuery>;
export type QueryMaterialFamiliesBySiteIdSuspenseQueryHookResult = ReturnType<typeof useQueryMaterialFamiliesBySiteIdSuspenseQuery>;
export type QueryMaterialFamiliesBySiteIdQueryResult = Apollo.QueryResult<QueryMaterialFamiliesBySiteId, QueryMaterialFamiliesBySiteIdVariables>;
export const QueryMaterialVariantsByIdDocument = gql`
    query QueryMaterialVariantsById($id: ID!) {
  materialVariants {
    materialVariant(materialVariantId: $id) {
      ...FragmentMaterialVariantWithMaterialType
    }
  }
}
    ${FragmentMaterialVariantWithMaterialType}`;

/**
 * __useQueryMaterialVariantsById__
 *
 * To run a query within a React component, call `useQueryMaterialVariantsById` and pass it any options that fit your needs.
 * When your component renders, `useQueryMaterialVariantsById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMaterialVariantsById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryMaterialVariantsById(baseOptions: Apollo.QueryHookOptions<QueryMaterialVariantsById, QueryMaterialVariantsByIdVariables> & ({ variables: QueryMaterialVariantsByIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryMaterialVariantsById, QueryMaterialVariantsByIdVariables>(QueryMaterialVariantsByIdDocument, options);
      }
export function useQueryMaterialVariantsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryMaterialVariantsById, QueryMaterialVariantsByIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryMaterialVariantsById, QueryMaterialVariantsByIdVariables>(QueryMaterialVariantsByIdDocument, options);
        }
export function useQueryMaterialVariantsByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryMaterialVariantsById, QueryMaterialVariantsByIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryMaterialVariantsById, QueryMaterialVariantsByIdVariables>(QueryMaterialVariantsByIdDocument, options);
        }
export type QueryMaterialVariantsByIdHookResult = ReturnType<typeof useQueryMaterialVariantsById>;
export type QueryMaterialVariantsByIdLazyQueryHookResult = ReturnType<typeof useQueryMaterialVariantsByIdLazyQuery>;
export type QueryMaterialVariantsByIdSuspenseQueryHookResult = ReturnType<typeof useQueryMaterialVariantsByIdSuspenseQuery>;
export type QueryMaterialVariantsByIdQueryResult = Apollo.QueryResult<QueryMaterialVariantsById, QueryMaterialVariantsByIdVariables>;
export const QueryZoneMaterialVariantsBySiteMapIdDocument = gql`
    query QueryZoneMaterialVariantsBySiteMapId($siteMapId: ID!) {
  zones {
    bySiteMapId(siteMapId: $siteMapId) {
      id
      zoneMaterialVariants {
        id
        materialVariantId
        materialVariant {
          id
          name
          materialType {
            id
            name
            materialFamily {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQueryZoneMaterialVariantsBySiteMapId__
 *
 * To run a query within a React component, call `useQueryZoneMaterialVariantsBySiteMapId` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoneMaterialVariantsBySiteMapId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoneMaterialVariantsBySiteMapId({
 *   variables: {
 *      siteMapId: // value for 'siteMapId'
 *   },
 * });
 */
export function useQueryZoneMaterialVariantsBySiteMapId(baseOptions: Apollo.QueryHookOptions<QueryZoneMaterialVariantsBySiteMapId, QueryZoneMaterialVariantsBySiteMapIdVariables> & ({ variables: QueryZoneMaterialVariantsBySiteMapIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryZoneMaterialVariantsBySiteMapId, QueryZoneMaterialVariantsBySiteMapIdVariables>(QueryZoneMaterialVariantsBySiteMapIdDocument, options);
      }
export function useQueryZoneMaterialVariantsBySiteMapIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryZoneMaterialVariantsBySiteMapId, QueryZoneMaterialVariantsBySiteMapIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryZoneMaterialVariantsBySiteMapId, QueryZoneMaterialVariantsBySiteMapIdVariables>(QueryZoneMaterialVariantsBySiteMapIdDocument, options);
        }
export function useQueryZoneMaterialVariantsBySiteMapIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryZoneMaterialVariantsBySiteMapId, QueryZoneMaterialVariantsBySiteMapIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryZoneMaterialVariantsBySiteMapId, QueryZoneMaterialVariantsBySiteMapIdVariables>(QueryZoneMaterialVariantsBySiteMapIdDocument, options);
        }
export type QueryZoneMaterialVariantsBySiteMapIdHookResult = ReturnType<typeof useQueryZoneMaterialVariantsBySiteMapId>;
export type QueryZoneMaterialVariantsBySiteMapIdLazyQueryHookResult = ReturnType<typeof useQueryZoneMaterialVariantsBySiteMapIdLazyQuery>;
export type QueryZoneMaterialVariantsBySiteMapIdSuspenseQueryHookResult = ReturnType<typeof useQueryZoneMaterialVariantsBySiteMapIdSuspenseQuery>;
export type QueryZoneMaterialVariantsBySiteMapIdQueryResult = Apollo.QueryResult<QueryZoneMaterialVariantsBySiteMapId, QueryZoneMaterialVariantsBySiteMapIdVariables>;
export const QueryPointOfInterestTypesDocument = gql`
    query QueryPointOfInterestTypes {
  pointOfInterestTypes {
    all {
      ...FragmentPointOfInterestType
    }
  }
}
    ${FragmentPointOfInterestType}`;

/**
 * __useQueryPointOfInterestTypes__
 *
 * To run a query within a React component, call `useQueryPointOfInterestTypes` and pass it any options that fit your needs.
 * When your component renders, `useQueryPointOfInterestTypes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPointOfInterestTypes({
 *   variables: {
 *   },
 * });
 */
export function useQueryPointOfInterestTypes(baseOptions?: Apollo.QueryHookOptions<QueryPointOfInterestTypes, QueryPointOfInterestTypesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryPointOfInterestTypes, QueryPointOfInterestTypesVariables>(QueryPointOfInterestTypesDocument, options);
      }
export function useQueryPointOfInterestTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryPointOfInterestTypes, QueryPointOfInterestTypesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryPointOfInterestTypes, QueryPointOfInterestTypesVariables>(QueryPointOfInterestTypesDocument, options);
        }
export function useQueryPointOfInterestTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryPointOfInterestTypes, QueryPointOfInterestTypesVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryPointOfInterestTypes, QueryPointOfInterestTypesVariables>(QueryPointOfInterestTypesDocument, options);
        }
export type QueryPointOfInterestTypesHookResult = ReturnType<typeof useQueryPointOfInterestTypes>;
export type QueryPointOfInterestTypesLazyQueryHookResult = ReturnType<typeof useQueryPointOfInterestTypesLazyQuery>;
export type QueryPointOfInterestTypesSuspenseQueryHookResult = ReturnType<typeof useQueryPointOfInterestTypesSuspenseQuery>;
export type QueryPointOfInterestTypesQueryResult = Apollo.QueryResult<QueryPointOfInterestTypes, QueryPointOfInterestTypesVariables>;
export const QuerySiteBySiteIdDocument = gql`
    query QuerySiteBySiteId($siteId: ID!) {
  sites {
    site(siteId: $siteId) {
      ...FragmentSite
    }
  }
}
    ${FragmentSite}`;

/**
 * __useQuerySiteBySiteId__
 *
 * To run a query within a React component, call `useQuerySiteBySiteId` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteBySiteId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteBySiteId({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQuerySiteBySiteId(baseOptions: Apollo.QueryHookOptions<QuerySiteBySiteId, QuerySiteBySiteIdVariables> & ({ variables: QuerySiteBySiteIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteBySiteId, QuerySiteBySiteIdVariables>(QuerySiteBySiteIdDocument, options);
      }
export function useQuerySiteBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteBySiteId, QuerySiteBySiteIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteBySiteId, QuerySiteBySiteIdVariables>(QuerySiteBySiteIdDocument, options);
        }
export function useQuerySiteBySiteIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteBySiteId, QuerySiteBySiteIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteBySiteId, QuerySiteBySiteIdVariables>(QuerySiteBySiteIdDocument, options);
        }
export type QuerySiteBySiteIdHookResult = ReturnType<typeof useQuerySiteBySiteId>;
export type QuerySiteBySiteIdLazyQueryHookResult = ReturnType<typeof useQuerySiteBySiteIdLazyQuery>;
export type QuerySiteBySiteIdSuspenseQueryHookResult = ReturnType<typeof useQuerySiteBySiteIdSuspenseQuery>;
export type QuerySiteBySiteIdQueryResult = Apollo.QueryResult<QuerySiteBySiteId, QuerySiteBySiteIdVariables>;
export const QuerySiteConfigurationsBySiteIdDocument = gql`
    query QuerySiteConfigurationsBySiteId($siteId: ID!) {
  sites {
    site(siteId: $siteId) {
      id
      configuration {
        ...FragmentSiteConfiguration
      }
    }
  }
}
    ${FragmentSiteConfiguration}`;

/**
 * __useQuerySiteConfigurationsBySiteId__
 *
 * To run a query within a React component, call `useQuerySiteConfigurationsBySiteId` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteConfigurationsBySiteId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteConfigurationsBySiteId({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQuerySiteConfigurationsBySiteId(baseOptions: Apollo.QueryHookOptions<QuerySiteConfigurationsBySiteId, QuerySiteConfigurationsBySiteIdVariables> & ({ variables: QuerySiteConfigurationsBySiteIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteConfigurationsBySiteId, QuerySiteConfigurationsBySiteIdVariables>(QuerySiteConfigurationsBySiteIdDocument, options);
      }
export function useQuerySiteConfigurationsBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteConfigurationsBySiteId, QuerySiteConfigurationsBySiteIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteConfigurationsBySiteId, QuerySiteConfigurationsBySiteIdVariables>(QuerySiteConfigurationsBySiteIdDocument, options);
        }
export function useQuerySiteConfigurationsBySiteIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteConfigurationsBySiteId, QuerySiteConfigurationsBySiteIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteConfigurationsBySiteId, QuerySiteConfigurationsBySiteIdVariables>(QuerySiteConfigurationsBySiteIdDocument, options);
        }
export type QuerySiteConfigurationsBySiteIdHookResult = ReturnType<typeof useQuerySiteConfigurationsBySiteId>;
export type QuerySiteConfigurationsBySiteIdLazyQueryHookResult = ReturnType<typeof useQuerySiteConfigurationsBySiteIdLazyQuery>;
export type QuerySiteConfigurationsBySiteIdSuspenseQueryHookResult = ReturnType<typeof useQuerySiteConfigurationsBySiteIdSuspenseQuery>;
export type QuerySiteConfigurationsBySiteIdQueryResult = Apollo.QueryResult<QuerySiteConfigurationsBySiteId, QuerySiteConfigurationsBySiteIdVariables>;
export const QuerySiteDeviceEventsDocument = gql`
    query QuerySiteDeviceEvents($siteId: ID!, $filterBy: SiteDeviceEventFilterInput, $orderBy: SiteDeviceEventSort!, $cursor: String, $first: Int!) {
  siteDeviceEventQueries {
    siteDeviceEvents(
      siteId: $siteId
      filterBy: $filterBy
      orderBy: $orderBy
      first: $first
      after: $cursor
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        ...FragmentSiteDeviceEvents
        cursor
      }
    }
  }
}
    ${FragmentSiteDeviceEvents}`;

/**
 * __useQuerySiteDeviceEvents__
 *
 * To run a query within a React component, call `useQuerySiteDeviceEvents` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteDeviceEvents` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteDeviceEvents({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQuerySiteDeviceEvents(baseOptions: Apollo.QueryHookOptions<QuerySiteDeviceEvents, QuerySiteDeviceEventsVariables> & ({ variables: QuerySiteDeviceEventsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteDeviceEvents, QuerySiteDeviceEventsVariables>(QuerySiteDeviceEventsDocument, options);
      }
export function useQuerySiteDeviceEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteDeviceEvents, QuerySiteDeviceEventsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteDeviceEvents, QuerySiteDeviceEventsVariables>(QuerySiteDeviceEventsDocument, options);
        }
export function useQuerySiteDeviceEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteDeviceEvents, QuerySiteDeviceEventsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteDeviceEvents, QuerySiteDeviceEventsVariables>(QuerySiteDeviceEventsDocument, options);
        }
export type QuerySiteDeviceEventsHookResult = ReturnType<typeof useQuerySiteDeviceEvents>;
export type QuerySiteDeviceEventsLazyQueryHookResult = ReturnType<typeof useQuerySiteDeviceEventsLazyQuery>;
export type QuerySiteDeviceEventsSuspenseQueryHookResult = ReturnType<typeof useQuerySiteDeviceEventsSuspenseQuery>;
export type QuerySiteDeviceEventsQueryResult = Apollo.QueryResult<QuerySiteDeviceEvents, QuerySiteDeviceEventsVariables>;
export const QuerySiteDeviceMachineEventsDocument = gql`
    query QuerySiteDeviceMachineEvents($siteId: String!, $machineId: String!, $filterBy: SiteDeviceMachineEventFilterInput, $orderBy: SiteDeviceEventSort!, $cursor: String, $first: Int!) {
  siteDeviceEventQueries {
    siteDeviceMachineEvents(
      first: $first
      after: $cursor
      siteId: $siteId
      machineId: $machineId
      filterBy: $filterBy
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        ...FragmentSiteDeviceEvents
        cursor
      }
    }
  }
}
    ${FragmentSiteDeviceEvents}`;

/**
 * __useQuerySiteDeviceMachineEvents__
 *
 * To run a query within a React component, call `useQuerySiteDeviceMachineEvents` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteDeviceMachineEvents` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteDeviceMachineEvents({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      machineId: // value for 'machineId'
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQuerySiteDeviceMachineEvents(baseOptions: Apollo.QueryHookOptions<QuerySiteDeviceMachineEvents, QuerySiteDeviceMachineEventsVariables> & ({ variables: QuerySiteDeviceMachineEventsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteDeviceMachineEvents, QuerySiteDeviceMachineEventsVariables>(QuerySiteDeviceMachineEventsDocument, options);
      }
export function useQuerySiteDeviceMachineEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteDeviceMachineEvents, QuerySiteDeviceMachineEventsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteDeviceMachineEvents, QuerySiteDeviceMachineEventsVariables>(QuerySiteDeviceMachineEventsDocument, options);
        }
export function useQuerySiteDeviceMachineEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteDeviceMachineEvents, QuerySiteDeviceMachineEventsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteDeviceMachineEvents, QuerySiteDeviceMachineEventsVariables>(QuerySiteDeviceMachineEventsDocument, options);
        }
export type QuerySiteDeviceMachineEventsHookResult = ReturnType<typeof useQuerySiteDeviceMachineEvents>;
export type QuerySiteDeviceMachineEventsLazyQueryHookResult = ReturnType<typeof useQuerySiteDeviceMachineEventsLazyQuery>;
export type QuerySiteDeviceMachineEventsSuspenseQueryHookResult = ReturnType<typeof useQuerySiteDeviceMachineEventsSuspenseQuery>;
export type QuerySiteDeviceMachineEventsQueryResult = Apollo.QueryResult<QuerySiteDeviceMachineEvents, QuerySiteDeviceMachineEventsVariables>;
export const QueryQuerySiteDeviceMaterialFlowEventsDocument = gql`
    query QueryQuerySiteDeviceMaterialFlowEvents($siteId: String!, $materialFlowId: String!, $filterBy: SiteDeviceMaterialFlowEventFilterInput, $orderBy: SiteDeviceEventSort) {
  siteDeviceEventQueries {
    siteDeviceMaterialFlowEvents(
      siteId: $siteId
      materialFlowId: $materialFlowId
      filterBy: $filterBy
      orderBy: $orderBy
    ) {
      edges {
        ...FragmentSiteDeviceEvents
        cursor
      }
    }
  }
}
    ${FragmentSiteDeviceEvents}`;

/**
 * __useQueryQuerySiteDeviceMaterialFlowEvents__
 *
 * To run a query within a React component, call `useQueryQuerySiteDeviceMaterialFlowEvents` and pass it any options that fit your needs.
 * When your component renders, `useQueryQuerySiteDeviceMaterialFlowEvents` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQuerySiteDeviceMaterialFlowEvents({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      materialFlowId: // value for 'materialFlowId'
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useQueryQuerySiteDeviceMaterialFlowEvents(baseOptions: Apollo.QueryHookOptions<QueryQuerySiteDeviceMaterialFlowEvents, QueryQuerySiteDeviceMaterialFlowEventsVariables> & ({ variables: QueryQuerySiteDeviceMaterialFlowEventsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryQuerySiteDeviceMaterialFlowEvents, QueryQuerySiteDeviceMaterialFlowEventsVariables>(QueryQuerySiteDeviceMaterialFlowEventsDocument, options);
      }
export function useQueryQuerySiteDeviceMaterialFlowEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryQuerySiteDeviceMaterialFlowEvents, QueryQuerySiteDeviceMaterialFlowEventsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryQuerySiteDeviceMaterialFlowEvents, QueryQuerySiteDeviceMaterialFlowEventsVariables>(QueryQuerySiteDeviceMaterialFlowEventsDocument, options);
        }
export function useQueryQuerySiteDeviceMaterialFlowEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryQuerySiteDeviceMaterialFlowEvents, QueryQuerySiteDeviceMaterialFlowEventsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryQuerySiteDeviceMaterialFlowEvents, QueryQuerySiteDeviceMaterialFlowEventsVariables>(QueryQuerySiteDeviceMaterialFlowEventsDocument, options);
        }
export type QueryQuerySiteDeviceMaterialFlowEventsHookResult = ReturnType<typeof useQueryQuerySiteDeviceMaterialFlowEvents>;
export type QueryQuerySiteDeviceMaterialFlowEventsLazyQueryHookResult = ReturnType<typeof useQueryQuerySiteDeviceMaterialFlowEventsLazyQuery>;
export type QueryQuerySiteDeviceMaterialFlowEventsSuspenseQueryHookResult = ReturnType<typeof useQueryQuerySiteDeviceMaterialFlowEventsSuspenseQuery>;
export type QueryQuerySiteDeviceMaterialFlowEventsQueryResult = Apollo.QueryResult<QueryQuerySiteDeviceMaterialFlowEvents, QueryQuerySiteDeviceMaterialFlowEventsVariables>;
export const QuerySiteLoadTicketsDocument = gql`
    query QuerySiteLoadTickets($siteId: ID!, $flowId: String, $fromZoneId: ID, $toZoneId: String, $from: DateTime, $to: DateTime, $cursor: String, $first: Int!, $orderBy: SiteLoadTicketsOrderBy, $system: String!) {
  siteLoadTickets {
    siteLoadTickets(
      filterBy: {siteId: $siteId, flowId: $flowId, from: $from, to: $to, fromZoneId: $fromZoneId, toZoneId: $toZoneId}
      orderBy: $orderBy
      first: $first
      after: $cursor
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...FragmentSiteLoadTicket
        }
      }
    }
  }
}
    ${FragmentSiteLoadTicket}`;

/**
 * __useQuerySiteLoadTickets__
 *
 * To run a query within a React component, call `useQuerySiteLoadTickets` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteLoadTickets` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteLoadTickets({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      flowId: // value for 'flowId'
 *      fromZoneId: // value for 'fromZoneId'
 *      toZoneId: // value for 'toZoneId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      system: // value for 'system'
 *   },
 * });
 */
export function useQuerySiteLoadTickets(baseOptions: Apollo.QueryHookOptions<QuerySiteLoadTickets, QuerySiteLoadTicketsVariables> & ({ variables: QuerySiteLoadTicketsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteLoadTickets, QuerySiteLoadTicketsVariables>(QuerySiteLoadTicketsDocument, options);
      }
export function useQuerySiteLoadTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteLoadTickets, QuerySiteLoadTicketsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteLoadTickets, QuerySiteLoadTicketsVariables>(QuerySiteLoadTicketsDocument, options);
        }
export function useQuerySiteLoadTicketsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteLoadTickets, QuerySiteLoadTicketsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteLoadTickets, QuerySiteLoadTicketsVariables>(QuerySiteLoadTicketsDocument, options);
        }
export type QuerySiteLoadTicketsHookResult = ReturnType<typeof useQuerySiteLoadTickets>;
export type QuerySiteLoadTicketsLazyQueryHookResult = ReturnType<typeof useQuerySiteLoadTicketsLazyQuery>;
export type QuerySiteLoadTicketsSuspenseQueryHookResult = ReturnType<typeof useQuerySiteLoadTicketsSuspenseQuery>;
export type QuerySiteLoadTicketsQueryResult = Apollo.QueryResult<QuerySiteLoadTickets, QuerySiteLoadTicketsVariables>;
export const QuerySiteLoadTicketsStatisticsDocument = gql`
    query QuerySiteLoadTicketsStatistics($granularity: StatisticsGranularity!, $field: SiteLoadTicketsStatisticsFields!, $from: DateTime!, $to: DateTime!, $filterBy: SiteLoadTicketsFilterBy, $cursor: String) {
  siteLoadTickets {
    statistics(
      granularity: $granularity
      field: $field
      from: $from
      to: $to
      filterBy: $filterBy
      after: $cursor
      first: 10
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ... on StatisticsNumericNode {
            sum {
              value
              unit
            }
            date
          }
        }
      }
    }
  }
}
    `;

/**
 * __useQuerySiteLoadTicketsStatistics__
 *
 * To run a query within a React component, call `useQuerySiteLoadTicketsStatistics` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteLoadTicketsStatistics` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteLoadTicketsStatistics({
 *   variables: {
 *      granularity: // value for 'granularity'
 *      field: // value for 'field'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filterBy: // value for 'filterBy'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useQuerySiteLoadTicketsStatistics(baseOptions: Apollo.QueryHookOptions<QuerySiteLoadTicketsStatistics, QuerySiteLoadTicketsStatisticsVariables> & ({ variables: QuerySiteLoadTicketsStatisticsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteLoadTicketsStatistics, QuerySiteLoadTicketsStatisticsVariables>(QuerySiteLoadTicketsStatisticsDocument, options);
      }
export function useQuerySiteLoadTicketsStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteLoadTicketsStatistics, QuerySiteLoadTicketsStatisticsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteLoadTicketsStatistics, QuerySiteLoadTicketsStatisticsVariables>(QuerySiteLoadTicketsStatisticsDocument, options);
        }
export function useQuerySiteLoadTicketsStatisticsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteLoadTicketsStatistics, QuerySiteLoadTicketsStatisticsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteLoadTicketsStatistics, QuerySiteLoadTicketsStatisticsVariables>(QuerySiteLoadTicketsStatisticsDocument, options);
        }
export type QuerySiteLoadTicketsStatisticsHookResult = ReturnType<typeof useQuerySiteLoadTicketsStatistics>;
export type QuerySiteLoadTicketsStatisticsLazyQueryHookResult = ReturnType<typeof useQuerySiteLoadTicketsStatisticsLazyQuery>;
export type QuerySiteLoadTicketsStatisticsSuspenseQueryHookResult = ReturnType<typeof useQuerySiteLoadTicketsStatisticsSuspenseQuery>;
export type QuerySiteLoadTicketsStatisticsQueryResult = Apollo.QueryResult<QuerySiteLoadTicketsStatistics, QuerySiteLoadTicketsStatisticsVariables>;
export const QuerySiteMapBySiteIdDocument = gql`
    query QuerySiteMapBySiteId($siteId: ID!) {
  siteMaps {
    site(siteId: $siteId) {
      ...FragmentSiteMap
    }
  }
}
    ${FragmentSiteMap}`;

/**
 * __useQuerySiteMapBySiteId__
 *
 * To run a query within a React component, call `useQuerySiteMapBySiteId` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteMapBySiteId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteMapBySiteId({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQuerySiteMapBySiteId(baseOptions: Apollo.QueryHookOptions<QuerySiteMapBySiteId, QuerySiteMapBySiteIdVariables> & ({ variables: QuerySiteMapBySiteIdVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteMapBySiteId, QuerySiteMapBySiteIdVariables>(QuerySiteMapBySiteIdDocument, options);
      }
export function useQuerySiteMapBySiteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteMapBySiteId, QuerySiteMapBySiteIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteMapBySiteId, QuerySiteMapBySiteIdVariables>(QuerySiteMapBySiteIdDocument, options);
        }
export function useQuerySiteMapBySiteIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteMapBySiteId, QuerySiteMapBySiteIdVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteMapBySiteId, QuerySiteMapBySiteIdVariables>(QuerySiteMapBySiteIdDocument, options);
        }
export type QuerySiteMapBySiteIdHookResult = ReturnType<typeof useQuerySiteMapBySiteId>;
export type QuerySiteMapBySiteIdLazyQueryHookResult = ReturnType<typeof useQuerySiteMapBySiteIdLazyQuery>;
export type QuerySiteMapBySiteIdSuspenseQueryHookResult = ReturnType<typeof useQuerySiteMapBySiteIdSuspenseQuery>;
export type QuerySiteMapBySiteIdQueryResult = Apollo.QueryResult<QuerySiteMapBySiteId, QuerySiteMapBySiteIdVariables>;
export const QuerySiteMetricDefinitionsDocument = gql`
    query QuerySiteMetricDefinitions($filterBy: SiteMetricDefinitionFilterBy, $siteId: ID!) {
  sites {
    site(siteId: $siteId) {
      siteMetricDefinitions(filterBy: $filterBy) {
        edges {
          node {
            ...FragmentSiteMetricDefinition
          }
        }
      }
    }
  }
}
    ${FragmentSiteMetricDefinition}`;

/**
 * __useQuerySiteMetricDefinitions__
 *
 * To run a query within a React component, call `useQuerySiteMetricDefinitions` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteMetricDefinitions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteMetricDefinitions({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQuerySiteMetricDefinitions(baseOptions: Apollo.QueryHookOptions<QuerySiteMetricDefinitions, QuerySiteMetricDefinitionsVariables> & ({ variables: QuerySiteMetricDefinitionsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteMetricDefinitions, QuerySiteMetricDefinitionsVariables>(QuerySiteMetricDefinitionsDocument, options);
      }
export function useQuerySiteMetricDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteMetricDefinitions, QuerySiteMetricDefinitionsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteMetricDefinitions, QuerySiteMetricDefinitionsVariables>(QuerySiteMetricDefinitionsDocument, options);
        }
export function useQuerySiteMetricDefinitionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteMetricDefinitions, QuerySiteMetricDefinitionsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteMetricDefinitions, QuerySiteMetricDefinitionsVariables>(QuerySiteMetricDefinitionsDocument, options);
        }
export type QuerySiteMetricDefinitionsHookResult = ReturnType<typeof useQuerySiteMetricDefinitions>;
export type QuerySiteMetricDefinitionsLazyQueryHookResult = ReturnType<typeof useQuerySiteMetricDefinitionsLazyQuery>;
export type QuerySiteMetricDefinitionsSuspenseQueryHookResult = ReturnType<typeof useQuerySiteMetricDefinitionsSuspenseQuery>;
export type QuerySiteMetricDefinitionsQueryResult = Apollo.QueryResult<QuerySiteMetricDefinitions, QuerySiteMetricDefinitionsVariables>;
export const QuerySiteOperatorsDocument = gql`
    query QuerySiteOperators($siteId: ID!) {
  siteOperators {
    operatorsBySiteId(siteId: $siteId) {
      id
      name
      phoneNumber
    }
  }
}
    `;

/**
 * __useQuerySiteOperators__
 *
 * To run a query within a React component, call `useQuerySiteOperators` and pass it any options that fit your needs.
 * When your component renders, `useQuerySiteOperators` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySiteOperators({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQuerySiteOperators(baseOptions: Apollo.QueryHookOptions<QuerySiteOperators, QuerySiteOperatorsVariables> & ({ variables: QuerySiteOperatorsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySiteOperators, QuerySiteOperatorsVariables>(QuerySiteOperatorsDocument, options);
      }
export function useQuerySiteOperatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySiteOperators, QuerySiteOperatorsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySiteOperators, QuerySiteOperatorsVariables>(QuerySiteOperatorsDocument, options);
        }
export function useQuerySiteOperatorsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuerySiteOperators, QuerySiteOperatorsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuerySiteOperators, QuerySiteOperatorsVariables>(QuerySiteOperatorsDocument, options);
        }
export type QuerySiteOperatorsHookResult = ReturnType<typeof useQuerySiteOperators>;
export type QuerySiteOperatorsLazyQueryHookResult = ReturnType<typeof useQuerySiteOperatorsLazyQuery>;
export type QuerySiteOperatorsSuspenseQueryHookResult = ReturnType<typeof useQuerySiteOperatorsSuspenseQuery>;
export type QuerySiteOperatorsQueryResult = Apollo.QueryResult<QuerySiteOperators, QuerySiteOperatorsVariables>;
export const QueryTripsDocument = gql`
    query QueryTrips($filterBy: TripFilterBy, $orderBy: TripFilterOrderBy, $first: Int, $after: String) {
  trips {
    trips(filterBy: $filterBy, orderBy: $orderBy, first: $first, after: $after) {
      totalCount
      edges {
        node {
          ...FragmentTrip
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
    ${FragmentTrip}`;

/**
 * __useQueryTrips__
 *
 * To run a query within a React component, call `useQueryTrips` and pass it any options that fit your needs.
 * When your component renders, `useQueryTrips` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTrips({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryTrips(baseOptions?: Apollo.QueryHookOptions<QueryTrips, QueryTripsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryTrips, QueryTripsVariables>(QueryTripsDocument, options);
      }
export function useQueryTripsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryTrips, QueryTripsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryTrips, QueryTripsVariables>(QueryTripsDocument, options);
        }
export function useQueryTripsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryTrips, QueryTripsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryTrips, QueryTripsVariables>(QueryTripsDocument, options);
        }
export type QueryTripsHookResult = ReturnType<typeof useQueryTrips>;
export type QueryTripsLazyQueryHookResult = ReturnType<typeof useQueryTripsLazyQuery>;
export type QueryTripsSuspenseQueryHookResult = ReturnType<typeof useQueryTripsSuspenseQuery>;
export type QueryTripsQueryResult = Apollo.QueryResult<QueryTrips, QueryTripsVariables>;
export const QueryZoneTypesAllDocument = gql`
    query QueryZoneTypesAll {
  zoneTypes {
    all {
      id
      type
    }
  }
}
    `;

/**
 * __useQueryZoneTypesAll__
 *
 * To run a query within a React component, call `useQueryZoneTypesAll` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoneTypesAll` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoneTypesAll({
 *   variables: {
 *   },
 * });
 */
export function useQueryZoneTypesAll(baseOptions?: Apollo.QueryHookOptions<QueryZoneTypesAll, QueryZoneTypesAllVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryZoneTypesAll, QueryZoneTypesAllVariables>(QueryZoneTypesAllDocument, options);
      }
export function useQueryZoneTypesAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryZoneTypesAll, QueryZoneTypesAllVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryZoneTypesAll, QueryZoneTypesAllVariables>(QueryZoneTypesAllDocument, options);
        }
export function useQueryZoneTypesAllSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryZoneTypesAll, QueryZoneTypesAllVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryZoneTypesAll, QueryZoneTypesAllVariables>(QueryZoneTypesAllDocument, options);
        }
export type QueryZoneTypesAllHookResult = ReturnType<typeof useQueryZoneTypesAll>;
export type QueryZoneTypesAllLazyQueryHookResult = ReturnType<typeof useQueryZoneTypesAllLazyQuery>;
export type QueryZoneTypesAllSuspenseQueryHookResult = ReturnType<typeof useQueryZoneTypesAllSuspenseQuery>;
export type QueryZoneTypesAllQueryResult = Apollo.QueryResult<QueryZoneTypesAll, QueryZoneTypesAllVariables>;
export const QueryZonesPoisAndFlowsDocument = gql`
    query QueryZonesPoisAndFlows($siteMapId: ID!, $siteId: ID!) {
  zones {
    bySiteMapId(siteMapId: $siteMapId) {
      ...FragmentZone
    }
  }
  pointsOfInterest {
    bySiteMapId(siteMapId: $siteMapId) {
      ...FragmentPointsOfInterest
    }
  }
  siteMaps {
    site(siteId: $siteId) {
      id
      siteMaterialFlows {
        edges {
          node {
            ...FragmentMaterialFlows
          }
        }
      }
    }
  }
}
    ${FragmentZone}
${FragmentPointsOfInterest}
${FragmentMaterialFlows}`;

/**
 * __useQueryZonesPoisAndFlows__
 *
 * To run a query within a React component, call `useQueryZonesPoisAndFlows` and pass it any options that fit your needs.
 * When your component renders, `useQueryZonesPoisAndFlows` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZonesPoisAndFlows({
 *   variables: {
 *      siteMapId: // value for 'siteMapId'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useQueryZonesPoisAndFlows(baseOptions: Apollo.QueryHookOptions<QueryZonesPoisAndFlows, QueryZonesPoisAndFlowsVariables> & ({ variables: QueryZonesPoisAndFlowsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryZonesPoisAndFlows, QueryZonesPoisAndFlowsVariables>(QueryZonesPoisAndFlowsDocument, options);
      }
export function useQueryZonesPoisAndFlowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryZonesPoisAndFlows, QueryZonesPoisAndFlowsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryZonesPoisAndFlows, QueryZonesPoisAndFlowsVariables>(QueryZonesPoisAndFlowsDocument, options);
        }
export function useQueryZonesPoisAndFlowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QueryZonesPoisAndFlows, QueryZonesPoisAndFlowsVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryZonesPoisAndFlows, QueryZonesPoisAndFlowsVariables>(QueryZonesPoisAndFlowsDocument, options);
        }
export type QueryZonesPoisAndFlowsHookResult = ReturnType<typeof useQueryZonesPoisAndFlows>;
export type QueryZonesPoisAndFlowsLazyQueryHookResult = ReturnType<typeof useQueryZonesPoisAndFlowsLazyQuery>;
export type QueryZonesPoisAndFlowsSuspenseQueryHookResult = ReturnType<typeof useQueryZonesPoisAndFlowsSuspenseQuery>;
export type QueryZonesPoisAndFlowsQueryResult = Apollo.QueryResult<QueryZonesPoisAndFlows, QueryZonesPoisAndFlowsVariables>;